import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_receiving_reportServiceInParams {
  orderId: number}

interface Iapp_custom_receiving_reportServiceData {
  Header?: { result?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string, PickupDate?: string, SealId?: string, TrailerId?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string } } }[], Project?: { LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string }, WarehouseContact?: { Id?: number, PreferredWarehouse?: { Id?: number, WarehousesContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }, OwnerContact?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, AccountContact?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, Email?: string }, OwnerContactLine1?: string, OwnerContactCity?: string, OwnerContactState?: string, OwnerContactPostalCode?: string } }
  Lines?: { result?: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackaged?: { ShortName?: string } }[], InventoryMeasurementUnit?: { ShortName?: string }, Material?: { ControllerTypeId?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_receiving_reportService extends ReportBaseService<Iapp_custom_receiving_reportServiceInParams, Iapp_custom_receiving_reportServiceData> {

  protected reportReferenceName = 'custom_receiving_report';
  protected appReferenceName = 'app';

  constructor(
    $utils: UtilsService,
    private $datasources: app_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_receiving_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_receiving_reportServiceInParams): Promise<Iapp_custom_receiving_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: Iapp_custom_receiving_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_receiving_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.app.custom_ds_receiving_header_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_receiving_details_report.get(dsParams);
      
      data.Lines = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
