import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService } from './Manufacturing.manufacturing_order_lines_components_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_materials_by_project_dd_singleComponent } from './Manufacturing.materials_by_project_dd_single.component'
import { Manufacturing_material_packagings_dd_singleComponent } from './Manufacturing.material_packagings_dd_single.component'


interface IManufacturing_manufacturing_order_lines_components_gridComponentEntity {
  Id?: number, Amount?: number, FinishedGoodLineId?: number, LineNumber?: number, MaterialId?: number, OrderId?: number, PackagedAmount?: number, PackagingId?: number, ParentId?: number, StatusId?: number, FinishedGoodLine?: { Amount?: number, LineNumber?: number, MaterialId?: number, PackagedAmount?: number }, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, Name?: string, PackagingLookups?: { PackagingId?: number, Packaging?: { ShortName?: string } }[], InventoryByWarehouseMaterial?: { MaterialId?: number, PackagedId?: number, CurrentlyAvailablePackagedAmount?: number }[] }, Status?: { Name?: string }}

interface IManufacturing_manufacturing_order_lines_components_gridComponentInParams {
  finished_good_id: number, warehouse_id: number, finished_good_quantity?: number, is_kit?: boolean, project_id: number, order_id: number, line_number: number, order_status_id: number}


class Manufacturing_manufacturing_order_lines_components_gridComponentRowModel extends GridRowModel {
  grid: Manufacturing_manufacturing_order_lines_components_gridComponent;
  entity: IManufacturing_manufacturing_order_lines_components_gridComponentEntity;


  options: { substitutions_swap: ButtonModel, delete_component: ButtonModel, undo_swap: ButtonModel } = {
    substitutions_swap: new ButtonModel('substitutions_swap', new ButtonStyles(null, null), true, false, false, 'Swap with substitute', '', null)
,delete_component: new ButtonModel('delete_component', new ButtonStyles(null, null), false, false, false, 'Delete', '', null)
,undo_swap: new ButtonModel('undo_swap', new ButtonStyles(null, null), true, false, false, 'Undo swap', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    component_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    initial_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    needed_quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    linenumber: null,
    component: null,
    initial: null,
    needed_quantity: null,
    packaging: null,
    availability: null,
    onhandinventory: null,
    substitutions: null,
  }

  get $fields_component_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.component?.editControl?.value;
    
    return expr;
  }


  constructor(
    grid: Manufacturing_manufacturing_order_lines_components_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.linenumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.linenumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.component = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.component,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['component_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.initial = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.initial,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['initial_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.needed_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.needed_quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['needed_quantity_edit'] as DatexFormControl, null, true, false, '', '', null)
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      );
    
    this.cells.availability = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.availability,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.onhandinventory = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.onhandinventory,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.substitutions = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.substitutions,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IManufacturing_manufacturing_order_lines_components_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IManufacturing_manufacturing_order_lines_components_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      finished_good_id:  $grid.inParams.finished_good_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IManufacturing_manufacturing_order_lines_components_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity?.LineNumber?.toString();
    (this.cells.component.displayControl as TextModel).text = $row.entity?.Material?.Name + "-" + $row.entity?.Material?.Description;
    (this.cells.component.editControl as SelectBoxModel).reset($row.entity?.Material?.Id);
    (this.cells.initial.displayControl as TextModel).text = $row.entity?.Amount?.toString();
    (this.cells.initial.editControl as NumberBoxModel).reset($row.entity?.Amount);
    (this.cells.needed_quantity.displayControl as TextModel).text = ($row.entity?.Amount * $grid.inParams.finished_good_quantity).toString();
    (this.cells.packaging.displayControl as TextModel).text = $row.entity?.PackagingId > 0 ? $row.entity?.Material?.PackagingLookups?.find(x => x?.PackagingId == $row.entity?.PackagingId)?.Packaging?.ShortName : "";
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.PackagingId);
    (this.cells.onhandinventory.displayControl as TextModel).text = `${$utils.isDefined($row.entity?.Material?.InventoryByWarehouseMaterial?.find(x => x?.MaterialId == $row.entity?.MaterialId)?.CurrentlyAvailablePackagedAmount) ? $row.entity?.Material?.InventoryByWarehouseMaterial?.find(x => x?.MaterialId == $row.entity?.MaterialId)?.CurrentlyAvailablePackagedAmount : 0} ${$utils.isDefined($row.entity?.Material?.PackagingLookups?.find(x => x?.PackagingId == $row.entity?.PackagingId)?.Packaging?.ShortName) ? $row.entity.Material.PackagingLookups?.find(x => x.PackagingId == $row.entity.PackagingId)?.Packaging?.ShortName : ""}`;

    await this.on_row_init();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_component_add();
      this.isNew = false;
    } else {
      await this.on_component_update();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_row_init');
  const onHandInventory = $row.entity?.Material?.InventoryByWarehouseMaterial?.find(x => x.MaterialId == $row.entity.MaterialId && x.PackagedId == $row.entity?.PackagingId)?.CurrentlyAvailablePackagedAmount;
  
  if( $utils.isDefined(onHandInventory) )
  {
      if( onHandInventory <= 0)
      {
          $row.cells.availability.styles.setStyle('color', 'rgb(217, 0, 0)');
          $row.cells.availability.displayControl.text = "\u26A0 Insufficient";
      }
      else if( $row.entity?.Amount * $grid.inParams.finished_good_quantity >= onHandInventory )
      {
          $row.cells.availability.styles.setStyle('color', 'rgb(224, 134, 0)');
          $row.cells.availability.displayControl.text = "\u26A0 Limited";
      }
      else
      {
          $row.cells.availability.styles.setStyle('color', 'rgb(65, 193, 23)');
          $row.cells.availability.displayControl.text = "\u2713 Sufficient";
      }
  }
  else
  {
      $row.cells.availability.styles.setStyle('color', 'rgb(217, 0, 0)');
      $row.cells.availability.displayControl.text = "\u26A0 Insufficient";
  }
  
  if( $grid.inParams.is_kit == false )
  {
      $grid.headers.substitutions.hidden = true;
  }
  }
  on_component_delete(event = null) {
    return this.on_component_deleteInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_component_deleteInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_component_delete');
  
  var deleteAction = await $flows.Utilities.crud_delete_flow({
      id: $row.entity.Id,
      entitySet: "ManufacturingOrderLines"
  });
  
  if ($utils.isDefined(deleteAction.reason)) {
      $shell.Utilities.openErrorDialog('Delete', `Component was not deleted: ${deleteAction.reason}`);
  } else {
      await $grid.refresh();
  
      if( $utils.isDefined($grid.vars.swapped_materials) )
      {
          const swappedItemIndex = $grid.vars.swapped_materials.findIndex( x => x.lineNumber == $row.entity.LineNumber);
  
          if($utils.isDefined(swappedItemIndex))
              $grid.vars.swapped_materials.splice(swappedItemIndex, 1);
      }
  }
  }
  on_component_add(event = null) {
    return this.on_component_addInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_component_addInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_component_add');
  
  const packagingId = $row.cells.packaging.editControl.value;
  const materialId = $row.cells.component.editControl.value;
  const packaging = (await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({
  	material_id: materialId,
  	packaging_id: packagingId
  }))?.result[0] ?? undefined;
  
  const entity = {
      OrderId: $grid.inParams.order_id,
      OrderLineTypeId: 2, //2	Component/1	Finished Good/3	Work in Progress
      FinishedGoodLineId: $grid.inParams.finished_good_id,
      StatusId: 1, //3	Batching/2	Canceled/1	Created/6	Incoming/5	Processing/4	Reserved/
      LineNumber: $grid.rows.length,
      MaterialId: materialId,
      PackagingId: packagingId,
      PackagedAmount: $row.cells.initial.editControl.value,
      Amount: $row.cells.initial.editControl.value * (packaging?.BasePackagingId ?? 0),
      Version: 'AAAAAA6M4Gc='
  }
  
  const addComponent = (await $flows.Utilities.crud_create_flow({
      entitySet: "ManufacturingOrderLines",
      entity: entity
  }));
  
  if (addComponent.reason) {
      $shell.Utilities.openErrorDialog('Save', 'Unable to add component.');
  } else {
      $grid.refresh();
  }
  
  
  }
  on_component_update(event = null) {
    return this.on_component_updateInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_component_updateInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_component_update');
  
  if (!$row.cells.component.editControl.isChanged
      && !$row.cells.initial.editControl.isChanged
      && !$row.cells.packaging.editControl.isChanged) {
      return;
  }
  
  const packagingId = $row.cells.packaging.editControl.value;
  const materialId = $row.cells.component.editControl.value;
  const packaging = (await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({
  	material_id: materialId,
  	packaging_id: packagingId
  }))?.result[0] ?? undefined;
  
  const entity = {
      PackagedAmount: $row.cells.initial.editControl.value,
      Amount: $row.cells.initial.editControl.value * (packaging?.BasePackagingQuantity ?? 0),
      PackagingId: $row.cells.packaging.editControl.value,
      MaterialId: $row.cells.component.editControl.value
  }
  
  const manufacturingOrderLine = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrderLines",
      id: $row.entity.Id,
      entity: entity
  }));
  
  if ($utils.isDefined(manufacturingOrderLine.reason)) {
      $shell.Utilities.openErrorDialog('Save', 'Unable to save order line.');
      throw new Error('Unable to save order line.' + manufacturingOrderLine.reason);
  } else {
      await $grid.refresh();
  
      const currentItemIndex = $grid.vars.swapped_materials.findIndex( x => x.lineNumber == $row.entity.LineNumber );
      if( currentItemIndex > -1 )
      {
          $row.cells.substitutions.displayControl.text = 'Substitution used.';
          $row.options.substitutions_swap.readOnly = true;
          $row.options.undo_swap.readOnly = false;
      }
  }
  
  }
  on_swap_with_substitutions_clicked(event = null) {
    return this.on_swap_with_substitutions_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_swap_with_substitutions_clickedInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_swap_with_substitutions_clicked');
  
  const substitutions = (await $datasources.Manufacturing.ds_get_material_substitutions.get({material_ids: [$row.entity.MaterialId], package_id: $row.entity.PackagingId, warehouse_id: $grid.inParams.warehouse_id}));
  
  if( !$utils.isDefined(substitutions.result) )
  {
      await $shell.Utilities.openErrorDialog('Error swapping substitution', 'Swapping substitution failed.');
      return;
  }
  
  let substitutionIdsWithAvailability = [];
  
  substitutions.result.forEach(x => {
      const substitutionInventoryAmount = x.SubstitutionMaterialPackagingLookup.Material?.InventoryByWarehouseMaterial[0]?.CurrentlyAvailablePackagedAmount;
  
      console.log(substitutionInventoryAmount);
      console.log(( ($row.entity?.Amount * $grid.inParams.finished_good_quantity) * x.Ratio ));
  
      if( ( ($row.entity?.Amount * $grid.inParams.finished_good_quantity) * x.Ratio ) <= ( $utils.isDefined(substitutionInventoryAmount) ? substitutionInventoryAmount : 0 ) )
      {
          substitutionIdsWithAvailability.push(x.SubstitutionMaterialId);
      }
  })
  
  if( substitutionIdsWithAvailability.length == 0 )
  {
      await $shell.Utilities.openErrorDialog('Unabe to swap with substitutions', `Substitution materials availability not enough to swap with the material.`);
      return;
  }
  
  let materialSwapped = false;
  let substitutite = null;
  
  if( substitutionIdsWithAvailability.length == 1 )
  {
      substitutite = substitutions.result.find(x => x.SubstitutionMaterialId == substitutionIdsWithAvailability[0]);
  
      const result = await $shell.Manufacturing.openmanufacturing_order_line_substitution_swap_formDialog({material_name: $row.cells.component.displayControl.text
                                                                                    , material_amount: $row.entity?.Amount * $grid.inParams.finished_good_quantity
                                                                                    , material_packaging: $row.cells.packaging.displayControl.text
                                                                                    , substitution_name: substitutite.SubstitutionMaterialPackagingLookup.Material.Name
                                                                                    , substitution_amount: ($row.entity?.Amount * $grid.inParams.finished_good_quantity) * substitutite.Ratio
                                                                                    , substitution_packaging: substitutite.SubstitutionMaterialPackagingLookup.Packaging.ShortName
                                                                                    , ratio: substitutite.Ratio});
  
      materialSwapped = result.material_swapped;
  }
  else if(substitutionIdsWithAvailability.length > 1)
  {
      const result = await $shell.Manufacturing.openmanufacturing_order_line_multiple_substitutions_swap_formDialog({material_name: $row.cells.component.displayControl.text
                                                                                    , material_amount: $row.entity?.Amount * $grid.inParams.finished_good_quantity
                                                                                    , material_packaging: $row.cells.packaging.displayControl.text
                                                                                    , substitution_ids: substitutionIdsWithAvailability
                                                                                    , warehouse_id: $grid.inParams.warehouse_id
                                                                                    , material_ids: [$row.entity.MaterialId]});
  
      materialSwapped = result.material_swapped;
      substitutite = substitutions.result.find( x => x.Id == result.subtitution_id );
  }
  
  if( !materialSwapped )
      return;
  
  if( !$utils.isDefined(substitutite) )
  {
      await $shell.Utilities.openErrorDialog('Error swapping substitution', 'Swapping substitution failed.');
      return;
  }
  
  const swappedInitialAmount = $row.entity.Amount;
  const swappedMaterialId = $row.entity.MaterialId;
  const swappedPackagingId = $row.entity.PackagingId;
  const swappedPackagedAmount = $row.entity.PackagedAmount;
  
  const substitutionInventoryAmount = substitutite.SubstitutionMaterialPackagingLookup.Material?.InventoryByWarehouseMaterial[0]?.CurrentlyAvailablePackagedAmount;
  
  const entity = {
      PackagedAmount: swappedInitialAmount * substitutite.Ratio,
      Amount: (swappedInitialAmount * substitutite.Ratio) * (substitutite?.SubstitutionMaterialPackagingLookup.BasePackagingQuantity ?? 0),
      PackagingId: $row.cells.packaging.editControl.value,
      MaterialId: substitutite.SubstitutionMaterialId
  }
  
  const manufacturingOrderLine = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrderLines",
      id: $row.entity.Id,
      entity: entity
  }));
  
  if ($utils.isDefined(manufacturingOrderLine.reason)) {
      $shell.Utilities.openErrorDialog('Swap failed', 'Material swap failed: ' + manufacturingOrderLine.reason);
      throw new Error('Material swap failed' + manufacturingOrderLine.reason);
  }
  
  if(!$utils.isDefined($grid.vars.swapped_materials))
      $grid.vars.swapped_materials = [];
  
  $grid.vars.swapped_materials.push({lineNumber: $row.entity.LineNumber, oldMaterialId: swappedMaterialId, oldAmount: swappedInitialAmount, oldPackagingId: swappedPackagingId, oldPackagedAmount: swappedPackagedAmount});
  
  await $row.refresh();
  $row.cells.substitutions.displayControl.text = 'Substitution used.';
  $row.options.substitutions_swap.readOnly = true;
  $row.options.undo_swap.readOnly = false;
  }
  on_undo_swap_clicked(event = null) {
    return this.on_undo_swap_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_undo_swap_clickedInternal(
    $row: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_lines_components_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_undo_swap_clicked');
  const swappedComponent = $grid.vars.swapped_materials.find(x => x.lineNumber == $row.entity.LineNumber);
  
  if( !$utils.isDefined(swappedComponent) )
      return;
  
  const entity = {
      PackagedAmount: swappedComponent.oldPackagedAmount,
      Amount: swappedComponent.oldAmount,
      PackagingId: swappedComponent.oldPackagingId,
      MaterialId: swappedComponent.oldMaterialId
  }
  
  const manufacturingOrderLine = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrderLines",
      id: $row.entity.Id,
      entity: entity
  }));
  
  if ($utils.isDefined(manufacturingOrderLine.reason)) {
      $shell.Utilities.openErrorDialog('Undo swap failed', 'Undo swap failed: ' + manufacturingOrderLine.reason);
      throw new Error('Undo swap failed' + manufacturingOrderLine.reason);
  }
  
  const swappedItemIndex = $grid.vars.swapped_materials.findIndex( x => x.lineNumber == $row.entity.LineNumber);
  
  console.log($grid.vars.swapped_materials);
  
  if($utils.isDefined(swappedItemIndex))
      $grid.vars.swapped_materials.splice(swappedItemIndex, 1);
  
  $row.options.substitutions_swap.readOnly = false;
  $row.options.undo_swap.readOnly = true;
  $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_materials_by_project_dd_singleComponent),
    forwardRef(() => Manufacturing_material_packagings_dd_singleComponent),
  ],
  selector: 'Manufacturing-manufacturing_order_lines_components_grid',
  templateUrl: './Manufacturing.manufacturing_order_lines_components_grid.component.html'
})
export class Manufacturing_manufacturing_order_lines_components_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManufacturing_manufacturing_order_lines_components_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IManufacturing_manufacturing_order_lines_components_gridComponentInParams = { finished_good_id: null, warehouse_id: null, finished_good_quantity: null, is_kit: null, project_id: null, order_id: null, line_number: null, order_status_id: null };


  //#region Variables
  vars: { swapped_materials?: { lineNumber?: number, oldMaterialId?: number, oldAmount?: number, oldPackagingId?: number, oldPackagedAmount?: number }[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', '#', false, false, null, false),       component: new GridHeaderModel(new HeaderStyles(null, null), 'component', 'Component', true, false, null, false),       initial: new GridHeaderModel(new HeaderStyles(null, null), 'initial', 'Initial', true, false, null, false),       needed_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'needed_quantity', 'Needed quantity', true, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       availability: new GridHeaderModel(new HeaderStyles(null, null), 'availability', 'Availability', false, false, null, false),       onhandinventory: new GridHeaderModel(new HeaderStyles(null, null), 'onhandinventory', 'On hand', false, false, null, false),       substitutions: new GridHeaderModel(new HeaderStyles(null, null), 'substitutions', 'Substitutions', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Manufacturing_manufacturing_order_lines_components_gridComponentRowModel[] = [];

  @Input('finished_good_id') set $inParams_finished_good_id(value: any) {
    this.inParams['finished_good_id'] = value;
  }
  get $inParams_finished_good_id(): any {
    return this.inParams['finished_good_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('finished_good_quantity') set $inParams_finished_good_quantity(value: any) {
    this.inParams['finished_good_quantity'] = value;
  }
  get $inParams_finished_good_quantity(): any {
    return this.inParams['finished_good_quantity'] ;
  }
  @Input('is_kit') set $inParams_is_kit(value: any) {
    this.inParams['is_kit'] = value;
  }
  get $inParams_is_kit(): any {
    return this.inParams['is_kit'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('line_number') set $inParams_line_number(value: any) {
    this.inParams['line_number'] = value;
  }
  get $inParams_line_number(): any {
    return this.inParams['line_number'] ;
  }
  @Input('order_status_id') set $inParams_order_status_id(value: any) {
    this.inParams['order_status_id'] = value;
  }
  get $inParams_order_status_id(): any {
    return this.inParams['order_status_id'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Manufacturing Order Lines Components';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.finished_good_id)) {
        this.$missingRequiredInParams.push('finished_good_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.line_number)) {
        this.$missingRequiredInParams.push('line_number');
      }
      if(isNil(this.inParams.order_status_id)) {
        this.$missingRequiredInParams.push('order_status_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 30;


    await this.on_grid_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      finished_good_id:  $grid.inParams.finished_good_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IManufacturing_manufacturing_order_lines_components_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Manufacturing_manufacturing_order_lines_components_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IManufacturing_manufacturing_order_lines_components_gridComponentEntity) {
    const row = new Manufacturing_manufacturing_order_lines_components_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: Manufacturing_manufacturing_order_lines_components_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_data_loaded');
  
  if( $grid.inParams.is_kit == false )
  {
      $grid.headers.substitutions.hidden = true;
      return;
  }
  
  let materialIds = [];
  
  $grid.rows.forEach(row => {
      materialIds.push(row.entity?.MaterialId);
  })
  
  const substitutions = (await $datasources.Manufacturing.ds_get_material_substitutions.get({material_ids: materialIds, warehouse_id: $grid.inParams.warehouse_id}));
  
  $grid.rows.forEach(row => {
      const substitutionsForMaterial = substitutions.result.filter(x => x.MaterialId == row.entity?.MaterialId);
  
      row.cells.substitutions.displayControl.text = "";
  
      let index = 0;
  
      substitutionsForMaterial.forEach(material => {
          const inventoryAmount = material.SubstitutionMaterialPackagingLookup.Material?.InventoryByWarehouseMaterial?.find( x => x.MaterialId == material.SubstitutionMaterialId
                                                                                                                      && x.PackagedId == material.SubstitutionPackagingId )?.CurrentlyAvailablePackagedAmount;
          const packaging = material.SubstitutionMaterialPackagingLookup?.Packaging?.ShortName;
          const materialName = material.SubstitutionMaterialPackagingLookup?.Material?.Name;
  
          row.cells.substitutions.displayControl.text += `${index > 0 ? "," : ""} ${$utils.isDefined(inventoryAmount) ? inventoryAmount : "0"} ${$utils.isDefined(packaging) ? packaging : ""} of ${$utils.isDefined(materialName) ? materialName : ""}`;
  
          index++;
      })
  
      row.options.substitutions_swap.readOnly = true;
  
      if( substitutionsForMaterial.length > 0 )
          row.options.substitutions_swap.readOnly = false;
  
      row.options.undo_swap.readOnly = true;
  
      if( $utils.isDefined($grid.vars.swapped_materials) )
      {
          const currentItemIndex = $grid.vars.swapped_materials.findIndex( x => x.lineNumber == row.entity.LineNumber );
          if( currentItemIndex > -1 )
          {
              row.options.undo_swap.readOnly = false;
              row.cells.substitutions.displayControl.text = 'Substitution used.';
          }
      }
  })
  
  
  }
  on_grid_init(event = null) {
    return this.on_grid_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_initInternal(
    $grid: Manufacturing_manufacturing_order_lines_components_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_lines_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid.on_grid_init');
  
  $grid.canAdd = $grid.inParams.order_status_id === 1;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
