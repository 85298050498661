import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_plan_editor_ComponentContextService } from './Manufacturing.plan_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manufacturing_orders_dd_singleComponent } from './Manufacturing.orders_dd_single.component'

type EntityType = { 
    plan_id?: string, name?: string, is_active?: boolean, template_order_id?: number, offset_in_days?: number, total_runs?: number, schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_orders_dd_singleComponent),
  ],
  selector: 'Manufacturing-plan_editor',
  templateUrl: './Manufacturing.plan_editor.component.html'
})
export class Manufacturing_plan_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { plan_id?: string } = { plan_id: null };
  //#region Inputs
  @Input('plan_id') set $inParams_plan_id(v: string) {
    this.inParams.plan_id = v;
  }
  get $inParams_plan_id(): string {
    return this.inParams.plan_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { cron_expression?: string, schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } } } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    is_active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    template_order_id: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    total_runs: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    offset_in_days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, true, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', '', null)
    , false),
      delete_plan: new ToolModel(new ButtonModel('delete_plan', new ButtonStyles(['destructive'], null), false, true, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      get_list: new ToolModel(new ButtonModel('get_list', new ButtonStyles(null, null), false, false, false, 'Get list', '', null)
    , false)
  };

  fields = {
    is_active: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_active'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Active', false, false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Plan name', true, false),
    template_order_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['template_order_id'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Source manufacturing order', true, false),
    total_runs: new FieldModel(new NumberBoxModel(this.formGroup.controls['total_runs'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Runs daily for', true, false),
    offset_in_days: new FieldModel(new NumberBoxModel(this.formGroup.controls['offset_in_days'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Days offset', true, false),
    scheduling: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Set schedule', 'icon-ic_fluent_calendar_20_regular', null)
, new ControlContainerStyles(null, null), 'Label of newField6', false, false),
  };

  fieldsets = {
    plan_group: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    plan_setup: new FieldsetModel(
      'Plan setup',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    order_schedule: new FieldsetModel(
      'Order schedule',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};


  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    is_active: this.fields.is_active.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    template_order_id: this.fields.template_order_id.control.valueChanges
    ,
    total_runs: this.fields.total_runs.control.valueChanges
    ,
    offset_in_days: this.fields.offset_in_days.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_plan_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'plan_editor';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      plan_id:  $editor.inParams.plan_id ?? null 
    };

    const data = await this.$datasources.Manufacturing.ds_get_plan.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.is_active.control as CheckBoxModel).reset($editor.entity?.is_active);
    (this.fields.name.control as TextBoxModel).reset($editor.entity?.name);
    (this.fields.template_order_id.control as SelectBoxModel).reset($editor.entity?.template_order_id);
    (this.fields.total_runs.control as NumberBoxModel).reset($editor.entity?.total_runs);
    (this.fields.offset_in_days.control as NumberBoxModel).reset($editor.entity?.offset_in_days);

    await this.data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .is_active
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.validate();
      });
    this.$formGroupFieldValidationObservables
      .template_order_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.validate();
      });
    this.$formGroupFieldValidationObservables
      .total_runs
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.validate();
      });
    this.$formGroupFieldValidationObservables
      .offset_in_days
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.validate();
      });
  }

  //#region private flows
  update(event = null) {
    return this.updateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async updateInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.update');
  
  const result = await $flows.Manufacturing.create_update_plan({
      plan_id: $utils.isDefined($editor.inParams.plan_id) ? $editor.inParams.plan_id : null,
      cron_expression: $editor.vars.cron_expression,
      is_active: $editor.fields.is_active.control.value,
      name: $editor.fields.name.control.value,
      offset_in_days: $editor.fields.offset_in_days.control.value,
      template_order_id: $editor.fields.template_order_id.control.value,
      total_runs: $editor.fields.total_runs.control.value
  });
  
  alert(JSON.stringify(result.plan_id));
  if ($utils.isDefined(result.reasons)) {
      alert(JSON.stringify(result.reasons));
  }
  }
  cacel(event = null) {
    return this.cacelInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async cacelInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.cacel');
  
  
  $editor.close();
  }
  set_schedule(event = null) {
    return this.set_scheduleInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_scheduleInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.set_schedule');
  // Format schedule
  if ($utils.isDefined($editor.vars.schedule)) {
  
      $editor.fields.scheduling.control.label = (await $flows.Manufacturing.auto_schedule_format_flow({
          schedule: $editor.vars.schedule
      })).scheduleString;
  
      $editor.fields.scheduling.control.styles.clearClasses();
  } else {
      $editor.fields.scheduling.control.label = `Please enter schedule details`;
      $editor.fields.scheduling.control.styles.setDestructiveClass();
  }
  
  
  }
  data_loaded(event = null) {
    return this.data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async data_loadedInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.data_loaded');
  
  if ($utils.isDefined($editor.entity?.plan_id)) {
      $editor.toolbar.delete_plan.control.disabled = true;
  
      // if(Object.keys($editor.entity.schedule).length > 0){
      //     $editor.vars.schedule = $editor.entity.schedule
      //     $editor.set_schedule();
      // }
  } else {
      $editor.toolbar.confirm.control.label = 'Create';
  }
  }
  schedule_clicked(event = null) {
    return this.schedule_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async schedule_clickedInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.schedule_clicked');
  let result = (await $shell.Utilities.openschedule_frequency_formDialog({ schedule: !$utils.isDefined($editor.vars.schedule) ? null : $editor.vars.schedule }, 'modal'));
  
  if ($utils.isDefined(result.schedule)) {
      $editor.vars.schedule = result.schedule;
      $editor.vars.cron_expression = result.cron_expression
  }
  
  if (!$utils.isDefined(result.schedule?.date_interval) &&
      !$utils.isDefined(result.schedule?.date_option) &&
      !$utils.isDefined(result.schedule?.time_interval) &&
      !$utils.isDefined(result.schedule?.time_offset) &&
      !$utils.isDefined(result.schedule?.time_option) &&
      !$utils.isDefined(result.schedule?.time_zone)) {
      $editor.vars.schedule = null
  }
  
  await $editor.set_schedule();
  $editor.validate();
  }
  validate(event = null) {
    return this.validateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async validateInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.validate');
  
  const isValid = 
      $utils.isDefined($editor.fields.name.control.value) &&
      $utils.isDefined($editor.fields.template_order_id.control.value) &&
      $utils.isDefined($editor.fields.total_runs.control.value) &&
      $utils.isDefined($editor.fields.offset_in_days.control.value) &&
      $utils.isDefined($editor.vars.cron_expression);
  
  $editor.toolbar.confirm.control.disabled = !isValid;
  
  }
  delete_plan(event = null) {
    return this.delete_planInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async delete_planInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.delete_plan');
  
  await $flows.Manufacturing.delete_plan({plan_id: $editor.inParams.plan_id})
  $editor.close();
  }
  get_list(event = null) {
    return this.get_listInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async get_listInternal(
    $editor: Manufacturing_plan_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_plan_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'plan_editor.get_list');
  
  
  const result = await $datasources.Manufacturing.test_get_plan.getList({});
  alert(JSON.stringify(result));
  }
  //#endregion private flows
  
}
