import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_transload_order_editor_ComponentContextService } from './FootprintManager.transload_order_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_transload_order_lines_gridComponent } from './FootprintManager.transload_order_lines_grid.component';
import { FootprintManager_transload_order_receiving_gridComponent } from './FootprintManager.transload_order_receiving_grid.component';
import { FootprintManager_transload_order_received_inventory_gridComponent } from './FootprintManager.transload_order_received_inventory_grid.component';
import { FootprintManager_transload_outbound_shipments_gridComponent } from './FootprintManager.transload_outbound_shipments_grid.component';
import { FootprintManager_orderaddresses_gridComponent } from './FootprintManager.orderaddresses_grid.component';
import { FootprintManager_accessorial_tasks_gridComponent } from './FootprintManager.accessorial_tasks_grid.component';
import { FootprintManager_billing_records_gridComponent } from './FootprintManager.billing_records_grid.component';
import { TransloadOrders_order_expected_licenseplate_count_widgetComponent } from './TransloadOrders.order_expected_licenseplate_count_widget.component';
import { TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent } from './TransloadOrders.order_crossdocked_licenseplate_count_widget.component';
import { TransloadOrders_order_loaded_licenseplate_count_widgetComponent } from './TransloadOrders.order_loaded_licenseplate_count_widget.component';
import { TransloadOrders_orderclasses_dd_singleComponent } from './TransloadOrders.orderclasses_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { TransloadOrders_conveyances_dd_singleComponent } from './TransloadOrders.conveyances_dd_single.component'

type EntityType = { 
    Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredCarrierId?: number, PreferredCarrierServiceTypeId?: number, PreferredWarehouseId?: number, ProjectId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Project?: { OwnerId?: number }, OrderClass?: { Name?: string }, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ContainerIdentifier?: string, ConveyanceId?: number, ConveyenceReference?: string, ExpectedWarehouseId?: number, ReferenceNumber?: string, SealId?: string } }[], OrderLines?: { LineNumber?: number }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { OrderStatusId?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] } }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_transload_order_lines_gridComponent),
    forwardRef(() => FootprintManager_transload_order_receiving_gridComponent),
    forwardRef(() => FootprintManager_transload_order_received_inventory_gridComponent),
    forwardRef(() => FootprintManager_transload_outbound_shipments_gridComponent),
    forwardRef(() => FootprintManager_orderaddresses_gridComponent),
    forwardRef(() => FootprintManager_accessorial_tasks_gridComponent),
    forwardRef(() => FootprintManager_billing_records_gridComponent),
    forwardRef(() => TransloadOrders_order_expected_licenseplate_count_widgetComponent),
    forwardRef(() => TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent),
    forwardRef(() => TransloadOrders_order_loaded_licenseplate_count_widgetComponent),
    forwardRef(() => TransloadOrders_orderclasses_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => TransloadOrders_conveyances_dd_singleComponent),
  ],
  selector: 'FootprintManager-transload_order_editor',
  templateUrl: './FootprintManager.transload_order_editor.component.html'
})
export class FootprintManager_transload_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { orderId: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { outboundShipmentIds?: number[] } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    order_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    inbound_vendor_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inbound_owner_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inbound_conveyance: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inbound_conveyance_reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inbound_notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'ms-Icon ms-Icon--Completed', null)
    , false),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, false, false, 'Schedule appointment', 'icon-ic_fluent_calendar_add_20_regular', null)
    , false),
      separator8: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), true, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_print: new ToolModel(new SplitButtonModel<{ receiving_report: ButtonModel, po_label: ButtonModel }>(
        'on_print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('receiving_report', new ButtonStyles(null, null), false, false, false, 'Receiving report', ''),
          new ButtonModel('po_label', new ButtonStyles(null, null), false, false, false, 'License plate labels', '')
        ])
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      surveys: new ToolModel(new ButtonModel('surveys', new ButtonStyles(null, null), false, false, false, 'Surveys', 'icon-ic_fluent_shifts_availability_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Order code', true, false),
    order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_class'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order class', true, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    order_status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Order status', false, false),
    inbound_vendor_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['inbound_vendor_reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Vendor reference', false, false),
    inbound_owner_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['inbound_owner_reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Owner reference', false, false),
    inbound_conveyance: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['inbound_conveyance'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Conveyance', false, false),
    inbound_conveyance_reference: new FieldModel(new TextBoxModel(this.formGroup.controls['inbound_conveyance_reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Conveyance reference', false, false),
    inbound_notes: new FieldModel(new TextBoxModel(this.formGroup.controls['inbound_notes'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '  ', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Header',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    inbound_details: new FieldsetModel(
      'Inbound details',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    notes: new FieldsetModel(
      'Notes',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      billing: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      purchase_order_lines: new TabItemModel(
        this.rootTabGroup, 
        'Inbound lines', 
        false,
        ),
      receiving_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Receiving tasks', 
        false,
        ),
      received_inventory: new TabItemModel(
        this.rootTabGroup, 
        'Received inventory', 
        false,
        ),
      outbound_shipments: new TabItemModel(
        this.rootTabGroup, 
        'Outbound shipments', 
        false,
        ),
      contacts: new TabItemModel(
        this.rootTabGroup, 
        'Contacts', 
        false,
        ),
      billing: new TabItemModel(
        this.rootTabGroup, 
        'Billing', 
        false,
        true,
        {
          accessorial_tasks: new TabItemModel(
          this.subTabGroups.billing, 
          'Accessorials', 
          false
          ),
          billing_records: new TabItemModel(
          this.subTabGroups.billing, 
          'Billing Records', 
          false
          ),
        }
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_purchase_order_lines_transload_order_lines_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_transload_order_receiving_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_transload_order_receiving_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_transload_order_receiving_grid_inParams_orderStatusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_receiving_tasks_transload_order_receiving_grid_inParams_transload_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.TransLoadsForInboundOrder[0]?.Id;
      
      return expr;
    }
  
    get $tabs_received_inventory_transload_order_received_inventory_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_received_inventory_transload_order_received_inventory_grid_inParams_shipmentId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ShipmentOrderLookups[0]?.ShipmentId;
      
      return expr;
    }
  
    get $tabs_received_inventory_transload_order_received_inventory_grid_inParams_transload_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.TransLoadsForInboundOrder[0]?.Id;
      
      return expr;
    }
  
    get $tabs_outbound_shipments_transload_outbound_shipments_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrderId;
      
      return expr;
    }
  
    get $tabs_contacts_orderaddresses_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.OrderStatusId;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.PreferredWarehouseId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_orderIds(): number[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = [$editor.entity.Id, $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrderId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_orderIds;
    }
  
    get $tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds: number[];
    get $tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = [$editor.entity.ShipmentOrderLookups[0]?.ShipmentId];
      
      if(!isEqual(this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_purchase_order_lines', { read: FootprintManager_transload_order_lines_gridComponent }) $tabs_purchase_order_lines: FootprintManager_transload_order_lines_gridComponent;
      @ViewChild('$tabs_receiving_tasks', { read: FootprintManager_transload_order_receiving_gridComponent }) $tabs_receiving_tasks: FootprintManager_transload_order_receiving_gridComponent;
      @ViewChild('$tabs_received_inventory', { read: FootprintManager_transload_order_received_inventory_gridComponent }) $tabs_received_inventory: FootprintManager_transload_order_received_inventory_gridComponent;
      @ViewChild('$tabs_outbound_shipments', { read: FootprintManager_transload_outbound_shipments_gridComponent }) $tabs_outbound_shipments: FootprintManager_transload_outbound_shipments_gridComponent;
      @ViewChild('$tabs_contacts', { read: FootprintManager_orderaddresses_gridComponent }) $tabs_contacts: FootprintManager_orderaddresses_gridComponent;
        @ViewChild('$tabs_billing_accessorial_tasks', { read: FootprintManager_accessorial_tasks_gridComponent }) $tabs_billing_accessorial_tasks: FootprintManager_accessorial_tasks_gridComponent;
        @ViewChild('$tabs_billing_billing_records', { read: FootprintManager_billing_records_gridComponent }) $tabs_billing_billing_records: FootprintManager_billing_records_gridComponent;
    //#endregion tabs children
    widgets = {
      expected_licenseplate_count: new WidgetModel(false),
      crossdocked_licenseplate_count: new WidgetModel(false),
      loaded_licenseplate_count: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_expected_licenseplate_count_inParams_orderId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.orderId;
      
      return expr;
    }
  
    cacheValueFor_$widgets_crossdocked_licenseplate_count_inParams_shipmentIds: number[];
    get $widgets_crossdocked_licenseplate_count_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.outboundShipmentIds;
      
      if(!isEqual(this.cacheValueFor_$widgets_crossdocked_licenseplate_count_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$widgets_crossdocked_licenseplate_count_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$widgets_crossdocked_licenseplate_count_inParams_shipmentIds;
    }
  
    cacheValueFor_$widgets_loaded_licenseplate_count_inParams_shipmentIds: number[];
    get $widgets_loaded_licenseplate_count_inParams_shipmentIds(): number[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.outboundShipmentIds;
      
      if(!isEqual(this.cacheValueFor_$widgets_loaded_licenseplate_count_inParams_shipmentIds, expr)) {
        this.cacheValueFor_$widgets_loaded_licenseplate_count_inParams_shipmentIds = expr;
      }
      return this.cacheValueFor_$widgets_loaded_licenseplate_count_inParams_shipmentIds;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_expected_licenseplate_count', { read:  TransloadOrders_order_expected_licenseplate_count_widgetComponent }) $widgets_expected_licenseplate_count: TransloadOrders_order_expected_licenseplate_count_widgetComponent;
      @ViewChild('$widgets_crossdocked_licenseplate_count', { read:  TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent }) $widgets_crossdocked_licenseplate_count: TransloadOrders_order_crossdocked_licenseplate_count_widgetComponent;
      @ViewChild('$widgets_loaded_licenseplate_count', { read:  TransloadOrders_order_loaded_licenseplate_count_widgetComponent }) $widgets_loaded_licenseplate_count: TransloadOrders_order_loaded_licenseplate_count_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    order_class: this.fields.order_class.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    inbound_vendor_reference: this.fields.inbound_vendor_reference.control.valueChanges
    ,
    inbound_owner_reference: this.fields.inbound_owner_reference.control.valueChanges
    ,
    inbound_conveyance: this.fields.inbound_conveyance.control.valueChanges
    ,
    inbound_conveyance_reference: this.fields.inbound_conveyance_reference.control.valueChanges
    ,
    inbound_notes: this.fields.inbound_notes.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_transload_order_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.purchase_order_lines,
      this.tabs.receiving_tasks,
      this.tabs.received_inventory,
      this.tabs.outbound_shipments,
      this.tabs.contacts,
      this.tabs.billing,
    ]; 
    this.subTabGroups.billing.tabs = [
        this.tabs.billing.tabs.accessorial_tasks,
        this.tabs.billing.tabs.billing_records,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Transload Order';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      orderId:  $editor.inParams.orderId 
    };

    const data = await this.$datasources.FootprintManager.ds_transload_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.order_class.control as SelectBoxModel).reset($editor.entity.OrderClassId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.warehouse.control as SelectBoxModel).reset($utils.isDefined($editor.entity.PreferredWarehouseId) ? $editor.entity.PreferredWarehouseId : $editor.entity.ShipmentOrderLookups[0]?.Shipment?.ExpectedWarehouseId);
    (this.fields.order_status.control as TextModel).text = $editor.entity.Status.Name;
    (this.fields.inbound_vendor_reference.control as TextBoxModel).reset($editor.entity.VendorReference);
    (this.fields.inbound_owner_reference.control as TextBoxModel).reset($editor.entity.OwnerReference);
    (this.fields.inbound_conveyance.control as SelectBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyanceId);
    (this.fields.inbound_conveyance_reference.control as TextBoxModel).reset($editor.entity.ShipmentOrderLookups[0]?.Shipment?.ConveyenceReference);
    (this.fields.inbound_notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_expected_licenseplate_count') {
      if (!isNil(this.$widgets_expected_licenseplate_count) && !this.widgets.expected_licenseplate_count.hidden && !this.widgets.expected_licenseplate_count.removed) {
        this.$widgets_expected_licenseplate_count.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_crossdocked_licenseplate_count') {
      if (!isNil(this.$widgets_crossdocked_licenseplate_count) && !this.widgets.crossdocked_licenseplate_count.hidden && !this.widgets.crossdocked_licenseplate_count.removed) {
        this.$widgets_crossdocked_licenseplate_count.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_loaded_licenseplate_count') {
      if (!isNil(this.$widgets_loaded_licenseplate_count) && !this.widgets.loaded_licenseplate_count.hidden && !this.widgets.loaded_licenseplate_count.removed) {
        this.$widgets_loaded_licenseplate_count.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_purchase_order_lines') {
        if (!isNil(this.$tabs_purchase_order_lines) && !this.tabs.purchase_order_lines.hidden && !this.tabs.purchase_order_lines.removed) {
          this.$tabs_purchase_order_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_receiving_tasks') {
        if (!isNil(this.$tabs_receiving_tasks) && !this.tabs.receiving_tasks.hidden && !this.tabs.receiving_tasks.removed) {
          this.$tabs_receiving_tasks.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_received_inventory') {
        if (!isNil(this.$tabs_received_inventory) && !this.tabs.received_inventory.hidden && !this.tabs.received_inventory.removed) {
          this.$tabs_received_inventory.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_outbound_shipments') {
        if (!isNil(this.$tabs_outbound_shipments) && !this.tabs.outbound_shipments.hidden && !this.tabs.outbound_shipments.removed) {
          this.$tabs_outbound_shipments.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_contacts') {
        if (!isNil(this.$tabs_contacts) && !this.tabs.contacts.hidden && !this.tabs.contacts.removed) {
          this.$tabs_contacts.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_billing_accessorial_tasks') {
        if (!isNil(this.$tabs_billing_accessorial_tasks) && !this.tabs.billing.tabs.accessorial_tasks.hidden && !this.tabs.billing.tabs.accessorial_tasks.removed) {
          this.$tabs_billing_accessorial_tasks.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_billing_billing_records') {
        if (!isNil(this.$tabs_billing_billing_records) && !this.tabs.billing.tabs.billing_records.hidden && !this.tabs.billing.tabs.billing_records.removed) {
          this.$tabs_billing_billing_records.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .order_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .inbound_vendor_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .inbound_owner_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .inbound_conveyance
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .inbound_conveyance_reference
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .inbound_notes
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_delete_clicked');
  
  const transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
   
   const result = await $flows.TransloadOrders.is_transload_order_deletable_flow({ transloadId: transloadId });
   const reason = result.reason;
   if ($utils.isDefined(reason)) {
       await $shell.FootprintManager.openErrorDialog('Order cannot be deleted.', reason);
       return;
   } else {
      const confirm = await $shell.FootprintManager.openConfirmationDialog('Delete order', `Are you sure you want to delete order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.TransloadOrders.delete_transload_order_flow({ transloadId: transloadId});
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootprintManager.openErrorDialog('Order cannot be deleted.', reason);
                  return;
              }
              else {
                  const title = 'Delete Order';
                  const message = `Order ${$editor.entity.LookupCode} deleted.`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $editor.close();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootprintManager.openErrorDialog('Delete order error', 'An error occurred during deletion of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_cancel_clicked');
  
  const transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
   
   const result = await $flows.TransloadOrders.is_transload_order_cancelable_flow({ transloadId: transloadId });
   const reason = result.reason;
   if ($utils.isDefined(reason)) {
       await $shell.FootprintManager.openErrorDialog('Order cannot be cancelled.', reason);
       return;
   } else {
      const confirm = await $shell.FootprintManager.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
              const result = await $flows.TransloadOrders.cancel_transload_order_flow({ transloadId: transloadId});
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootprintManager.openErrorDialog('Order cannot be cancelled.', reason);
                  return;
              }
              else {
                  const title = 'Cancel Order';
                  const message = `Order ${$editor.entity.LookupCode} cancelled.`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $editor.refresh();
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootprintManager.openErrorDialog('Cancel order error', 'An error occurred during cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_data_loaded');
  // Set editor title
  $editor.title = `Transload order ${$editor.entity.LookupCode}`;
  
  // Initialize
  $editor.vars.outboundShipmentIds = [];
  
  // Set outbound shipment variable
  const outboundShipments = $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.ShipmentOrderLookups;
  if ($utils.isDefined(outboundShipments)) {
  
      for (let outboundShipment of outboundShipments) {
          let shipmentId = outboundShipment.ShipmentId
          $editor.vars.outboundShipmentIds.push(shipmentId);
      }
  
  
  }
  
  // Apply appointment text logic
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
      $editor.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dock_appointments: [{ id: dockAppointments[0]?.DockAppointmentId}],
      })).dock_appointments[0]?.formatted_value;
  
  }
  else {
      // Reset the appointment label back to original
      $editor.toolbar.appointment.control.styles.resetStyle();
      $editor.toolbar.appointment.control.label = 'Appointment'
  }
  
  
  // Fetch inbound shipments
  const shipments = (await $datasources.TransloadOrders.ds_get_shipments_by_orderId.get({ orderId: $editor.entity.Id })).result;
  
  if (shipments.length > 1) {
      // close
      await $shell.TransloadOrders.openErrorDialog('Invalid order', 'This order has more than one shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  else if (!$utils.isDefined(shipments)) {
      // close
      await $shell.TransloadOrders.openErrorDialog('Invalid order', 'This order does not have a shipment. This editor cannot display this kind of order. Editor will close');
      $editor.close();
      return;
  }
  else {
  
      $editor.fields.warehouse.control.readOnly = false;
      $editor.fields.warehouse.required = false;
  
      if (shipments.length === 1) {
          $editor.fields.warehouse.required = true;
  
      }
  
  
      const noWarehouse = $utils.isDefined($editor.fields.warehouse.control.value) === false;
  
      const inboundOrderStatus = $editor.entity.OrderStatusId;
      const outboundOrderStatus = $editor.entity.TransLoadsForInboundOrder[0].OutboundOrder.OrderStatusId;
  
      // created status
      if (inboundOrderStatus === 1) {
          $editor.toolbar.cancel.hidden = false;
          $editor.toolbar.cancel.control.readOnly = false;
          $editor.toolbar.on_delete.hidden = false;
          $editor.toolbar.on_delete.control.readOnly = false;
          $editor.toolbar.process.hidden = false;
          // disable process if no warehouse
          $editor.toolbar.process.control.readOnly = noWarehouse;
  
  
          // disable owner project if order lines exist
          const hasOrderLines = $editor.entity.OrderLines.length > 0;
          $editor.fields.owner.control.readOnly = hasOrderLines;
          $editor.fields.project.control.readOnly = hasOrderLines;
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = false;
  
          $editor.fields.inbound_notes.control.readOnly = false;
          $editor.fields.inbound_owner_reference.control.readOnly = false;
          $editor.fields.inbound_vendor_reference.control.readOnly = false;
          $editor.fields.inbound_conveyance.control.readOnly = false;
          $editor.fields.inbound_conveyance_reference.control.readOnly = false;
  
          $editor.tabs.receiving_tasks.hidden = true;
  
  
      } else if (inboundOrderStatus === 2) {
          // processing status
  
          $editor.fields.lookupcode.control.readOnly = false;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
  
          $editor.fields.inbound_notes.control.readOnly = false;
          $editor.fields.inbound_owner_reference.control.readOnly = false;
          $editor.fields.inbound_vendor_reference.control.readOnly = false;
          $editor.fields.inbound_conveyance.control.readOnly = false;
          $editor.fields.inbound_conveyance_reference.control.readOnly = false;
  
          $editor.tabs.receiving_tasks.hidden = false;
  
          // Set custom status
          let transload_state = await $flows.TransloadOrders.get_transload_order_state({ transloadId: $editor.entity.TransLoadsForInboundOrder[0].Id });
  
          switch (transload_state.state) {
  
              case 2: {
                  $editor.fields.order_status.control.text = 'Ready to receive'
                  $editor.toolbar.cancel.hidden = false;
                  $editor.toolbar.cancel.control.readOnly = false;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process.hidden = true;
                  $editor.toolbar.complete.control.readOnly = false;
                  break;
              }
              case 3: {
                  $editor.fields.order_status.control.text = 'Receiving in progress'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process.hidden = true;
                  $editor.toolbar.complete.control.readOnly = false;
                  break;
              }
              case 4: {
                  $editor.fields.order_status.control.text = 'Ready to cross-dock'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process.hidden = true;
                  $editor.toolbar.complete.control.readOnly = true;
                  break;
              }
              case 5: {
                  $editor.fields.order_status.control.text = 'Ready to load'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process.hidden = true;
                  $editor.toolbar.complete.control.readOnly = true;
                  break;
              }
              case 6: {
                  $editor.fields.order_status.control.text = 'Ready to complete'
                  $editor.toolbar.cancel.hidden = true;
                  $editor.toolbar.cancel.control.readOnly = true;
                  $editor.toolbar.on_delete.hidden = true;
                  $editor.toolbar.process.hidden = true;
                  $editor.toolbar.complete.control.readOnly = false;
                  break;
              }
  
          }
  
  
  
      }
      // Inbound and outbound completed or inbound and outbound cancelled
      else if ((inboundOrderStatus === 4 && outboundOrderStatus === 4) || (inboundOrderStatus === 8 && outboundOrderStatus === 8)) {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.complete.hidden = true;
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
  
  
          $editor.fields.inbound_notes.control.readOnly = false;
          $editor.fields.inbound_owner_reference.control.readOnly = false;
          $editor.fields.inbound_vendor_reference.control.readOnly = false;
          $editor.fields.inbound_conveyance.control.readOnly = true;
          $editor.fields.inbound_conveyance_reference.control.readOnly = true;
  
          $editor.tabs.receiving_tasks.hidden = true;
  
  
      }
      // Inbound completed but the outbound is not completed or cancelled
      else if (inboundOrderStatus === 4 && outboundOrderStatus !== 4 && outboundOrderStatus !== 8) {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.complete.hidden = false;
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
  
  
          $editor.fields.inbound_notes.control.readOnly = false;
          $editor.fields.inbound_owner_reference.control.readOnly = false;
          $editor.fields.inbound_vendor_reference.control.readOnly = false;
          $editor.fields.inbound_conveyance.control.readOnly = false;
          $editor.fields.inbound_conveyance_reference.control.readOnly = false;
  
          $editor.tabs.receiving_tasks.hidden = true;
  
      }
  
      // Catch all
      else {
          $editor.toolbar.process.hidden = true;
          $editor.toolbar.cancel.hidden = true;
          $editor.toolbar.on_delete.hidden = true;
          $editor.toolbar.complete.hidden = true;
  
          $editor.fields.lookupcode.control.readOnly = true;
          $editor.fields.order_class.control.readOnly = true;
          $editor.fields.owner.control.readOnly = true;
          $editor.fields.project.control.readOnly = true;
          $editor.fields.warehouse.control.readOnly = true;
  
  
          $editor.fields.inbound_notes.control.readOnly = false;
          $editor.fields.inbound_owner_reference.control.readOnly = false;
          $editor.fields.inbound_vendor_reference.control.readOnly = false;
          $editor.fields.inbound_conveyance.control.readOnly = true;
          $editor.fields.inbound_conveyance_reference.control.readOnly = true;
  
          $editor.tabs.receiving_tasks.hidden = true;
  
      }
  }
  
  if ($editor.entity.Notes?.length > 0) { 
  
      $editor.fieldsets.notes.expanded = true; 
  
  } 
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_init');
  
  
  
  // Set Date/Time Formats
  const date_format = `${$settings.FootprintManager.DateFormat}, ${$settings.FootprintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_owner_changed');
  $editor.fields.project.control.value = null;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_process_clicked');
  
  const orderlines = (await $datasources.TransloadOrders.ds_get_orderLines_by_orderId.get({ orderId: $editor.entity.Id })).result;
  if (!$utils.isDefined(orderlines)) {
      throw new Error('Order must have at least one order line to process.')
  }
  
  
  var transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
  
  // Initialize the reason variable
  var reason = null;
  
  const confirmed = await $shell.FootprintManager.openConfirmationDialog('Process Order', `Are you sure you want to process transload order ${$editor.entity.LookupCode}?`, 'Yes', 'No');
  if (confirmed) {
      try {
  
          $editor.toolbar.process.control.readOnly = true;
          $editor.toolbar.process.control.icon = "icon datex-default-spinner";
          $editor.toolbar.cancel.control.readOnly = true;
          $editor.toolbar.on_delete.control.readOnly = true;
  
          $editor.toolbar.process.control.label = 'Processing'
          const result = await $flows.TransloadOrders.process_transload_order_flow({ transloadId: transloadId });
          const reason = result.reason;
  
          if ($utils.isDefined(reason)) {
              await $shell.FootprintManager.openErrorDialog('Order cannot be processed.', reason);
              $editor.toolbar.process.control.styles.resetStyle();
              $editor.toolbar.process.control.icon = null;
              $editor.toolbar.process.control.label = 'Process';
              $editor.toolbar.process.control.readOnly = false;
              $editor.refresh();
          }
          else {
              $editor.toolbar.process.hidden = true;
              $editor.refresh();
              $editor.tabs.receiving_tasks.activate();
          }
  
      } catch (error) {
          $editor.toolbar.process.control.styles.resetStyle();
          $editor.toolbar.process.control.icon = null;
          $editor.toolbar.process.control.label = 'Process';
          $editor.toolbar.process.control.readOnly = false;
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
          await $shell.FootprintManager.openErrorDialog('Order processing error', 'An error occurred during processing of the transload order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_save');
  const allRequiredFieldHaveValues = $utils.isAllDefined(
      $editor.fields.lookupcode.control.value,
      $editor.fields.order_class.control.value,
      $editor.fields.project.control.value
      
  );
  
  if (allRequiredFieldHaveValues === false) {
      return;
  }
  
  
  const inboundShipmentExist = $utils.isDefined($editor.entity.ShipmentOrderLookups[0]?.ShipmentId);
  
  // Inbound Order
  let payload: any = {};
  if ($editor.fields.project.control.isChanged) {
      payload.ProjectId = $editor.fields.project.control.value;
  }
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.order_class.control.isChanged) {
      payload.OrderClassId = $editor.fields.order_class.control.value;
  }
  if ($editor.fields.inbound_owner_reference.control.isChanged) {
      payload.OwnerReference = $editor.fields.inbound_owner_reference.control.value;
  }
  if ($editor.fields.inbound_vendor_reference.control.isChanged) {
      payload.VendorReference = $editor.fields.inbound_vendor_reference.control.value;
  }
  if ($editor.fields.inbound_notes.control.isChanged) {
      payload.Notes = $editor.fields.inbound_notes.control.value;
  }
  if ($editor.fields.warehouse.control.isChanged) {
      payload.PreferredWarehouseId = $editor.fields.warehouse.control.value;
  }
  
  await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: $editor.entity.Id, entity: payload });
  
  
  
  
  if (inboundShipmentExist) {
      // Inbound Shipment
      payload = {};
  
      if ($editor.fields.lookupcode.control.isChanged) {
          payload.LookupCode = $editor.fields.lookupcode.control.value;
      }
      if ($editor.fields.warehouse.control.isChanged) {
          payload.ExpectedWarehouseId = $editor.fields.warehouse.control.value;
      }
      if ($editor.fields.inbound_conveyance.control.isChanged) {
          payload.ConveyanceId = $editor.fields.inbound_conveyance.control.value;
      }
      if ($editor.fields.inbound_conveyance_reference.control.isChanged) {
          payload.ConveyenceReference = $editor.fields.inbound_conveyance_reference.control.value;
      }
      await $flows.Utilities.crud_update_flow({ entitySet: 'Shipments', id: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId, entity: payload });
  
  
  }
  
  
  
  await $editor.refresh();
  }
  on_receiving_report_clicked(event = null) {
    return this.on_receiving_report_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receiving_report_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_receiving_report_clicked');
  
  
  
  
  
  
  
  
  
  await $shell.PurchaseOrders.openreceiving_reportDialog({ orderId: $editor.inParams.orderId });
  }
  on_complete_clicked(event = null) {
    return this.on_complete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_complete_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_complete_clicked');
  const transloadId = $editor.entity.TransLoadsForInboundOrder[0].Id;
  const inboundOrderStatusId = $editor.entity.OrderStatusId;
  
  const outboundShipments = $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrder?.ShipmentOrderLookups;
  const outboundOrderId = $editor.entity.TransLoadsForInboundOrder[0]?.OutboundOrderId;
  let orders: { orderId: number; shipmentId: number }[] = [];
  
  if ($utils.isDefined(outboundShipments)) {
  
      for (let outboundShipment of outboundShipments) {
  
          let shipmentId = outboundShipment.ShipmentId;
          orders.push({ orderId: outboundOrderId, shipmentId: shipmentId })
  
      }
  
  }
  let can_continue = true;
  
  // Check the received inventory associated to the transload, if nothing received allow for the completion
  const received_inventory = (await $datasources.FootprintManager.ds_transload_received_inventory_grid.get({
      orderId: $editor.inParams.orderId,
      shipmentId: $editor.entity.ShipmentOrderLookups[0]?.ShipmentId
  })).result;
  
  if ($utils.isDefined(received_inventory)) {
      if ($utils.isDefined(orders)) {
          const order_states = (await $flows.TransloadOrders.get_transload_shipment_state({ orders: orders })).states;
  
          if ($utils.isAllDefined(order_states)) {
  
              for (let order_state of order_states) {
  
                  let stateId = order_state.stateId;
                  // Not in a ready to complete state or already in a completed or cancelled
                  if (stateId == 8 || stateId === 11 || stateId == 12) {
                      // Do nothing
                  } else
                      can_continue = false;
  
              }
          }
  
      }
  }
  
  const reason = 'One or more outbound shipments associated to the transload are not in a ready to complete state.';
  if (!can_continue) {
      await $shell.FootprintManager.openErrorDialog('Transload order cannot be completed.', reason);
      $editor.tabs.outbound_shipments.activate();
      return;
  } else {
      const confirm = await $shell.FootprintManager.openConfirmationDialog('Complete transload order', `Are you sure you want to complete transload order ${$editor.entity.LookupCode} and all of the associated outbound shipments?`, 'Yes', 'No');
      if (confirm) {
          try {
              var orderReason = null;
  
              // Only if the inbound order is in a processing status
              if (inboundOrderStatusId === 2) {
                  const result = await $flows.TransloadOrders.complete_inbound_transload_order_flow({ transloadId: transloadId });
                  orderReason = result.reason;
              }
  
              if ($utils.isDefined(orderReason)) {
                  await $shell.FootprintManager.openErrorDialog('Transload order cannot be completed.', orderReason);
                  return;
              }
              else {
  
                  const result = await $flows.TransloadOrders.complete_outbound_transload_order_flow({ transloadId: transloadId });
                  const outboundReason = result.reason;
  
                  if ($utils.isDefined(outboundReason)) {
                      await $shell.FootprintManager.openErrorDialog('Transload order cannot be completed.', outboundReason);
                      return;
                  }
                  else {
                      // Complete the outbound order header
                      let payload: any = {};
                      payload.OrderStatusId = 4;
                      await $flows.Utilities.crud_update_flow({ entitySet: 'Orders', id: outboundOrderId, entity: payload });
  
  
                      let title = `Transload order ${$editor.entity.LookupCode} completed`;
                      $shell.FootprintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
                      await $editor.refresh();
                  }
  
  
              }
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.FootprintManager.openErrorDialog('Cancel order error', 'An error occurred during cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  
  
  
  
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_appointment_clicked');
  
  const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
      orderId: $editor.inParams.orderId
  })).result;
  
  if ($utils.isDefined(dockAppointments)) {
  
      const dialogResult = await $shell.FootprintManager.opendock_appointment_editorDialog({
          dockAppointmentId: dockAppointments[0].DockAppointmentId
      },'flyout',EModalSize.Large )
  
  } else {
        // Check if the selected order is in a rejected status and throw an error.
      if ($editor.entity.OrderStatusId == 4096) {
  
          throw new Error('Selected order is currently in a rejected status, a dock appointment cannot be created against rejected orders.')
      }
  
      const nextAppointmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'DockAppointment' })).nextId;
  
      const dialogResult = await $shell.FootprintManager.opendock_appointment_creation_formDialog({
         
          warehouseId: [$editor.entity.PreferredWarehouseId],
          lookupcode: nextAppointmentId.toString(),
          scheduledArrival: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $editor.entity.RequestedDeliveryDate : $utils.date.now(),
          scheduledDeparture: $utils.isDefined($editor.entity.RequestedDeliveryDate) ? $utils.date.add(1, 'hour', $editor.entity.RequestedDeliveryDate) : $utils.date.add(1, 'hour', $utils.date.now()),
          typeId: 1,
          ownerId:  $editor.entity.Project.OwnerId,
          projectId: $editor.entity.ProjectId,
          carrierId: $editor.entity.PreferredCarrierId,
          orderId: $editor.inParams.orderId
      },'flyout',EModalSize.Large );
  
  }
  
  // Always refresh as the user might have made some changes to an existing address
  await $editor.refresh();
  
  }
  on_po_labels_clicked(event = null) {
    return this.on_po_labels_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_po_labels_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_po_labels_clicked');
  $shell.FootprintManager.openprint_order_labels_formDialog({ orderId: $editor.entity.Id ,context:"po"});
  }
  on_surveys_clicked(event = null) {
    return this.on_surveys_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_surveys_clickedInternal(
    $editor: FootprintManager_transload_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_transload_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'transload_order_editor.on_surveys_clicked');
  
  if ($utils.isDefined($editor.entity.ShipmentOrderLookups)) {
      await $shell.FootprintManager.opensubmitted_surveys_gridDialog({
          entities: [{ type: 'Shipment', ids: [$editor.entity.ShipmentOrderLookups[0].ShipmentId] }],
          operationContextTypes: null,
          warehouseIds: null
      }, 'flyout',EModalSize.Large 
  
   );
  }
  
  
  }
  //#endregion private flows
  
}
