import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Manufacturing.frontend.types'

@Injectable({ providedIn: 'root' })
export class Manufacturing_create_update_planService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { plan_id?: string, cron_expression: string, template_order_id: number, name: string, offset_in_days: number, total_runs: number, is_active: boolean }): Promise<{ reasons?: string[], plan_id?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.cron_expression)) {
      missingRequiredInParams.push('\'cron_expression\'');
    }
    if (isNil(inParams.template_order_id)) {
      missingRequiredInParams.push('\'template_order_id\'');
    }
    if (isNil(inParams.name)) {
      missingRequiredInParams.push('\'name\'');
    }
    if (isNil(inParams.offset_in_days)) {
      missingRequiredInParams.push('\'offset_in_days\'');
    }
    if (isNil(inParams.total_runs)) {
      missingRequiredInParams.push('\'total_runs\'');
    }
    if (isNil(inParams.is_active)) {
      missingRequiredInParams.push('\'is_active\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/functions/create_update_plan`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

