<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.delete_line.readOnly"
                [ngStyle]="row.options.delete_line.styles.style"
                [ngClass]="row.options.delete_line.styles.classes"
                (click)="row.on_delete_clicked()">
          <span *ngIf="row.options.delete_line.label">{{row.options.delete_line.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.editControl.styles.style"
          [ngClass]="row.cells.line_number.editControl.styles.classes"
          [matTooltip]="row.cells.line_number.editControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.editControl.text }}</div>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="consumed_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.consumed_quantity.displayControl.styles.style"
          [ngClass]="row.cells.consumed_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.consumed_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.consumed_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['consumed_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.consumed_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.consumed_quantity.editControl.readOnly || row.cells.consumed_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.consumed_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.consumed_quantity.editControl.styles.style"
            [ngClass]="row.cells.consumed_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.consumed_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="scrapped_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.scrapped_quantity.displayControl.styles.style"
          [ngClass]="row.cells.scrapped_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.scrapped_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.scrapped_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['scrapped_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.scrapped_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.scrapped_quantity.editControl.readOnly || row.cells.scrapped_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.scrapped_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.scrapped_quantity.editControl.styles.style"
            [ngClass]="row.cells.scrapped_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.scrapped_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_license_plate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.source_license_plate.displayControl.styles.style"
          [ngClass]="row.cells.source_license_plate.displayControl.styles.classes"
          [matTooltip]="row.cells.source_license_plate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.source_license_plate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-license_plates_by_location_with_inventory_single 
        data-cy="selector"
        [type]="row.cells.source_license_plate.editControl.type"
        [formControl]="row.formGroup.controls['source_license_plate_edit']"
        (displayTextChange)="row.cells.source_license_plate.editControl.displayText=$event"
        [placeholder]="row.cells.source_license_plate.editControl.placeholder"
        [styles]="row.cells.source_license_plate.editControl.styles"
        [tooltip]="row.cells.source_license_plate.editControl.tooltip"
      [material_id]="row.$fields_source_license_plate_selector_inParams_material_id"
      [project_id]="row.$fields_source_license_plate_selector_inParams_project_id"
      [location_id]="row.$fields_source_license_plate_selector_inParams_location_id"
    >
    </Manufacturing-license_plates_by_location_with_inventory_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-lots_by_material_ids_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
        [tooltip]="row.cells.lot.editControl.tooltip"
      [material_ids]="row.$fields_lot_selector_inParams_material_ids"
    >
    </Manufacturing-lots_by_material_ids_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_expiration">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['lot_expiration_display']"
        [format]="row.cells.lot_expiration.displayControl.format"
        [mode]="row.cells.lot_expiration.displayControl.mode"
        [ngStyle]="row.cells.lot_expiration.displayControl.styles.style"
        [ngClass]="row.cells.lot_expiration.displayControl.styles.classes"
        [tooltip]="row.cells.lot_expiration.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
