<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-scheduled_run" *ngIf="!fields.scheduled_run.hidden && !fields.scheduled_run.removed" 
                            class="field-container standard {{fields.scheduled_run.invalid ? 'invalid' : ''}} {{fields.scheduled_run.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scheduled_run.styles.style"
                            [ngClass]="fields.scheduled_run.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scheduled_run.label + (fields.scheduled_run.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scheduled_run.label}}<span *ngIf="fields.scheduled_run.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.scheduled_run.control.styles.style"
                              [ngClass]="fields.scheduled_run.control.styles.classes"
                              [matTooltip]="fields.scheduled_run.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.scheduled_run.control.text }}</div>
                        <ng-container *ngIf="fields.scheduled_run.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_run.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-output_location" *ngIf="!fields.output_location.hidden && !fields.output_location.removed" 
                            class="field-container standard {{fields.output_location.invalid ? 'invalid' : ''}} {{fields.output_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.output_location.styles.style"
                            [ngClass]="fields.output_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.output_location.label + (fields.output_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.output_location.label}}<span *ngIf="fields.output_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_output_locations_dd_single 
                            data-cy="selector"
                            [type]="fields.output_location.control.type"
                            formControlName="output_location"
                            (displayTextChange)="fields.output_location.control.displayText=$event"
                            [placeholder]="fields.output_location.control.placeholder"
                            [styles]="fields.output_location.control.styles"
                            [tooltip]="fields.output_location.control.tooltip"
                          [warehouse_id]="$fields_output_location_selector_inParams_warehouse_id"
                          [project_id]="$fields_output_location_selector_inParams_project_id"
                        >
                        </Manufacturing-manufacturing_output_locations_dd_single>
                        <ng-container *ngIf="fields.output_location.invalid">
                          <ng-container *ngFor="let error of fields.output_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-workshift" *ngIf="!fields.workshift.hidden && !fields.workshift.removed" 
                            class="field-container standard {{fields.workshift.invalid ? 'invalid' : ''}} {{fields.workshift.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.workshift.styles.style"
                            [ngClass]="fields.workshift.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.workshift.label + (fields.workshift.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.workshift.label}}<span *ngIf="fields.workshift.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_orders_workshifts_dd_single 
                            data-cy="selector"
                            [type]="fields.workshift.control.type"
                            formControlName="workshift"
                            (displayTextChange)="fields.workshift.control.displayText=$event"
                            [placeholder]="fields.workshift.control.placeholder"
                            [styles]="fields.workshift.control.styles"
                            [tooltip]="fields.workshift.control.tooltip"
                          [warehouse_id]="$fields_workshift_selector_inParams_warehouse_id"
                        >
                        </Manufacturing-manufacturing_orders_workshifts_dd_single>
                        <ng-container *ngIf="fields.workshift.invalid">
                          <ng-container *ngFor="let error of fields.workshift.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-equipment" *ngIf="!fields.equipment.hidden && !fields.equipment.removed" 
                            class="field-container standard {{fields.equipment.invalid ? 'invalid' : ''}} {{fields.equipment.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.equipment.styles.style"
                            [ngClass]="fields.equipment.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.equipment.label + (fields.equipment.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.equipment.label}}<span *ngIf="fields.equipment.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_orders_equipment_dd_single 
                            data-cy="selector"
                            [type]="fields.equipment.control.type"
                            formControlName="equipment"
                            (displayTextChange)="fields.equipment.control.displayText=$event"
                            [placeholder]="fields.equipment.control.placeholder"
                            [styles]="fields.equipment.control.styles"
                            [tooltip]="fields.equipment.control.tooltip"
                          [warehouse_id]="$fields_equipment_selector_inParams_warehouse_id"
                        >
                        </Manufacturing-manufacturing_orders_equipment_dd_single>
                        <ng-container *ngIf="fields.equipment.invalid">
                          <ng-container *ngFor="let error of fields.equipment.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>