import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_sales_order_line_components_grid_ComponentContextService } from './FootprintManager.sales_order_line_components_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { FootprintManager_lots_dd_singleComponent } from './FootprintManager.lots_dd_single.component'
import { FootprintManager_vendorlots_dd_singleComponent } from './FootprintManager.vendorlots_dd_single.component'
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component'
import { Inventory_licenseplate_by_inventory_dd_singleComponent } from './Inventory.licenseplate_by_inventory_dd_single.component'
import { Inventory_serialnumbers_by_inventory_dd_singleComponent } from './Inventory.serialnumbers_by_inventory_dd_single.component'


interface IFootprintManager_sales_order_line_components_gridComponentEntity {
  OrderId?: number, LineNumber?: number, GrossWeight?: number, LicensePlateId?: number, LotId?: number, MaterialId?: number, NetWeight?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, SerialNumberId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[], LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, SerialNumber?: { LookupCode?: string, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } } }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } }}

interface IFootprintManager_sales_order_line_components_gridComponentInParams {
  order_id: number, shipment_id: number, project_id: number, order_status_id: number, warehouse_id: number, line_number: number}


class FootprintManager_sales_order_line_components_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_sales_order_line_components_gridComponent;
  entity: IFootprintManager_sales_order_line_components_gridComponentEntity;


  options: { cancel_line_button: ButtonModel, custom_fields: ButtonModel } = {
    cancel_line_button: new ButtonModel('cancel_line_button', new ButtonStyles(null, null), false, false, false, 'Cancel line', '', null)
,custom_fields: new ButtonModel('custom_fields', new ButtonStyles(null, null), false, false, false, 'Custom fields', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    net_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    tare_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    gross_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    licenseplate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    serialnumber_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    line_number: null,
    status: null,
    material: null,
    packaging: null,
    packaged_amount: null,
    available: null,
    non_allocatable: null,
    lot: null,
    vendorlot: null,
    net_weight: null,
    tare_weight: null,
    gross_weight: null,
    uom: null,
    licenseplate: null,
    serialnumber: null,
    notes: null,
  }

  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.lot.editControl.value;
    
    return expr;
  }
  get $fields_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.lot.editControl.value;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_packagingId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.packaging.editControl.value;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }
  get $fields_serialnumber_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.lot.editControl.value;
    
    return expr;
  }
  get $fields_serialnumber_selector_inParams_packagingId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.packaging.editControl.value;
    
    return expr;
  }
  get $fields_serialnumber_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }
  get $fields_serialnumber_selector_inParams_licenseplateId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.licenseplate.editControl.value;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_sales_order_line_components_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.line_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaged_amount,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.available = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.available,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.non_allocatable = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.non_allocatable,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.net_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.net_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['net_weight_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.tare_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.tare_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['tare_weight_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.gross_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.gross_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['gross_weight_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.uom = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.uom,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['uom_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.licenseplate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.licenseplate,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['licenseplate_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.serialnumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.serialnumber,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['serialnumber_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.formGroup
      .controls['net_weight_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_net_weight_changed();
      });
    this.formGroup
      .controls['tare_weight_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_tare_weight_changed();
      });
    this.formGroup
      .controls['gross_weight_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_gross_weight_changed();
      });
    this.formGroup
      .controls['licenseplate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_licenseplate_changed();
      });
    this.formGroup
      .controls['serialnumber_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_serialnumber_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_sales_order_line_components_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_sales_order_line_components_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'LineNumber',this.entity.LineNumber);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.order_id ,
      fullTextSearch:  $grid.fullTextSearch ,
      line_number:  $grid.inParams.line_number ,
    };
    const data = await this.$datasources.FootprintManager.ds_sales_order_line_components_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_sales_order_line_components_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.line_number.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity.MaterialId);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.InventoryMeasurementUnit?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.PackagedId);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity.PackagedAmount);
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity.LotId);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.VendorLotId);
    (this.cells.net_weight.displayControl as TextModel).text = $row.entity.NetWeight?.toString();
    (this.cells.net_weight.editControl as NumberBoxModel).reset($row.entity.NetWeight);
    (this.cells.gross_weight.displayControl as TextModel).text = $row.entity.GrossWeight?.toString();
    (this.cells.gross_weight.editControl as NumberBoxModel).reset($row.entity.GrossWeight);
    (this.cells.uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.uom.editControl as SelectBoxModel).reset($row.entity.WeightUomId);
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.LicensePlate?.LookupCode;
    (this.cells.licenseplate.editControl as SelectBoxModel).reset($row.entity.LicensePlateId);
    (this.cells.serialnumber.displayControl as TextModel).text = $row.entity.SerialNumber?.LookupCode;
    (this.cells.serialnumber.editControl as SelectBoxModel).reset($row.entity.SerialNumberId);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $row.entity = {
      OrderId: $grid.inParams.order_id,
      StatusId: 1,
      PackagedAmount: null
  };
  
  
  // Lock down the weight and weight uom fields
  $row.cells.net_weight.editControl.readOnly = true;
  $row.cells.net_weight.editControl.styles.setStyle('background', 'transparent');
  $row.cells.gross_weight.editControl.readOnly = true;
  $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
  $row.cells.tare_weight.editControl.readOnly = true;
  $row.cells.tare_weight.editControl.styles.setStyle('background', 'transparent');
  $row.cells.uom.editControl.readOnly = true;
  $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
  // Focus on the material cell
  $row.cells.material.editControl.focus();
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.material.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootprintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if (($utils.isDefined($row.cells.gross_weight.editControl.value) || $utils.isDefined($row.cells.gross_weight.editControl.value))
      && !$utils.isDefined($row.cells.uom.editControl.value)
  ) {
      $shell.FootprintManager.openErrorDialog('Save', 'Missing weight Uom');
      throw new Error('Missing weight Uom'); // to prevent displayMode 
  }
  
  if ($utils.isDefined($row.cells.serialnumber.editControl.value) && $row.cells.packaged_amount.editControl.value > 1) {
      $shell.FootprintManager.openErrorDialog('Save', 'Order line quantity cannot exceed 1 when a serial number is selected');
      throw new Error('Order line quantity cannot exceed 1 when a serial number is selected'); // to prevent displayMode 
  }
  
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootprintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  var orderStatusId = $grid.inParams.order_status_id;
  if (orderStatusId === 1) {
  
      try {
          var linenumber;
          var orderId = $grid.inParams.order_id;
  
  
  
          if (
              ($utils.isDefined($row.cells.gross_weight.editControl.value) || $utils.isDefined($row.cells.net_weight.editControl.value))
              && $utils.isDefined($row.cells.uom.editControl.value)
          ) {
              var grossWeight = $row.cells.gross_weight.editControl.value;
              var netWeight = $row.cells.net_weight.editControl.value;
              var uom = $row.cells.uom.editControl.value;
          }
  
          // Lot provided
          if ($utils.isDefined($row.cells.lot.editControl.value) && !$utils.isDefined($row.cells.serialnumber.editControl.value)) {
              const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  lotId: $row.cells.lot.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipment_id
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
  
          }
          // Vendor lot provided but lot is null
          else if ($utils.isDefined($row.cells.vendorlot.editControl.value) && !$utils.isDefined($row.cells.lot.editControl.value)
              && !$utils.isDefined($row.cells.serialnumber.editControl.value)) {
              const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  vendorlotId: $row.cells.vendorlot.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipment_id
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
  
          }
          // Serial provided
          else if ($utils.isDefined($row.cells.serialnumber.editControl.value)) {
              const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                  orderId: orderId,
                  serialnumberId: $row.cells.serialnumber.editControl.value,
                  shipmentId: $grid.inParams.shipment_id
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
  
          }
          // Material only provided
          else {
              const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  materialId: $row.cells.material.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipment_id
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
          }
  
          let payload: any = {};
  
          if ($utils.isDefinedTrimmed($row.cells.notes.editControl.value)) {
              payload.Notes = $row.cells.notes.editControl.value;
          }
  
          if ($utils.isAllDefined(linenumber, orderId, $row.cells.licenseplate.editControl.value)) {
              payload.LicensePlateId = $row.cells.licenseplate.editControl.value;
          }
  
          if ($utils.isDefined($grid.inParams.line_number)){
              payload.ParentLineNumber = $grid.inParams.line_number;
          }
  
          if (
              $utils.isAllDefined(linenumber, orderId, uom) && ($utils.isDefined(grossWeight) || $utils.isDefined(netWeight))
          ) {
              payload.GrossWeight = $utils.isDefined($row.cells.gross_weight.editControl.value) ? $row.cells.gross_weight.editControl.value : $row.cells.net_weight.editControl.value;
              payload.NetWeight = $utils.isDefined($row.cells.net_weight.editControl.value) ? $row.cells.net_weight.editControl.value : $row.cells.gross_weight.editControl.value;
              payload.WeightUomId = $row.cells.uom.editControl.value;
          }
  
          if (Object.keys(payload).length > 0) {
              await $flows.Utilities.crud_update_dual_pk_flow({
                  entitySet: 'OrderLines',
                  pk1String: 'OrderId',
                  pk1: orderId,
                  pk2String: 'LineNumber',
                  pk2: linenumber,
                  entity: payload
              });
          }
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  }
  
  
  else {
      $shell.FootprintManager.openErrorDialog('Save', 'Order is not in a created or processing status');
      throw new Error('Order is not in a created or processing status'); // to prevent displayMode 
  
  }
  
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Clear licenseplate
  $row.cells.licenseplate.editControl.value = null;
  
  // Clear serialnumbers
  $row.cells.serialnumber.editControl.value = null
  
  // Vendor Lot
  if ($utils.isDefined($row.cells.lot.editControl.value)) {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({
          lotId: $row.cells.lot.editControl.value
      })).result;
  
      if ($utils.isDefined(lot)) {
          $row.cells.vendorlot.editControl.value = lot[0].VendorLotId;
      }
  } else {
      $row.cells.vendorlot.editControl.value = null;
  }
  
  const materialId = $row.cells.material.editControl.value;
  const packagedId = $utils.isDefined($row.cells.packaging.editControl.value) ? $row.cells.packaging.editControl.value : $row.entity.PackagedId;
  const lotId = $row.cells.lot.editControl.value;
  const warehouseId = $grid.inParams.warehouse_id;
  const licenseplateId = $utils.isDefined($row.cells.licenseplate.editControl.value) ? $row.cells.licenseplate.editControl.value : $row.entity.LicensePlateId;
  const serialnumberId = $utils.isDefined($row.cells.serialnumber.editControl.value) ? $row.cells.serialnumber.editControl.value : $row.entity.SerialNumberId;
  
  // Set available cell
  const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
      materialId: materialId,
      licenseplateId: licenseplateId,
      serialnumberId: serialnumberId,
      lotId: lotId,
      packagedId: packagedId,
      warehouseId: warehouseId
  }));
  if ($utils.isDefined(inventory)) {
      $row.cells.available.displayControl.text = inventory.availableHelper;
      $row.cells.non_allocatable.displayControl.text = inventory.nonallocatableHelper;
  }
  else {
      $row.cells.available.displayControl.text = '0'
      $row.cells.non_allocatable.displayControl.text = '0'
  }
  
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Clear related cells
  $row.cells.lot.editControl.value = null;
  $row.cells.licenseplate.editControl.value = null;
  $row.cells.serialnumber.editControl.value = null
  $row.cells.available.displayControl.text = null;
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Clear related cells
  $row.cells.packaging.editControl.value = null;
  $row.cells.gross_weight.editControl.value = null;
  $row.cells.net_weight.editControl.value = null;
  $row.cells.tare_weight.editControl.value = null;
  $row.cells.uom.editControl.value = null;
  $row.cells.lot.editControl.value = null;
  $row.cells.vendorlot.editControl.value = null;
  $row.cells.licenseplate.editControl.value = null;
  $row.cells.serialnumber.editControl.value = null
  $row.cells.gross_weight.displayControl.text = null;
  $row.cells.net_weight.displayControl.text = null;
  $row.cells.tare_weight.displayControl.text = null;
  $row.cells.uom.displayControl.text = null;
  $row.cells.lot.displayControl.text = null;
  $row.cells.vendorlot.displayControl.text = null;
  $row.cells.licenseplate.displayControl.text = null;
  $row.cells.serialnumber.displayControl.text = null;
  
  
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($utils.isDefined(materialId)) {
  
      // Default the packaging
      const defaultPackaging = (await $flows.Materials.get_material_default_packaging({ materialId: materialId }));
      var packagingId = defaultPackaging.packagingId;
  
      if ($utils.isDefined(packagingId)) {
  
          $row.cells.packaging.editControl.value = packagingId;
  
          // Default the decimal formatting
          const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
              materialId: materialId,
              packagingId: packagingId
          }));
  
          if ($utils.isDefined(decimalFormat)) {
              $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
          }
  
          var materialPackaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
              materialId: materialId,
              packagingId: packagingId
          })).result;
  
  
          if ($utils.isDefined(materialPackaging)) {
              var isBasePackaging = materialPackaging[0].IsBasePackaging;
          }
  
          // Set available cell
          const warehouseId = $grid.inParams.warehouse_id;
  
          const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
              materialId: materialId,
              packagedId: packagingId,
              warehouseId: warehouseId
          }));
          if ($utils.isDefined(inventory)) {
              $row.cells.available.displayControl.text = inventory.availableHelper;
              $row.cells.non_allocatable.displayControl.text = inventory.nonallocatableHelper;
          }
          else {
              $row.cells.available.displayControl.text = '0';
              $row.cells.non_allocatable.displayControl.text = '0';
          }
  
  
      }
  
  
      // Extract packaging detail
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      }))?.result;
  
      // Check if material is serial controlled and or lot controlled
  
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
      const materialIsFixedLength = material[0].IsFixedLength;
      const materialIsFixedVolume = material[0].IsFixedVolume;
  
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4 || materialIsFixedLength === false ||
          materialIsFixedVolume === false) {
          $row.cells.net_weight.editControl.readOnly = false;
          $row.cells.net_weight.editControl.styles.resetStyle();
          $row.cells.gross_weight.editControl.readOnly = false;
          $row.cells.gross_weight.editControl.styles.resetStyle();
          $row.cells.tare_weight.editControl.readOnly = false;
          $row.cells.tare_weight.editControl.styles.resetStyle();
          $row.cells.uom.editControl.readOnly = false;
          $row.cells.uom.editControl.styles.resetStyle();
          const weightUomId = packaging[0].WeightUomId;
          if ($utils.isDefined(weightUomId)) {
              $row.cells.uom.editControl.value = weightUomId;
          }
  
      } else {
          $row.cells.net_weight.editControl.readOnly = true;
          $row.cells.net_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.gross_weight.editControl.readOnly = true;
          $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.tare_weight.editControl.readOnly = true;
          $row.cells.tare_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.uom.editControl.readOnly = true;
          $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
      }
  
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
          $row.cells.lot.editControl.readOnly = false;
          $row.cells.lot.editControl.styles.resetStyle();
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
  
      } else {
          $row.cells.lot.editControl.readOnly = true;
          $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
      }
  
  
  
  }
  else {
  
      $row.cells.available.displayControl.text = null;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.entity.MaterialId;
  var lotId = $row.entity.LotId;
  var packagedId = $row.entity.PackagedId;
  var licenseplateId = $row.entity.LicensePlateId;
  var serialnumberId = $row.entity.SerialNumberId;
  var warehouseId = $grid.inParams.warehouse_id;
  var projectId = $grid.inParams.project_id;
  
  // Get packaging details
  if ($utils.isAllDefined(materialId, packagedId)) {
      var packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagedId
      })).result;
  
  
      if ($utils.isDefined(packaging)) {
          var isBasePackaging = packaging[0].IsBasePackaging;
      }
  }
  
  
  
  // Set Order Line Material
  if ($utils.isAllDefined(projectId, $row.entity.Material?.ProjectId)) {
      if (projectId != $row.entity.Material?.ProjectId) {
          $row.cells.material.displayControl.text = $row.entity.Material?.LookupCode + ' - ' + $row.entity.Material?.Project?.LookupCode
  
      } else {
          $row.cells.material.displayControl.text = $row.entity.Material?.LookupCode
      }
  }
  else {
      $row.cells.material.displayControl.text = $row.entity.Material?.LookupCode
  }
  
  if ($utils.isDefined(materialId)) {
  
      // Set available cell
      const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
          materialId: materialId,
          licenseplateId: licenseplateId,
          serialnumberId: serialnumberId,
          lotId: lotId,
          packagedId: packagedId,
          warehouseId: warehouseId
      }));
      if ($utils.isDefined(inventory)) {
          $row.cells.available.displayControl.text = inventory.availableHelper;
          $row.cells.non_allocatable.displayControl.text = inventory.nonallocatableHelper;
      }
      else {
          $row.cells.available.displayControl.text = '0'
          $row.cells.non_allocatable.displayControl.text = '0'
      }
  
  
  
      // Check if material is serial controlled and or lot controlled
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
      const materialIsFixedVolume = material[0].IsFixedVolume;
      const materialIsFixedLength = material[0].IsFixedLength;
  
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4 || materialIsFixedVolume === false ||
          materialIsFixedLength === false) {
          $row.cells.net_weight.editControl.readOnly = false;
          $row.cells.net_weight.editControl.styles.resetStyle();
          $row.cells.gross_weight.editControl.readOnly = false;
          $row.cells.gross_weight.editControl.styles.resetStyle();
          $row.cells.tare_weight.editControl.readOnly = false;
          $row.cells.tare_weight.editControl.styles.resetStyle();
          $row.cells.uom.editControl.readOnly = false;
          $row.cells.uom.editControl.styles.resetStyle();
  
      } else {
          $row.cells.net_weight.editControl.readOnly = true;
          $row.cells.net_weight.editControl.styles.setStyle('background', 'transparent')
          $row.cells.gross_weight.editControl.readOnly = true;
          $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.tare_weight.editControl.readOnly = true;
          $row.cells.tare_weight.editControl.styles.setStyle('background', 'transparent')
          $row.cells.uom.editControl.readOnly = true;
          $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
      }
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
          $row.cells.lot.editControl.readOnly = false;
          $row.cells.lot.editControl.styles.resetStyle();
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
  
  
      } else {
          $row.cells.lot.editControl.readOnly = true;
          $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
  
  
      }
  
  }
  
  
  
  // Set Expected / Actual calculation
  
  if ($grid.inParams.order_status_id !== 1 && $utils.isDefined($row.entity.PackagedAmount)) {
  
      const actualPicked = $utils.isDefined($row.entity.total_picked?.TotalCount) ? $row.entity.total_picked?.TotalCount : 0;
  
      const calculation = `${actualPicked} / ${$row.entity.PackagedAmount}`;
      $row.cells.packaged_amount.displayControl.text = calculation
  
  
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.material.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootprintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if ($row.cells.serialnumber.editControl.isChanged 
          && ($utils.isDefined($row.cells.serialnumber.editControl.value) || $utils.isDefined($row.entity.SerialNumberId) )
          && $row.cells.packaged_amount.editControl.value > 1
          ) {
      $shell.FootprintManager.openErrorDialog('Save', 'Order line quantity cannot exceed 1 when a serial number is selected');
      throw new Error('Order line quantity cannot exceed 1 when a serial number is selected'); // to prevent displayMode 
  }
  
  const payload: any = {};
  if ($row.cells.material.editControl.isChanged) {
      payload.MaterialId = $row.cells.material.editControl.value;
  }
  if ($row.cells.packaging.editControl.isChanged) {
      payload.PackagedId = $row.cells.packaging.editControl.value;
  }
  if ($row.cells.packaged_amount.editControl.isChanged) {
      if ($row.cells.packaged_amount.editControl.value <= 0) {
          $shell.FootprintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
          throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
      }
      else {
          payload.PackagedAmount = $row.cells.packaged_amount.editControl.value;
      }
  }
  if ($row.cells.lot.editControl.isChanged) {
      payload.LotId = $row.cells.lot.editControl.value;
  }
  if ($row.cells.vendorlot.editControl.isChanged) {
      payload.VendorLotId = $row.cells.vendorlot.editControl.value;
  }
  if ($row.cells.gross_weight.editControl.isChanged) {
      payload.GrossWeight = $row.cells.gross_weight.editControl.value;
  }
  if ($row.cells.net_weight.editControl.isChanged) {
      payload.NetWeight = $row.cells.net_weight.editControl.value;
  }
  if ($row.cells.uom.editControl.isChanged) {
      payload.WeightUomId = $row.cells.uom.editControl.value;
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  if ($row.cells.licenseplate.editControl.isChanged) {
      payload.LicensePlateId = $row.cells.licenseplate.editControl.value;
  }
  if ($row.cells.serialnumber.editControl.isChanged) {
      payload.SerialNumberId = $row.cells.serialnumber.editControl.value;
  }
  
  try {
  
  
      await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'OrderLines',
          pk1String: 'OrderId',
          pk1: $row.entity.OrderId,
          pk2String: 'LineNumber',
          pk2: $row.entity.LineNumber,
          entity: payload
      });
  
  
      await $row.refresh();
  } catch (error) {
      $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  // Clear licenseplate field
  $row.cells.licenseplate.editControl.value = null;
  // Clear serialnumbers
  $row.cells.serialnumber.editControl.value = null
  
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  const lotId = $row.cells.lot.editControl.value;
  const warehouseId = $grid.inParams.warehouse_id;
  const licenseplateId = $utils.isDefined($row.cells.licenseplate.editControl.value) ? $row.cells.licenseplate.editControl.value : $row.entity.LicensePlateId;
  const serialnumberId = $utils.isDefined($row.cells.serialnumber.editControl.value) ? $row.cells.serialnumber.editControl.value : $row.entity.SerialNumberId;
  
  if ($utils.isAllDefined(materialId, packagingId)) {
  
      // Default to base material packaging
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      }))?.result;
  
      if ($utils.isDefined(packaging)) {
          if ($utils.isDefined(packaging[0].WeightUomId)) {
              const uomId = packaging[0].WeightUomId;
              $row.cells.uom.editControl.value = uomId;
          }
  
  
          var isBasePackaging = packaging[0].IsBasePackaging;
  
      }
  
      // Set available cell
      const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
          materialId: materialId,
          licenseplateId: licenseplateId,
          serialnumberId: serialnumberId,
          lotId: lotId,
          packagedId: packagingId,
          warehouseId: warehouseId
      }));
      if ($utils.isDefined(inventory)) {
          $row.cells.available.displayControl.text = inventory.availableHelper;
          $row.cells.non_allocatable.displayControl.text = inventory.nonallocatableHelper;
      }
      else {
          $row.cells.available.displayControl.text = '0';
          $row.cells.non_allocatable.displayControl.text = '0';
      }
  
  
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
          materialId: materialId,
          packagingId: packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
      }
  
  }
  }
  on_serialnumber_changed(event = null) {
    return this.on_serialnumber_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_serialnumber_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Clear licenseplate
  $row.cells.licenseplate.editControl.value = null;
  
  }
  on_licenseplate_changed(event = null) {
    return this.on_licenseplate_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Clear serialnumber
  $row.cells.serialnumber.editControl.value = null;
  
  }
  on_net_weight_changed(event = null) {
    return this.on_net_weight_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_net_weight_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($row.cells.net_weight.editControl.value) && $row.cells.net_weight.editControl.value > 0) {
  
      if ($utils.isDefined($row.cells.tare_weight.editControl.value)) {
  
          $row.cells.gross_weight.editControl.value = $row.cells.net_weight.editControl.value + $row.cells.tare_weight.editControl.value
      }
  }
  }
  on_gross_weight_changed(event = null) {
    return this.on_gross_weight_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_gross_weight_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($row.cells.gross_weight.editControl.value) && $row.cells.gross_weight.editControl.value > 0) {
  
      if ($utils.isDefined($row.cells.tare_weight.editControl.value)) {
  
          $row.cells.net_weight.editControl.value = $row.cells.gross_weight.editControl.value - $row.cells.tare_weight.editControl.value
      }
  }
  }
  on_tare_weight_changed(event = null) {
    return this.on_tare_weight_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_tare_weight_changedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($row.cells.tare_weight.editControl.value)) {
  
      if ($utils.isAllDefined($row.cells.net_weight.editControl.value, $row.cells.gross_weight.editControl.value)) {
  
          $row.cells.gross_weight.editControl.value = $row.cells.net_weight.editControl.value + $row.cells.tare_weight.editControl.value
      }
  }
  }
  on_cancel_line_clicked(event = null) {
    return this.on_cancel_line_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_line_clickedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let reasonCode = (await $shell.FootprintManager.opensales_order_cancellation_options_formDialog({ parentId: 45, parentEntity: 'OperationType' }))
  var lineNumber: number[] = [];
  
  if ($utils.isDefined(reasonCode.confirm)) {
      lineNumber.push($row.entity.LineNumber)
      let cancelResponse = (await $flows.SalesOrders.cancel_order_line_flow({ orderId: $grid.inParams.order_id, orderLineNumbers: lineNumber, reasonCodeId: reasonCode.reasonCodeId })).reasons
      if ($utils.isDefined(cancelResponse)) {
          $shell.FootprintManager.openErrorDialog(`Failed to Cancel`, cancelResponse.join(' '))
      }
      await $row.refresh();
  }
  for (let row of $grid.rows) {
      if (row.entity.Status.Name !== 'Canceled') {
          var stillProcessing = true
      } else {
          stillProcessing = false
      }
  }
  if (stillProcessing = false) {
      //send hub refresh.
  }
  
  }
  on_custom_fields_clicked(event = null) {
    return this.on_custom_fields_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_custom_fields_clickedInternal(
    $row: FootprintManager_sales_order_line_components_gridComponentRowModel,
  $grid: FootprintManager_sales_order_line_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootprintManager.openentity_user_defined_field_values_gridDialog({ entityType: 'OrderLine', entityKeys: [{name: 'OrderId', value: $row.entity.OrderId},{name: 'LineNumber', value: $row.entity.LineNumber}]}, 'flyout');
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => FootprintManager_lots_dd_singleComponent),
    forwardRef(() => FootprintManager_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_measurement_units_dd_singleComponent),
    forwardRef(() => Inventory_licenseplate_by_inventory_dd_singleComponent),
    forwardRef(() => Inventory_serialnumbers_by_inventory_dd_singleComponent),
  ],
  selector: 'FootprintManager-sales_order_line_components_grid',
  templateUrl: './FootprintManager.sales_order_line_components_grid.component.html'
})
export class FootprintManager_sales_order_line_components_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_sales_order_line_components_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_sales_order_line_components_gridComponentInParams = { order_id: null, shipment_id: null, project_id: null, order_status_id: null, warehouse_id: null, line_number: null };


  //#region Variables
  vars: { kit_view?: boolean } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'line_number', '#', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', true, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'Quantity', true, false, null, false),       available: new GridHeaderModel(new HeaderStyles(null, null), 'available', 'Available', false, false, null, false),       non_allocatable: new GridHeaderModel(new HeaderStyles(null, null), 'non_allocatable', 'Non allocatable', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       net_weight: new GridHeaderModel(new HeaderStyles(null, null), 'net_weight', 'Net weight', false, false, null, false),       tare_weight: new GridHeaderModel(new HeaderStyles(null, null), 'tare_weight', 'Tare', false, false, null, false),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'gross_weight', 'Gross weight', false, false, null, false),       uom: new GridHeaderModel(new HeaderStyles(null, null), 'uom', 'UOM', false, false, null, false),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate', 'License plate', false, false, null, false),       serialnumber: new GridHeaderModel(new HeaderStyles(null, null), 'serialnumber', 'Serial number', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_sales_order_line_components_gridComponentRowModel[] = [];

  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('shipment_id') set $inParams_shipment_id(value: any) {
    this.inParams['shipment_id'] = value;
  }
  get $inParams_shipment_id(): any {
    return this.inParams['shipment_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('order_status_id') set $inParams_order_status_id(value: any) {
    this.inParams['order_status_id'] = value;
  }
  get $inParams_order_status_id(): any {
    return this.inParams['order_status_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('line_number') set $inParams_line_number(value: any) {
    this.inParams['line_number'] = value;
  }
  get $inParams_line_number(): any {
    return this.inParams['line_number'] ;
  }

  topToolbar = {
      add_by_inventory: new ToolModel(new ButtonModel('add_by_inventory', new ButtonStyles(null, null), false, false, false, 'Add lines by inventory', 'icon-ic_fluent_add_20_regular', null)
    , false),
      select_inventory: new ToolModel(new ButtonModel('select_inventory', new ButtonStyles(null, null), false, false, false, 'Select inventory', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Sales order line kit components';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.shipment_id)) {
        this.$missingRequiredInParams.push('shipment_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.order_status_id)) {
        this.$missingRequiredInParams.push('order_status_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.line_number)) {
        this.$missingRequiredInParams.push('line_number');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.order_id ,
      fullTextSearch:  $grid.fullTextSearch ,
      line_number:  $grid.inParams.line_number ,
    };
    try {
      const data = await this.$datasources.FootprintManager.ds_sales_order_line_components_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_sales_order_line_components_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_sales_order_line_components_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_sales_order_line_components_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_sales_order_line_components_gridComponentEntity) {
    const row = new FootprintManager_sales_order_line_components_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Initialize
  $grid.vars.kit_view = false;
  
  
  // Disable Delete button
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  
  const orderStatusId = $grid.inParams.order_status_id;
  
  if (orderStatusId === 1) {
  
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.canEdit = true;
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
      // Initialize this to Quantity if the revert feature was used
      $grid.headers.packaged_amount.name = 'Quantity';
      $grid.topToolbar.add_by_inventory.control.readOnly = false;
      $grid.topToolbar.select_inventory.hidden = true;
  
  
  }
  else if (orderStatusId === 2) {
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
      $grid.canEdit = false;
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.headers.packaged_amount.name = 'Picked';
      $grid.topToolbar.select_inventory.hidden = true;
      $grid.topToolbar.add_by_inventory.hidden = true;
  
  }
  else {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = false;
      $grid.headers.material.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.packaged_amount.required = false;
      $grid.headers.packaged_amount.name = 'Picked';
      $grid.topToolbar.select_inventory.hidden = true;
      $grid.topToolbar.add_by_inventory.hidden = true;
  
  }
  
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  const orderStatusId = $grid.inParams.order_status_id;
  
  const selectedRowsCount = $grid.selectedRows.length;
  const selectedRow = $grid.selectedRows;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount > 1 && orderStatusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.add_by_inventory.hidden = true;
      $grid.topToolbar.select_inventory.hidden = true;
   
  
  } else if (selectedRowsCount === 1 && orderStatusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.add_by_inventory.hidden = true;
      $grid.topToolbar.select_inventory.hidden = false;
  
  }
  else if (selectedRowsCount === 0 && orderStatusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.add_by_inventory.hidden = false;
      $grid.topToolbar.select_inventory.hidden = true;
  
  }
  
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.add_by_inventory.hidden = true;
      $grid.topToolbar.select_inventory.hidden = true;
  }
  
  
  
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Order Line Delete Error', 'No order lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.OrderId,
              lineNumber: row.entity.LineNumber
          };
          const result = await $flows.SalesOrders.is_sales_order_line_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order Line ${row.entity.LineNumber.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete order line errors';
          const errorMessage = `Order line(s) ${failures.map(c => c.entity.LineNumber.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order line(s) ${candidates.map(c => c.entity.LineNumber.toString()).join(',')} - Once deleted, the order line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order line(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following order line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.OrderId,
                          lineNumber: candidate.entity.LineNumber
                      }
                      const result = await $flows.SalesOrders.delete_sales_order_line_flow(flowParams);
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          processFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                      } else {
                          processSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order line ${candidate.entity.LineNumber.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All order line(s) deleted';
                  const message = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} deleted`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All order line(s) failed to delete';
                      const message = `Order line(s) ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')} could not be deleted`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order lines could not be deleted';
                      const success = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} were deleted.`;
                      const errors = `The following order line(s) could not be deleted: ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_add_by_inventory_clicked(event = null) {
    return this.on_add_by_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_by_inventory_clickedInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = await $shell.FootprintManager.openinventory_type_selection_formDialog();
  const confirmResult = dialogResult.confirm;
  const inventoryTypeResult = dialogResult.inventoryTypeId;
  if (confirmResult && $utils.isDefined(inventoryTypeResult)) {
  
      // Initialize variables
      var wizardResult;
      var inventorySelectionResults;
  
      if (inventoryTypeResult == 1) {
  
          wizardResult = await $shell.FootprintManager.openinventory_by_lp_selection_wizardDialog({
              warehouseId: $grid.inParams.warehouse_id,
              projectId: $grid.inParams.project_id
          });
          inventorySelectionResults = wizardResult.inventory;
      }
  
      else if (inventoryTypeResult == 2) {
  
          wizardResult = await $shell.FootprintManager.openinventory_by_lot_selection_wizardDialog({
              warehouseId: $grid.inParams.warehouse_id,
              projectId: $grid.inParams.project_id
          });
          inventorySelectionResults = wizardResult.inventory;
      }
  
      else if (inventoryTypeResult == 3) {
  
          wizardResult = await $shell.FootprintManager.openinventory_by_serialnumber_selection_wizardDialog({
              warehouseId: $grid.inParams.warehouse_id,
              projectId: $grid.inParams.project_id
          });
          inventorySelectionResults = wizardResult.inventory;
      }
  
      if ($utils.isDefined(inventorySelectionResults)) {
  
          for (const inventorySelectionResult of inventorySelectionResults) {
              const packagedAmount = inventorySelectionResult.packagedAmount;
              const lotId = inventorySelectionResult.lotId;
              const packagingId = inventorySelectionResult.packagingId;
              const licenseplateId = inventorySelectionResult.licenseplateId;
              const serialnumberId = inventorySelectionResult.serialnumberId;
              try {
  
                  if ($utils.isAllDefined(lotId, packagingId, packagedAmount) && !$utils.isDefined(serialnumberId)) {
                      const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                          orderId: $grid.inParams.order_id,
                          packagedAmount: packagedAmount,
                          lotId: lotId,
                          packagingId: packagingId,
                          shipmentId: $grid.inParams.shipment_id
                      }));
  
                      if ($utils.isDefined(orderLine)) {
  
                          if ($utils.isDefined(orderLine.linenumber)) {
                              var linenumber = orderLine.linenumber;
  
                          }
                          else {
                              throw new Error('Unable to create order line.')
                          }
                      }
                      else {
                          throw new Error('Unable to create order line.')
                      }
  
  
                      if ($utils.isDefined(licenseplateId)) {
                          const payload: any = {};
  
                          payload.LicensePlateId = licenseplateId;
  
  
                          await $flows.Utilities.crud_update_dual_pk_flow({
                              entitySet: 'OrderLines',
                              pk1String: 'OrderId',
                              pk1: $grid.inParams.order_id,
                              pk2String: 'LineNumber',
                              pk2: linenumber,
                              entity: payload
                          });
                      }
                  }
  
                  else if ($utils.isAllDefined(serialnumberId)) {
                      const orderLine = (await $flows.SalesOrders.create_sales_order_line_flow({
                          orderId: $grid.inParams.order_id,
                          serialnumberId: serialnumberId,
                          shipmentId: $grid.inParams.shipment_id
                      }));
  
                      if (!$utils.isDefined(orderLine)) {
  
                          throw new Error('Unable to create order line.')
  
                      }
  
                  }
  
  
                  // Tie new line to parent order line
                  let payload: any = {};
  
  
                  if ($utils.isDefined($grid.inParams.line_number)) {
                      payload.ParentLineNumber = $grid.inParams.line_number;
                  }
  
                  if (Object.keys(payload).length > 0) {
                      await $flows.Utilities.crud_update_dual_pk_flow({
                          entitySet: 'OrderLines',
                          pk1String: 'OrderId',
                          pk1: $grid.inParams.order_id,
                          pk2String: 'LineNumber',
                          pk2: linenumber,
                          entity: payload
                      });
                  }
  
  
              } catch (error) {
                  $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
                  throw error; // to prevent displayMode 
              }
  
  
  
          }
  
  
          await $grid.refresh();
  
      }
  
  
  
  }
  }
  on_select_inventory_clicked(event = null) {
    return this.on_select_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_inventory_clickedInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = await $shell.FootprintManager.openinventory_type_selection_formDialog();
  const confirmResult = dialogResult.confirm;
  const inventoryTypeResult = dialogResult.inventoryTypeId;
  if (confirmResult && $utils.isDefined(inventoryTypeResult)) {
  
      // Initialize variables
      var wizardResult;
      var inventorySelectionResults;
  
  
  
      if (inventoryTypeResult == 1) {
  
          wizardResult = await $shell.FootprintManager.openinventory_by_lp_single_selection_wizardDialog({
              warehouseId: $grid.inParams.warehouse_id,
              projectId: $grid.inParams.project_id,
              materialId: $grid.selectedRows[0].entity.MaterialId,
              packagingId: $grid.selectedRows[0].entity.PackagedId,
              lotId: $grid.selectedRows[0].entity.LotId,
              packagedAmountRequest: $grid.selectedRows[0].entity.PackagedAmount
          });
          inventorySelectionResults = wizardResult.inventory;
      }
  
      else if (inventoryTypeResult == 2) {
  
          wizardResult = await $shell.FootprintManager.openinventory_by_lot_single_selection_wizardDialog({
              warehouseId: $grid.inParams.warehouse_id,
              projectId: $grid.inParams.project_id,
              materialId: $grid.selectedRows[0].entity.MaterialId,
              packagingId: $grid.selectedRows[0].entity.PackagedId,
              packagedAmountRequest: $grid.selectedRows[0].entity.PackagedAmount
          });
          inventorySelectionResults = wizardResult.inventory;
      }
  
      else if (inventoryTypeResult == 3) {
  
          if ($grid.selectedRows[0].entity.PackagedAmount > 1) {
              $shell.FootprintManager.openErrorDialog('Serial selection error', 'Order line quantity cannot exceed 1 when a serial number is selected.');
              return;
          }
          
          else {
              wizardResult = await $shell.FootprintManager.openinventory_by_serialnumber_single_selection_wizardDialog({
                  warehouseId: $grid.inParams.warehouse_id,
                  projectId: $grid.inParams.project_id,
                  materialId: $grid.selectedRows[0].entity.MaterialId,
                  packagingId: $grid.selectedRows[0].entity.PackagedId,
                  lotId: $grid.selectedRows[0].entity.LotId
              });
              inventorySelectionResults = wizardResult.inventory;
          }
      }
  
      if ($utils.isDefined(inventorySelectionResults)) {
  
          const lotId = inventorySelectionResults[0].lotId;
          const vendorLotId = inventorySelectionResults[0].vendorLotId;
          const licenseplateId = inventorySelectionResults[0].licenseplateId;
          const serialnumberId = inventorySelectionResults[0].serialnumberId;
          try {
  
  
  
              const linenumber = $grid.selectedRows[0].entity.LineNumber;
  
  
              const payload: any = {};
  
  
              payload.LotId = $utils.isDefined(lotId) ? lotId : null;
              payload.VendorLotId = $utils.isDefined(vendorLotId) ? vendorLotId : null;
              payload.LicensePlateId = $utils.isDefined(licenseplateId) ? licenseplateId : null;
              payload.SerialNumberId = $utils.isDefined(serialnumberId) ? serialnumberId : null;
  
  
  
              await $flows.Utilities.crud_update_dual_pk_flow({
                  entitySet: 'OrderLines',
                  pk1String: 'OrderId',
                  pk1: $grid.inParams.order_id,
                  pk2String: 'LineNumber',
                  pk2: linenumber,
                  entity: payload
              });
  
  
  
  
  
  
  
          } catch (error) {
              $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
  
  
  
  
  
          await $grid.refresh();
  
      }
  
  
  
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_sales_order_line_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_sales_order_line_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var orderLineNumbers: number[] = [];
  for (let row of $grid.rows) {
      orderLineNumbers.push(row.entity.LineNumber)
  }
  
  let pickingInfo = (await $datasources.SalesOrders.ds_get_tasks_by_orderId_orderLineNumbers.get({ orderId: $grid.inParams.order_id, orderLineNumbers: orderLineNumbers })).result
  
  for (let pick of pickingInfo) {
      var pickCheck = (await $flows.SalesOrders.is_pick_task_cancelable_flow({ taskId: pick.Id })).reason
      if ($utils.isDefined(pickCheck)) {
          for (let row of $grid.rows) {
              if (pick.OrderLineNumber === row.entity.LineNumber)
                  row.options.cancel_line_button.readOnly = true;
          }
      }
  }
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
