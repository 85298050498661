<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="finished_product">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.finished_product.displayControl.styles.style"
          [ngClass]="row.cells.finished_product.displayControl.styles.classes"
          [matTooltip]="row.cells.finished_product.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.finished_product.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="produced_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.produced_quantity.displayControl.styles.style"
          [ngClass]="row.cells.produced_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.produced_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.produced_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="quantity_to_unmake">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.quantity_to_unmake.displayControl.styles.style"
          [ngClass]="row.cells.quantity_to_unmake.displayControl.styles.classes"
          [matTooltip]="row.cells.quantity_to_unmake.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.quantity_to_unmake.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_to_unmake_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity_to_unmake.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.quantity_to_unmake.editControl.readOnly || row.cells.quantity_to_unmake.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity_to_unmake.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity_to_unmake.editControl.styles.style"
            [ngClass]="row.cells.quantity_to_unmake.editControl.styles.classes"
            [matTooltip]="row.cells.quantity_to_unmake.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="unmake_from_lp">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.unmake_from_lp.displayControl.styles.style"
          [ngClass]="row.cells.unmake_from_lp.displayControl.styles.classes"
          [matTooltip]="row.cells.unmake_from_lp.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.unmake_from_lp.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-licenseplates_by_location_single 
        data-cy="selector"
        [type]="row.cells.unmake_from_lp.editControl.type"
        [formControl]="row.formGroup.controls['unmake_from_lp_edit']"
        (displayTextChange)="row.cells.unmake_from_lp.editControl.displayText=$event"
        [placeholder]="row.cells.unmake_from_lp.editControl.placeholder"
        [styles]="row.cells.unmake_from_lp.editControl.styles"
        [tooltip]="row.cells.unmake_from_lp.editControl.tooltip"
      [location_id]="row.$fields_unmake_from_lp_selector_inParams_location_id"
    >
    </Manufacturing-licenseplates_by_location_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="unmake_from_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.unmake_from_location.displayControl.styles.style"
          [ngClass]="row.cells.unmake_from_location.displayControl.styles.classes"
          [matTooltip]="row.cells.unmake_from_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.unmake_from_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-manufacturing_output_locations_dd_single 
        data-cy="selector"
        [type]="row.cells.unmake_from_location.editControl.type"
        [formControl]="row.formGroup.controls['unmake_from_location_edit']"
        (displayTextChange)="row.cells.unmake_from_location.editControl.displayText=$event"
        [placeholder]="row.cells.unmake_from_location.editControl.placeholder"
        [styles]="row.cells.unmake_from_location.editControl.styles"
        [tooltip]="row.cells.unmake_from_location.editControl.tooltip"
      [warehouse_id]="row.$fields_unmake_from_location_selector_inParams_warehouse_id"
      [project_id]="row.$fields_unmake_from_location_selector_inParams_project_id"
    >
    </Manufacturing-manufacturing_output_locations_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
