import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_orders_grid_ComponentContextService } from './Manufacturing.manufacturing_orders_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent } from './Manufacturing.manufacturnig_order_production_confirmation_dd_single.component'
import { Manufacturing_manufacturing_order_date_types_dd_singleComponent } from './Manufacturing.manufacturing_order_date_types_dd_single.component'


interface IManufacturing_manufacturing_orders_gridComponentEntity {
  Id?: number, DefaultStagingLocationId?: number, LookupCode?: string, ManufacturingLocationId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Status?: { Id?: number, Name?: string }, DefaultStagingLocation?: { Id?: number, Name?: string }, ManufacturingLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, OrderLines?: { Id?: number, StatusId?: number }[]}

interface IManufacturing_manufacturing_orders_gridComponentInParams {
  warehouse_ids?: number[], owner_id?: number, project_id?: number, status_ids?: number[]}


class Manufacturing_manufacturing_orders_gridComponentRowModel extends GridRowModel {
  grid: Manufacturing_manufacturing_orders_gridComponent;
  entity: IManufacturing_manufacturing_orders_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    order: null,
    status: null,
    wave: null,
    production_confirmation: null,
    schedule_type: null,
    next_schedule_date: null,
    staging_location: null,
    output_location: null,
    customer: null,
    project: null,
    warehouse: null,
  }



  constructor(
    grid: Manufacturing_manufacturing_orders_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.order = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.order,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.wave = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.wave,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.production_confirmation = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.production_confirmation,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.schedule_type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.schedule_type,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.next_schedule_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.next_schedule_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.staging_location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.staging_location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.output_location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.output_location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.customer = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.customer,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IManufacturing_manufacturing_orders_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IManufacturing_manufacturing_orders_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouse_ids:  $grid.inParams.warehouse_ids ,
      project_id:  $grid.inParams.project_id ,
      owner_id:  $grid.inParams.owner_id ,
      status_ids:  $grid.inParams.status_ids ,
      from_date:  $grid.filters.date_from.control.value ,
      to_date:  $grid.filters.date_to.control.value ,
      date_type:  $grid.filters.date_type.control.value ,
      production_confirmation:  $grid.filters.product_confirmation.control.value ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_orders_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IManufacturing_manufacturing_orders_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.order.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status.Name;
    (this.cells.staging_location.displayControl as TextModel).text = $row.entity.DefaultStagingLocation?.Name;
    (this.cells.output_location.displayControl as TextModel).text = $row.entity.ManufacturingLocation?.Name;
    (this.cells.customer.displayControl as TextModel).text = $row.entity.Project.Owner.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project.Name;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse.Name;

    await this.on_row_init();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'order' : {
        this.on_manufacturing_order_click();
        break;
      }
    }
  }

  //#region private flows
  on_manufacturing_order_click(event = null) {
    return this.on_manufacturing_order_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manufacturing_order_clickInternal(
    $row: Manufacturing_manufacturing_orders_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_orders_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_manufacturing_order_click');
  $shell.Manufacturing.openmanufacturing_order_editor({ manufacturing_order_id: $row.entity.Id });
  }
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: Manufacturing_manufacturing_orders_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_orders_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_row_init');
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_order_date_types_dd_singleComponent),
  ],
  selector: 'Manufacturing-manufacturing_orders_grid',
  templateUrl: './Manufacturing.manufacturing_orders_grid.component.html'
})
export class Manufacturing_manufacturing_orders_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManufacturing_manufacturing_orders_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);


  inParams: IManufacturing_manufacturing_orders_gridComponentInParams = { warehouse_ids: [], owner_id: null, project_id: null, status_ids: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     order: new GridHeaderModel(new HeaderStyles(null, null), 'order', 'Manufacturing order', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       wave: new GridHeaderModel(new HeaderStyles(null, null), 'wave', 'Wave', false, false, null, false),       production_confirmation: new GridHeaderModel(new HeaderStyles(null, null), 'production_confirmation', 'Production confirmation', false, false, null, false),       schedule_type: new GridHeaderModel(new HeaderStyles(null, null), 'schedule_type', 'Schedule type', false, false, null, false),       next_schedule_date: new GridHeaderModel(new HeaderStyles(null, null), 'next_schedule_date', 'Next scheduled date', false, false, null, false),       staging_location: new GridHeaderModel(new HeaderStyles(null, null), 'staging_location', 'Staging location', false, false, null, false),       output_location: new GridHeaderModel(new HeaderStyles(null, null), 'output_location', 'Output location', false, false, null, false),       customer: new GridHeaderModel(new HeaderStyles(null, null), 'customer', 'Customer', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Manufacturing_manufacturing_orders_gridComponentRowModel[] = [];

  @Input('warehouse_ids') set $inParams_warehouse_ids(value: any) {
    this.inParams['warehouse_ids'] = value;
  }
  get $inParams_warehouse_ids(): any {
    return this.inParams['warehouse_ids'] ;
  }
  @Input('owner_id') set $inParams_owner_id(value: any) {
    this.inParams['owner_id'] = value;
  }
  get $inParams_owner_id(): any {
    return this.inParams['owner_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('status_ids') set $inParams_status_ids(value: any) {
    this.inParams['status_ids'] = value;
  }
  get $inParams_status_ids(): any {
    return this.inParams['status_ids'] ;
  }

  topToolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false),
      confirm_production: new ToolModel(new ButtonModel('confirm_production', new ButtonStyles(null, null), false, false, false, 'Confirm production', 'ms-Icon ms-Icon--Accept', null)
    , false),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      schedule_order: new ToolModel(new ButtonModel('schedule_order', new ButtonStyles(null, null), false, false, false, 'Schedule order', 'icon-ic_fluent_calendar_clock_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new SplitButtonModel<{ print: ButtonModel }>(
        'print',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('print', new ButtonStyles(null, null), false, false, false, 'Print', '')
        ])
    , false),
      additional_menu: new ToolModel(new SplitButtonModel<{ select_fields: ButtonModel }>(
        'additional_menu',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'ms-Icon ms-Icon--BulletedListText',
        null,
        [
          new ButtonModel('select_fields', new ButtonStyles(null, null), false, false, false, 'Select fields', '')
        ])
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    product_confirmation: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    product_confirmation: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['product_confirmation'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Product confirmation', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'From', false, false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'To', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Orders';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;

    (this.filters.date_type.control as SelectBoxModel).reset('CreatedSysDateTime');

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouse_ids:  $grid.inParams.warehouse_ids ,
      project_id:  $grid.inParams.project_id ,
      owner_id:  $grid.inParams.owner_id ,
      status_ids:  $grid.inParams.status_ids ,
      from_date:  $grid.filters.date_from.control.value ,
      to_date:  $grid.filters.date_to.control.value ,
      date_type:  $grid.filters.date_type.control.value ,
      production_confirmation:  $grid.filters.product_confirmation.control.value ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_orders_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IManufacturing_manufacturing_orders_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Manufacturing_manufacturing_orders_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Manufacturing_manufacturing_orders_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_cancel_click(event = null) {
    return this.on_cancel_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_cancel_click');
  if( $grid.selectedRows.length > 1 )
  {
      await $shell.Utilities.openErrorDialog('Order canceling', `Please select only one order for cancelling.`);
      return;
  }
  
  const order = $grid.selectedRows[0].entity;
  
  const result = await $flows.Manufacturing.is_manufacturing_order_cancelable_flow({ 
      order_id: order.Id});
      
  const reasons = result.reasons;
  
  if ($utils.isDefined(reasons)) {
      await $shell.Utilities.openErrorDialog('Order cannot be canceled.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.Utilities.openConfirmationDialog('Cancel order', `Are you sure you want to cancel order ${order.LookupCode}?`, 'Yes', 'No');
      if (confirm) {
          try {
  
              const result = await $flows.Manufacturing.cancel_manufacturing_order_flow({ order_id: order.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.Utilities.openErrorDialog('Order cannot be cancelled.', `${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
                  return;
              }
              else {
  
                  const title = `Order ${order.LookupCode} cancelled`;
                  $shell.Utilities.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
                  await $grid.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${order.LookupCode} - ${errorMessage}`;
              await $shell.Utilities.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_grid_data_loaded');
  $grid.topToolbar.process.control.readOnly = true;
  $grid.topToolbar.cancel.control.readOnly = true;
  $grid.topToolbar.complete.control.readOnly = true;
  $grid.topToolbar.confirm_production.control.readOnly = true;
  $grid.topToolbar.schedule_order.control.readOnly = true;
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_process_clicked');
  if( $grid.selectedRows.length > 1 )
  {
      await $shell.Utilities.openErrorDialog('Order processing', `Please select only one order for processing.`);
      return;
  }
  }
  on_order_complete(event = null) {
    return this.on_order_completeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_completeInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_order_complete');
  const candidates = [];
  const failures = [];
  const errors = [];
  
  for (const row of $grid.selectedRows) {
      const flowParams = {
          order_id: row.entity.Id,
      };
      const result = await $flows.Manufacturing.is_manufacturing_order_cancelable_flow(flowParams);
      const reasons = result.reasons;
      if ($utils.isDefined(reasons)) {
          failures.push(row);
          errors.push(`Order ${row.entity.LookupCode.toString()} ${reasons.map(r => r.toLowerCase()).join(', ').replace(/, ([^,]*)$/, ', and $1')}.`);
      } else {
          candidates.push(row);
      }
  }
  
  // no candidate
  if (candidates.length === 0) {
      const title = 'Complete order errors';
      const errorMessage = `Order(s) ${failures.map(c => c.entity.LookupCode.toString()).join(',')} cannot be completed`;
      const errorList = errors;
      await $shell.Utilities.openErrorDialog(title, errorMessage, errorList);
      return;
  } else {
      const confirmCandidates = `Order(s) ${candidates.map(c => c.entity.LookupCode.toString()).join(',')} - Once completed, the ordes(s) cannot be edited.`
  
      let confirm = false;
      if (failures.length >= 1) {
          const title = 'Some order(s) cannot be completed';
          const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
          confirm = await $shell.Utilities.openConfirmationDialog(title, message);
      } else {
          const title = 'Complete the following order(s)';
          const message = confirmCandidates;
          confirm = await $shell.Utilities.openConfirmationDialog(title, message, 'Proceed');
      }
  
      if (confirm) {
          const processSuccess = [];
          const processFailures = [];
          const errorMsgList = [];
          const errorMsgListDetails = [];
  
          for (const candidate of candidates) {
              try {
                  const flowParams = {
                      order_id: candidate.entity.Id
                  }
                  const result = await $flows.Manufacturing.complete_manufacturing_order_flow(flowParams);
  
                  processSuccess.push(candidate);
              } catch (error) {
                  processFailures.push(candidate);
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Order ${candidate.entity.LookupCode.toString()} - ${errorMessage}`;
                  errorMsgList.push(errorDescription);
                  errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
              }
          }
  
          // all succeeded
          if (processSuccess.length === candidates.length) {
              const title = 'All order(s) completed';
              const message = `Order(s) ${processSuccess.map(c => c.entity.LookupCode.toString()).join(',')} completed`;
              await $shell.Utilities.openInfoDialog(title, message);
              await $grid.refresh();
          } else {
              // all failures
              if (processFailures.length === candidates.length) {
                  const title = 'All order(s) failed to complete';
                  const message = `Order(s) ${processFailures.map(c => c.entity.LookupCode.toString()).join(',')} could not be completed`;
                  await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              } else {
                  const title = 'Some orders could not be completed';
                  const success = `Order(s) ${processSuccess.map(c => c.entity.LookupCode.toString()).join(',')} were completed.`;
                  const errors = `The following order(s) could not be completed: ${processFailures.map(c => c.entity.LookupCode.toString()).join(',')}`;
                  const message = `${success} \r\n\r\n${errors}`;
                  await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  await $grid.refresh();
              }
          }
      }
  }
  }
  on_confirm_production_clicked(event = null) {
    return this.on_confirm_production_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_production_clickedInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_confirm_production_clicked');
  if( $grid.selectedRows.length > 1 )
  {
      await $shell.Utilities.openErrorDialog('Order production confirming', `Please select only one order for confirming production.`);
      return;
  }
  }
  on_scheduling_order(event = null) {
    return this.on_scheduling_orderInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scheduling_orderInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_scheduling_order');
  if( $grid.selectedRows.length > 1 )
  {
      await $shell.Utilities.openErrorDialog('Order scheduling', `Please select only one order for scheduling.`);
      return;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_row_selected');
  if( $grid.selectedRows.length > 0 )
  {
      $grid.topToolbar.process.control.readOnly = false;
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.complete.control.readOnly = false;
      $grid.topToolbar.confirm_production.control.readOnly = false;
      $grid.topToolbar.schedule_order.control.readOnly = false;
  }
  else
  {
      $grid.topToolbar.process.control.readOnly = true;
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.confirm_production.control.readOnly = true;
      $grid.topToolbar.schedule_order.control.readOnly = true;
  }
  }
  on_field_selector_clicked(event = null) {
    return this.on_field_selector_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_field_selector_clickedInternal(
    $grid: Manufacturing_manufacturing_orders_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid.on_field_selector_clicked');
  // TODO: Add the fields selecting dialog.
  //let columns: { Key: string, Name: string, IsSelected: boolean }[] = [];
  //
  //for (let key in $grid.headers) {
  //    columns.push({ Key: key, Name: $grid.headers[key].name, IsSelected: !$grid.headers[key].hidden });
  //}
  //
  //let result = (await $shell.FootPrintManager.openfield_selector_gridDialog({ Columns: columns }));
  //
  //if ($utils.isDefined(result.Columns)) {
  //    for (let key in $grid.headers) {
  //        $grid.headers[key].hidden = !result.Columns.find(column => column.Key === key)?.IsSelected
  //    }
  //}
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
