import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_line_substitution_swap_form_ComponentContextService } from './Manufacturing.manufacturing_order_line_substitution_swap_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Manufacturing-manufacturing_order_line_substitution_swap_form',
  templateUrl: './Manufacturing.manufacturing_order_line_substitution_swap_form.component.html'
})
export class Manufacturing_manufacturing_order_line_substitution_swap_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { material_name?: string, material_packaging?: string, substitution_name?: string, substitution_packaging?: string, material_amount?: number, substitution_amount?: number, ratio?: number } = { material_name: null, material_packaging: null, substitution_name: null, substitution_packaging: null, material_amount: null, substitution_amount: null, ratio: null };
  //#region Inputs
  @Input('material_name') set $inParams_material_name(v: string) {
    this.inParams.material_name = v;
  }
  get $inParams_material_name(): string {
    return this.inParams.material_name;
  }
  @Input('material_packaging') set $inParams_material_packaging(v: string) {
    this.inParams.material_packaging = v;
  }
  get $inParams_material_packaging(): string {
    return this.inParams.material_packaging;
  }
  @Input('substitution_name') set $inParams_substitution_name(v: string) {
    this.inParams.substitution_name = v;
  }
  get $inParams_substitution_name(): string {
    return this.inParams.substitution_name;
  }
  @Input('substitution_packaging') set $inParams_substitution_packaging(v: string) {
    this.inParams.substitution_packaging = v;
  }
  get $inParams_substitution_packaging(): string {
    return this.inParams.substitution_packaging;
  }
  @Input('material_amount') set $inParams_material_amount(v: number) {
    this.inParams.material_amount = v;
  }
  get $inParams_material_amount(): number {
    return this.inParams.material_amount;
  }
  @Input('substitution_amount') set $inParams_substitution_amount(v: number) {
    this.inParams.substitution_amount = v;
  }
  get $inParams_substitution_amount(): number {
    return this.inParams.substitution_amount;
  }
  @Input('ratio') set $inParams_ratio(v: number) {
    this.inParams.ratio = v;
  }
  get $inParams_ratio(): number {
    return this.inParams.ratio;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { material_swapped?: boolean } = { material_swapped: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ material_swapped?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm_swap: new ToolModel(new ButtonModel('confirm_swap', new ButtonStyles(['primary'], null), false, false, false, 'Swap material', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['tertiary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
  };

  fieldsets = {
};


  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_line_substitution_swap_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'manufacturing_order_line_substitution_swap_form';
  
    const $form = this;
    const $utils = this.$utils;


    await this.on_init_flow();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $form: Manufacturing_manufacturing_order_line_substitution_swap_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_line_substitution_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form.on_init_flow');
  
  $form.title = `Swap material ${$form.inParams.material_name} (${$form.inParams.material_amount} ${$form.inParams.material_packaging}) for substitute ${$form.inParams.substitution_name} (${$form.inParams.substitution_amount} ${$form.inParams.substitution_packaging}) at a ratio of 1:${$form.inParams.ratio}`;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: Manufacturing_manufacturing_order_line_substitution_swap_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_line_substitution_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form.on_confirm_clicked');
  $form.outParams.material_swapped = true;
  $form.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: Manufacturing_manufacturing_order_line_substitution_swap_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_line_substitution_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form.on_cancel_clicked');
  $form.outParams.material_swapped = false;
  $form.close();
  }
  //#endregion private flows
}
