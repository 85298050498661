import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_orders_create_serial_numbers_editor_ComponentContextService } from './Manufacturing.manufacturing_orders_create_serial_numbers_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manufacturing_serialnumbers_creation_gridComponent } from './Manufacturing.serialnumbers_creation_grid.component';

type EntityType = { 
    id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_serialnumbers_creation_gridComponent),
  ],
  selector: 'Manufacturing-manufacturing_orders_create_serial_numbers_editor',
  templateUrl: './Manufacturing.manufacturing_orders_create_serial_numbers_editor.component.html'
})
export class Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { material_id?: number, produced_quantity?: number, lot_id?: number, packaging_id?: number, license_plate_id?: number } = { material_id: null, produced_quantity: null, lot_id: null, packaging_id: null, license_plate_id: null };
  //#region Inputs
  @Input('material_id') set $inParams_material_id(v: number) {
    this.inParams.material_id = v;
  }
  get $inParams_material_id(): number {
    return this.inParams.material_id;
  }
  @Input('produced_quantity') set $inParams_produced_quantity(v: number) {
    this.inParams.produced_quantity = v;
  }
  get $inParams_produced_quantity(): number {
    return this.inParams.produced_quantity;
  }
  @Input('lot_id') set $inParams_lot_id(v: number) {
    this.inParams.lot_id = v;
  }
  get $inParams_lot_id(): number {
    return this.inParams.lot_id;
  }
  @Input('packaging_id') set $inParams_packaging_id(v: number) {
    this.inParams.packaging_id = v;
  }
  get $inParams_packaging_id(): number {
    return this.inParams.packaging_id;
  }
  @Input('license_plate_id') set $inParams_license_plate_id(v: number) {
    this.inParams.license_plate_id = v;
  }
  get $inParams_license_plate_id(): number {
    return this.inParams.license_plate_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { serials?: { MaterialId?: number, LicensePlateId?: number, LotId?: number, GrossVolume?: number, GrossWeight?: number, VolumeUomId?: number, WeightUomId?: number, NetWeight?: number, NetVolume?: number, DimensionUomId?: number, Height?: number, Length?: number, PackagingId?: number, Width?: number, LookupCode?: string }[] } = { serials: [] };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { packaging?: { weightUom?: number, dimensionsUom?: number, length?: number, width?: number, weight?: number, height?: number }, materialName?: string } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    description: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false, false),
    produced_serials: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), ' Produced quantity', false, false),
  };

  fieldsets = {
    details: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      serial_numbers_create_grid: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_materialId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.material_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_packagingId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.packaging_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_packagedAmount(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.produced_quantity;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageGross(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.weight;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageNet(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.weight;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_weightUomId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.weightUom;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageLength(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.length;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageWidth(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.width;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageHeight(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.height;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_dimensionUomId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.packaging?.dimensionsUom;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_lotId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.lot_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_licensePlateId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.license_plate_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_close_grid_on_confirm(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = false;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_serial_numbers_create_grid', { read: Manufacturing_serialnumbers_creation_gridComponent }) $tabs_serial_numbers_create_grid: Manufacturing_serialnumbers_creation_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_manufacturing_orders_create_serial_numbers_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.serial_numbers_create_grid,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Create new serials';
    
    await this.on_init_flow();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
    };

    const data = await this.$datasources.Manufacturing.ds_manufacturing_orders_create_serial_numbers_editor_dummy.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.description.control as TextModel).text = `Finished product${$utils.isDefined($editor.vars.materialName) ? ` ${$editor.vars.materialName}` : " "} is a serial-controlled material so you have to add serial numbers to each item.`;
    (this.fields.produced_serials.control as TextModel).text = $editor.inParams.produced_quantity?.toString();

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_serial_numbers_create_grid') {
        if (!isNil(this.$tabs_serial_numbers_create_grid) && !this.tabs.serial_numbers_create_grid.hidden && !this.tabs.serial_numbers_create_grid.removed) {
          this.$tabs_serial_numbers_create_grid.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
  }

  //#region private flows
  handle_serial_numbers_grid_cancel_event(event = null) {
    return this.handle_serial_numbers_grid_cancel_eventInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async handle_serial_numbers_grid_cancel_eventInternal(
    $editor: Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_create_serial_numbers_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor.handle_serial_numbers_grid_cancel_event');
  $editor.close();
  }
  handle_serial_numbers_grid_confirm_event(event = null) {
    return this.handle_serial_numbers_grid_confirm_eventInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async handle_serial_numbers_grid_confirm_eventInternal(
    $editor: Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_create_serial_numbers_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor.handle_serial_numbers_grid_confirm_event');
  const createdSerialNumbers = $event;
  
  // Get material packagings for all distinct materials
  let packaging = (await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({ material_id: $editor.inParams.material_id, packaging_id: $editor.inParams.packaging_id })).result;
  
  let serialNumbers= [];
  
  createdSerialNumbers.forEach( serial => {
      let serialCreated = { MaterialId: $editor.inParams.material_id
                         , LicensePlateId: $editor.inParams.license_plate_id
                         , LotId: $editor.inParams.lot_id
                         , GrossWeight: serial.GrossWeightValue ?? packaging[0]?.ShippingWeight
                         , GrossVolume: serial.GrossVolumeValue ?? packaging[0]?.ShippingVolume
                         , NetWeight: serial.NetWeightValue ?? packaging[0]?.Weight
                         , NetVolume: serial.NetVolumeValue ?? packaging[0]?.Volume
                         , WeightUomId: serial.WeightUOM ?? packaging[0]?.WeightUomId
                         , VolumeUomId: serial.VolumeUOM ?? packaging[0]?.VolumeUomId
                         , DimensionUomId: serial.DimensionUOM ?? packaging[0]?.DimensionUomId
                         , LookupCode: serial.LookupCode
                         , Width: serial.Width
                         , Height: serial.Height
                         , Length: serial.Length
                         , PackagingId: $editor.inParams.packaging_id};
  
      serialNumbers.push(serialCreated);
  } )
  
  $editor.outParams.serials = serialNumbers;
  
  $editor.close();
  }
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $editor: Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_create_serial_numbers_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor.on_init_flow');
  
  $editor.fields.produced_serials.control.text = $editor.inParams.produced_quantity.toString();
  
  const material = (await $datasources.Manufacturing.ds_get_material_by_materialId.get({material_id: $editor.inParams.material_id})).result;
  if($utils.isDefined(material))
      $editor.vars.materialName = material.Name;
      
  const packaging = (await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({material_id: $editor.inParams.material_id, packaging_id: $editor.inParams.packaging_id})).result;
  if( $utils.isDefined(packaging) )
  {
      $editor.vars.packaging = {};
      $editor.vars.packaging.dimensionsUom = packaging[0].DimensionUomId;
      $editor.vars.packaging.weightUom = packaging[0].WeightUomId;
      $editor.vars.packaging.weightUom = packaging[0].WeightUomId;
      $editor.vars.packaging.length = packaging[0].Length;
      $editor.vars.packaging.height = packaging[0].Height;
      $editor.vars.packaging.weight = packaging[0].Weight;
      $editor.vars.packaging.width = packaging[0].Width;
  }
  }
  //#endregion private flows
  
}
