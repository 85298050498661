import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_single_parent_licenseplate_hub_ComponentContextService } from './FootprintManager.single_parent_licenseplate_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_parent_licenseplate_gridComponent } from './FootprintManager.inventory_parent_licenseplate_grid.component';
import { FootprintManager_inventory_tasks_gridComponent } from './FootprintManager.inventory_tasks_grid.component';
import { FootprintManager_serialnumbers_gridComponent } from './FootprintManager.serialnumbers_grid.component';
import { Inventory_licenseplate_total_packaged_amount_widgetComponent } from './Inventory.licenseplate_total_packaged_amount_widget.component';
import { Inventory_licenseplate_total_gross_weight_widgetComponent } from './Inventory.licenseplate_total_gross_weight_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_parent_licenseplate_gridComponent),
    forwardRef(() => FootprintManager_inventory_tasks_gridComponent),
    forwardRef(() => FootprintManager_serialnumbers_gridComponent),
    forwardRef(() => Inventory_licenseplate_total_packaged_amount_widgetComponent),
    forwardRef(() => Inventory_licenseplate_total_gross_weight_widgetComponent),
  ],
  selector: 'FootprintManager-single_parent_licenseplate_hub',
  templateUrl: './FootprintManager.single_parent_licenseplate_hub.component.html'
})
export class FootprintManager_single_parent_licenseplate_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateId: number } = { licenseplateId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string, hubDescription?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      create_inventory: new ToolModel(new ButtonModel('create_inventory', new ButtonStyles(null, null), false, false, false, 'Create inventory', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        ),
      tasks: new TabItemModel(
        this.rootTabGroup, 
        'Activity', 
        false,
        ),
      serialnumbers: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_inventory_parent_licenseplate_grid_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    get $tabs_tasks_inventory_tasks_grid_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds: number[];
    get $tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = [$hub.inParams.licenseplateId];
      
      if(!isEqual(this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds, expr)) {
        this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds = expr;
      }
      return this.cacheValueFor_$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds;
    }
  
    get $tabs_serialnumbers_serialnumbers_grid_inParams_archived(): boolean {
      const $hub = this;
      const $utils = this.$utils;
      const expr = false;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory', { read: FootprintManager_inventory_parent_licenseplate_gridComponent }) $tabs_inventory: FootprintManager_inventory_parent_licenseplate_gridComponent;
      @ViewChild('$tabs_tasks', { read: FootprintManager_inventory_tasks_gridComponent }) $tabs_tasks: FootprintManager_inventory_tasks_gridComponent;
      @ViewChild('$tabs_serialnumbers', { read: FootprintManager_serialnumbers_gridComponent }) $tabs_serialnumbers: FootprintManager_serialnumbers_gridComponent;
    //#endregion tabs children
    widgets = {
      licenseplate_total_packaged_amount_widget: new WidgetModel(false),
      licenseplate_total_gross_weight_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_licenseplate_total_packaged_amount_widget_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    get $widgets_licenseplate_total_gross_weight_widget_inParams_licenseplateId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.licenseplateId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_licenseplate_total_packaged_amount_widget', { read:  Inventory_licenseplate_total_packaged_amount_widgetComponent }) $widgets_licenseplate_total_packaged_amount_widget: Inventory_licenseplate_total_packaged_amount_widgetComponent;
      @ViewChild('$widgets_licenseplate_total_gross_weight_widget', { read:  Inventory_licenseplate_total_gross_weight_widgetComponent }) $widgets_licenseplate_total_gross_weight_widget: Inventory_licenseplate_total_gross_weight_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubDescription;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_single_parent_licenseplate_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
      this.tabs.tasks,
      this.tabs.serialnumbers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Single Parent Licenseplate Hub';
    
    const $hub = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_licenseplate_total_packaged_amount_widget') {
      if (!isNil(this.$widgets_licenseplate_total_packaged_amount_widget) && !this.widgets.licenseplate_total_packaged_amount_widget.hidden) {
        this.$widgets_licenseplate_total_packaged_amount_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_licenseplate_total_gross_weight_widget') {
      if (!isNil(this.$widgets_licenseplate_total_gross_weight_widget) && !this.widgets.licenseplate_total_gross_weight_widget.hidden) {
        this.$widgets_licenseplate_total_gross_weight_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory') {
      if (!isNil(this.$tabs_inventory) && !this.tabs.inventory.hidden) {
        this.$tabs_inventory.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tasks') {
      if (!isNil(this.$tabs_tasks) && !this.tabs.tasks.hidden) {
        this.$tabs_tasks.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_serialnumbers') {
      if (!isNil(this.$tabs_serialnumbers) && !this.tabs.serialnumbers.hidden) {
        this.$tabs_serialnumbers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_clicked(event = null) {
    return this.on_create_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_clickedInternal(
    $hub: FootprintManager_single_parent_licenseplate_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_single_parent_licenseplate_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  const dialogResult = await $shell.FootprintManager.openinventory_creation_formDialog({
      licenseplateId: $hub.inParams.licenseplateId
  });
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintManager_single_parent_licenseplate_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_single_parent_licenseplate_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const licenseplate = (await $datasources.Locations.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $hub.inParams.licenseplateId })).result;
  if ($utils.isDefined(licenseplate)) {
  
      $hub.title = `Licenseplate ${licenseplate.LookupCode}`;
  
      const title = `${licenseplate.LookupCode} Status ${licenseplate.Status.Name}  Location ${licenseplate.Location.Name}  Warehouse ${licenseplate.Warehouse.Name}`;
      const description = `Type ${licenseplate.Type.Name}  Archived ${licenseplate.Archived}  `;
      $hub.vars.hubDescription = description;
      $hub.vars.hubTitle = title;
  
  }
  
  // Hide serialnumbers tab if no serialnumbers exists
  const serialnumber = (await $datasources.SerialNumbers.ds_get_serialnumber_by_various_top1.get({ licenseplateId: $hub.inParams.licenseplateId })).result;
  if ($utils.isDefined(serialnumber)) {
  
      $hub.tabs.serialnumbers.hidden = false;
  }
  else {
      $hub.tabs.serialnumbers.hidden = true;
  }
  
  //Hide Create Inventory button for any License plate Type that is NOT ivnentory or Loose. 
  if(licenseplate.TypeId !== 1 && licenseplate.TypeId !== 3){
      $hub.toolbar.create_inventory.hidden = true;
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootprintManager_single_parent_licenseplate_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_single_parent_licenseplate_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const dialogResult = await $shell.FootprintManager.openlicenseplate_editorDialog({licenseplateId: $hub.inParams.licenseplateId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
    if (userConfirmed) {
  
        await $hub.on_init();
        await $hub.refresh();
    }
  
  }
  //#endregion private flows
}
