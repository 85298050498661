import { Inject, Injectable, Injector }from '@angular/core';

import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';

import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_custom_ds_outbound_detail_pick_slip_reportService } from './app.datasource.index';
import { app_custom_ds_outbound_details_bill_of_lading_reportService } from './app.datasource.index';
import { app_custom_ds_outbound_header_bill_of_lading_reportService } from './app.datasource.index';
import { app_custom_ds_receiving_header_reportService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.FootprintManager = this.injector.get(FootprintManager_DatasourceService);
  }

    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Utilities: Utilities_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public FootprintManager: FootprintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _custom_ds_outbound_detail_pick_slip_report: app_custom_ds_outbound_detail_pick_slip_reportService;
  public get custom_ds_outbound_detail_pick_slip_report(): app_custom_ds_outbound_detail_pick_slip_reportService {
    if(!this._custom_ds_outbound_detail_pick_slip_report) {
      this._custom_ds_outbound_detail_pick_slip_report = this.injector.get(app_custom_ds_outbound_detail_pick_slip_reportService);
    }
    return this._custom_ds_outbound_detail_pick_slip_report;
  }
  private _custom_ds_outbound_details_bill_of_lading_report: app_custom_ds_outbound_details_bill_of_lading_reportService;
  public get custom_ds_outbound_details_bill_of_lading_report(): app_custom_ds_outbound_details_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report) {
      this._custom_ds_outbound_details_bill_of_lading_report = this.injector.get(app_custom_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report;
  }
  private _custom_ds_outbound_header_bill_of_lading_report: app_custom_ds_outbound_header_bill_of_lading_reportService;
  public get custom_ds_outbound_header_bill_of_lading_report(): app_custom_ds_outbound_header_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_header_bill_of_lading_report) {
      this._custom_ds_outbound_header_bill_of_lading_report = this.injector.get(app_custom_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_header_bill_of_lading_report;
  }
  private _custom_ds_receiving_header_report: app_custom_ds_receiving_header_reportService;
  public get custom_ds_receiving_header_report(): app_custom_ds_receiving_header_reportService {
    if(!this._custom_ds_receiving_header_report) {
      this._custom_ds_receiving_header_report = this.injector.get(app_custom_ds_receiving_header_reportService);
    }
    return this._custom_ds_receiving_header_report;
  }
}

