import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_additional_options_form_ComponentContextService } from './FootprintManager.inventory_transformation_additional_options_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-inventory_transformation_additional_options_form',
  templateUrl: './FootprintManager.inventory_transformation_additional_options_form.component.html'
})
export class FootprintManager_inventory_transformation_additional_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { inventory_transformation_id?: number, source_line_id?: number } = { inventory_transformation_id: null, source_line_id: null };
  //#region Inputs
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(v: number) {
    this.inParams.inventory_transformation_id = v;
  }
  get $inParams_inventory_transformation_id(): number {
    return this.inParams.inventory_transformation_id;
  }
  @Input('source_line_id') set $inParams_source_line_id(v: number) {
    this.inParams.source_line_id = v;
  }
  get $inParams_source_line_id(): number {
    return this.inParams.source_line_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } } = { additional_options: { general: { keep_source_creation_date: null, convert_packaging_to_base: null }, serial_number_options: { copy_weights: null, copy_volume: null, copy_dimensions: null }, lot_number_options: { copy_udfs: null, copy_lot_number: null, copy_vendor_lot_number: null } } };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    convert_packaging_to_base: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    copy_volume: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    copy_dimensions: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    copy_weights: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    copy_lot_number: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    copy_lot_udfs: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    keep_source_creation_date: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    instruction_text: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    convert_packaging_to_base: new FieldModel(new CheckBoxModel(this.formGroup.controls['convert_packaging_to_base'] as DatexFormControl, null, false, false, 'Convert packaging to base measure', null)
, new ControlContainerStyles(null, null), '', false, false),
    copy_volume: new FieldModel(new CheckBoxModel(this.formGroup.controls['copy_volume'] as DatexFormControl, null, false, false, 'Copy volume', null)
, new ControlContainerStyles(null, null), '', false, false),
    copy_dimensions: new FieldModel(new CheckBoxModel(this.formGroup.controls['copy_dimensions'] as DatexFormControl, null, false, false, 'Copy dimensions', null)
, new ControlContainerStyles(null, null), '', false, false),
    copy_weights: new FieldModel(new CheckBoxModel(this.formGroup.controls['copy_weights'] as DatexFormControl, null, false, false, 'Copy weights', null)
, new ControlContainerStyles(null, null), '', false, false),
    copy_lot_number: new FieldModel(new CheckBoxModel(this.formGroup.controls['copy_lot_number'] as DatexFormControl, null, false, false, 'Copy lot number', null)
, new ControlContainerStyles(null, null), '', false, false),
    copy_lot_udfs: new FieldModel(new CheckBoxModel(this.formGroup.controls['copy_lot_udfs'] as DatexFormControl, null, false, false, 'Copy UDFs', null)
, new ControlContainerStyles(null, null), '', false, false),
    keep_source_creation_date: new FieldModel(new CheckBoxModel(this.formGroup.controls['keep_source_creation_date'] as DatexFormControl, null, false, false, 'Keep source creation date', null)
, new ControlContainerStyles(null, null), '', false, false),
    instructions: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Select instruction', '', null)
, new ControlContainerStyles(null, null), '', false, false),
    add_custom_instruction: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, false, false, 'Add custom instruction', 'icon-ic_fluent_add_20_regular', null)
, new ControlContainerStyles(null, null), '', false, false),
    instruction_text: new FieldModel(new TextBoxModel(this.formGroup.controls['instruction_text'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
    general: new FieldsetModel(
      'General',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    serial_number_options: new FieldsetModel(
      'Serial number options',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    lot_number_options: new FieldsetModel(
      'Lot number options',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    instructions: new FieldsetModel(
      'Instructions',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    convert_packaging_to_base: this.fields.convert_packaging_to_base.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    copy_volume: this.fields.copy_volume.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    copy_dimensions: this.fields.copy_dimensions.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    copy_weights: this.fields.copy_weights.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    copy_lot_number: this.fields.copy_lot_number.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    copy_lot_udfs: this.fields.copy_lot_udfs.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    keep_source_creation_date: this.fields.keep_source_creation_date.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    instruction_text: this.fields.instruction_text.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Inventory transformation additonal options';
  
    const $form = this;
    const $utils = this.$utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .convert_packaging_to_base
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .copy_volume
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .copy_dimensions
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .copy_weights
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .copy_lot_number
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_copy_lot_changed();
      });
    this.$formGroupFieldValidationObservables
      .copy_lot_udfs
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .keep_source_creation_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .instruction_text
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_inventory_transformation_additional_options_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_additional_options_form.on_init');
  
  $form.fields.copy_volume.hidden = true
  $form.fieldsets.instructions.hidden = true
  
  //check source lines for selected input
  let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
      {
          inventory_transformation_id: $form.inParams.inventory_transformation_id,
          ids: [$form.inParams.source_line_id]
      }
  ))?.result
  
  
  if (!$utils.isDefined(source_line_check)) {
  
      let error = `Source line is missing for inventory transformation ${$form.inParams.inventory_transformation_id}. Please create the source line before proceeding.`
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      throw new Error(error)
  }
  else {
      /*
      let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({materialId: source_line_check[0]?.SourceMaterialId}))?.result[0]
  
      let material_controller = material_check?.ControllerTypeId
  
      //hide full_quantity for serialized materials
      if (material_controller === 3 || material_controller === 4 || !material_check?.IsFixedLength || !material_check?.IsFixedVolume || !material_check?.IsFixedWeight) {
          $form.fieldsets.serial_number_options.hidden = false
      }
      else
      {
          $form.fieldsets.serial_number_options.hidden = true
      }
      */
      if ($utils.isDefined(source_line_check[0]?.SourceSerialNumberId)) {
          $form.fieldsets.serial_number_options.hidden = false
      }
      else {
          $form.fieldsets.serial_number_options.hidden = true
      }
  
      if ($utils.isDefined(source_line_check[0]?.SourceLotId)) {
          $form.fieldsets.lot_number_options.hidden = false
      }
      else {
          $form.fieldsets.lot_number_options.hidden = true
      }
  
  }
  
  //hide copy lot udf
  $form.fields.copy_lot_udfs.hidden = true
  
  
  
  
  
  
  
  await $form.map_outputs()
  
  
  
  
  
  
  
  
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: FootprintManager_inventory_transformation_additional_options_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_additional_options_form.on_save');
  await $form.map_outputs()
  }
  map_outputs(event = null) {
    return this.map_outputsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async map_outputsInternal(
    $form: FootprintManager_inventory_transformation_additional_options_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_additional_options_form.map_outputs');
  
  
  
  //general
  $form.outParams.additional_options.general.keep_source_creation_date = $form.fields.keep_source_creation_date.control.value ?? false
  $form.outParams.additional_options.general.convert_packaging_to_base = $form.fields.convert_packaging_to_base.control.value ?? false
  
  //lot
  $form.outParams.additional_options.lot_number_options.copy_lot_number = $form.fields.copy_lot_number.control.value ?? false
  $form.outParams.additional_options.lot_number_options.copy_udfs = $form.fields.copy_lot_udfs.control.value ?? false
  
  
  //serial
  $form.outParams.additional_options.serial_number_options.copy_dimensions = $form.fields.copy_dimensions.control.value ?? false
  $form.outParams.additional_options.serial_number_options.copy_volume = $form.fields.copy_volume.control.value ?? false
  $form.outParams.additional_options.serial_number_options.copy_weights = $form.fields.copy_weights.control.value ?? false
  
  
  
  $form.events.outParamsChange.emit();
  
  }
  on_copy_lot_changed(event = null) {
    return this.on_copy_lot_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_lot_changedInternal(
    $form: FootprintManager_inventory_transformation_additional_options_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_additional_options_form.on_copy_lot_changed');
  if ($form.fields.copy_lot_number.control.value) {
      $form.fields.copy_lot_udfs.hidden = false
      $form.fields.keep_source_creation_date.hidden = false
  }
  else {
      $form.fields.copy_lot_udfs.hidden = true
      $form.fields.keep_source_creation_date.hidden = true
  }
  
  await $form.on_save()
  }
  //#endregion private flows
  //#region validation flows
  
  on_form_validation(fieldErrors: { convert_packaging_to_base: Pick<string[], 'push'>,copy_volume: Pick<string[], 'push'>,copy_dimensions: Pick<string[], 'push'>,copy_weights: Pick<string[], 'push'>,copy_lot_number: Pick<string[], 'push'>,copy_lot_udfs: Pick<string[], 'push'>,keep_source_creation_date: Pick<string[], 'push'>,instruction_text: Pick<string[], 'push'>, } = null) {
    const emptyResult = { convert_packaging_to_base:[],copy_volume:[],copy_dimensions:[],copy_weights:[],copy_lot_number:[],copy_lot_udfs:[],keep_source_creation_date:[],instruction_text:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_form_validationInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async on_form_validationInternal(
    $form: FootprintManager_inventory_transformation_additional_options_formComponent,
    $validation:{
      fieldErrors: { convert_packaging_to_base: Pick<string[], 'push'>,copy_volume: Pick<string[], 'push'>,copy_dimensions: Pick<string[], 'push'>,copy_weights: Pick<string[], 'push'>,copy_lot_number: Pick<string[], 'push'>,copy_lot_udfs: Pick<string[], 'push'>,keep_source_creation_date: Pick<string[], 'push'>,instruction_text: Pick<string[], 'push'>, }
    },
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $context: FootprintManager_inventory_transformation_additional_options_form_ComponentContextService
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_additional_options_form.on_form_validation');
    /*
  $form.outParams.copy_lot_number = $form.fields.copy_lot_number.control.value ?? false
  $form.outParams.copy_vendot_lot_number = $form.fields.copy_lot_udfs.control.value ?? false
  $form.outParams.source_line_id = $form.inParams.source_line_id
  */
    return $validation.fieldErrors as { convert_packaging_to_base:[],copy_volume:[],copy_dimensions:[],copy_weights:[],copy_lot_number:[],copy_lot_udfs:[],keep_source_creation_date:[],instruction_text:[], };
  }
  //#endregion validation flows
}
