import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService } from './FootprintManager.inventory_transformation_source_lines_selector_by_location_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity {
  LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, Name?: string, WarehouseId?: number, Status?: { Name?: string }, ParentLocationContainer?: { Id?: number, Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string }}

interface IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentInParams {
  material_id: number, lot_id?: number, vendor_lot_id?: number, warehouse_id?: number, project_id?: number, package_id?: number, inventory_transformation_id: number, type: number}

interface IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentOutParams {
  source_line_id?: number}

class FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent;
  entity: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    full_quantity_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    full_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    quantity_to_transform_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    quantity_to_transform_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    status: null,
    parent_zone: null,
    location: null,
    lot: null,
    vendor_lot: null,
    packaging: null,
    weight: null,
    available: null,
    full_quantity: null,
    quantity_to_transform: null,
  }



  constructor(
    grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.parent_zone = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.parent_zone,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendor_lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendor_lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.available = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.available,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.full_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.full_quantity,
      new CheckBoxModel(this.formGroup.controls['full_quantity_display'] as DatexFormControl, null, false, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['full_quantity_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.quantity_to_transform = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.quantity_to_transform,
      new NumberBoxModel(this.formGroup.controls['quantity_to_transform_display'] as DatexFormControl, null, false, false, '', '', null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_to_transform_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    
    this.formGroup
      .controls['full_quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_full_quantity_changed();
      });
    this.formGroup
      .controls['quantity_to_transform_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_quantity_to_transform_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'LocationId',this.entity.LocationId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouse_id ,
      materialId:  $grid.inParams.material_id ,
      packagingId:  $grid.inParams.package_id ,
      lotId:  $grid.inParams.lot_id ,
      projectId:  $grid.inParams.project_id ,
      fullTextSearch:  null ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_inventory_by_location_selection_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.status.displayControl as TextModel).text = 'Available';
    (this.cells.parent_zone.displayControl as TextModel).text = $row?.entity?.Location?.ParentLocationContainer?.Name;
    (this.cells.location.displayControl as TextModel).text = $row?.entity?.Location?.Name;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging.ShortName;
    (this.cells.available.displayControl as TextModel).text = $row.entity.PackagedAmount.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_save_existing_row');
  
  
  
  try {
  
      //set package amount calculation. check if full quantity
      let package_amount: number = 0
      if ($row.cells.full_quantity.editControl.value === true) {
          package_amount = $row.entity?.PackagedAmount
      }
      else {
          package_amount = $row.cells.quantity_to_transform.editControl.value //quantity_to_transform_edit_control - quantity_to_transform_display_control
      }
  
      //if quantity to transform > inventory line quantity then throw error
      if (package_amount > $row.entity?.PackagedAmount) {
          throw new Error('Quantity to transform must not exceed available inventory.')
      }
  
      //check source lines for selected input
      let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
          {
              inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          }
      ))?.result
  
      /* SWITCH BY Inventory Transformation Type*/
      switch ($grid.inParams.type) {
          //one-to-many
          case 1:
  
  
              //if line >1, throw error
              if (source_line_check?.length > 1) {
                  throw new Error('Selected inventory transformation type supports one source-to-many target only.')
              }
  
              //if line does not exist create new line
              if (!$utils.isDefined(source_line_check)) {
  
                  //if quantity to transform <=0 then throw error
                  if (package_amount <= 0) {
                      throw new Error('Quantity must be greater than 0.')
                  }
  
                  //check source lines for selected input
                  let source_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_source_line_flow({
                      inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                      //license_plate_id: $row.entity?.LicensePlateId,
                      lot_id: $row.entity?.LotId,
                      material_id: $row.entity.Lot?.MaterialId,
                      package_amount: package_amount,
                      package_id: $row.entity?.PackagedId,
                      vendorlot_id: $row.entity?.Lot?.VendorLotId
                  }))?.inventory_transformation_source_line_id
  
                  $grid.outParams.source_line_id = source_line_request
  
                  await $row.refresh();
              }
  
              //if line exists and is equal to 1, check if line matches input from row. If yes, add to existing line. If no, throw error
              if (source_line_check?.length === 1) {
                  let source_line_validation = source_line_check.filter(sl =>
                      //sl.SourceLicensePlateId === $row.entity?.LicensePlateId &&
                      sl.SourceLotId === $row.entity?.LotId &&
                      sl.SourceMaterialId === $row.entity.Lot?.MaterialId &&
                      sl.SourcePackageId === $row.entity?.PackagedId &&
                      sl.SourceVendorLotId === $row.entity?.Lot?.VendorLotId &&
                      sl.SourceSerialNumberId === null
                  )[0]
  
                  if ($utils.isDefined(source_line_validation)) {
  
  
                      let inventory_source_line_payload: any = {}
  
                      if ($utils.isDefined(source_line_validation?.SourceLicensePlateId)) {inventory_source_line_payload.SourceLicensePlateId = source_line_validation?.SourceLicensePlateId}
                      if ($utils.isDefined(source_line_validation?.SourceLotId)) {inventory_source_line_payload.SourceLotId = source_line_validation?.SourceLotId}
                      if ($utils.isDefined(source_line_validation?.SourceMaterialId)) {inventory_source_line_payload.SourceMaterialId = source_line_validation?.SourceMaterialId}
                      if ($utils.isDefined(source_line_validation?.SourcePackageId)) {inventory_source_line_payload.SourcePackageId = source_line_validation?.SourcePackageId}
                      if ($utils.isDefined(source_line_validation?.SourceVendorLotId)) {inventory_source_line_payload.SourceVendorLotId = source_line_validation?.SourceVendorLotId}
                      if ($utils.isDefined(source_line_validation?.SourcePackageAmount)) {inventory_source_line_payload.SourcePackageAmount = source_line_validation?.SourcePackageAmount}
  
  
                      //if amount <= 0, then delete, else update values to new amount
                      if (package_amount <= 0) {
                          try {
  
                              let delete_row = (await $flows.Utilities.crud_delete_flow({
                                  entitySet: 'InventoryTransformationSourceLines',
                                  id: source_line_validation?.Id
                              }))
                              $grid.outParams.source_line_id = null
                              await $row.refresh();
                              
  
                          } catch (error) {
                              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
                              throw error; // to prevent displayMode 
                          }
  
                      }
                      else {
                          try {
  
                              let update_row = (await $flows.Utilities.crud_update_flow({
                                  entitySet: 'InventoryTransformationSourceLines',
                                  id: source_line_validation.Id,
                                  entity: inventory_source_line_payload
                              }));
  
                              $grid.outParams.source_line_id = source_line_validation.Id
                              await $row.refresh();
                              
  
                          } catch (error) {
                              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
                              throw error; // to prevent displayMode 
                          }
                      }
  
                  }
                  else {
                      throw new Error('Inventory transformation (one-to-many) can only have one source line.')
  
                  }
  
              }
  
              break;
  
          case 2:
              break;
  
          case 3:
              break;
  
          default:
              break;
  
      }
  
  
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      throw new Error(error)
  
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_row_data_loaded');
  
  
  //check if inventory line corresponds to a source line for the inventory transformation id
  //if yes, update quantity in quantity to transform, if line quantity is equal to source line quantity, set full quantity to true
  //if inventory line quantity > quantity to transform, throw error
  
  
  //check source lines for selected input
  let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
      {
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          material_id: $row.entity.Lot?.MaterialId,
          lot_id: $row.entity?.LotId,
          vendor_lot_id: $row.entity?.Lot?.VendorLotId,
          location_id: $row.entity?.LocationId,
          packaging_id: $row.entity?.PackagedId
      }
  ))?.result
  
  if ($utils.isDefined(source_line_check)) {
      $row.cells.quantity_to_transform.displayControl.value = source_line_check[0].SourcePackageAmount
      $row.cells.quantity_to_transform.editControl.value = source_line_check[0].SourcePackageAmount
  
      if (source_line_check[0].SourcePackageAmount === $row?.entity?.PackagedAmount) {
          $row.cells.full_quantity.displayControl.value = true
          $row.cells.full_quantity.editControl.value = true
      }
      else {
          $row.cells.full_quantity.displayControl.value = false
          $row.cells.full_quantity.editControl.value = false
      }
  
  }
  
  else {
      $row.cells.full_quantity.displayControl.value = false
      $row.cells.full_quantity.editControl.value = false
      $row.cells.quantity_to_transform.displayControl.value = null
      $row.cells.quantity_to_transform.editControl.value = null
  
  }
  
  }
  on_quantity_to_transform_changed(event = null) {
    return this.on_quantity_to_transform_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_quantity_to_transform_changedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_quantity_to_transform_changed');
  
  if ($row?.cells.quantity_to_transform.editControl.value<$row.entity.PackagedAmount)
  {
      $row.cells.full_quantity.editControl.value = false
  }
  }
  on_full_quantity_changed(event = null) {
    return this.on_full_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_full_quantity_changedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_full_quantity_changed');
  
  if ($row?.cells.full_quantity.editControl.value === true)
  {
      $row.cells.quantity_to_transform.editControl.value = $row?.entity.PackagedAmount
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-inventory_transformation_source_lines_selector_by_location_grid',
  templateUrl: './FootprintManager.inventory_transformation_source_lines_selector_by_location_grid.component.html'
})
export class FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentInParams = { material_id: null, lot_id: null, vendor_lot_id: null, warehouse_id: null, project_id: null, package_id: null, inventory_transformation_id: null, type: null };

  outParams: IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentOutParams = { source_line_id: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ source_line_id?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       parent_zone: new GridHeaderModel(new HeaderStyles(null, null), 'parent_zone', 'Parent zone', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_lot', 'Vendor lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       weight: new GridHeaderModel(new HeaderStyles(null, null), 'weight', 'Weight', false, false, null, false),       available: new GridHeaderModel(new HeaderStyles(null, null), 'available', 'Available', false, false, null, false),       full_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'full_quantity', 'Full quantity', false, false, null, false),       quantity_to_transform: new GridHeaderModel(new HeaderStyles(null, null), 'quantity_to_transform', 'Quantity to transform', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel[] = [];

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('lot_id') set $inParams_lot_id(value: any) {
    this.inParams['lot_id'] = value;
  }
  get $inParams_lot_id(): any {
    return this.inParams['lot_id'] ;
  }
  @Input('vendor_lot_id') set $inParams_vendor_lot_id(value: any) {
    this.inParams['vendor_lot_id'] = value;
  }
  get $inParams_vendor_lot_id(): any {
    return this.inParams['vendor_lot_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('package_id') set $inParams_package_id(value: any) {
    this.inParams['package_id'] = value;
  }
  get $inParams_package_id(): any {
    return this.inParams['package_id'] ;
  }
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(value: any) {
    this.inParams['inventory_transformation_id'] = value;
  }
  get $inParams_inventory_transformation_id(): any {
    return this.inParams['inventory_transformation_id'] ;
  }
  @Input('type') set $inParams_type(value: any) {
    this.inParams['type'] = value;
  }
  get $inParams_type(): any {
    return this.inParams['type'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    ) {
    super();
    this.title = 'inventory_transformation_source_lines_selector_by_location_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
      if(isNil(this.inParams.type)) {
        this.$missingRequiredInParams.push('type');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouse_id ,
      materialId:  $grid.inParams.material_id ,
      packagingId:  $grid.inParams.package_id ,
      lotId:  $grid.inParams.lot_id ,
      projectId:  $grid.inParams.project_id ,
      fullTextSearch:  null ,
      take:  null ,
      skip:  null ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_inventory_by_location_selection_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded_flow();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_material_check(event = null) {
    return this.on_material_checkInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_checkInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_material_check');
  //check if material is serial controlled
  
  let material = (await $datasources.Materials.ds_get_material_by_materialId.get({materialId: $grid.inParams.material_id})).result[0]
  
  if(material?.ControllerTypeId === 3 || material?.ControllerTypeId === 4)
  {
      $grid.headers.full_quantity.hidden = true
      $grid.headers.quantity_to_transform.hidden = true
      
      $grid.outParams.source_line_id = 1
  }
  }
  on_data_loaded_flow(event = null) {
    return this.on_data_loaded_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loaded_flowInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_location_grid.on_data_loaded_flow');
  await $grid.on_material_check()
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
