import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_creation_form_ComponentContextService } from './FootprintManager.inventory_transformation_creation_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_creation_form',
  templateUrl: './FootprintManager.inventory_transformation_creation_form.component.html'
})
export class FootprintManager_inventory_transformation_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, ownerId?: number, warehouseId?: number, quick_transform?: boolean } = { projectId: null, ownerId: null, warehouseId: null, quick_transform: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('quick_transform') set $inParams_quick_transform(v: boolean) {
    this.inParams.quick_transform = v;
  }
  get $inParams_quick_transform(): boolean {
    return this.inParams.quick_transform;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { inventory_transformation?: { id: number, warehouse_id: number, project_id: number } } = { inventory_transformation: { id: null, warehouse_id: null, project_id: null } };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { lookupcode?: string } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inventory_transformation?: { id: number, warehouse_id: number, project_id: number } }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['destructive'], null), false, false, false, 'Discard', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Lookup', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    owner: this.fields.owner.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    project: this.fields.project.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
    warehouse: this.fields.warehouse.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.on_form_validation(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'New inventory transformation';
  
    const $form = this;
    const $utils = this.$utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_discard_clicked(event = null) {
    return this.on_discard_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discard_clickedInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_discard_clicked');
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_init');
  
  if (!$utils.isDefined($form.inParams.quick_transform))
  {
      $form.inParams.quick_transform = false
  }
  
  if($form.inParams.quick_transform)
  {
      $form.toolbar.discard.hidden = true
  }
  
  // Delay to give the form time to load before setting the focus logic
  await new Promise(resolve => setTimeout(resolve, 100));
  
  if ($utils.isDefined($form.inParams.ownerId) && !$utils.isDefined($form.inParams.projectId)) {
      $form.fields.owner.control.value = $form.inParams.ownerId;
      $form.fields.project.control.focus();
  }
  
  if ($utils.isDefined($form.inParams.projectId)) {
      $form.fields.project.control.value = $form.inParams.projectId;
  
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: $form.inParams.projectId })).result;
      if ($utils.isAllDefined(project, project[0].OwnerId)) {
          $form.fields.owner.control.value = project[0].OwnerId;
          $form.fields.warehouse.control.focus();
      }
  }
  
  if ($utils.isAllDefined($form.inParams.projectId) && !$utils.isDefined($form.inParams.warehouseId)) {
      $form.fields.warehouse.control.focus();
  }
  
  if($utils.isDefined($form.inParams.warehouseId)){
      $form.fields.warehouse.control.value = $form.inParams.warehouseId
  }
  
  if ($utils.isAllDefined($form.inParams.ownerId, $form.inParams.projectId, $form.inParams.warehouseId)){
      $form.fields.lookupcode.control.focus();
      $form.on_save();
  }else {
      $form.fields.owner.control.focus();
  }
  
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_owner_change');
  
  // Clear out the project selection
  $form.fields.project.control.value = null;
  
  
  // Call the on save flow
  $form.on_save();
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_save');
  
  
  
  
  
  
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.project.control.value,
      $form.fields.warehouse.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      return;
  }
  
  let lookup_code: string = ''
  if (!$utils.isDefined($form.fields.lookupcode.control.value)) {
  
  
      let next_id = (await $flows.Utilities.reserve_nextId_flow({ entity: 'InventoryTransformations' })).nextId
      if ($utils.isDefined(next_id)) {
          lookup_code = (next_id + 1).toString()
      }
  }
  else {
      lookup_code = ($form.fields.lookupcode.control.value).toString()
  }
  
  
  try {
  
      let lookupcode_check = (await $datasources.InventoryTransformations.ds_get_inventory_transformation_by_id_lookupcode.get({ lookup_code: lookup_code }))?.result[0]?.Id
  
      if ($utils.isDefined(lookupcode_check)) {
          throw ('Lookupcode already exists. Please choose a different lookupcode.')
      }
  
      const inventory_transformation_request = (await $flows.InventoryTransformations.create_inventory_transformation_flow({
          project_id: $form.fields.project.control.value,
          warehouse_id: $form.fields.warehouse.control.value,
          lookup_code: lookup_code,
      }));
  
      if ($utils.isDefined(inventory_transformation_request?.inventory_transformation_id)) {
  
          $shell.InventoryTransformations.openToaster(
              'Successfully created inventory transformation.',
              `Inventory transformation ${lookup_code} has been created.`,
              EToasterType.Success,
              {
                  positionClass: EToasterPosition.topRight,
                  tapToDismiss: true,
                  timeOut: 5000,
                  progressBar: true,
                  newestOnTop: true
              });
  
          var inventory_transformation_id = inventory_transformation_request?.inventory_transformation_id;
  
  
  
          if (!$form.inParams.quick_transform) {
              await $shell.FootprintManager.openinventory_transformation_editor({ inventory_transformation_id: inventory_transformation_id });
  
          }
          else {
  
              $form.fields.lookupcode.control.readOnly = true
              $form.fields.owner.control.readOnly = true
              $form.fields.project.control.readOnly = true
              $form.fields.warehouse.control.readOnly = true
  
              $form.outParams.inventory_transformation.id = inventory_transformation_request?.inventory_transformation_id
              $form.outParams.inventory_transformation.project_id = $form.fields.project.control.value
              $form.outParams.inventory_transformation.warehouse_id = $form.fields.warehouse.control.value
              $form.events.outParamsChange.emit();
  
          }
  
          $form.close();
  
      }
      else {
          throw new Error(inventory_transformation_request?.reason)
      }
  
  }
  
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
  
  }
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_project_change');
  const projectId = $form.fields.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($form.fields.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $form.fields.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  
  
  // Call the on save flow
  $form.on_save();
  }
  //#endregion private flows
  //#region validation flows
  
  on_form_validation(fieldErrors: { lookupcode: Pick<string[], 'push'>,owner: Pick<string[], 'push'>,project: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>, } = null) {
    const emptyResult = { lookupcode:[],owner:[],project:[],warehouse:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_form_validationInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async on_form_validationInternal(
    $form: FootprintManager_inventory_transformation_creation_formComponent,
    $validation:{
      fieldErrors: { lookupcode: Pick<string[], 'push'>,owner: Pick<string[], 'push'>,project: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>, }
    },
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $context: FootprintManager_inventory_transformation_creation_form_ComponentContextService
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_creation_form.on_form_validation');
    
    return $validation.fieldErrors as { lookupcode:[],owner:[],project:[],warehouse:[], };
  }
  //#endregion validation flows
}
