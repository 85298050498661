<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="new_order" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_new_order($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="open_plan_editor" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="open_plan_editor($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden && !filtersets.filters.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-customer" *ngIf="!filters.customer.hidden && !filters.customer.removed" 
                                  class="field-container standard {{filters.customer.invalid ? 'invalid' : ''}} {{filters.customer.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.customer.styles.style"
                                  [ngClass]="filters.customer.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.customer.label + (filters.customer.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.customer.label}}<span *ngIf="filters.customer.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.customer.control.type"
                                  formControlName="customer"
                                  (displayTextChange)="filters.customer.control.displayText=$event"
                                  [placeholder]="filters.customer.control.placeholder"
                                  [styles]="filters.customer.control.styles"
                                  [tooltip]="filters.customer.control.tooltip"
                                [project_id]="$fields_customer_selector_inParams_project_id"
                              >
                              </Manufacturing-owners_dd_single>
                              <ng-container *ngIf="filters.customer.invalid">
                                <ng-container *ngFor="let error of filters.customer.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}} {{filters.project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                              >
                              </Manufacturing-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden && !filters.warehouses.removed" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}} {{filters.warehouses.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </Manufacturing-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-statuses" *ngIf="!filters.statuses.hidden && !filters.statuses.removed" 
                                  class="field-container standard {{filters.statuses.invalid ? 'invalid' : ''}} {{filters.statuses.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.statuses.styles.style"
                                  [ngClass]="filters.statuses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.statuses.label + (filters.statuses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.statuses.label}}<span *ngIf="filters.statuses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-order_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.statuses.control.type"
                                  formControlName="statuses"
                                  (displayTextChange)="filters.statuses.control.displayText=$event"
                                  [placeholder]="filters.statuses.control.placeholder"
                                  [styles]="filters.statuses.control.styles"
                                  [tooltip]="filters.statuses.control.tooltip"
                              >
                              </Manufacturing-order_statuses_dd_multi>
                              <ng-container *ngIf="filters.statuses.invalid">
                                <ng-container *ngFor="let error of filters.statuses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.filters.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!filtersets.newGroup2.hidden && !filtersets.newGroup2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup2.collapsible }">
                    <div *ngIf="!filtersets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup2.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-plan_id" *ngIf="!filters.plan_id.hidden && !filters.plan_id.removed" 
                                  class="field-container standard {{filters.plan_id.invalid ? 'invalid' : ''}} {{filters.plan_id.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.plan_id.styles.style"
                                  [ngClass]="filters.plan_id.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.plan_id.label + (filters.plan_id.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.plan_id.label}}<span *ngIf="filters.plan_id.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="plan_id"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{ (filters.plan_id.control.readOnly || filters.plan_id.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.plan_id.control.placeholder}}"
                                      [ngStyle]="filters.plan_id.control.styles.style"
                                      [ngClass]="filters.plan_id.control.styles.classes"
                                      [matTooltip]="filters.plan_id.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.plan_id.invalid">
                                <ng-container *ngFor="let error of filters.plan_id.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.newGroup2.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.pending_confirmation_widget.hidden && !widgets.pending_confirmation_widget.removed" class="widget-container">
            <Manufacturing-pending_confirmation_widget
            #$widgets_pending_confirmation_widget 
            ($refreshEvent)="refresh(false, false, '$widgets_pending_confirmation_widget')"
            >
            </Manufacturing-pending_confirmation_widget>
          </div>
          <div *ngIf="!widgets.scheduled_for_today_widget.hidden && !widgets.scheduled_for_today_widget.removed" class="widget-container">
            <Manufacturing-scheduled_for_today_widget
            #$widgets_scheduled_for_today_widget 
            ($refreshEvent)="refresh(false, false, '$widgets_scheduled_for_today_widget')"
            >
            </Manufacturing-scheduled_for_today_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.orders.hasTabToShow" class="tab" data-cy="tab-orders">
              <h2 [className]="tabs.orders.active? 'active': ''" (click)="tabs.orders.activate()">{{tabs.orders.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-manufacturing_orders_grid *ngIf="tabs.orders.active"
              #$tabs_orders
              [warehouse_ids]="$tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids"
              [owner_id]="$tabs_orders_manufacturing_orders_grid_inParams_owner_id"
              [project_id]="$tabs_orders_manufacturing_orders_grid_inParams_project_id"
              [status_ids]="$tabs_orders_manufacturing_orders_grid_inParams_status_ids"
              ($refreshEvent)="refresh(false, false, '$tabs_orders')">
              </Manufacturing-manufacturing_orders_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>