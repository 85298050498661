<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="process" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_process_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="confirm_production" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_confirm_production_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="complete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_order_complete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="schedule_order" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_scheduling_order($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator2" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="cancel" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_cancel_click($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator3" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="print" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.print.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.print.readOnly || tool.control.buttons.print.disabled"
                          [ngStyle]="tool.control.buttons.print.styles.style"
                          [ngClass]="tool.control.buttons.print.styles.classes"
                          [matTooltip]="tool.control.buttons.print.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.print.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.print.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="additional_menu" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.select_fields.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.select_fields.readOnly || tool.control.buttons.select_fields.disabled"
                          [ngStyle]="tool.control.buttons.select_fields.styles.style"
                          [ngClass]="tool.control.buttons.select_fields.styles.classes"
                          [matTooltip]="tool.control.buttons.select_fields.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_field_selector_clicked($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.select_fields.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.select_fields.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.select_fields.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-product_confirmation" *ngIf="!filters.product_confirmation.hidden && !filters.product_confirmation.removed" 
            class="field-container standard {{filters.product_confirmation.invalid ? 'invalid' : ''}} {{filters.product_confirmation.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.product_confirmation.styles.style"
            [ngClass]="filters.product_confirmation.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.product_confirmation.label + (filters.product_confirmation.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.product_confirmation.label}}<span *ngIf="filters.product_confirmation.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Manufacturing-manufacturnig_order_production_confirmation_dd_single 
            data-cy="selector"
            [type]="filters.product_confirmation.control.type"
            formControlName="product_confirmation"
            (displayTextChange)="filters.product_confirmation.control.displayText=$event"
            [placeholder]="filters.product_confirmation.control.placeholder"
            [styles]="filters.product_confirmation.control.styles"
            [tooltip]="filters.product_confirmation.control.tooltip"
        >
        </Manufacturing-manufacturnig_order_production_confirmation_dd_single>
        <ng-container *ngIf="filters.product_confirmation.invalid">
          <ng-container *ngFor="let error of filters.product_confirmation.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden && !filters.date_type.removed" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}} {{filters.date_type.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_type.label + (filters.date_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_type.label}}<span *ngIf="filters.date_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Manufacturing-manufacturing_order_date_types_dd_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
            [tooltip]="filters.date_type.control.tooltip"
        >
        </Manufacturing-manufacturing_order_date_types_dd_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden && !filters.date_from.removed" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}} {{filters.date_from.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden && !filters.date_to.removed" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}} {{filters.date_to.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order.displayControl.styles.style"
          [ngClass]="row.cells.order.displayControl.styles.classes"
          [matTooltip]="row.cells.order.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="wave">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.wave.displayControl.styles.style"
          [ngClass]="row.cells.wave.displayControl.styles.classes"
          [matTooltip]="row.cells.wave.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.wave.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="production_confirmation">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.production_confirmation.displayControl.styles.style"
          [ngClass]="row.cells.production_confirmation.displayControl.styles.classes"
          [matTooltip]="row.cells.production_confirmation.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.production_confirmation.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="schedule_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.schedule_type.displayControl.styles.style"
          [ngClass]="row.cells.schedule_type.displayControl.styles.classes"
          [matTooltip]="row.cells.schedule_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.schedule_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="next_schedule_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.next_schedule_date.displayControl.styles.style"
          [ngClass]="row.cells.next_schedule_date.displayControl.styles.classes"
          [matTooltip]="row.cells.next_schedule_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.next_schedule_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="staging_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.staging_location.displayControl.styles.style"
          [ngClass]="row.cells.staging_location.displayControl.styles.classes"
          [matTooltip]="row.cells.staging_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.staging_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="output_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.output_location.displayControl.styles.style"
          [ngClass]="row.cells.output_location.displayControl.styles.classes"
          [matTooltip]="row.cells.output_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.output_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="customer">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.customer.displayControl.styles.style"
          [ngClass]="row.cells.customer.displayControl.styles.classes"
          [matTooltip]="row.cells.customer.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.customer.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
