import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { app_DatasourceService } from './app.datasource.index';

interface Iapp_custom_pick_slip_reportServiceInParams {
  orderId: number}

interface Iapp_custom_pick_slip_reportServiceData {
  PickSlipDetail?: { result?: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, OrderId?: number, Shipment?: { Id?: number, ExpectedDate?: string, FobLocation?: string, LookupCode?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { LookupCode?: string }[], Warehouse?: { Name?: string } } }, ExpectedSourceLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ExpectedSourceLocation?: { Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, PickSlip?: { LookupCode?: string }, Lot?: { LookupCode?: string }, DimensionsUOM?: { Name?: string, Short_name?: string }, OrderLine?: { Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, Order?: { Id?: number, CreatedSysDateTime?: string, RequestedDeliveryDate?: string }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class app_custom_pick_slip_reportService extends ReportBaseService<Iapp_custom_pick_slip_reportServiceInParams, Iapp_custom_pick_slip_reportServiceData> {

  protected reportReferenceName = 'custom_pick_slip_report';
  protected appReferenceName = 'app';

  constructor(
    $utils: UtilsService,
    private $datasources: app_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('app', 'custom_pick_slip_report');
  }

  override throwIfMissingRequiredInParams (inParams: Iapp_custom_pick_slip_reportServiceInParams) {
  }

  protected async getData(inParams: Iapp_custom_pick_slip_reportServiceInParams): Promise<Iapp_custom_pick_slip_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: Iapp_custom_pick_slip_reportServiceInParams } = {
      inParams: inParams
    };

    const data: Iapp_custom_pick_slip_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.app.custom_ds_outbound_detail_pick_slip_report.get(dsParams);
      
      data.PickSlipDetail = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
