import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { InventoryTransformations_ds_active_inventory_transformations_widgetService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_entity_user_defined_field_values_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_hardallocationdetails_by_inventorytransformationsourcelineidService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_inventory_transformation_by_id_lookupcodeService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_inventory_transformation_source_line_editorService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_inventory_transformation_top_1Service } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_material_packaging_by_materialId_and_packagingIdService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_serialnumber_by_serialnumberIdService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_get_userdefinedfields_by_entity_nameService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_by_licenseplate_selection_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_by_location_selection_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_by_lot_selection_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_by_material_selection_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_editorService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackagingService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_source_lines_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_statuses_ddService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_target_lines_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformation_target_lines_total_packaged_amountService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformations_by_status_widgetService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformations_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_inventory_transformations_hubService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_licenseplates_ddService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_serialnumbers_modification_gridService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_ds_serialnumbers_selection_gridService } from './InventoryTransformations.datasource.index';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public InventoryTransformations: InventoryTransformations_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_active_inventory_transformations_widget: InventoryTransformations_ds_active_inventory_transformations_widgetService;
  public get ds_active_inventory_transformations_widget(): InventoryTransformations_ds_active_inventory_transformations_widgetService {
    if(!this._ds_active_inventory_transformations_widget) {
      this._ds_active_inventory_transformations_widget = this.injector.get(InventoryTransformations_ds_active_inventory_transformations_widgetService);
    }
    return this._ds_active_inventory_transformations_widget;
  }
  private _ds_entity_user_defined_field_values_grid: InventoryTransformations_ds_entity_user_defined_field_values_gridService;
  public get ds_entity_user_defined_field_values_grid(): InventoryTransformations_ds_entity_user_defined_field_values_gridService {
    if(!this._ds_entity_user_defined_field_values_grid) {
      this._ds_entity_user_defined_field_values_grid = this.injector.get(InventoryTransformations_ds_entity_user_defined_field_values_gridService);
    }
    return this._ds_entity_user_defined_field_values_grid;
  }
  private _ds_get_hardallocationdetails_by_inventorytransformationsourcelineid: InventoryTransformations_ds_get_hardallocationdetails_by_inventorytransformationsourcelineidService;
  public get ds_get_hardallocationdetails_by_inventorytransformationsourcelineid(): InventoryTransformations_ds_get_hardallocationdetails_by_inventorytransformationsourcelineidService {
    if(!this._ds_get_hardallocationdetails_by_inventorytransformationsourcelineid) {
      this._ds_get_hardallocationdetails_by_inventorytransformationsourcelineid = this.injector.get(InventoryTransformations_ds_get_hardallocationdetails_by_inventorytransformationsourcelineidService);
    }
    return this._ds_get_hardallocationdetails_by_inventorytransformationsourcelineid;
  }
  private _ds_get_inventory_transformation_by_id_lookupcode: InventoryTransformations_ds_get_inventory_transformation_by_id_lookupcodeService;
  public get ds_get_inventory_transformation_by_id_lookupcode(): InventoryTransformations_ds_get_inventory_transformation_by_id_lookupcodeService {
    if(!this._ds_get_inventory_transformation_by_id_lookupcode) {
      this._ds_get_inventory_transformation_by_id_lookupcode = this.injector.get(InventoryTransformations_ds_get_inventory_transformation_by_id_lookupcodeService);
    }
    return this._ds_get_inventory_transformation_by_id_lookupcode;
  }
  private _ds_get_inventory_transformation_source_line_editor: InventoryTransformations_ds_get_inventory_transformation_source_line_editorService;
  public get ds_get_inventory_transformation_source_line_editor(): InventoryTransformations_ds_get_inventory_transformation_source_line_editorService {
    if(!this._ds_get_inventory_transformation_source_line_editor) {
      this._ds_get_inventory_transformation_source_line_editor = this.injector.get(InventoryTransformations_ds_get_inventory_transformation_source_line_editorService);
    }
    return this._ds_get_inventory_transformation_source_line_editor;
  }
  private _ds_get_inventory_transformation_top_1: InventoryTransformations_ds_get_inventory_transformation_top_1Service;
  public get ds_get_inventory_transformation_top_1(): InventoryTransformations_ds_get_inventory_transformation_top_1Service {
    if(!this._ds_get_inventory_transformation_top_1) {
      this._ds_get_inventory_transformation_top_1 = this.injector.get(InventoryTransformations_ds_get_inventory_transformation_top_1Service);
    }
    return this._ds_get_inventory_transformation_top_1;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: InventoryTransformations_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): InventoryTransformations_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(InventoryTransformations_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_serialnumber_by_serialnumberId: InventoryTransformations_ds_get_serialnumber_by_serialnumberIdService;
  public get ds_get_serialnumber_by_serialnumberId(): InventoryTransformations_ds_get_serialnumber_by_serialnumberIdService {
    if(!this._ds_get_serialnumber_by_serialnumberId) {
      this._ds_get_serialnumber_by_serialnumberId = this.injector.get(InventoryTransformations_ds_get_serialnumber_by_serialnumberIdService);
    }
    return this._ds_get_serialnumber_by_serialnumberId;
  }
  private _ds_get_userdefinedfields_by_entity_name: InventoryTransformations_ds_get_userdefinedfields_by_entity_nameService;
  public get ds_get_userdefinedfields_by_entity_name(): InventoryTransformations_ds_get_userdefinedfields_by_entity_nameService {
    if(!this._ds_get_userdefinedfields_by_entity_name) {
      this._ds_get_userdefinedfields_by_entity_name = this.injector.get(InventoryTransformations_ds_get_userdefinedfields_by_entity_nameService);
    }
    return this._ds_get_userdefinedfields_by_entity_name;
  }
  private _ds_inventory_by_licenseplate_selection_grid: InventoryTransformations_ds_inventory_by_licenseplate_selection_gridService;
  public get ds_inventory_by_licenseplate_selection_grid(): InventoryTransformations_ds_inventory_by_licenseplate_selection_gridService {
    if(!this._ds_inventory_by_licenseplate_selection_grid) {
      this._ds_inventory_by_licenseplate_selection_grid = this.injector.get(InventoryTransformations_ds_inventory_by_licenseplate_selection_gridService);
    }
    return this._ds_inventory_by_licenseplate_selection_grid;
  }
  private _ds_inventory_by_location_selection_grid: InventoryTransformations_ds_inventory_by_location_selection_gridService;
  public get ds_inventory_by_location_selection_grid(): InventoryTransformations_ds_inventory_by_location_selection_gridService {
    if(!this._ds_inventory_by_location_selection_grid) {
      this._ds_inventory_by_location_selection_grid = this.injector.get(InventoryTransformations_ds_inventory_by_location_selection_gridService);
    }
    return this._ds_inventory_by_location_selection_grid;
  }
  private _ds_inventory_by_lot_selection_grid: InventoryTransformations_ds_inventory_by_lot_selection_gridService;
  public get ds_inventory_by_lot_selection_grid(): InventoryTransformations_ds_inventory_by_lot_selection_gridService {
    if(!this._ds_inventory_by_lot_selection_grid) {
      this._ds_inventory_by_lot_selection_grid = this.injector.get(InventoryTransformations_ds_inventory_by_lot_selection_gridService);
    }
    return this._ds_inventory_by_lot_selection_grid;
  }
  private _ds_inventory_by_material_selection_grid: InventoryTransformations_ds_inventory_by_material_selection_gridService;
  public get ds_inventory_by_material_selection_grid(): InventoryTransformations_ds_inventory_by_material_selection_gridService {
    if(!this._ds_inventory_by_material_selection_grid) {
      this._ds_inventory_by_material_selection_grid = this.injector.get(InventoryTransformations_ds_inventory_by_material_selection_gridService);
    }
    return this._ds_inventory_by_material_selection_grid;
  }
  private _ds_inventory_transformation_editor: InventoryTransformations_ds_inventory_transformation_editorService;
  public get ds_inventory_transformation_editor(): InventoryTransformations_ds_inventory_transformation_editorService {
    if(!this._ds_inventory_transformation_editor) {
      this._ds_inventory_transformation_editor = this.injector.get(InventoryTransformations_ds_inventory_transformation_editorService);
    }
    return this._ds_inventory_transformation_editor;
  }
  private _ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging: InventoryTransformations_ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackagingService;
  public get ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging(): InventoryTransformations_ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackagingService {
    if(!this._ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging) {
      this._ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging = this.injector.get(InventoryTransformations_ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackagingService);
    }
    return this._ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging;
  }
  private _ds_inventory_transformation_source_lines_grid: InventoryTransformations_ds_inventory_transformation_source_lines_gridService;
  public get ds_inventory_transformation_source_lines_grid(): InventoryTransformations_ds_inventory_transformation_source_lines_gridService {
    if(!this._ds_inventory_transformation_source_lines_grid) {
      this._ds_inventory_transformation_source_lines_grid = this.injector.get(InventoryTransformations_ds_inventory_transformation_source_lines_gridService);
    }
    return this._ds_inventory_transformation_source_lines_grid;
  }
  private _ds_inventory_transformation_statuses_dd: InventoryTransformations_ds_inventory_transformation_statuses_ddService;
  public get ds_inventory_transformation_statuses_dd(): InventoryTransformations_ds_inventory_transformation_statuses_ddService {
    if(!this._ds_inventory_transformation_statuses_dd) {
      this._ds_inventory_transformation_statuses_dd = this.injector.get(InventoryTransformations_ds_inventory_transformation_statuses_ddService);
    }
    return this._ds_inventory_transformation_statuses_dd;
  }
  private _ds_inventory_transformation_target_lines_grid: InventoryTransformations_ds_inventory_transformation_target_lines_gridService;
  public get ds_inventory_transformation_target_lines_grid(): InventoryTransformations_ds_inventory_transformation_target_lines_gridService {
    if(!this._ds_inventory_transformation_target_lines_grid) {
      this._ds_inventory_transformation_target_lines_grid = this.injector.get(InventoryTransformations_ds_inventory_transformation_target_lines_gridService);
    }
    return this._ds_inventory_transformation_target_lines_grid;
  }
  private _ds_inventory_transformation_target_lines_total_packaged_amount: InventoryTransformations_ds_inventory_transformation_target_lines_total_packaged_amountService;
  public get ds_inventory_transformation_target_lines_total_packaged_amount(): InventoryTransformations_ds_inventory_transformation_target_lines_total_packaged_amountService {
    if(!this._ds_inventory_transformation_target_lines_total_packaged_amount) {
      this._ds_inventory_transformation_target_lines_total_packaged_amount = this.injector.get(InventoryTransformations_ds_inventory_transformation_target_lines_total_packaged_amountService);
    }
    return this._ds_inventory_transformation_target_lines_total_packaged_amount;
  }
  private _ds_inventory_transformations_by_status_widget: InventoryTransformations_ds_inventory_transformations_by_status_widgetService;
  public get ds_inventory_transformations_by_status_widget(): InventoryTransformations_ds_inventory_transformations_by_status_widgetService {
    if(!this._ds_inventory_transformations_by_status_widget) {
      this._ds_inventory_transformations_by_status_widget = this.injector.get(InventoryTransformations_ds_inventory_transformations_by_status_widgetService);
    }
    return this._ds_inventory_transformations_by_status_widget;
  }
  private _ds_inventory_transformations_grid: InventoryTransformations_ds_inventory_transformations_gridService;
  public get ds_inventory_transformations_grid(): InventoryTransformations_ds_inventory_transformations_gridService {
    if(!this._ds_inventory_transformations_grid) {
      this._ds_inventory_transformations_grid = this.injector.get(InventoryTransformations_ds_inventory_transformations_gridService);
    }
    return this._ds_inventory_transformations_grid;
  }
  private _ds_inventory_transformations_hub: InventoryTransformations_ds_inventory_transformations_hubService;
  public get ds_inventory_transformations_hub(): InventoryTransformations_ds_inventory_transformations_hubService {
    if(!this._ds_inventory_transformations_hub) {
      this._ds_inventory_transformations_hub = this.injector.get(InventoryTransformations_ds_inventory_transformations_hubService);
    }
    return this._ds_inventory_transformations_hub;
  }
  private _ds_licenseplates_dd: InventoryTransformations_ds_licenseplates_ddService;
  public get ds_licenseplates_dd(): InventoryTransformations_ds_licenseplates_ddService {
    if(!this._ds_licenseplates_dd) {
      this._ds_licenseplates_dd = this.injector.get(InventoryTransformations_ds_licenseplates_ddService);
    }
    return this._ds_licenseplates_dd;
  }
  private _ds_serialnumbers_modification_grid: InventoryTransformations_ds_serialnumbers_modification_gridService;
  public get ds_serialnumbers_modification_grid(): InventoryTransformations_ds_serialnumbers_modification_gridService {
    if(!this._ds_serialnumbers_modification_grid) {
      this._ds_serialnumbers_modification_grid = this.injector.get(InventoryTransformations_ds_serialnumbers_modification_gridService);
    }
    return this._ds_serialnumbers_modification_grid;
  }
  private _ds_serialnumbers_selection_grid: InventoryTransformations_ds_serialnumbers_selection_gridService;
  public get ds_serialnumbers_selection_grid(): InventoryTransformations_ds_serialnumbers_selection_gridService {
    if(!this._ds_serialnumbers_selection_grid) {
      this._ds_serialnumbers_selection_grid = this.injector.get(InventoryTransformations_ds_serialnumbers_selection_gridService);
    }
    return this._ds_serialnumbers_selection_grid;
  }
}

