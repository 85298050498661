import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_counts_hub_ComponentContextService } from './FootprintManager.inventory_counts_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_counts_gridComponent } from './FootprintManager.inventory_counts_grid.component';
import { FootprintManager_count_tasks_gridComponent } from './FootprintManager.count_tasks_grid.component';
import { InventoryCounts_inventory_counts_in_progress_widgetComponent } from './InventoryCounts.inventory_counts_in_progress_widget.component';
import { InventoryCounts_count_accuracy_widgetComponent } from './InventoryCounts.count_accuracy_widget.component';
import { InventoryCounts_warehouses_dd_multiComponent } from './InventoryCounts.warehouses_dd_multi.component'
import { InventoryCounts_inventory_count_statuses_dd_multiComponent } from './InventoryCounts.inventory_count_statuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_counts_gridComponent),
    forwardRef(() => FootprintManager_count_tasks_gridComponent),
    forwardRef(() => InventoryCounts_inventory_counts_in_progress_widgetComponent),
    forwardRef(() => InventoryCounts_count_accuracy_widgetComponent),
    forwardRef(() => InventoryCounts_warehouses_dd_multiComponent),
    forwardRef(() => InventoryCounts_inventory_count_statuses_dd_multiComponent),
  ],
  selector: 'FootprintManager-inventory_counts_hub',
  templateUrl: './FootprintManager.inventory_counts_hub.component.html'
})
export class FootprintManager_inventory_counts_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_inventory_count: new ToolModel(new ButtonModel('create_inventory_count', new ButtonStyles(null, null), false, false, false, 'New count', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
    filter: new FieldsetModel(
      'Filters',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory_counts_grid: new TabItemModel(
        this.rootTabGroup, 
        'Counts', 
        false,
        ),
      standalone_count_tasks: new TabItemModel(
        this.rootTabGroup, 
        'Count tasks', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds: number[];
    get $tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds: number[];
    get $tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds;
    }
  
    get $tabs_standalone_count_tasks_count_tasks_grid_inParams_allowEdit(): boolean {
      const $hub = this;
      const $utils = this.$utils;
      const expr = true;
      
      return expr;
    }
  
    cacheValueFor_$tabs_standalone_count_tasks_count_tasks_grid_inParams_warehouseIds: number[];
    get $tabs_standalone_count_tasks_count_tasks_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_standalone_count_tasks_count_tasks_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_standalone_count_tasks_count_tasks_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_standalone_count_tasks_count_tasks_grid_inParams_warehouseIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory_counts_grid', { read: FootprintManager_inventory_counts_gridComponent }) $tabs_inventory_counts_grid: FootprintManager_inventory_counts_gridComponent;
      @ViewChild('$tabs_standalone_count_tasks', { read: FootprintManager_count_tasks_gridComponent }) $tabs_standalone_count_tasks: FootprintManager_count_tasks_gridComponent;
    //#endregion tabs children
    widgets = {
      inventory_counts_in_progress: new WidgetModel(false),
      accuracy: new WidgetModel(false),
    };
  
    //#region widgets inParams
    cacheValueFor_$widgets_inventory_counts_in_progress_inParams_warehouseIds: number[];
    get $widgets_inventory_counts_in_progress_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_inventory_counts_in_progress_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_inventory_counts_in_progress_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_inventory_counts_in_progress_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_accuracy_inParams_warehouseIds: number[];
    get $widgets_accuracy_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_accuracy_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$widgets_accuracy_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$widgets_accuracy_inParams_warehouseIds;
    }
  
    cacheValueFor_$widgets_accuracy_inParams_statusIds: number[];
    get $widgets_accuracy_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_accuracy_inParams_statusIds, expr)) {
        this.cacheValueFor_$widgets_accuracy_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$widgets_accuracy_inParams_statusIds;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_inventory_counts_in_progress', { read:  InventoryCounts_inventory_counts_in_progress_widgetComponent }) $widgets_inventory_counts_in_progress: InventoryCounts_inventory_counts_in_progress_widgetComponent;
      @ViewChild('$widgets_accuracy', { read:  InventoryCounts_count_accuracy_widgetComponent }) $widgets_accuracy: InventoryCounts_count_accuracy_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Inventory counts hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Create and manage inventory counts`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_inventory_counts_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory_counts_grid,
      this.tabs.standalone_count_tasks,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Inventory Counts';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.status.control as SelectBoxModel).reset([2]);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_inventory_counts_in_progress') {
      if (!isNil(this.$widgets_inventory_counts_in_progress) && !this.widgets.inventory_counts_in_progress.hidden) {
        this.$widgets_inventory_counts_in_progress.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_accuracy') {
      if (!isNil(this.$widgets_accuracy) && !this.widgets.accuracy.hidden) {
        this.$widgets_accuracy.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inventory_counts_grid') {
      if (!isNil(this.$tabs_inventory_counts_grid) && !this.tabs.inventory_counts_grid.hidden) {
        this.$tabs_inventory_counts_grid.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_standalone_count_tasks') {
      if (!isNil(this.$tabs_standalone_count_tasks) && !this.tabs.standalone_count_tasks.hidden) {
        this.$tabs_standalone_count_tasks.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_inventory_count_clicked(event = null) {
    return this.on_create_inventory_count_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_inventory_count_clickedInternal(
    $hub: FootprintManager_inventory_counts_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_counts_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.filters.warehouses.control.value)) {
      var warehouseId = $hub.filters.warehouses.control.value[0];
  }
  else {
      // Choose a default warehouse
      warehouseId = (await $datasources.InventoryCounts.ds_warehouses_dd.getList({$skip: 0, $top: 1})).result[0]?.Id;
  }
  
  try {
      // Create Inventory Count
      let inventoryCountId = (await $flows.InventoryCounts.create_inventory_count_flow({
          lookupCode: null,
          warehouseId: warehouseId,
          locationFilter: {
              locationIds: null,
              includeEmptyLocations: null,
              onlyEmptyLocations: null,
              skipHardAllocationLocations: null,
              skipLocationsWithReleasedCountTask: null
          },
          materialFilter_ownerId: null,
          materialFilter_projectId: null,
          materialFilter_materialIds: null,
          materialFilter_vendorLotIds: null
      })).inventoryCountId;
  
      // Open new Inventory Count
      await $shell.FootprintManager.openinventory_count_editor({ inventoryCountId: inventoryCountId });
  }
  catch (exception) {
      await $shell.InventoryCounts.openErrorDialog('Error Creating Inventory Count', $utils.isDefined(exception.error?.error) ? exception.error.error.message : exception.message);
  }
  }
  on_status_change(event = null) {
    return this.on_status_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_status_changeInternal(
    $hub: FootprintManager_inventory_counts_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_counts_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_counts_hub.on_status_change');
  
  
  
  //$row.cells.status_name.displayControl.text = (await $flows.InventoryCounts.get_inventory_count_status_flow({
  //    inventoryCountIds: [$row.entity.Id],
  //    optimalInputs: [{
  //        inventoryCountId: $row.entity.Id,
  //        statusId: $row.entity.Status.Id,
  //        statusName: $row.entity.Status.Name,
  //        tasks: $row.entity.InventoryCountTaskProperties.map(p => ({
  //            operationCodeId: p.Task.OperationCodeId,
  //            statusId: p.Task.StatusId
  //        }))
  //    }]
  //}))?.inventoryCounts[0]?.descriptiveStatusName ?? $row.cells.status_name.displayControl.text;
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintManager_inventory_counts_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_counts_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if (await $operations.FootprintManager.Disable_Navigation_Inventory_Inventory_Count.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  
  let username = (await $flows.Utilities.get_username_flow({})).userName;
  
  let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
  $hub.filters.warehouses.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  
  $hub.filters.status.hidden=true
  }
  //#endregion private flows
}
