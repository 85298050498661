import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_warehouse_transfer_picking_grid_ComponentContextService } from './FootprintManager.warehouse_transfer_picking_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent } from './SalesOrders.licenseplates_by_locationId_lotId_packagingId_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'


interface IFootprintManager_warehouse_transfer_picking_gridComponentEntity {
  Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocationId?: number, LotId?: number, MaterialId?: number, OperationCodeId?: number, OrderId?: number, OrderLineNumber?: number, PickSlipId?: number, ProjectId?: number, ShipmentId?: number, ShipmentLineId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string, ProjectId?: number }, ExpectedPackagedPack?: { ShortName?: string }, ActualPackagedPack?: { ShortName?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string, Vat?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string, ShortName?: string } }, OrderLine?: { GrossWeight?: number, LotId?: number, NetWeight?: number, PackagedAmount?: number, WeightUomId?: number }, ExpectedSourceLocation?: { Id?: number, Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LocationId?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, ExpectedTargetShippingContainer?: { Id?: number, LookupCode?: string }, OperationCode?: { Name?: string }, total_picked?: { TotalCount?: number, ExpectedCount?: number, ShipmentLine?: { OrderId?: number, OrderLineNumber?: number } }}

interface IFootprintManager_warehouse_transfer_picking_gridComponentInParams {
  orderId?: number, shipmentId?: number, waveId?: number, orderStatusId?: number, waveStatusId?: number}


class FootprintManager_warehouse_transfer_picking_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_warehouse_transfer_picking_gridComponent;
  entity: IFootprintManager_warehouse_transfer_picking_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    source_licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    target_location_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    target_licenseplate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    operation: null,
    linenumber: null,
    material: null,
    packaging: null,
    lot: null,
    vendorlot: null,
    picked: null,
    remaining: null,
    quantity: null,
    source_location: null,
    source_licenseplate: null,
    target_location: null,
    target_licenseplate: null,
    expected_target_shipping_container: null,
  }

  get $fields_source_licenseplate_selector_inParams_locationId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.LocationId) ? $row.entity.ExpectedSourceLicensePlate?.LocationId : $row.entity.ExpectedSourceLocationId;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.LotId;
    
    return expr;
  }
  get $fields_source_licenseplate_selector_inParams_packagedId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.ActualPackagedPackId;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.WarehouseId;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_isLoose(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }
  get $fields_target_location_selector_inParams_eligibleForAllocation(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_warehouse_transfer_picking_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.operation = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.operation,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.linenumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.linenumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.picked = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.picked,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.remaining = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.remaining,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.source_location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.source_location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.source_licenseplate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.source_licenseplate,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['source_licenseplate_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.target_location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.target_location,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['target_location_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.target_licenseplate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.target_licenseplate,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['target_licenseplate_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.expected_target_shipping_container = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.expected_target_shipping_container,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
    this.formGroup
      .controls['quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_quantity_changed();
      });
    this.formGroup
      .controls['target_licenseplate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_licenseplate_change();
      });
  }

  async $initializeExisting(entity: IFootprintManager_warehouse_transfer_picking_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_warehouse_transfer_picking_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shipmentId:  $grid.inParams.shipmentId ,
      waveId:  $grid.inParams.waveId ,
    };
    const data = await this.$datasources.WarehouseTransfers.ds_warehouse_transfer_picking_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_warehouse_transfer_picking_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.operation.displayControl as TextModel).text = $row.entity.OperationCode.Name;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.ActualPackagedPack?.ShortName;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.picked.displayControl as TextModel).text = $utils.isDefined($row.entity.total_picked?.TotalCount) ? $row.entity.total_picked?.TotalCount?.toString(): '0';
    (this.cells.source_location.displayControl as TextModel).text = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.Location.Name) ? $row.entity.ExpectedSourceLicensePlate?.Location.Name : $row.entity.ExpectedSourceLocation?.Name;
    (this.cells.target_location.displayControl as TextModel).text = $row.entity.ActualTargetLocation?.Name;
    (this.cells.target_location.editControl as SelectBoxModel).reset($row.entity.ActualTargetLocationId);
    (this.cells.target_licenseplate.displayControl as TextModel).text = '';
    (this.cells.target_licenseplate.editControl as TextBoxModel).reset('');
    (this.cells.expected_target_shipping_container.displayControl as TextModel).text = $row.entity?.ExpectedTargetShippingContainer?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_licenseplate_change(event = null) {
    return this.on_licenseplate_changeInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_changeInternal(
    $row: FootprintManager_warehouse_transfer_picking_gridComponentRowModel,
  $grid: FootprintManager_warehouse_transfer_picking_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_licenseplate_change');
  if ($utils.isDefinedTrimmed($row.cells.target_licenseplate.editControl.value)) {
          let exists = true;
  
          const licenseplate = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.target_licenseplate.editControl.value
          })).result;
          exists = $utils.isDefined(licenseplate);
  
          if (exists) {
              $row.cells.target_licenseplate.styles.setAttentionClass();
          } else {
              $row.cells.target_licenseplate.styles.resetClasses();
          }
      } else {
          $row.cells.target_licenseplate.styles.resetClasses();
      }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_warehouse_transfer_picking_gridComponentRowModel,
  $grid: FootprintManager_warehouse_transfer_picking_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_row_data_loaded');
  
  
  const operationCodeId = $row.entity.OperationCodeId;
  
  if (operationCodeId == 8) {
      // Set remaining amount
      const actualPackagedAmount = $row.cells.quantity.editControl.value;
      $row.cells.remaining.displayControl.text =
          `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
      if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
          actualPackagedAmount > 0) {
          $row.cells.remaining.styles.resetClasses();
          $row.cells.remaining.styles.setAttentionClass();
      } else {
          $row.cells.remaining.styles.resetClasses();
      }
  
  
      //Set default target LP
      $row.cells.target_licenseplate.editControl.value = `${$row.entity.OrderId}`;
      $row.cells.target_licenseplate.displayControl.text = `${$row.entity.OrderId}`;
      //Set Qty to expected Qty from task
      $row.cells.quantity.editControl.value = $row.entity.ExpectedPackagedAmount;
      $row.cells.quantity.displayControl.text = `${$row.entity.ExpectedPackagedAmount} `;
  
  
      //Set default target LP
      $row.cells.target_licenseplate.editControl.value = `${$row.entity.OrderId}`
  
      //Default picking quantity
      $row.cells.quantity.editControl.value = $row.entity.ExpectedPackagedAmount
  
      const sourceLocationId = $utils.isDefined($row.entity.ExpectedSourceLicensePlate?.LocationId) ? $row.entity.ExpectedSourceLicensePlate?.LocationId : $row.entity.ExpectedSourceLocationId
  
      //Set source LP when source location is loose
      const licenseplates = (await $datasources.Locations.ds_get_licenseplate_by_loose_locationId.get({ locationId: sourceLocationId })).result;
      if ($utils.isDefined(licenseplates)) {
  
          $row.cells.source_licenseplate.editControl.reset(licenseplates[0].Id);
          $row.cells.source_licenseplate.editControl.readOnly = true;
          $row.cells.source_licenseplate.displayControl.text = `${licenseplates[0].LookupCode}`;
  
      } else {
          //set value and defaultValue to keep changed to false
          $row.cells.source_licenseplate.editControl.reset('');
          $row.cells.source_licenseplate.editControl.readOnly = false;
      }
  
      // If there is only one LP in the source LP that matches the criteria of the pick task then default it
      const sourcelicenseplates = (await $datasources.SalesOrders.ds_get_licenseplates_by_locationId_lotId_packagingId.get({
          locationId: sourceLocationId,
          lotId: $row.entity.LotId,
          packagedId: $row.entity.ActualPackagedPackId
      })).result;
      if ($utils.isDefined(sourcelicenseplates) && sourcelicenseplates.length === 1) {
  
          $row.cells.source_licenseplate.editControl.value = sourcelicenseplates[0].Id;
          $row.cells.source_licenseplate.displayControl.text = sourcelicenseplates[0].LookupCode;
      }
  
  }
  
  else if (operationCodeId == 79) {
  
      // Initialize total quantity of children pick task
      var totalExpectedAmount = 0;
  
  
      const childTasks = (await $datasources.SalesOrders.ds_get_full_licenseplate_pick_task_child_tasks.get({ taskId: $row.entity.Id })).result;
      if ($utils.isDefined(childTasks)) {
  
          // Default to the first tasks data
          var lotId = childTasks[0].LotId;
          var multiLot = false;
  
          var vendorLotId = childTasks[0].VendorLotId;
          var multiVendorLot = false;
  
          var materialId = childTasks[0].MaterialId;
          var multiMaterial = false;
  
          var packagingId = childTasks[0].ExpectedPackagedPackId;
          var multiPackaging = false;
  
          var linenumber = childTasks[0].OrderLineNumber;
          var multiLine = false;
  
          // Loop through all of the children tasks
          for (let childTask of childTasks) {
  
              totalExpectedAmount = totalExpectedAmount + childTask.ExpectedPackagedAmount;
  
              if (childTask.LotId !== lotId) {
                  multiLot = true;
              }
              if (childTask.VendorLotId !== vendorLotId) {
                  multiVendorLot = true;
              }
              if (childTask.MaterialId !== materialId) {
                  multiMaterial = true;
              }
              if (childTask.ExpectedPackagedPackId !== packagingId) {
                  multiPackaging = true;
              }
              if (childTask.OrderLineNumber !== linenumber) {
                  multiLine = true;
              }
          }
  
          if (multiLine) {
              $row.cells.linenumber.displayControl.text = childTasks[0].OrderLineNumber?.toString() + '**';
              $row.cells.linenumber.styles.setAttentionClass();
          }
          else {
              $row.cells.linenumber.displayControl.text = childTasks[0].OrderLineNumber?.toString();
          }
  
          if (multiMaterial) {
              $row.cells.material.displayControl.text = childTasks[0].Material?.LookupCode + '**';
              $row.cells.material.styles.setAttentionClass();
          }
          else {
              $row.cells.material.displayControl.text = childTasks[0].Material?.LookupCode;
  
          }
  
          if (multiPackaging) {
              $row.cells.packaging.displayControl.text = childTasks[0].ExpectedPackagedPack?.ShortName + '**';
              $row.cells.packaging.styles.setAttentionClass();
          }
          else {
              $row.cells.packaging.displayControl.text = childTasks[0].ExpectedPackagedPack?.ShortName;
  
          }
  
          if (multiLot) {
              $row.cells.lot.displayControl.text = childTasks[0].Lot?.LookupCode + '**';
              $row.cells.lot.styles.setAttentionClass();
          }
          else {
              $row.cells.lot.displayControl.text = childTasks[0].Lot?.LookupCode;
  
          }
  
          if (multiVendorLot) {
              $row.cells.vendorlot.displayControl.text = childTasks[0].Lot?.VendorLot?.LookupCode + '**';
              $row.cells.vendorlot.styles.setAttentionClass();
          }
          else {
              $row.cells.vendorlot.displayControl.text = childTasks[0].Lot?.VendorLot?.LookupCode;
  
          }
  
          $row.cells.picked.displayControl.text = '0';
          $row.cells.remaining.displayControl.text = totalExpectedAmount?.toString();
  
          $row.cells.quantity.displayControl.text = totalExpectedAmount?.toString();
          $row.cells.quantity.editControl.readOnly = true;
  
          $row.cells.source_licenseplate.editControl.value = $row.entity.ExpectedSourceLicensePlate?.Id;
          $row.cells.source_licenseplate.displayControl.text = $row.entity.ExpectedSourceLicensePlate?.LookupCode;
          $row.cells.source_licenseplate.editControl.readOnly = true;
  
          $row.cells.target_licenseplate.displayControl.text = $row.entity.ExpectedSourceLicensePlate?.LookupCode;
          $row.cells.target_licenseplate.editControl.value = $row.entity.ExpectedSourceLicensePlate?.Id;
          $row.cells.target_licenseplate.editControl.readOnly = true;
  
      }
  
  }
  
  // Set default target picking location from warehouse
  const defaultLocation = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $row.entity.WarehouseId })).result;
  if ($utils.isDefined(defaultLocation)) {
      if (defaultLocation.DefaultPickDestinationLocationId) {
          $row.cells.target_location.editControl.value = defaultLocation.DefaultPickDestinationLocationId
          $row.cells.target_location.displayControl.text = `${defaultLocation.DefaultPickDestinationLocation?.Name}`;
      }
  }
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_warehouse_transfer_picking_gridComponentRowModel,
  $grid: FootprintManager_warehouse_transfer_picking_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_save_existing_row');
  
  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  
  $row.cells.target_licenseplate.displayControl.text = $row.cells.target_licenseplate.editControl.value;
  $row.cells.target_location.displayControl.text = $row.cells.target_location.editControl.displayText;
  
  
  
  try {
  
      if ($row.entity.OperationCodeId == 8) {
          const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $row.entity.MaterialId
          })).result;
          if ($utils.isDefined(materials)) {
  
              var controllerTypeId = materials[0].ControllerTypeId;
              var isFixedWeight = materials[0].IsFixedWeight;
              var isFixedLength = materials[0].IsFixedLength;
              var isFixedVolume = materials[0].IsFixedVolume;
  
          } else {
              throw new Error('Unable to determine material properties.')
          }
  
          if ($row.cells.quantity.editControl.value > $row.entity.ExpectedPackagedAmount) {
              throw new Error('Quantity entered is greater than the remaining amount, overpicking not allowed!');
          }
  
  
          //Check if quantity entered is 0
          if ($row.cells.quantity.editControl.value == 0) {
              throw new Error('Quantity entered cannot be zero. If you need to cancel the task select the row then click on the cancel button.');
          }
  
  
  
          // Check if material is serial controlled
          if (controllerTypeId === 3 || controllerTypeId === 4 || isFixedWeight === false || isFixedLength === false || isFixedVolume === false) {
  
              const dialogResult = await $shell.FootprintManager.openserialnumbers_selection_gridDialog({
                  packagingId: $row.entity.ActualPackagedPackId,
                  lotId: $row.entity.LotId,
                  licenseplateId: $row.cells.source_licenseplate.editControl.value,
                  packagedAmountRequest: $row.cells.quantity.editControl.value
              }, 'modal');
  
  
              var serialnumbersResult = dialogResult.SerialNumberIds;
              var confirmResult = dialogResult.confirm;
              if (confirmResult === false) {
                  throw new Error('Serial numbers were not created, please try again.')
              }
  
          }
          //Re-initialize variable
          var serialNumbers = [];
  
          if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === $row.cells.quantity.editControl.value) {
  
              serialNumbers = serialnumbersResult;
  
          }
          if (confirmResult === true && (!$utils.isDefined(serialnumbersResult) || serialnumbersResult.length !== $row.cells.quantity.editControl.value)) {
  
              throw new Error('Error selecting serial numbers, please try again.')
  
          }
  
          // Check if target license plate exists, use 2 different APIs based on result
          const licenseplates = (await $datasources.SalesOrders.ds_get_licenseplate_by_lookupcode_warehouseId_shipmentId.get({
              warehouseId: $row.entity.WarehouseId,
              lookupcode: $row.cells.target_licenseplate.editControl.value,
              shipmentId: $row.entity.ShipmentId
          })).result;
          if (!$utils.isDefined(licenseplates)) {
  
              await $flows.SalesOrders.pick_on_new_license_plate({
                  taskId: $row.entity.Id,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagedPackId: $row.entity.ActualPackagedPackId,
                  sourceLicensePlateId: $row.cells.source_licenseplate.editControl.value,
                  targetLocationId: $row.cells.target_location.editControl.value,
                  targetLicensePlateLookupCode: $row.cells.target_licenseplate.editControl.value,
                  serialNumberIds: serialNumbers
              });
  
  
          } else {
              const licenseplateId = licenseplates[0].Id;
  
              await $flows.SalesOrders.pick_on_existing_license_plate({
                  targetLicensePlateId: licenseplateId,
                  sourceLicensePlateId: $row.cells.source_licenseplate.editControl.value,
                  packagedAmount: $row.cells.quantity.editControl.value,
                  packagedPackId: $row.entity.ActualPackagedPackId,
                  taskId: $row.entity.Id,
                  serialNumberIds: serialNumbers
              });
  
          }
  
      }
      // Full license plate pick task
      else if ($row.entity.OperationCodeId == 79) {
          await $flows.SalesOrders.pick_full_license_plate({
              taskId: $row.entity.Id,
              targetLocationId: $row.cells.target_location.editControl.value
          });
      }
  
      await $grid.refresh();
  
  
  
  } catch (error) {
  
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
  
      $shell.FootprintManager.openErrorDialog('Selected task could not be picked.', `${errorMessage}`);
  
  
  
  }
  
  }
  on_quantity_changed(event = null) {
    return this.on_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_quantity_changedInternal(
    $row: FootprintManager_warehouse_transfer_picking_gridComponentRowModel,
  $grid: FootprintManager_warehouse_transfer_picking_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_quantity_changed');
  // set-row-remaining.flow.json
  const actualPackagedAmount = $row.cells.quantity.editControl.value;
  $row.cells.remaining.displayControl.text =
      `${$row.entity.ExpectedPackagedAmount} ${$row.entity.ExpectedPackagedPack?.ShortName}`;
  if ($row.entity.ExpectedPackagedAmount !== actualPackagedAmount &&
      actualPackagedAmount > 0) {
      $row.cells.remaining.styles.setAttentionClass();
  } else {
      $row.cells.remaining.styles.resetClasses();
  }
  
  
  
  var materialId = $row.entity.MaterialId;
  var packagingId = $row.entity.ActualPackagedPackId;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.quantity.editControl.format = decimalFormat.format;
  
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SalesOrders_licenseplates_by_locationId_lotId_packagingId_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
  ],
  selector: 'FootprintManager-warehouse_transfer_picking_grid',
  templateUrl: './FootprintManager.warehouse_transfer_picking_grid.component.html'
})
export class FootprintManager_warehouse_transfer_picking_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_warehouse_transfer_picking_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_warehouse_transfer_picking_gridComponentInParams = { orderId: null, shipmentId: null, waveId: null, orderStatusId: null, waveStatusId: null };


  //#region Variables
  vars: { entities?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLocationId?: number, ExpectedPackagedAmount?: number, LotId?: number, MaterialId?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, VendorLotId?: number, WarehouseId?: number, Material?: { LookupCode?: string }, ExpectedPackagedPack?: { Name?: string }, ActualPackagedPack?: { Name?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ShipmentLine?: { ActualPackagedAmount?: number, ActualPackaged?: { Name?: string } } }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     operation: new GridHeaderModel(new HeaderStyles(null, null), 'operation', 'Operation', false, false, null, false),       linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', 'Line #', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       picked: new GridHeaderModel(new HeaderStyles(null, null), 'picked', 'Picked', false, false, null, false),       remaining: new GridHeaderModel(new HeaderStyles(null, null), 'remaining', 'Remaining', false, false, null, false),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'quantity', 'Pick quantity', false, false, null, false),       source_location: new GridHeaderModel(new HeaderStyles(null, null), 'source_location', 'Source', false, false, null, false),       source_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'source_licenseplate', 'Source LP', true, false, null, false),       target_location: new GridHeaderModel(new HeaderStyles(null, null), 'target_location', 'Target', true, false, null, false),       target_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'target_licenseplate', 'Target LP', true, false, null, false),       expected_target_shipping_container: new GridHeaderModel(new HeaderStyles(null, null), 'expected_target_shipping_container', 'Target container', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_warehouse_transfer_picking_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('waveId') set $inParams_waveId(value: any) {
    this.inParams['waveId'] = value;
  }
  get $inParams_waveId(): any {
    return this.inParams['waveId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }
  @Input('waveStatusId') set $inParams_waveStatusId(value: any) {
    this.inParams['waveStatusId'] = value;
  }
  get $inParams_waveStatusId(): any {
    return this.inParams['waveStatusId'] ;
  }

  topToolbar = {
      reallocate: new ToolModel(new ButtonModel('reallocate', new ButtonStyles(null, null), false, false, false, 'Reallocate', 'ms-Icon ms-Icon--BranchMerge', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Picking tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
      shipmentId:  $grid.inParams.shipmentId ,
      waveId:  $grid.inParams.waveId ,
    };
    try {
      const data = await this.$datasources.WarehouseTransfers.ds_warehouse_transfer_picking_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_warehouse_transfer_picking_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_warehouse_transfer_picking_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_warehouse_transfer_picking_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_warehouse_transfer_picking_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_init');
  // Disable Cancel button
  $grid.topToolbar.cancel.control.readOnly = true;
  
      // Check if the order status is provided and check to see if it is in a processing status
      if ($utils.isDefined($grid.inParams.orderStatusId) && $grid.inParams.orderStatusId === 2) {
  
          $grid.canEdit = true;
  
  
      } 
      // Check if the wave status is provided and make sure it is not in a completed or cancelled status
      else if ($utils.isDefined($grid.inParams.waveStatusId) && $grid.inParams.waveStatusId !== 5 && $grid.inParams.waveStatusId !== 4){
           $grid.canEdit = true;
      }
      else {
  
          $grid.canEdit = false;
  
          $grid.headers.quantity.required = false;
          $grid.headers.packaging.required = false;
          $grid.headers.lot.required = false;
          $grid.headers.vendorlot.required = false;
          $grid.headers.target_licenseplate.required = false;
          $grid.headers.target_location.required = false;
  
      }
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootprintManager_warehouse_transfer_picking_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_cancel_clicked');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Task Cancellation Error', 'No tasks selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_pick_task_cancelable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order Line ${row.cells.linenumber.displayControl.text?.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Cancel pick task errors';
          const errorMessage = `Pick task(s) ${failures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} cannot be cancelled}`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Pick task(s) ${candidates.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} - Once cancelled, the task(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some pick task(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following pick task(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              const dialogResult = await $shell.FootprintManager.opensales_order_cancellation_options_formDialog({
                  title: 'Select a reason code',
                  parentId: 6,
                  parentEntity: 'OperationType'
              });
              var userConfirmed = dialogResult.confirm;
              var reasonCodeId = dialogResult.reasonCodeId;
  
              if (userConfirmed) {
  
                  for (const candidate of candidates) {
                      try {
                          const flowParams = {
                              taskId: candidate.entity.Id,
                              reasonCodeId: reasonCodeId
                          }
                          const result = await $flows.SalesOrders.cancel_pick_task_flow(flowParams);
                          const reason = result.reason;
                          if ($utils.isDefined(reason)) {
                              processFailures.push(candidate);
                              errorMsgList.push(`Order line ${candidate.cells.linenumber.displayControl.text?.toString()} - ${reason}`);
                          } else {
                              processSuccess.push(candidate);
                          }
  
                      } catch (error) {
                          processFailures.push(candidate);
                          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                          const errorDescription = `Order line ${candidate.cells.linenumber.displayControl.text?.toString()} - ${errorMessage}`;
                          errorMsgList.push(errorDescription);
                          errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                      }
                  }
  
                  // all succeeded
                  if (processSuccess.length === candidates.length) {
                      const title = 'All pick task(s) cancelled';
                      const message = `Pick task(s) ${processSuccess.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} cancelled`;
                      await $shell.FootprintManager.openInfoDialog(title, message);
                  } else {
                      // all failures
                      if (processFailures.length === candidates.length) {
                          const title = 'All pick task(s) failed to cancel';
                          const message = `Pick task(s) ${processFailures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')} could not be cancelled`;
                          await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      } else {
                          const title = 'Some pick task(s) could not be cancelled';
                          const success = `Pick task(s) ${processSuccess.map(c => c.ecells.linenumber.displayControl.text?.toString()).join(',')} were cancelled.`;
                          const errors = `The following pick task(s) could not be cancelled: ${processFailures.map(c => c.cells.linenumber.displayControl.text?.toString()).join(',')}`;
                          const message = `${success} \r\n\r\n${errors}`;
                          await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      }
                  }
                  await $grid.refresh();
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_warehouse_transfer_picking_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_row_selected');
  
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount === 1) {
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.reallocate.control.readOnly = false;
  
  }
  else if (selectedRowsCount > 1) {
      $grid.topToolbar.cancel.control.readOnly = false;
      $grid.topToolbar.reallocate.control.readOnly = true;
  }
  else {
      $grid.topToolbar.cancel.control.readOnly = true;
      $grid.topToolbar.reallocate.control.readOnly = true;
  
  }
  
  
  
  
  }
  on_reallocate_clicked(event = null) {
    return this.on_reallocate_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reallocate_clickedInternal(
    $grid: FootprintManager_warehouse_transfer_picking_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_warehouse_transfer_picking_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'warehouse_transfer_picking_grid.on_reallocate_clicked');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Reallocate Error', 'No task selected.');
      return;
  }
  else {
  
      const wizardResult = await $shell.FootprintManager.openinventory_by_location_selection_wizardDialog({
          warehouseId: $grid.selectedRows[0].entity.WarehouseId,
          projectId: $grid.selectedRows[0].entity.Material.ProjectId, // Just in case the projectId on the pick task is not set correctly
          materialId: $grid.selectedRows[0].entity.MaterialId,
          packagingId: $grid.selectedRows[0].entity.ActualPackagedPackId,
          lotId: $utils.isDefined($grid.selectedRows[0].entity.OrderLine.LotId) ? $grid.selectedRows[0].entity.OrderLine.LotId : null,
          packagedAmountRequest: $grid.selectedRows[0].entity.ExpectedPackagedAmount
      });
      var inventorySelectionResults = wizardResult.inventory;
  
  
      if ($utils.isDefined(inventorySelectionResults)) {
  
          const currentPackagedAmount = selectedRows[0].entity.ExpectedPackagedAmount;
          const taskId = selectedRows[0].entity.Id;
          const newLotId = inventorySelectionResults[0].lotId;
          const newLocationId = inventorySelectionResults[0].locationId;
          const updatedPackagedAmount = inventorySelectionResults[0].packagedAmount;
          const vendorLotId = inventorySelectionResults[0].vendorLotId;
  
          try {
              const result = await $flows.SalesOrders.reallocate_pick_task_flow({ 
                  taskId: taskId,
                  currentPackagedAmount: currentPackagedAmount,
                  updatedPackagedAmount: updatedPackagedAmount,
                  lotId: newLotId,
                  locationId: newLocationId,
                  vendorLotId: vendorLotId
                  });
              const reason = result.reason;
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootprintManager.openErrorDialog('Task cannot be reallocated.', reason);
                  return;
              }
              else {
                 await $grid.refresh();
              }
          }
          catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `${errorMessage}`;
              await $shell.FootprintManager.openErrorDialog('Task reallocation error', 'An error occurred during the reallocation of the task', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
  
      }
  
  }
  
  
  
  
  
  
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
