import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_ds_serialnumbers_selection_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { material_id: number, lot_id?: number, packagingId?: number, fullTextSearch?: string, filterSerialNumberIds?: number[], vendor_lot_id?: number, excludeSerialNumberIds?: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_serialnumbers_selection_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { material_id: number, lot_id?: number, packagingId?: number, fullTextSearch?: string, filterSerialNumberIds?: number[], vendor_lot_id?: number, excludeSerialNumberIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_serialnumbers_selection_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { material_id: number, lot_id?: number, packagingId?: number, fullTextSearch?: string, filterSerialNumberIds?: number[], vendor_lot_id?: number, excludeSerialNumberIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_serialnumbers_selection_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      material_id: number, lot_id?: number, packagingId?: number, fullTextSearch?: string, filterSerialNumberIds?: number[], vendor_lot_id?: number, excludeSerialNumberIds?: number[]    }) {
        if(isNil(inParams.material_id)) {
          return true; 
        }
      return false;
    }
}
