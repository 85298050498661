<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm_serial" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="back" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_back_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}} {{fields.description.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.description.control.styles.style"
                              [ngClass]="fields.description.control.styles.classes"
                              [matTooltip]="fields.description.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.description.control.text }}</div>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-confirmed_materials" *ngIf="!fields.confirmed_materials.hidden && !fields.confirmed_materials.removed" 
                            class="field-container full {{fields.confirmed_materials.invalid ? 'invalid' : ''}} {{fields.confirmed_materials.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.confirmed_materials.styles.style"
                            [ngClass]="fields.confirmed_materials.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.confirmed_materials.label + (fields.confirmed_materials.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.confirmed_materials.label}}<span *ngIf="fields.confirmed_materials.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.confirmed_materials.control.styles.style"
                              [ngClass]="fields.confirmed_materials.control.styles.classes"
                              [matTooltip]="fields.confirmed_materials.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.confirmed_materials.control.text }}</div>
                        <ng-container *ngIf="fields.confirmed_materials.invalid">
                          <ng-container *ngFor="let error of fields.confirmed_materials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.material.control.styles.style"
                              [ngClass]="fields.material.control.styles.classes"
                              [matTooltip]="fields.material.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.material.control.text }}</div>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-consumed_quantity" *ngIf="!fields.consumed_quantity.hidden && !fields.consumed_quantity.removed" 
                            class="field-container standard {{fields.consumed_quantity.invalid ? 'invalid' : ''}} {{fields.consumed_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.consumed_quantity.styles.style"
                            [ngClass]="fields.consumed_quantity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.consumed_quantity.label + (fields.consumed_quantity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.consumed_quantity.label}}<span *ngIf="fields.consumed_quantity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.consumed_quantity.control.styles.style"
                              [ngClass]="fields.consumed_quantity.control.styles.classes"
                              [matTooltip]="fields.consumed_quantity.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.consumed_quantity.control.text }}</div>
                        <ng-container *ngIf="fields.consumed_quantity.invalid">
                          <ng-container *ngFor="let error of fields.consumed_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate" *ngIf="!fields.license_plate.hidden && !fields.license_plate.removed" 
                            class="field-container standard {{fields.license_plate.invalid ? 'invalid' : ''}} {{fields.license_plate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.license_plate.styles.style"
                            [ngClass]="fields.license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.license_plate.label + (fields.license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.license_plate.label}}<span *ngIf="fields.license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.license_plate.control.styles.style"
                              [ngClass]="fields.license_plate.control.styles.classes"
                              [matTooltip]="fields.license_plate.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.license_plate.control.text }}</div>
                        <ng-container *ngIf="fields.license_plate.invalid">
                          <ng-container *ngFor="let error of fields.license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.serial_numbers_grid.hasTabToShow" class="tab" data-cy="tab-serial_numbers_grid">
              <h2 [className]="tabs.serial_numbers_grid.active? 'active': ''" (click)="tabs.serial_numbers_grid.activate()">{{tabs.serial_numbers_grid.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-manufacturing_order_component_serial_number_selection_grid *ngIf="tabs.serial_numbers_grid.active"
              #$tabs_serial_numbers_grid
              [material_id]="$tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_material_id"
              [warehouse_id]="$tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_warehouse_id"
              [serials_needed_count]="$tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_serials_needed_count"
              [license_plate]="$tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_license_plate"
              (serial_selection_event)="serial_numbers_select_event_handler($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_serial_numbers_grid')">
              </Manufacturing-manufacturing_order_component_serial_number_selection_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>