import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { EasyPost_create_address_formComponent } from './EasyPost.create_address_form.component';
import { EasyPost_easypost_library_homeComponent } from './EasyPost.easypost_library_home.component';
import { EasyPost_easypost_tester_formComponent } from './EasyPost.easypost_tester_form.component';


@Injectable({ providedIn: 'root' })
export class EasyPost_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public EasyPost: EasyPost_ShellService = this;

  public opencreate_address_form(replaceCurrentView?: boolean) {
    this.$logger.log('EasyPost', 'create_address_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'create_address_form',
        referenceName: 'EasyPost_create_address_form',
        component: EasyPost_create_address_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencreate_address_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('EasyPost', 'create_address_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EasyPost_create_address_formComponent,
      'create_address_form',
      mode,
      dialogSize
    )
  }
  public openeasypost_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('EasyPost', 'easypost_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'EasyPost_easypost_library_home',
        component: EasyPost_easypost_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openeasypost_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('EasyPost', 'easypost_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EasyPost_easypost_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openeasypost_tester_form(replaceCurrentView?: boolean) {
    this.$logger.log('EasyPost', 'easypost_tester_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Test harness',
        referenceName: 'EasyPost_easypost_tester_form',
        component: EasyPost_easypost_tester_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openeasypost_tester_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('EasyPost', 'easypost_tester_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EasyPost_easypost_tester_formComponent,
      'Test harness',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'EasyPost_create_address_form') {
      this.$logger.log('EasyPost', 'create_address_form');
      const title = 'create_address_form';
      const component = EasyPost_create_address_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EasyPost_easypost_library_home') {
      this.$logger.log('EasyPost', 'easypost_library_home');
      const title = 'Home';
      const component = EasyPost_easypost_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EasyPost_easypost_tester_form') {
      this.$logger.log('EasyPost', 'easypost_tester_form');
      const title = 'Test harness';
      const component = EasyPost_easypost_tester_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    return result;
  }
}
