import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Notifications_auto_email_rule_copy_formComponent } from './Notifications.auto_email_rule_copy_form.component';
import { Notifications_insert_alert_formComponent } from './Notifications.insert_alert_form.component';
import { Notifications_insert_log_formComponent } from './Notifications.insert_log_form.component';
import { Notifications_preview_fileComponent } from './Notifications.preview_file.component';
import { Notifications_schedule_frequency_formComponent } from './Notifications.schedule_frequency_form.component';
import { Notifications_auto_email_rule_editorComponent } from './Notifications.auto_email_rule_editor.component';
import { Notifications_alerts_filters_gridComponent } from './Notifications.alerts_filters_grid.component';
import { Notifications_alerts_gridComponent } from './Notifications.alerts_grid.component';
import { Notifications_auto_email_field_parameters_by_entity_gridComponent } from './Notifications.auto_email_field_parameters_by_entity_grid.component';
import { Notifications_auto_email_report_configurations_gridComponent } from './Notifications.auto_email_report_configurations_grid.component';
import { Notifications_auto_email_rule_reports_gridComponent } from './Notifications.auto_email_rule_reports_grid.component';
import { Notifications_auto_email_rules_gridComponent } from './Notifications.auto_email_rules_grid.component';
import { Notifications_email_request_attachments_gridComponent } from './Notifications.email_request_attachments_grid.component';
import { Notifications_email_requests_gridComponent } from './Notifications.email_requests_grid.component';
import { Notifications_report_inputs_gridComponent } from './Notifications.report_inputs_grid.component';
import { Notifications_alerts_hubComponent } from './Notifications.alerts_hub.component';
import { Notifications_auto_email_rules_hubComponent } from './Notifications.auto_email_rules_hub.component';
import { Notifications_log_notes_reportComponent } from './Notifications.log_notes_report.component';
import { Notifications_transaction_reportComponent } from './Notifications.transaction_report.component';
import { Notifications_view_field_code_editorComponent } from './Notifications.view_field_code_editor.component';
import { Notifications_view_attachment_contentComponent } from './Notifications.view_attachment_content.component';
import { Notifications_auto_email_attachment_types_dd_singleComponent } from './Notifications.auto_email_attachment_types_dd_single.component';
import { Notifications_auto_email_report_options_dd_singleComponent } from './Notifications.auto_email_report_options_dd_single.component';
import { Notifications_auto_email_rule_contexts_dd_singleComponent } from './Notifications.auto_email_rule_contexts_dd_single.component';
import { Notifications_carriers_dd_singleComponent } from './Notifications.carriers_dd_single.component';
import { Notifications_dock_appointment_types_dd_singleComponent } from './Notifications.dock_appointment_types_dd_single.component';
import { Notifications_frequency_interval_dd_singleComponent } from './Notifications.frequency_interval_dd_single.component';
import { Notifications_monthly_frequency_dd_singleComponent } from './Notifications.monthly_frequency_dd_single.component';
import { Notifications_owners_dd_singleComponent } from './Notifications.owners_dd_single.component';
import { Notifications_projects_dd_singleComponent } from './Notifications.projects_dd_single.component';
import { Notifications_time_frequency_dd_singleComponent } from './Notifications.time_frequency_dd_single.component';
import { Notifications_time_interval_dd_singleComponent } from './Notifications.time_interval_dd_single.component';
import { Notifications_time_meridiem_dd_singleComponent } from './Notifications.time_meridiem_dd_single.component';
import { Notifications_time_selector_dd_singleComponent } from './Notifications.time_selector_dd_single.component';
import { Notifications_warehouses_dd_singleComponent } from './Notifications.warehouses_dd_single.component';
import { Notifications_weekly_frequency_dd_singleComponent } from './Notifications.weekly_frequency_dd_single.component';
import { Notifications_auto_email_attachment_types_dd_multiComponent } from './Notifications.auto_email_attachment_types_dd_multi.component';
import { Notifications_auto_email_report_options_dd_multiComponent } from './Notifications.auto_email_report_options_dd_multi.component';
import { Notifications_auto_email_rule_contexts_dd_multiComponent } from './Notifications.auto_email_rule_contexts_dd_multi.component';
import { Notifications_carriers_dd_multiComponent } from './Notifications.carriers_dd_multi.component';
import { Notifications_dock_appointment_types_dd_multiComponent } from './Notifications.dock_appointment_types_dd_multi.component';
import { Notifications_frequency_interval_dd_multiComponent } from './Notifications.frequency_interval_dd_multi.component';
import { Notifications_monthly_frequency_dd_multiComponent } from './Notifications.monthly_frequency_dd_multi.component';
import { Notifications_owners_dd_multiComponent } from './Notifications.owners_dd_multi.component';
import { Notifications_projects_dd_multiComponent } from './Notifications.projects_dd_multi.component';
import { Notifications_time_frequency_dd_multiComponent } from './Notifications.time_frequency_dd_multi.component';
import { Notifications_time_interval_dd_multiComponent } from './Notifications.time_interval_dd_multi.component';
import { Notifications_time_meridiem_dd_multiComponent } from './Notifications.time_meridiem_dd_multi.component';
import { Notifications_time_selector_dd_multiComponent } from './Notifications.time_selector_dd_multi.component';
import { Notifications_warehouses_dd_multiComponent } from './Notifications.warehouses_dd_multi.component';
import { Notifications_weekly_frequency_dd_multiComponent } from './Notifications.weekly_frequency_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Notifications: Notifications_ShellService = this;

  public openauto_email_rule_copy_form(inParams:{ rule_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Email Rule Copy Form',
        referenceName: 'Notifications_auto_email_rule_copy_form',
        component: Notifications_auto_email_rule_copy_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_rule_copy_formDialog(
    inParams:{ rule_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ projectId?: number, rule_id?: string[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_rule_copy_formComponent,
      'Email Rule Copy Form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinsert_alert_form(inParams:{ alert?: any }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'insert_alert_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'insert_alert_form',
        referenceName: 'Notifications_insert_alert_form',
        component: Notifications_insert_alert_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinsert_alert_formDialog(
    inParams:{ alert?: any }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'insert_alert_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_insert_alert_formComponent,
      'insert_alert_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinsert_log_form(replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'insert_log_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'insert_log_form',
        referenceName: 'Notifications_insert_log_form',
        component: Notifications_insert_log_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinsert_log_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'insert_log_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_insert_log_formComponent,
      'insert_log_form',
      mode,
      dialogSize
    )
  }
  public openpreview_file(inParams:{ imageSource: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'preview_file');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Preview file',
        referenceName: 'Notifications_preview_file',
        component: Notifications_preview_fileComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpreview_fileDialog(
    inParams:{ imageSource: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'preview_file');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_preview_fileComponent,
      'Preview file',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openschedule_frequency_form(inParams:{ schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Frequency',
        referenceName: 'Notifications_schedule_frequency_form',
        component: Notifications_schedule_frequency_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openschedule_frequency_formDialog(
    inParams:{ schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_schedule_frequency_formComponent,
      'Frequency',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_email_rule_editor(inParams:{ rule_id?: string, project_id?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'auto_email_rule_editor',
        referenceName: 'Notifications_auto_email_rule_editor',
        component: Notifications_auto_email_rule_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_rule_editorDialog(
    inParams:{ rule_id?: string, project_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_rule_editorComponent,
      'auto_email_rule_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openalerts_filters_grid(inParams:{ application_name?: string, alert_id?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'alerts_filters_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Alerts filters grid',
        referenceName: 'Notifications_alerts_filters_grid',
        component: Notifications_alerts_filters_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openalerts_filters_gridDialog(
    inParams:{ application_name?: string, alert_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'alerts_filters_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_alerts_filters_gridComponent,
      'Alerts filters grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openalerts_grid(inParams:{ application_name?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'alerts_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Alerts grid',
        referenceName: 'Notifications_alerts_grid',
        component: Notifications_alerts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openalerts_gridDialog(
    inParams:{ application_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'alerts_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_alerts_gridComponent,
      'Alerts grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_email_field_parameters_by_entity_grid(inParams:{ entity: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Parameters',
        referenceName: 'Notifications_auto_email_field_parameters_by_entity_grid',
        component: Notifications_auto_email_field_parameters_by_entity_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_field_parameters_by_entity_gridDialog(
    inParams:{ entity: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ parameters?: string[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_field_parameters_by_entity_gridComponent,
      'Parameters',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_email_report_configurations_grid(replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'auto_email_report_configurations_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Auto-email reports',
        referenceName: 'Notifications_auto_email_report_configurations_grid',
        component: Notifications_auto_email_report_configurations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openauto_email_report_configurations_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'auto_email_report_configurations_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_report_configurations_gridComponent,
      'Auto-email reports',
      mode,
      dialogSize
    )
  }
  public openauto_email_rule_reports_grid(inParams:{ ruleId?: string, ruleContext: string, readOnly: boolean }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Notifications_auto_email_rule_reports_grid',
        component: Notifications_auto_email_rule_reports_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_rule_reports_gridDialog(
    inParams:{ ruleId?: string, ruleContext: string, readOnly: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ reports?: string[] }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_rule_reports_gridComponent,
      'Reports',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_email_rules_grid(inParams:{ entityType?: string, entityKeys?: { name: string, value: any }, projectId?: number, context?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Auto-email rules',
        referenceName: 'Notifications_auto_email_rules_grid',
        component: Notifications_auto_email_rules_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_rules_gridDialog(
    inParams:{ entityType?: string, entityKeys?: { name: string, value: any }, projectId?: number, context?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_rules_gridComponent,
      'Auto-email rules',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openemail_request_attachments_grid(inParams:{ requestId: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Attachments',
        referenceName: 'Notifications_email_request_attachments_grid',
        component: Notifications_email_request_attachments_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openemail_request_attachments_gridDialog(
    inParams:{ requestId: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_email_request_attachments_gridComponent,
      'Attachments',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openemail_requests_grid(inParams:{ entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Email requests',
        referenceName: 'Notifications_email_requests_grid',
        component: Notifications_email_requests_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openemail_requests_gridDialog(
    inParams:{ entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_email_requests_gridComponent,
      'Email requests',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openreport_inputs_grid(inParams:{ reportName: string, inputs?: { name: string, value: string }[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'report_inputs_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Inputs',
        referenceName: 'Notifications_report_inputs_grid',
        component: Notifications_report_inputs_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreport_inputs_gridDialog(
    inParams:{ reportName: string, inputs?: { name: string, value: string }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inputs?: { name: string, value: string }[] }> {
    this.$logger.log('Notifications', 'report_inputs_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_report_inputs_gridComponent,
      'Inputs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openalerts_hub(inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'alerts_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'alerts_hub',
        referenceName: 'Notifications_alerts_hub',
        component: Notifications_alerts_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openalerts_hubDialog(
    inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'alerts_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_alerts_hubComponent,
      'alerts_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openauto_email_rules_hub(inParams:{ projectId?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'auto_email_rules_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Auto-email rules',
        referenceName: 'Notifications_auto_email_rules_hub',
        component: Notifications_auto_email_rules_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openauto_email_rules_hubDialog(
    inParams:{ projectId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'auto_email_rules_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_auto_email_rules_hubComponent,
      'Auto-email rules',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlog_notes_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'log_notes_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'log_notes_report',
        referenceName: 'Notifications_log_notes_report',
        component: Notifications_log_notes_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlog_notes_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'log_notes_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Notifications_log_notes_reportComponent,
      'log_notes_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opentransaction_report(inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Notifications', 'transaction_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'transaction_report',
        referenceName: 'Notifications_transaction_report',
        component: Notifications_transaction_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opentransaction_reportDialog(
    inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Notifications', 'transaction_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Notifications_transaction_reportComponent,
      'transaction_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_field_code_editor(inParams:{ field_name: string, field_value: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'View field',
        referenceName: 'Notifications_view_field_code_editor',
        component: Notifications_view_field_code_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_field_code_editorDialog(
    inParams:{ field_name: string, field_value: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_view_field_code_editorComponent,
      'View field',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_attachment_content(inParams:{ source_url: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'View attachment',
        referenceName: 'Notifications_view_attachment_content',
        component: Notifications_view_attachment_contentComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_attachment_contentDialog(
    inParams:{ source_url: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Notifications_view_attachment_contentComponent,
      'View attachment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Notifications_auto_email_rule_copy_form') {
      const title = 'Email Rule Copy Form';
      const component = Notifications_auto_email_rule_copy_formComponent;
      const inParams:{ rule_id?: string } = { rule_id: null };
      if (!isNil(params.get('rule_id'))) {
        const paramValueString = params.get('rule_id');
        // TODO: date
        inParams.rule_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_insert_alert_form') {
      this.$logger.log('Notifications', 'insert_alert_form');
      const title = 'insert_alert_form';
      const component = Notifications_insert_alert_formComponent;
      const inParams:{ alert?: any } = { alert: null };
      if (!isNil(params.get('alert'))) {
        const paramValueString = params.get('alert');
        inParams.alert = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_insert_log_form') {
      this.$logger.log('Notifications', 'insert_log_form');
      const title = 'insert_log_form';
      const component = Notifications_insert_log_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_preview_file') {
      this.$logger.log('Notifications', 'preview_file');
      const title = 'Preview file';
      const component = Notifications_preview_fileComponent;
      const inParams:{ imageSource: string } = { imageSource: null };
      if (!isNil(params.get('imageSource'))) {
        const paramValueString = params.get('imageSource');
        // TODO: date
        inParams.imageSource = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_schedule_frequency_form') {
      const title = 'Frequency';
      const component = Notifications_schedule_frequency_formComponent;
      const inParams:{ schedule?: { dateOption: string, dateInterval: any, timeOption: string, timeInterval: any, timeOffset: number } } = { schedule: { dateOption: null, dateInterval: null, timeOption: null, timeInterval: null, timeOffset: null } };
      if (!isNil(params.get('schedule'))) {
        const paramValueString = params.get('schedule');
        inParams.schedule = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rule_editor') {
      const title = 'auto_email_rule_editor';
      const component = Notifications_auto_email_rule_editorComponent;
      const inParams:{ rule_id?: string, project_id?: number } = { rule_id: null, project_id: null };
      if (!isNil(params.get('rule_id'))) {
        const paramValueString = params.get('rule_id');
        // TODO: date
        inParams.rule_id = paramValueString;
              }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_alerts_filters_grid') {
      this.$logger.log('Notifications', 'alerts_filters_grid');
      const title = 'Alerts filters grid';
      const component = Notifications_alerts_filters_gridComponent;
      const inParams:{ application_name?: string, alert_id?: string } = { application_name: null, alert_id: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('alert_id'))) {
        const paramValueString = params.get('alert_id');
        // TODO: date
        inParams.alert_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_alerts_grid') {
      this.$logger.log('Notifications', 'alerts_grid');
      const title = 'Alerts grid';
      const component = Notifications_alerts_gridComponent;
      const inParams:{ application_name?: string } = { application_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_field_parameters_by_entity_grid') {
      const title = 'Parameters';
      const component = Notifications_auto_email_field_parameters_by_entity_gridComponent;
      const inParams:{ entity: string } = { entity: null };
      if (!isNil(params.get('entity'))) {
        const paramValueString = params.get('entity');
        // TODO: date
        inParams.entity = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_report_configurations_grid') {
      this.$logger.log('Notifications', 'auto_email_report_configurations_grid');
      const title = 'Auto-email reports';
      const component = Notifications_auto_email_report_configurations_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rule_reports_grid') {
      const title = 'Reports';
      const component = Notifications_auto_email_rule_reports_gridComponent;
      const inParams:{ ruleId?: string, ruleContext: string, readOnly: boolean } = { ruleId: null, ruleContext: null, readOnly: null };
      if (!isNil(params.get('ruleId'))) {
        const paramValueString = params.get('ruleId');
        // TODO: date
        inParams.ruleId = paramValueString;
              }
      if (!isNil(params.get('ruleContext'))) {
        const paramValueString = params.get('ruleContext');
        // TODO: date
        inParams.ruleContext = paramValueString;
              }
      if (!isNil(params.get('readOnly'))) {
        const paramValueString = params.get('readOnly');
        inParams.readOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rules_grid') {
      const title = 'Auto-email rules';
      const component = Notifications_auto_email_rules_gridComponent;
      const inParams:{ entityType?: string, entityKeys?: { name: string, value: any }, projectId?: number, context?: string } = { entityType: null, entityKeys: { name: null, value: null }, projectId: null, context: null };
      if (!isNil(params.get('entityType'))) {
        const paramValueString = params.get('entityType');
        // TODO: date
        inParams.entityType = paramValueString;
              }
      if (!isNil(params.get('entityKeys'))) {
        const paramValueString = params.get('entityKeys');
        inParams.entityKeys = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_email_request_attachments_grid') {
      const title = 'Attachments';
      const component = Notifications_email_request_attachments_gridComponent;
      const inParams:{ requestId: string } = { requestId: null };
      if (!isNil(params.get('requestId'))) {
        const paramValueString = params.get('requestId');
        // TODO: date
        inParams.requestId = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_email_requests_grid') {
      const title = 'Email requests';
      const component = Notifications_email_requests_gridComponent;
      const inParams:{ entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string } = { entityType: null, entityId: null, ruleId: null, projectId: null, context: null };
      if (!isNil(params.get('entityType'))) {
        const paramValueString = params.get('entityType');
        // TODO: date
        inParams.entityType = paramValueString;
              }
      if (!isNil(params.get('entityId'))) {
        const paramValueString = params.get('entityId');
        inParams.entityId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ruleId'))) {
        const paramValueString = params.get('ruleId');
        // TODO: date
        inParams.ruleId = paramValueString;
              }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_report_inputs_grid') {
      this.$logger.log('Notifications', 'report_inputs_grid');
      const title = 'Inputs';
      const component = Notifications_report_inputs_gridComponent;
      const inParams:{ reportName: string, inputs?: { name: string, value: string }[] } = { reportName: null, inputs: [] };
      if (!isNil(params.get('reportName'))) {
        const paramValueString = params.get('reportName');
        // TODO: date
        inParams.reportName = paramValueString;
              }
      if (!isNil(params.get('inputs'))) {
        const paramValueString = params.get('inputs');
        inParams.inputs = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_alerts_hub') {
      this.$logger.log('Notifications', 'alerts_hub');
      const title = 'alerts_hub';
      const component = Notifications_alerts_hubComponent;
      const inParams:{ application_name?: string, column_names?: any, filter_values?: any, user_name?: string } = { application_name: null, column_names: null, filter_values: null, user_name: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rules_hub') {
      this.$logger.log('Notifications', 'auto_email_rules_hub');
      const title = 'Auto-email rules';
      const component = Notifications_auto_email_rules_hubComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_log_notes_report') {
      this.$logger.log('Notifications', 'log_notes_report');
      const title = 'log_notes_report';
      const component = Notifications_log_notes_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_transaction_report') {
      this.$logger.log('Notifications', 'transaction_report');
      const title = 'transaction_report';
      const component = Notifications_transaction_reportComponent;
      const inParams:{ transaction_id: string, log_ids?: string[], message_ids?: string[] } = { transaction_id: null, log_ids: [], message_ids: [] };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('log_ids'))) {
        const paramValueString = params.get('log_ids');
        inParams.log_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('message_ids'))) {
        const paramValueString = params.get('message_ids');
        inParams.message_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_view_field_code_editor') {
      const title = 'View field';
      const component = Notifications_view_field_code_editorComponent;
      const inParams:{ field_name: string, field_value: string } = { field_name: null, field_value: null };
      if (!isNil(params.get('field_name'))) {
        const paramValueString = params.get('field_name');
        // TODO: date
        inParams.field_name = paramValueString;
              }
      if (!isNil(params.get('field_value'))) {
        const paramValueString = params.get('field_value');
        // TODO: date
        inParams.field_value = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_view_attachment_content') {
      const title = 'View attachment';
      const component = Notifications_view_attachment_contentComponent;
      const inParams:{ source_url: string } = { source_url: null };
      if (!isNil(params.get('source_url'))) {
        const paramValueString = params.get('source_url');
        // TODO: date
        inParams.source_url = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_attachment_types_dd_single') {
      this.$logger.log('Notifications', 'auto_email_attachment_types_dd_single');
      const title = 'auto_email_attachment_types_dd';
      const component = Notifications_auto_email_attachment_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_report_options_dd_single') {
      const title = 'auto_email_report_options_dd';
      const component = Notifications_auto_email_report_options_dd_singleComponent;
      const inParams:{ ruleContext: string, excludeReports?: string[] } = { ruleContext: null, excludeReports: [] };
      if (!isNil(params.get('ruleContext'))) {
        const paramValueString = params.get('ruleContext');
        // TODO: date
        inParams.ruleContext = paramValueString;
              }
      if (!isNil(params.get('excludeReports'))) {
        const paramValueString = params.get('excludeReports');
        inParams.excludeReports = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rule_contexts_dd_single') {
      const title = 'auto_email_rule_contexts_dd';
      const component = Notifications_auto_email_rule_contexts_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_carriers_dd_single') {
      const title = 'List of carriers.';
      const component = Notifications_carriers_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_dock_appointment_types_dd_single') {
      const title = 'dock_appointment_types_dd';
      const component = Notifications_dock_appointment_types_dd_singleComponent;
      const inParams:{ option?: string } = { option: null };
      if (!isNil(params.get('option'))) {
        const paramValueString = params.get('option');
        // TODO: date
        inParams.option = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_frequency_interval_dd_single') {
      const title = 'frequency_interval_dd';
      const component = Notifications_frequency_interval_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_monthly_frequency_dd_single') {
      const title = 'monthly_frequency_dd';
      const component = Notifications_monthly_frequency_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_owners_dd_single') {
      const title = 'owners_dd';
      const component = Notifications_owners_dd_singleComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_projects_dd_single') {
      const title = 'projects_dd';
      const component = Notifications_projects_dd_singleComponent;
      const inParams:{ ownerId?: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_frequency_dd_single') {
      const title = 'time_frequency_dd';
      const component = Notifications_time_frequency_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_interval_dd_single') {
      const title = 'time_interval_dd';
      const component = Notifications_time_interval_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_meridiem_dd_single') {
      const title = 'time_meridiem_dd';
      const component = Notifications_time_meridiem_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_selector_dd_single') {
      const title = 'time_selector_dd';
      const component = Notifications_time_selector_dd_singleComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_warehouses_dd_single') {
      const title = 'warehouses_dd';
      const component = Notifications_warehouses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_weekly_frequency_dd_single') {
      const title = 'weekly_frequency_dd';
      const component = Notifications_weekly_frequency_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_attachment_types_dd_multi') {
      this.$logger.log('Notifications', 'auto_email_attachment_types_dd_multi');
      const title = 'auto_email_attachment_types_dd';
      const component = Notifications_auto_email_attachment_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_report_options_dd_multi') {
      const title = 'auto_email_report_options_dd';
      const component = Notifications_auto_email_report_options_dd_multiComponent;
      const inParams:{ ruleContext: string, excludeReports?: string[] } = { ruleContext: null, excludeReports: [] };
      if (!isNil(params.get('ruleContext'))) {
        const paramValueString = params.get('ruleContext');
        // TODO: date
        inParams.ruleContext = paramValueString;
              }
      if (!isNil(params.get('excludeReports'))) {
        const paramValueString = params.get('excludeReports');
        inParams.excludeReports = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_auto_email_rule_contexts_dd_multi') {
      const title = 'auto_email_rule_contexts_dd';
      const component = Notifications_auto_email_rule_contexts_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_carriers_dd_multi') {
      const title = 'List of carriers.';
      const component = Notifications_carriers_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_dock_appointment_types_dd_multi') {
      const title = 'dock_appointment_types_dd';
      const component = Notifications_dock_appointment_types_dd_multiComponent;
      const inParams:{ option?: string } = { option: null };
      if (!isNil(params.get('option'))) {
        const paramValueString = params.get('option');
        // TODO: date
        inParams.option = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_frequency_interval_dd_multi') {
      const title = 'frequency_interval_dd';
      const component = Notifications_frequency_interval_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_monthly_frequency_dd_multi') {
      const title = 'monthly_frequency_dd';
      const component = Notifications_monthly_frequency_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_owners_dd_multi') {
      const title = 'owners_dd';
      const component = Notifications_owners_dd_multiComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_projects_dd_multi') {
      const title = 'projects_dd';
      const component = Notifications_projects_dd_multiComponent;
      const inParams:{ ownerId?: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_frequency_dd_multi') {
      const title = 'time_frequency_dd';
      const component = Notifications_time_frequency_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_interval_dd_multi') {
      const title = 'time_interval_dd';
      const component = Notifications_time_interval_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_meridiem_dd_multi') {
      const title = 'time_meridiem_dd';
      const component = Notifications_time_meridiem_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_time_selector_dd_multi') {
      const title = 'time_selector_dd';
      const component = Notifications_time_selector_dd_multiComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_warehouses_dd_multi') {
      const title = 'warehouses_dd';
      const component = Notifications_warehouses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Notifications_weekly_frequency_dd_multi') {
      const title = 'weekly_frequency_dd';
      const component = Notifications_weekly_frequency_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
