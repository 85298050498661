import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_dock_doors_hub_ComponentContextService } from './FootprintManager.dock_doors_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_dock_doors_listComponent } from './FootprintManager.dock_doors_list.component';
import { FootprintManager_dock_door_visualizationComponent } from './FootprintManager.dock_door_visualization.component';
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_dock_doors_listComponent),
    forwardRef(() => FootprintManager_dock_door_visualizationComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
  ],
  selector: 'FootprintManager-dock_doors_hub',
  templateUrl: './FootprintManager.dock_doors_hub.component.html'
})
export class FootprintManager_dock_doors_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseIds?: number[] } = { warehouseIds: [] };
  //#region Inputs
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, appointmentList_fromDate?: string, appointmentList_toDate?: string, appointmentList_dockDoorIds?: number[], calendar_viewDate?: string, calendar_dockDoorIds?: number[], first_warehouse_id?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  toolbar = {
      create_dock_door: new ToolModel(new ButtonModel('create_dock_door', new ButtonStyles(null, null), false, false, false, 'New dock door', 'icon-ic_fluent_add_20_regular', null)
    , false),
      appointment_types: new ToolModel(new ButtonModel('appointment_types', new ButtonStyles(null, null), false, false, false, 'Manage appointment types', 'icon-ic_fluent_calendar_ltr_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    active: new FieldModel(new CheckBoxModel(this.formGroup.controls['active'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Enabled', false, false),
  };


  filtersets = {
    newGroup1: new FieldsetModel(
      'Filters',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      dock_doors: new TabItemModel(
        this.rootTabGroup, 
        'Dock doors', 
        false,
        ),
      dock_door_visualization: new TabItemModel(
        this.rootTabGroup, 
        '3D Warehouse', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds: number[];
    get $tabs_dock_doors_dock_doors_list_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds;
    }
  
    get $tabs_dock_doors_dock_doors_list_inParams_active(): boolean {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.active.control.value;
      
      return expr;
    }
  
    get $tabs_dock_door_visualization_dock_door_visualization_inParams_warehouse_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.first_warehouse_id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_dock_doors', { read: FootprintManager_dock_doors_listComponent }) $tabs_dock_doors: FootprintManager_dock_doors_listComponent;
      @ViewChild('$tabs_dock_door_visualization', { read: FootprintManager_dock_door_visualizationComponent }) $tabs_dock_door_visualization: FootprintManager_dock_door_visualizationComponent;
    //#endregion tabs children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Dock doors hub`;
  }


  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_dock_doors_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.dock_doors,
      this.tabs.dock_door_visualization,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Dock Doors Hub';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.active.control as CheckBoxModel).reset(true);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['warehouse']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_selected();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_dock_doors') {
      if (!isNil(this.$tabs_dock_doors) && !this.tabs.dock_doors.hidden) {
        this.$tabs_dock_doors.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_dock_door_visualization') {
      if (!isNil(this.$tabs_dock_door_visualization) && !this.tabs.dock_door_visualization.hidden) {
        this.$tabs_dock_door_visualization.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintManager_dock_doors_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_doors_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootprintManager.Disable_Navigation_Transportation_Dock_Doors.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  
  if ($utils.isDefined($hub.inParams.warehouseIds)) {
      $hub.filters.warehouse.control.value = $hub.inParams.warehouseIds;
      $hub.vars.first_warehouse_id = $hub.inParams.warehouseIds[0];
  }
  
  
  }
  on_create_dock_door_clicked(event = null) {
    return this.on_create_dock_door_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_dock_door_clickedInternal(
    $hub: FootprintManager_dock_doors_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_doors_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Initialize the first warehouse selected
  var warehouseId = null;
  
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      warehouseId = $hub.filters.warehouse.control.value[0];
  }
  else {
      $shell.FootprintManager.openErrorDialog('Create Dock Doors', 'Please first select a warehouse.')
      return;
  }
  
  
  const dialogResult = await $shell.FootprintManager.opendock_door_creation_formDialog({
      warehouseId: warehouseId
  
  }, 'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  }
  on_manage_dock_appointment_types(event = null) {
    return this.on_manage_dock_appointment_typesInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_dock_appointment_typesInternal(
    $hub: FootprintManager_dock_doors_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_doors_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootprintManager.opendock_appointment_types_gridDialog('modal')
  
  $hub.refresh();
  }
  on_warehouse_selected(event = null) {
    return this.on_warehouse_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_selectedInternal(
    $hub: FootprintManager_dock_doors_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_doors_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      $hub.vars.first_warehouse_id = $hub.filters.warehouse.control.value[0]
  
  }
  else {
      $hub.vars.first_warehouse_id = null;
  }
  }
  //#endregion private flows
}
