import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_get_manufacturing_order_production_confirmation_dataService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { manufacturing_order_id: number, manufacturing_order_line_id: number, operation_code?: number, task_chain_head?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, AuxiliaryExternalIdentifier?: number, ChainHead?: number, LotId?: number, ManufacturingOrderId?: number, ManufacturingOrderLineId?: number, MaterialId?: number, OperationCodeId?: number, SerialNumberId?: number, WarehouseId?: number, Material?: { Name?: string, ProjectId?: number }, ActualPackagedPack?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.manufacturing_order_id)) {
      missingRequiredInParams.push('\'manufacturing_order_id\'');
    }
    if (isNil(inParams.manufacturing_order_line_id)) {
      missingRequiredInParams.push('\'manufacturing_order_line_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_production_confirmation_data/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { manufacturing_order_id: number, manufacturing_order_line_id: number, operation_code?: number, task_chain_head?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, AuxiliaryExternalIdentifier?: number, ChainHead?: number, LotId?: number, ManufacturingOrderId?: number, ManufacturingOrderLineId?: number, MaterialId?: number, OperationCodeId?: number, SerialNumberId?: number, WarehouseId?: number, Material?: { Name?: string, ProjectId?: number }, ActualPackagedPack?: { Name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.manufacturing_order_id)) {
      missingRequiredInParams.push('\'manufacturing_order_id\'');
    }
    if (isNil(inParams.manufacturing_order_line_id)) {
      missingRequiredInParams.push('\'manufacturing_order_line_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_production_confirmation_data/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { manufacturing_order_id: number, manufacturing_order_line_id: number, operation_code?: number, task_chain_head?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, AuxiliaryExternalIdentifier?: number, ChainHead?: number, LotId?: number, ManufacturingOrderId?: number, ManufacturingOrderLineId?: number, MaterialId?: number, OperationCodeId?: number, SerialNumberId?: number, WarehouseId?: number, Material?: { Name?: string, ProjectId?: number }, ActualPackagedPack?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.manufacturing_order_id)) {
      missingRequiredInParams.push('\'manufacturing_order_id\'');
    }
    if (isNil(inParams.manufacturing_order_line_id)) {
      missingRequiredInParams.push('\'manufacturing_order_line_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_production_confirmation_data/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      manufacturing_order_id: number, manufacturing_order_line_id: number, operation_code?: number, task_chain_head?: number    }) {
        if(isNil(inParams.manufacturing_order_id)) {
          return true; 
        }
        if(isNil(inParams.manufacturing_order_line_id)) {
          return true; 
        }
      return false;
    }
}
