import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_custom_materials_udf_grid_ComponentContextService } from './app.custom_materials_udf_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { app_custom_receive_hold_types_dd_singleComponent } from './app.custom_receive_hold_types_dd_single.component'


interface IFootprintManager_materials_udf_gridComponentEntity {
  Id?: number, Description?: string, LookupCode?: string, Name?: string, ReceiveHoldType?: string, InventoryByWarehouseMaterial?: { AvailablePackagedAmount?: number }[]}

interface IFootprintManager_materials_udf_gridComponentInParams {
  projectId?: number, ownerId?: number, materialIds?: number[]}


class FootprintManager_materials_udf_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_materials_udf_gridComponent;
  entity: IFootprintManager_materials_udf_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    description_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    receive_hold_type_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    lookupcode: null,
    description: null,
    receive_hold_type: null,
  }



  constructor(
    grid: FootprintManager_materials_udf_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: app_ShellService,
private $datasources: app_DatasourceService,
private $flows: app_FlowService,
private $reports: app_ReportService,
private $localization: app_LocalizationService,
private $operations: app_OperationService,
private $logger: CleanupLoggerService,
private $context: app_custom_materials_udf_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.description = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.description,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['description_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.receive_hold_type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.receive_hold_type,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['receive_hold_type_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_materials_udf_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_materials_udf_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.$datasources.app.custom_ds_materials_udf_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_materials_udf_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.description.editControl as TextBoxModel).reset($row.entity.Description);
    (this.cells.receive_hold_type.displayControl as TextModel).text = $row.entity.ReceiveHoldType;
    (this.cells.receive_hold_type.editControl as SelectBoxModel).reset($row.entity.ReceiveHoldType);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_materials_udf_gridComponentRowModel,
  $grid: FootprintManager_materials_udf_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_materials_udf_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  
  if ($row.cells.description?.editControl.isChanged) {
      payload.Description = $row.cells.description?.editControl.value;
  }
  
  if ($row.cells.receive_hold_type.editControl.isChanged) {
      payload.ReceiveHoldType = $row.cells.receive_hold_type.editControl.value;
  }
  
  
  try {
     
      await $flows.Utilities.crud_update_flow({ entitySet: 'Materials', id: $row.entity.Id, entity: payload});
  
      $row.refresh();
  } catch (error) {
      $shell.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => app_custom_receive_hold_types_dd_singleComponent),
  ],
  selector: 'FootprintManager-materials_udf_grid',
  templateUrl: './FootprintManager.materials_udf_grid.component.html'
})
export class FootprintManager_materials_udf_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_materials_udf_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_materials_udf_gridComponentInParams = { projectId: null, ownerId: null, materialIds: [] };


  //#region Variables
  vars: { entities?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Name?: string, SpecialInstructions?: string }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Lookupcode', false, false, null, false),       description: new GridHeaderModel(new HeaderStyles(null, null), 'description', 'Description', false, false, null, false),       receive_hold_type: new GridHeaderModel(new HeaderStyles(null, null), 'receive_hold_type', 'Receive Hold Type', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_materials_udf_gridComponentRowModel[] = [];

  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: app_ShellService,
    private $datasources: app_DatasourceService,
    private $flows: app_FlowService,
    private $reports: app_ReportService,
    private $localization: app_LocalizationService,
    private $operations: app_OperationService,
    private $logger: CleanupLoggerService,
    private $context: app_custom_materials_udf_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Custom Materials UDF';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 40;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      take:  null ,
      skip:  null ,
    };
    try {
      const data = await this.$datasources.app.custom_ds_materials_udf_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_materials_udf_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_materials_udf_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootprintManager_materials_udf_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_materials_udf_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Initialize
  var wb = $event;
  
  var transactions = [];
  
  
  const batchSize = 250;
  
  var allData: any[] = [];
  
  
  
  
  
  // Get all data from datasource
  do {
  
      var response = await $datasources.custom_ds_materials_udf_grid.get({
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          materialIds: $grid.inParams.materialIds,
          fullTextSearch: $grid.fullTextSearch,
          take: batchSize,
          skip: allData.length
      });
  
      allData = allData.concat(response.result);
  
  } while (allData.length < response.totalCount)
  
  // Compile all data into JSON
  for (let record of allData) {
  
      transactions.push({
          "LookupCode": record.LookupCode,
          "Name": record.Name,
          "Description": record.Description,
          "Receive Hold Type": record.ReceiveHoldType
      });
  }
  
  var ws = $utils.excel.json_to_sheet(transactions);
  
  $utils.excel.book_append_sheet(wb, ws, "MaterialUdfs");
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootprintManager_materials_udf_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
