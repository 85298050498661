import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_purchase_order_received_inventory_grid_ComponentContextService } from './FootprintManager.purchase_order_received_inventory_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_purchase_order_received_inventory_gridComponentEntity {
  Id?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualTargetLicensePlateId?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualTargetLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ActualPackagedPack?: { ShortName?: string }, WeightUOM?: { Short_name?: string }, ShipmentLine?: { ExpectedPackagedAmount?: number, OrderLineNumber?: number, ExpectedPackaged?: { ShortName?: string } }}

interface IFootprintManager_purchase_order_received_inventory_gridComponentInParams {
  orderId: number, shipmentId: number}


class FootprintManager_purchase_order_received_inventory_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_purchase_order_received_inventory_gridComponent;
  entity: IFootprintManager_purchase_order_received_inventory_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    linenumber: null,
    material: null,
    total_expected: null,
    actual_packaged_amount: null,
    licenseplate: null,
    location: null,
    Lot: null,
    vendorlot: null,
  }



  constructor(
    grid: FootprintManager_purchase_order_received_inventory_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.linenumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.linenumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.total_expected = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.total_expected,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.actual_packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.actual_packaged_amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.licenseplate = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.licenseplate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Lot = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.Lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_purchase_order_received_inventory_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_purchase_order_received_inventory_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.PurchaseOrders.ds_received_inventory_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_purchase_order_received_inventory_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.ShipmentLine?.OrderLineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material.LookupCode;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.Location?.Name;
    (this.cells.Lot.displayControl as TextModel).text = $row.entity.Lot.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'licenseplate' : {
        this.on_licenseplate_clicked();
        break;
      }
      case 'Lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootprintManager_purchase_order_received_inventory_gridComponentRowModel,
  $grid: FootprintManager_purchase_order_received_inventory_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opensingle_material_hub({materialId: $row.entity.MaterialId});
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootprintManager_purchase_order_received_inventory_gridComponentRowModel,
  $grid: FootprintManager_purchase_order_received_inventory_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_purchase_order_received_inventory_gridComponentRowModel,
  $grid: FootprintManager_purchase_order_received_inventory_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  }
  else {
      $row.cells.vendorlot.displayControl.text = $row.entity.Lot?.VendorLot.LookupCode
  }
  
  
  // Set expected column
  $row.cells.total_expected.displayControl.text = $row.entity.ShipmentLine?.ExpectedPackagedAmount?.toString() + ' ' +  $row.entity.ShipmentLine?.ExpectedPackaged?.ShortName
  
  
  // Set actual column
  $row.cells.actual_packaged_amount.displayControl.text = $row.entity.ActualPackagedAmount?.toString() + ' ' +  $row.entity.ActualPackagedPack?.ShortName
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: FootprintManager_purchase_order_received_inventory_gridComponentRowModel,
  $grid: FootprintManager_purchase_order_received_inventory_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opensingle_licenseplate_hub({licenseplateId: $row.entity.ActualTargetLicensePlateId});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-purchase_order_received_inventory_grid',
  templateUrl: './FootprintManager.purchase_order_received_inventory_grid.component.html'
})
export class FootprintManager_purchase_order_received_inventory_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_purchase_order_received_inventory_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_purchase_order_received_inventory_gridComponentInParams = { orderId: null, shipmentId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', 'Line #', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       total_expected: new GridHeaderModel(new HeaderStyles(null, null), 'total_expected', 'Total expected', false, false, null, false),       actual_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'actual_packaged_amount', 'Actual', false, false, null, false),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate', 'License plate', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Current Location', false, false, null, false),       Lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_purchase_order_received_inventory_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }

  topToolbar = {
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, false, false, 'Unreceive', 'icon-ic_fluent_archive_arrow_back_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Received inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      shipmentId:  $grid.inParams.shipmentId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.PurchaseOrders.ds_received_inventory_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_purchase_order_received_inventory_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_purchase_order_received_inventory_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_purchase_order_received_inventory_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_purchase_order_received_inventory_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result
  if (order.OrderStatusId !== 4) {
      const hasOneSelectedRows = $grid.selectedRows.length === 1;
  
      $grid.topToolbar.unreceive.control.readOnly = !hasOneSelectedRows;
  
  } else {
      $grid.topToolbar.unreceive.control.readOnly = true;
  }
  
  if (await $operations.FootprintManager.Disable_PurchaseOrder_Editing.isAuthorized()) {
      $grid.topToolbar.unreceive.control.readOnly = true;
  }
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $grid: FootprintManager_purchase_order_received_inventory_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  const dialogResult = await $shell.FootprintManager.openunreceiving_single_taskId_formDialog({
      licenseplateId: $grid.selectedRows[0].entity.ActualTargetLicensePlateId,
      lotId: $grid.selectedRows[0].entity.LotId,
      packagingId: $grid.selectedRows[0].entity.ActualPackagedPackId,
      packagedAmount:  $grid.selectedRows[0].entity.ActualPackagedAmount,
      taskId: $grid.selectedRows[0].entity.Id
  });
  const userConfirmed = dialogResult.confirm;
  
  
  
  if (userConfirmed) {
      await $grid.refresh();
  
  }
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootprintManager_purchase_order_received_inventory_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_purchase_order_received_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  // Initialize
  var wb = $event;
  var transactions = [];
  
  
  
  try {
  
      var response = (await $datasources.PurchaseOrders.ds_received_inventory_grid.get({
          orderId: $grid.inParams.orderId,
          shipmentId: $grid.inParams.shipmentId,
          fullTextSearch: $grid.fullTextSearch
      })).result;
     
  
  
      // Compile all data into JSON
      for (let record of response) {
          
  
          transactions.push({
  
              "Line #": record.ShipmentLine?.OrderLineNumber?.toString(),
              "Material": record.Material?.LookupCode,
              "Total Expected": record.ShipmentLine?.ExpectedPackagedAmount?.toString() + ' ' +  record.ShipmentLine?.ExpectedPackaged?.ShortName,
              "Actual": record.ActualPackagedAmount?.toString() + ' ' +  record.ActualPackagedPack?.ShortName,
              "Licenseplate": record.ActualTargetLicensePlate?.LookupCode,
              "Current Location": record.ActualTargetLicensePlate?.Location?.Name,
              "Lot": record.Lot.LookupCode,
              "Vendor Lot": record.Lot?.VendorLot?.LookupCode 
          });
      }
  
      var ws = $utils.excel.json_to_sheet(transactions);
      $utils.excel.book_append_sheet(wb, ws, "ReceivedInventoryExport");
  
  
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootprintManager_purchase_order_received_inventory_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
