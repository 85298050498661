import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { InventoryTransformations_inventory_transformation_date_tyes_dd_singleComponent } from './InventoryTransformations.inventory_transformation_date_tyes_dd_single.component';
import { InventoryTransformations_inventory_transformation_statuses_dd_singleComponent } from './InventoryTransformations.inventory_transformation_statuses_dd_single.component';
import { InventoryTransformations_inventory_transformation_types_dd_singleComponent } from './InventoryTransformations.inventory_transformation_types_dd_single.component';
import { InventoryTransformations_licenseplates_dd_singleComponent } from './InventoryTransformations.licenseplates_dd_single.component';
import { InventoryTransformations_inventory_transformation_date_tyes_dd_multiComponent } from './InventoryTransformations.inventory_transformation_date_tyes_dd_multi.component';
import { InventoryTransformations_inventory_transformation_statuses_dd_multiComponent } from './InventoryTransformations.inventory_transformation_statuses_dd_multi.component';
import { InventoryTransformations_inventory_transformation_types_dd_multiComponent } from './InventoryTransformations.inventory_transformation_types_dd_multi.component';
import { InventoryTransformations_licenseplates_dd_multiComponent } from './InventoryTransformations.licenseplates_dd_multi.component';
import { InventoryTransformations_active_inventory_transformations_widgetComponent } from './InventoryTransformations.active_inventory_transformations_widget.component';
import { InventoryTransformations_inventory_transformations_by_status_widgetComponent } from './InventoryTransformations.inventory_transformations_by_status_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public InventoryTransformations: InventoryTransformations_ShellService = this;


  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'InventoryTransformations_inventory_transformation_date_tyes_dd_single') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_date_tyes_dd_single');
      const title = 'inventory_transformation_date_tyes_dd';
      const component = InventoryTransformations_inventory_transformation_date_tyes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformation_statuses_dd_single') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_statuses_dd_single');
      const title = 'Inventory transformation statuses';
      const component = InventoryTransformations_inventory_transformation_statuses_dd_singleComponent;
      const inParams:{ ids?: number[] } = { ids: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformation_types_dd_single') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_types_dd_single');
      const title = 'inventory_transformation_types_dd';
      const component = InventoryTransformations_inventory_transformation_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_licenseplates_dd_single') {
      this.$logger.log('InventoryTransformations', 'licenseplates_dd_single');
      const title = 'Licenseplates dropdown';
      const component = InventoryTransformations_licenseplates_dd_singleComponent;
      const inParams:{ locationId?: number, typeId: number, archived: boolean, shipmentId?: number } = { locationId: null, typeId: null, archived: null, shipmentId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformation_date_tyes_dd_multi') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_date_tyes_dd_multi');
      const title = 'inventory_transformation_date_tyes_dd';
      const component = InventoryTransformations_inventory_transformation_date_tyes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformation_statuses_dd_multi') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_statuses_dd_multi');
      const title = 'Inventory transformation statuses';
      const component = InventoryTransformations_inventory_transformation_statuses_dd_multiComponent;
      const inParams:{ ids?: number[] } = { ids: [] };
      if (!isNil(params.get('ids'))) {
        const paramValueString = params.get('ids');
        inParams.ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformation_types_dd_multi') {
      this.$logger.log('InventoryTransformations', 'inventory_transformation_types_dd_multi');
      const title = 'inventory_transformation_types_dd';
      const component = InventoryTransformations_inventory_transformation_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_licenseplates_dd_multi') {
      this.$logger.log('InventoryTransformations', 'licenseplates_dd_multi');
      const title = 'Licenseplates dropdown';
      const component = InventoryTransformations_licenseplates_dd_multiComponent;
      const inParams:{ locationId?: number, typeId: number, archived: boolean, shipmentId?: number } = { locationId: null, typeId: null, archived: null, shipmentId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_active_inventory_transformations_widget') {
      this.$logger.log('InventoryTransformations', 'active_inventory_transformations_widget');
      const title = 'Active transformations';
      const component = InventoryTransformations_active_inventory_transformations_widgetComponent;
      const inParams:{ owner_id?: number, project_id?: number, warehouse_ids?: number[] } = { owner_id: null, project_id: null, warehouse_ids: [] };
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransformations_inventory_transformations_by_status_widget') {
      this.$logger.log('InventoryTransformations', 'inventory_transformations_by_status_widget');
      const title = 'Transformation status';
      const component = InventoryTransformations_inventory_transformations_by_status_widgetComponent;
      const inParams:{ owner_id?: number, project_id?: number, warehouse_ids?: number[], status_ids?: number[] } = { owner_id: null, project_id: null, warehouse_ids: [], status_ids: [] };
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('status_ids'))) {
        const paramValueString = params.get('status_ids');
        inParams.status_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
