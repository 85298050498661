import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_confirm_finished_product_ComponentContextService } from './Manufacturing.manufacturing_order_confirm_finished_product.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manufacturing_manufacturing_order_line_production_confirmation_gridComponent } from './Manufacturing.manufacturing_order_line_production_confirmation_grid.component';
import { Manufacturing_manufacturing_output_locations_dd_singleComponent } from './Manufacturing.manufacturing_output_locations_dd_single.component'
import { Manufacturing_manufacturing_orders_workshifts_dd_singleComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_single.component'
import { Manufacturing_manufacturing_orders_equipment_dd_singleComponent } from './Manufacturing.manufacturing_orders_equipment_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_manufacturing_order_line_production_confirmation_gridComponent),
    forwardRef(() => Manufacturing_manufacturing_output_locations_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_orders_workshifts_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_orders_equipment_dd_singleComponent),
  ],
  selector: 'Manufacturing-manufacturing_order_confirm_finished_product',
  templateUrl: './Manufacturing.manufacturing_order_confirm_finished_product.component.html'
})
export class Manufacturing_manufacturing_order_confirm_finished_productComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string } = { scheduled_run_code: null, warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, finished_good_id: null, finished_good: null };
  //#region Inputs
  @Input('scheduled_run_code') set $inParams_scheduled_run_code(v: number) {
    this.inParams.scheduled_run_code = v;
  }
  get $inParams_scheduled_run_code(): number {
    return this.inParams.scheduled_run_code;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('output_location_id') set $inParams_output_location_id(v: number) {
    this.inParams.output_location_id = v;
  }
  get $inParams_output_location_id(): number {
    return this.inParams.output_location_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('planned_quantity') set $inParams_planned_quantity(v: number) {
    this.inParams.planned_quantity = v;
  }
  get $inParams_planned_quantity(): number {
    return this.inParams.planned_quantity;
  }
  @Input('finished_good_id') set $inParams_finished_good_id(v: number) {
    this.inParams.finished_good_id = v;
  }
  get $inParams_finished_good_id(): number {
    return this.inParams.finished_good_id;
  }
  @Input('finished_good') set $inParams_finished_good(v: string) {
    this.inParams.finished_good = v;
  }
  get $inParams_finished_good(): string {
    return this.inParams.finished_good;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    output_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    workshift: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    equipment: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    scheduled_run: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Scheduled run', false, false),
    output_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['output_location'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Output location', false, false),
    workshift: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['workshift'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Work shift', false, false),
    equipment: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['equipment'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Equipment', false, false),
  };


  filtersets = {
    details: new FieldsetModel(
      ' ',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      finushed_product: new TabItemModel(
        this.rootTabGroup, 
        'Finished product', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_finished_good(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.finished_good;
      
      return expr;
    }
  
    get $tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_planned_quantity(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.planned_quantity;
      
      return expr;
    }
  
    get $tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_material_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.finished_good_id;
      
      return expr;
    }
  
    get $tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_output_location_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.inParams.output_location_id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_finushed_product', { read: Manufacturing_manufacturing_order_line_production_confirmation_gridComponent }) $tabs_finushed_product: Manufacturing_manufacturing_order_line_production_confirmation_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_output_location_selector_inParams_warehouse_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.inParams.warehouse_id;
    
    return expr;
  }



  get $fields_output_location_selector_inParams_project_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.inParams.project_id;
    
    return expr;
  }



  get $fields_workshift_selector_inParams_warehouse_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.inParams.warehouse_id;
    
    return expr;
  }



  get $fields_equipment_selector_inParams_warehouse_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.inParams.warehouse_id;
    
    return expr;
  }



  //#endregion filters inParams



  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: Manufacturing_ShellService,
  private $datasources: Manufacturing_DatasourceService,
  private $flows: Manufacturing_FlowService,
  private $reports: Manufacturing_ReportService,
  private $localization: Manufacturing_LocalizationService,
  private $operations: Manufacturing_OperationService,
  private $logger: CleanupLoggerService,
  private $context: Manufacturing_manufacturing_order_confirm_finished_product_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.finushed_product,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'manufacturing_order_confirm_finished_product';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.scheduled_run.control as TextModel).text = $hub.inParams.scheduled_run_code.toString();
    (this.filters.output_location.control as SelectBoxModel).reset($hub.inParams.output_location_id);


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_finushed_product') {
      if (!isNil(this.$tabs_finushed_product) && !this.tabs.finushed_product.hidden) {
        this.$tabs_finushed_product.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $hub: Manufacturing_manufacturing_order_confirm_finished_productComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_confirm_finished_product_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_confirm_finished_product.on_init_flow');
  
  
  }
  //#endregion private flows
}
