<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="schedule_order" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator2" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_click($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator3" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attach" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="printmain" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div *ngIf="!tool.control.buttons.printsub.removed" 
                      mat-menu-item 
                      class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.printsub.readOnly || tool.control.buttons.printsub.disabled"
                            [ngStyle]="tool.control.buttons.printsub.styles.style"
                            [ngClass]="tool.control.buttons.printsub.styles.classes"
                            [matTooltip]="tool.control.buttons.printsub.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.printsub.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.printsub.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.printsub.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-order"
                     *ngIf="!fieldsets.order.hidden && !fieldsets.order.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.order.collapsible }">
                    <div *ngIf="!fieldsets.order.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.order.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.order.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.order.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.order.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.order.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.order.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-order_id" *ngIf="!fields.order_id.hidden && !fields.order_id.removed" 
                            class="field-container standard {{fields.order_id.invalid ? 'invalid' : ''}} {{fields.order_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order_id.styles.style"
                            [ngClass]="fields.order_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order_id.label + (fields.order_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order_id.label}}<span *ngIf="fields.order_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="order_id"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.order_id.control.readOnly || fields.order_id.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_id.control.placeholder}}"
                                [ngStyle]="fields.order_id.control.styles.style"
                                [ngClass]="fields.order_id.control.styles.classes"
                                [matTooltip]="fields.order_id.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.order_id.invalid">
                          <ng-container *ngFor="let error of fields.order_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <label data-cy="label" class="datex-label"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip">{{fields.status.control.label}}</label>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}} {{fields.owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [project_id]="$fields_owner_selector_inParams_project_id"
                        >
                        </Manufacturing-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [status_id]="$fields_project_selector_inParams_status_id"
                          [owner_id]="$fields_project_selector_inParams_owner_id"
                        >
                        </Manufacturing-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Manufacturing-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-empty" *ngIf="!fields.empty.hidden && !fields.empty.removed" 
                            class="field-container standard {{fields.empty.invalid ? 'invalid' : ''}} {{fields.empty.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.empty.styles.style"
                            [ngClass]="fields.empty.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.empty.label + (fields.empty.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.empty.label}}<span *ngIf="fields.empty.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="empty"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.empty.control.readOnly || fields.empty.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.empty.control.placeholder}}"
                                [ngStyle]="fields.empty.control.styles.style"
                                [ngClass]="fields.empty.control.styles.classes"
                                [matTooltip]="fields.empty.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.empty.invalid">
                          <ng-container *ngFor="let error of fields.empty.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-staging_location" *ngIf="!fields.staging_location.hidden && !fields.staging_location.removed" 
                            class="field-container standard {{fields.staging_location.invalid ? 'invalid' : ''}} {{fields.staging_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.staging_location.styles.style"
                            [ngClass]="fields.staging_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.staging_location.label + (fields.staging_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.staging_location.label}}<span *ngIf="fields.staging_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_staging_locations_dd_single 
                            data-cy="selector"
                            [type]="fields.staging_location.control.type"
                            formControlName="staging_location"
                            (displayTextChange)="fields.staging_location.control.displayText=$event"
                            [placeholder]="fields.staging_location.control.placeholder"
                            [styles]="fields.staging_location.control.styles"
                            [tooltip]="fields.staging_location.control.tooltip"
                          [warehouse_id]="$fields_staging_location_selector_inParams_warehouse_id"
                          [project_id]="$fields_staging_location_selector_inParams_project_id"
                        >
                        </Manufacturing-manufacturing_staging_locations_dd_single>
                        <ng-container *ngIf="fields.staging_location.invalid">
                          <ng-container *ngFor="let error of fields.staging_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-output_location" *ngIf="!fields.output_location.hidden && !fields.output_location.removed" 
                            class="field-container standard {{fields.output_location.invalid ? 'invalid' : ''}} {{fields.output_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.output_location.styles.style"
                            [ngClass]="fields.output_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.output_location.label + (fields.output_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.output_location.label}}<span *ngIf="fields.output_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_output_locations_dd_single 
                            data-cy="selector"
                            [type]="fields.output_location.control.type"
                            formControlName="output_location"
                            (displayTextChange)="fields.output_location.control.displayText=$event"
                            [placeholder]="fields.output_location.control.placeholder"
                            [styles]="fields.output_location.control.styles"
                            [tooltip]="fields.output_location.control.tooltip"
                          [warehouse_id]="$fields_output_location_selector_inParams_warehouse_id"
                          [project_id]="$fields_output_location_selector_inParams_project_id"
                        >
                        </Manufacturing-manufacturing_output_locations_dd_single>
                        <ng-container *ngIf="fields.output_location.invalid">
                          <ng-container *ngFor="let error of fields.output_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_start" *ngIf="!fields.estimated_start.hidden && !fields.estimated_start.removed" 
                            class="field-container standard {{fields.estimated_start.invalid ? 'invalid' : ''}} {{fields.estimated_start.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.estimated_start.styles.style"
                            [ngClass]="fields.estimated_start.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.estimated_start.label + (fields.estimated_start.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.estimated_start.label}}<span *ngIf="fields.estimated_start.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="estimated_start"
                            [format]="fields.estimated_start.control.format"
                            [mode]="fields.estimated_start.control.mode"
                            [ngStyle]="fields.estimated_start.control.styles.style"
                            [ngClass]="fields.estimated_start.control.styles.classes"
                            [tooltip]="fields.estimated_start.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.estimated_start.invalid">
                          <ng-container *ngFor="let error of fields.estimated_start.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-estimated_end" *ngIf="!fields.estimated_end.hidden && !fields.estimated_end.removed" 
                            class="field-container standard {{fields.estimated_end.invalid ? 'invalid' : ''}} {{fields.estimated_end.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.estimated_end.styles.style"
                            [ngClass]="fields.estimated_end.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.estimated_end.label + (fields.estimated_end.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.estimated_end.label}}<span *ngIf="fields.estimated_end.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="estimated_end"
                            [format]="fields.estimated_end.control.format"
                            [mode]="fields.estimated_end.control.mode"
                            [ngStyle]="fields.estimated_end.control.styles.style"
                            [ngClass]="fields.estimated_end.control.styles.classes"
                            [tooltip]="fields.estimated_end.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.estimated_end.invalid">
                          <ng-container *ngFor="let error of fields.estimated_end.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_start_date" *ngIf="!fields.actual_start_date.hidden && !fields.actual_start_date.removed" 
                            class="field-container standard {{fields.actual_start_date.invalid ? 'invalid' : ''}} {{fields.actual_start_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_start_date.styles.style"
                            [ngClass]="fields.actual_start_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_start_date.label + (fields.actual_start_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_start_date.label}}<span *ngIf="fields.actual_start_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="actual_start_date"
                            [format]="fields.actual_start_date.control.format"
                            [mode]="fields.actual_start_date.control.mode"
                            [ngStyle]="fields.actual_start_date.control.styles.style"
                            [ngClass]="fields.actual_start_date.control.styles.classes"
                            [tooltip]="fields.actual_start_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.actual_start_date.invalid">
                          <ng-container *ngFor="let error of fields.actual_start_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-actual_end_date" *ngIf="!fields.actual_end_date.hidden && !fields.actual_end_date.removed" 
                            class="field-container standard {{fields.actual_end_date.invalid ? 'invalid' : ''}} {{fields.actual_end_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.actual_end_date.styles.style"
                            [ngClass]="fields.actual_end_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.actual_end_date.label + (fields.actual_end_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.actual_end_date.label}}<span *ngIf="fields.actual_end_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="actual_end_date"
                            [format]="fields.actual_end_date.control.format"
                            [mode]="fields.actual_end_date.control.mode"
                            [ngStyle]="fields.actual_end_date.control.styles.style"
                            [ngClass]="fields.actual_end_date.control.styles.classes"
                            [tooltip]="fields.actual_end_date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.actual_end_date.invalid">
                          <ng-container *ngFor="let error of fields.actual_end_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.order.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-created_on" *ngIf="!fields.created_on.hidden && !fields.created_on.removed" 
                            class="field-container standard {{fields.created_on.invalid ? 'invalid' : ''}} {{fields.created_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_on.styles.style"
                            [ngClass]="fields.created_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_on.label + (fields.created_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_on.label}}<span *ngIf="fields.created_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="created_on"
                            [format]="fields.created_on.control.format"
                            [mode]="fields.created_on.control.mode"
                            [ngStyle]="fields.created_on.control.styles.style"
                            [ngClass]="fields.created_on.control.styles.classes"
                            [tooltip]="fields.created_on.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.created_on.invalid">
                          <ng-container *ngFor="let error of fields.created_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_by" *ngIf="!fields.created_by.hidden && !fields.created_by.removed" 
                            class="field-container standard {{fields.created_by.invalid ? 'invalid' : ''}} {{fields.created_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_by.styles.style"
                            [ngClass]="fields.created_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_by.label + (fields.created_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_by.label}}<span *ngIf="fields.created_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.created_by.control.styles.style"
                              [ngClass]="fields.created_by.control.styles.classes"
                              [matTooltip]="fields.created_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.created_by.control.text }}</div>
                        <ng-container *ngIf="fields.created_by.invalid">
                          <ng-container *ngFor="let error of fields.created_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_on" *ngIf="!fields.modified_on.hidden && !fields.modified_on.removed" 
                            class="field-container standard {{fields.modified_on.invalid ? 'invalid' : ''}} {{fields.modified_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.modified_on.styles.style"
                            [ngClass]="fields.modified_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_on.label + (fields.modified_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_on.label}}<span *ngIf="fields.modified_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="modified_on"
                            [format]="fields.modified_on.control.format"
                            [mode]="fields.modified_on.control.mode"
                            [ngStyle]="fields.modified_on.control.styles.style"
                            [ngClass]="fields.modified_on.control.styles.classes"
                            [tooltip]="fields.modified_on.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.modified_on.invalid">
                          <ng-container *ngFor="let error of fields.modified_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_by" *ngIf="!fields.modified_by.hidden && !fields.modified_by.removed" 
                            class="field-container standard {{fields.modified_by.invalid ? 'invalid' : ''}} {{fields.modified_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.modified_by.styles.style"
                            [ngClass]="fields.modified_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_by.label + (fields.modified_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_by.label}}<span *ngIf="fields.modified_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.modified_by.control.styles.style"
                              [ngClass]="fields.modified_by.control.styles.classes"
                              [matTooltip]="fields.modified_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.modified_by.control.text }}</div>
                        <ng-container *ngIf="fields.modified_by.invalid">
                          <ng-container *ngFor="let error of fields.modified_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pick_wave" *ngIf="!fields.pick_wave.hidden && !fields.pick_wave.removed" 
                            class="field-container standard {{fields.pick_wave.invalid ? 'invalid' : ''}} {{fields.pick_wave.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.pick_wave.styles.style"
                            [ngClass]="fields.pick_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.pick_wave.label + (fields.pick_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.pick_wave.label}}<span *ngIf="fields.pick_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.pick_wave.control.styles.style"
                              [ngClass]="fields.pick_wave.control.styles.classes"
                              [matTooltip]="fields.pick_wave.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.pick_wave.control.text }}</div>
                        <ng-container *ngIf="fields.pick_wave.invalid">
                          <ng-container *ngFor="let error of fields.pick_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-notes"
                     *ngIf="!fieldsets.notes.hidden && !fieldsets.notes.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.notes.collapsible }">
                    <div *ngIf="!fieldsets.notes.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.notes.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.notes.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.notes.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.notes.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.notes.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.notes.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container standard {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.notes.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.manufacturing_lines_grid.hasTabToShow" class="tab" data-cy="tab-manufacturing_lines_grid">
              <h2 [className]="tabs.manufacturing_lines_grid.active? 'active': ''" (click)="tabs.manufacturing_lines_grid.activate()">{{tabs.manufacturing_lines_grid.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-manufacturing_order_lines_grid *ngIf="tabs.manufacturing_lines_grid.active"
              #$tabs_manufacturing_lines_grid
              [order_id]="$tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_order_id"
              [project_id]="$tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_project_id"
              [order_status_id]="$tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_order_status_id"
              [warehouse_id]="$tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_warehouse_id"
              ($refreshEvent)="refresh(false, false, '$tabs_manufacturing_lines_grid')">
              </Manufacturing-manufacturing_order_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>