import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_target_line_editor_ComponentContextService } from './FootprintManager.inventory_transformation_target_line_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_transformation_target_lines_gridComponent } from './FootprintManager.inventory_transformation_target_lines_grid.component';
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { FootprintManager_lots_dd_singleComponent } from './FootprintManager.lots_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component'
import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component'

type EntityType = { 
    Id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_transformation_target_lines_gridComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => FootprintManager_lots_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_measurement_units_dd_singleComponent),
    forwardRef(() => Inventory_measurement_units_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_target_line_editor',
  templateUrl: './FootprintManager.inventory_transformation_target_line_editor.component.html'
})
export class FootprintManager_inventory_transformation_target_line_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { inventory_transformation_id: number, source_line_id?: number, projectId?: number, warehouse_id?: number, additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } } = { inventory_transformation_id: null, source_line_id: null, projectId: null, warehouse_id: null, additional_options: { general: { keep_source_creation_date: null, convert_packaging_to_base: null }, serial_number_options: { copy_weights: null, copy_volume: null, copy_dimensions: null }, lot_number_options: { copy_udfs: null, copy_lot_number: null, copy_vendor_lot_number: null } } };
  //#region Inputs
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(v: number) {
    this.inParams.inventory_transformation_id = v;
  }
  get $inParams_inventory_transformation_id(): number {
    return this.inParams.inventory_transformation_id;
  }
  @Input('source_line_id') set $inParams_source_line_id(v: number) {
    this.inParams.source_line_id = v;
  }
  get $inParams_source_line_id(): number {
    return this.inParams.source_line_id;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('additional_options') set $inParams_additional_options(v: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } }) {
    this.inParams.additional_options = v;
  }
  get $inParams_additional_options(): { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } {
    return this.inParams.additional_options;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { target_line_id?: number } = { target_line_id: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { project_id?: number, target_line_id?: number, source_serial_number?: number, is_serialized?: boolean, is_fixed_weight?: boolean, is_fixed_length?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    target_material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    target_material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material code', true, false),
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', true, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot', false, false),
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false, false),
    weight: new FieldModel(new TextBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Weight UOM', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Length', false, false),
    length_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['length_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Length UOM', false, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Width', false, false),
    width_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['width_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Width UOM', false, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Height', false, false),
    height_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['height_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Height UOM', false, false),
    quantity_to_transform: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Quantity to transform', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      target_lines: new TabItemModel(
        this.rootTabGroup, 
        'Target lines', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_target_lines_inventory_transformation_target_lines_grid_inParams_source_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.source_line_id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform: { target_material_id?: number, packaging_id?: number, lot_id?: number, vendor_lot_id?: number, weight?: number, weight_uom?: number, new_quantity?: number, length?: number, width?: number, height?: number, dimensions_uom?: number };
    get $tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform(): { target_material_id?: number, packaging_id?: number, lot_id?: number, vendor_lot_id?: number, weight?: number, weight_uom?: number, new_quantity?: number, length?: number, width?: number, height?: number, dimensions_uom?: number } {
      const $editor = this;
      const $utils = this.$utils;
      const expr = {target_material_id: Number($editor.fields.target_material.control.value), packaging_id: Number($editor.fields.packaging.control.value), lot_id:Number( $editor.fields.lot.control.value), vendor_lot_id: Number($editor.fields.vendor_lot.control.value), weight: Number($editor.fields.weight.control.value), weight_uom: Number($editor.fields.weight_uom.control.value), new_quantity: Number($editor.fields.quantity_to_transform.control.text), length: Number($editor.fields.length.control.value), width: Number($editor.fields.width.control.value), height: Number($editor.fields.height.control.value), dimensions_uom: Number($editor.fields.length_uom.control.value)};
      
      if(!isEqual(this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform, expr)) {
        this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform = expr;
      }
      return this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform;
    }
  
    get $tabs_target_lines_inventory_transformation_target_lines_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } };
    get $tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options(): { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.additional_options;
      
      if(!isEqual(this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options, expr)) {
        this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options = expr;
      }
      return this.cacheValueFor_$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_target_lines', { read: FootprintManager_inventory_transformation_target_lines_gridComponent }) $tabs_target_lines: FootprintManager_inventory_transformation_target_lines_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_target_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.inParams.projectId;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.lot.control.value;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_length_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_width_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    target_material: this.fields.target_material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    weight: this.fields.weight.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    length_uom: this.fields.length_uom.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    width_uom: this.fields.width_uom.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    height_uom: this.fields.height_uom.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.target_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'inventory_transformation_target_line_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      project_id:  $editor.inParams.projectId 
    };

    const data = await this.$datasources.InventoryTransformations.ds_get_inventory_transformation_source_line_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_target_lines') {
        if (!isNil(this.$tabs_target_lines) && !this.tabs.target_lines.hidden && !this.tabs.target_lines.removed) {
          this.$tabs_target_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .target_material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_grid_output_change(event = null) {
    return this.on_grid_output_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_output_changeInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_grid_output_change');
  
  
  
  
  
  $editor.outParams.target_line_id = $editor.vars.target_line_id
  
  // Emit all outparams that have been set
  document?.getElementsByTagName('FootprintManager-inventory_transformation_quick_wizard')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': $editor.outParams.target_line_id })); ///change to FootprintManager-inventory_transformation_advanced_wizard
  
  // Emit all outparams that have been set
  document?.getElementsByTagName('FootprintManager-inventory_transformation_advanced_wizard')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': $editor.outParams.target_line_id })); ///change to FootprintManager-inventory_transformation_advanced_wizard
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_data_loaded');
  //default to base packaging
  if ($editor.inParams.additional_options.general.convert_packaging_to_base === true) {
      let base_packaging = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: $editor.fields.target_material.control.value }))
      $editor.fields.packaging.control.value = base_packaging?.result?.PackagingId
  }
  
  let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
      inventory_transformation_id: $editor.inParams.inventory_transformation_id,
      source_line_number: $editor.inParams.source_line_id
  }))?.result
  
  if($utils.isDefined(target_line_check))
  {
      $editor.vars.target_line_id = target_line_check[0]?.Id
  }
  else
  {
      $editor.vars.target_line_id = null
  }
  
  
  
  await $editor.on_grid_output_change()
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_init');
  //check if source line already exists for the transformation
  let source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
      inventory_transformation_id: $editor.inParams.inventory_transformation_id,
      ids: [$editor.inParams.source_line_id],
  }))?.result
  
  
  if ($utils.isDefined(source_line)) {
      $editor.fields.target_material.control.value = source_line[0]?.SourceMaterialId
      $editor.fields.quantity_to_transform.control.text = source_line[0]?.SourcePackageAmount.toString()
  }
  
  
  
  $editor.fields.length.hidden = true
  $editor.fields.length_uom.hidden = true
  $editor.fields.width.hidden = true
  $editor.fields.width_uom.hidden = true
  $editor.fields.height.hidden = true
  $editor.fields.height_uom.hidden = true
  
  
  await add_events()
  
  
  /*************************************
   * CUSTOM EVENTS
  **************************************/
  async function add_events() {
      await new Promise(result => setTimeout(result, 100));
  
      // Get element to attach to 
      const element = document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]; ///change to FootprintManager-inventory_transformation_advanced_wizard
  
      if (element == null) {
          // throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      // 'on' events to detect different stages of the wizard
      element?.addEventListener('on_inventory_transformation_target_lines_grid_initialized', ((event?: CustomEvent | null) => { }) as EventListener);
  
      // 'handle' events to perform common actions that may be invoked
      element?.addEventListener('handle_target_line_value', ((event?: CustomEvent<number> | null) => {
          $editor.vars.target_line_id = event?.detail
          $editor.outParams.target_line_id = event?.detail
      }) as EventListener);
  
  }
  
  
  
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_material_changed');
  
  
  
  
  //clear lot and vendor lot
  $editor.fields.lot.control.value = null
  $editor.fields.vendor_lot.control.value = null
  $editor.fields.packaging.control.value = null
  $editor.fields.packaging.control.readOnly = false
  $editor.fields.weight.control.value = null
  $editor.fields.weight_uom.control.value = null
  $editor.vars.is_serialized = false
  $editor.vars.source_serial_number = null
  
  
  
  try {
  
      if ($utils.isDefined($editor.fields.target_material.control.value)) {
  
          //source line check
          let source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.get(
              {
                  inventory_transformation_id: $editor.inParams.inventory_transformation_id,
                  line_number: $editor.inParams.source_line_id
              }))?.result[0]
  
          //check target material
          let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $editor.fields.target_material.control.value
          }))?.result[0]
  
          let material_controller = material_check?.ControllerTypeId
  
          //hide length, width height, weight for non-serialized materials
          if (material_controller === 3 || material_controller === 4 || !material_check?.IsFixedLength || !material_check?.IsFixedVolume || !material_check?.IsFixedWeight) {
              $editor.fields.weight.hidden = false
              $editor.fields.weight_uom.hidden = false
              $editor.vars.is_serialized = true
              $editor.vars.source_serial_number = source_line?.SourceSerialNumberId
          }
          else {
              $editor.fields.weight.hidden = true
              $editor.fields.weight_uom.hidden = true
              $editor.vars.is_serialized = false
              $editor.vars.source_serial_number = null
          }
  
  
          $editor.vars.is_fixed_length = material_check.IsFixedWeight ?? false
          $editor.vars.is_fixed_weight = material_check?.IsFixedWeight ?? false
  
          //copy lot and vendor lot
          if ($editor.inParams.additional_options.lot_number_options.copy_lot_number === true) {
              let copy_lot_and_vendor_lot = (await $shell.InventoryTransformations.openConfirmationDialog('Copy lot and vendor lot from the source material to this target material?', '', 'Confirm', 'Discard'))
  
              if (!copy_lot_and_vendor_lot) { return }
  
              let lot_check = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
                  materialId: $editor.fields.target_material.control.value,
                  lotLookupcode: source_line?.Lot?.LookupCode,
                  vendorLookupcode: source_line?.Lot?.VendorLot?.LookupCode
              }))
  
              if ($utils.isDefined(lot_check?.result[0]?.Id)) {
                  throw Error(`Lot [${source_line?.Lot?.LookupCode}] already exists for selected material.`)
              }
  
              let new_lot_and_vendor_lot = (await $flows.Lots.create_lot_and_vendor_lot(
                  {
                      materialId: $editor.fields.target_material.control.value,
                      lotLookupCode: source_line?.Lot?.LookupCode,
                      vendorLotLookupCode: source_line?.Lot?.VendorLot?.LookupCode,
                      lotStatusId: source_line?.Lot?.StatusId,
                  }))
  
              if ($utils.isDefined(new_lot_and_vendor_lot)) {
                  const title = 'Copy lot and vendor lot';
                  const message = `Vendor lot ${source_line?.Lot?.VendorLot?.LookupCode} and lot ${source_line?.Lot?.LookupCode} copied to Material Id: ${$editor.fields.target_material.control.value}.`;
                  $shell.InventoryTransformations.openToaster(
                      title,
                      message,
                      EToasterType.Success,
                      {
                          positionClass: EToasterPosition.topRight,
                          tapToDismiss: true,
                          timeOut: 10000,
                          newestOnTop: false
                      });
                  $editor.fields.vendor_lot.control.value = new_lot_and_vendor_lot?.VendorLotId
                  $editor.fields.lot.control.value = new_lot_and_vendor_lot?.LotId
              }
  
              //if copy_udfs is true
              if ($editor.inParams.additional_options.lot_number_options.copy_udfs === true) {
                  let check_udf = (await $datasources.InventoryTransformations.ds_entity_user_defined_field_values_grid.getList({ EntityType: 'Material', EntityKeys: [{ name: 'Id', value: source_line?.Lot?.Id }] }))
                  const udfDatas = check_udf.result?.map(item => ({
                      UdfName: item.UdfName,
                      UdfValue: item.UdfValue
                  })) || [];
  
                  let lot_payload: any = {}
                  for (let udfdata of udfDatas) {
                      lot_payload[udfdata?.UdfName] = udfdata?.UdfValue
                  }
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'Lots',
                      id: new_lot_and_vendor_lot?.LotId,
                      entity: lot_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
                  const title = 'Copy lot UDFs';
                  const message = `Lot ${source_line?.Lot?.LookupCode} UDFs copied.`;
                  $shell.InventoryTransformations.openToaster(
                      title,
                      message,
                      EToasterType.Success,
                      {
                          positionClass: EToasterPosition.topRight,
                          tapToDismiss: true,
                          timeOut: 10000,
                          newestOnTop: false
                      });
              }
          }
  
          //convert to base packaging
          if ($editor.inParams.additional_options.general.convert_packaging_to_base === true) {
              let base_packaging = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: $editor.fields.target_material.control.value }))
              $editor.fields.packaging.control.value = base_packaging?.result?.PackagingId
          }
      }
  
  
  
  
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
  
  }
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_packaging_changed');
  
  
  //check if target material is fixed wieght or not
  
  if ($utils.isAllDefined($editor.fields.target_material, $editor.fields.packaging) && $editor.vars.is_serialized === true) {
  
      let is_fixed = (await $datasources.InventoryTransformations.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: $editor.fields.target_material.control.value,
          packagingId: $editor.fields.packaging.control.value,
      }))?.result
  
      
      let source_serial_check = (await $datasources.InventoryTransformations.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: $editor?.vars?.source_serial_number }))?.result
  
      if ($editor.vars.is_fixed_weight) {
          $editor.fields.weight.control.readOnly = true
          $editor.fields.weight_uom.control.readOnly = true
          $editor.fields.weight.control.value = is_fixed[0]?.ShippingWeight
          $editor.fields.weight_uom.control.value = is_fixed[0]?.WeightUomId
      }
      else {
          if($editor?.inParams?.additional_options?.serial_number_options?.copy_weights)
          {    
              $editor.fields.weight.control.readOnly = false
              $editor.fields.weight_uom.control.readOnly = false
              $editor.fields.weight.control.value = source_serial_check?.GrossWeight
              $editor.fields.weight_uom.control.value = source_serial_check?.WeightUomId
          }
  
      }
  
      if ($editor.vars.is_fixed_length) {
          $editor.fields.length.control.readOnly = true
          $editor.fields.length_uom.control.readOnly = true
          $editor.fields.length.control.value = is_fixed[0]?.Length
          $editor.fields.length_uom.control.value = is_fixed[0]?.DimensionUomId
  
          $editor.fields.width.control.readOnly = true
          $editor.fields.width_uom.control.readOnly = true
          $editor.fields.width.control.value = is_fixed[0]?.Width
          $editor.fields.width_uom.control.value = is_fixed[0]?.DimensionUomId
  
          $editor.fields.height.control.readOnly = true
          $editor.fields.height_uom.control.readOnly = true
          $editor.fields.height.control.value = is_fixed[0]?.Height
          $editor.fields.height_uom.control.value = is_fixed[0]?.DimensionUomId
  
      }
  
  
  }
  
  
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $editor: FootprintManager_inventory_transformation_target_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_line_editor.on_lot_change');
  $editor.fields.vendor_lot.control.value = null
  
  if ($utils.isDefined($editor.fields.lot.control.value))
  {
      let vendor_lot = (await $datasources.Lots.ds_find_vendorlots.get({lotId: $editor.fields.lot.control.value})).result
      $editor.fields.vendor_lot.control.value = vendor_lot[0]?.Id
  }
  }
  //#endregion private flows
  
}
