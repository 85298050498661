import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService } from './FootprintManager.pack_verification_shipping_container_contents_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_scanned_values_gridComponent } from './FootprintManager.scanned_values_grid.component';

interface IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity {
  ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, VendorLotId?: number, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, LookupCode?: string, PackagingLookups?: { PackagingId?: number, UpcCode?: string }[] } }, Packaged?: { Name?: string }}

interface IFootprintManager_pack_verification_shipping_container_contents_gridComponentInParams {
  shippingContainerId: number, canAddManually?: boolean, startVerificationTaskId: number, isReadOnly?: boolean, scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[], isBlind?: boolean, expectedSerialNumbers?: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[], locationId: number}


class FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent;
  entity: IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity;

  vars: { expectedSerialNumbers?: string[], isSelected?: boolean, completedAmount?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    verified_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    image_preview: null,
    material: null,
    lot: null,
    packaging: null,
    verified_quantity: null,
    remove_verified: null,
    add_verified: null,
  }


  //#region rowExpand inParams

  cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues: { Value?: string, Note?: string }[];

  get $rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues(): { Value?: string, Note?: string }[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.scannedValues.filter(v => v.lotId === $row.entity.LotId && v.packagingId === $row.entity.PackagedId).map(v => ({Value: v.scannedValue, Note: null}));
    
    if(!isEqual(this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues, expr)) {
      this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues = expr;
    }
    return this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues;
  }

  cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues: string[];

  get $rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues(): string[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.isBlind ? null : $row.vars.expectedSerialNumbers;
    
    if(!isEqual(this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues, expr)) {
      this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues = expr;
    }
    return this.cacheValueFor_$rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.image_preview = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.image_preview,
      new ImageModel(new Styles(null, { 'width': '100px', 'max-height': '100px' }), null, null)
,
null
      );
    
    this.cells.material = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.verified_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.verified_quantity,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['verified_quantity_edit'] as DatexFormControl, null, true, false, '', null)
      );
    
    this.cells.remove_verified = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.remove_verified,
      new ButtonModel('', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_subtract_circle_20_regular', null)
,
null
      );
    
    this.cells.add_verified = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.add_verified,
      new ButtonModel('', new ButtonStyles(null, null), true, false, false, ' ', 'icon-ic_fluent_add_circle_20_regular', null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.ShipmentLineId,this.entity.LicensePlateId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.ShipmentLineId,this.entity.LicensePlateId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'ShipmentLineId',this.entity.ShipmentLineId);
    set($resultKey, 'LicensePlateId',this.entity.LicensePlateId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      shippingContainerId:  $grid.inParams.shippingContainerId ,
    };
    const data = await this.$datasources.PackVerification.ds_pack_verification_shipping_container_contents_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaged?.Name;
    

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel,
  $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_row_data_loaded');
  $row.vars.isSelected = false;
  
  // Get verified amount
  let completedTasks = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ licenseplateId: $row.entity.LicensePlateId, lotId: $row.entity.LotId, packagingId: $row.entity.PackagedId, chainHead: $grid.inParams.startVerificationTaskId })).result;
  completedTasks = completedTasks.filter(t => t.StatusId === 2);
  
  $row.vars.completedAmount = completedTasks.reduce((total, item) => total += item.ActualPackagedAmount, 0);
  
  $row.cells.verified_quantity.displayControl.text = $grid.inParams.isBlind ?
      `${$row.vars.completedAmount}` :
      `${$row.vars.completedAmount} / ${$row.entity.PackagedAmount}`;
  $row.cells.verified_quantity.editControl.value = $row.vars.completedAmount;
  
  $row.cells.remove_verified.displayControl.readOnly = $row.vars.completedAmount <= 0;
  
  // Hide increment button if serial controlled
  if (!$grid.inParams.canAddManually || $grid.inParams.isReadOnly) {
      $row.cells.add_verified.displayControl.readOnly = true;
      $row.cells.remove_verified.displayControl.readOnly = true;
      $row.cells.verified_quantity.editControl.readOnly = true;
  } else {
      if ($row.entity.Lot.Material.ControllerTypeId === 3 || $row.entity.Lot.Material.ControllerTypeId === 4) {
          $row.cells.add_verified.displayControl.readOnly = true;
      } else {
          $row.cells.add_verified.displayControl.readOnly = false;
      }
  
      $row.cells.verified_quantity.editControl.readOnly = false;
  }
  
  // Format date
  // $row.cells.expiration_date.displayControl.text = ($utils.date.format($row.cells.expiration_date.displayControl.text, $settings.FootprintManager.DateFormat));
  
  // Get image
  let images =
      (await $datasources.Attachments.ds_get_attachments_by_entity.getList({
          entityType: 'MaterialsPackagingsLookup',
          entityKeys: [{ name: 'MaterialId', value: $row.entity.Lot.Material.Id }, { name: 'PackagingId', value: $row.entity.PackagedId }]
      })).result;
  
  for (let i = 0; i < images.length && !$utils.isDefined($row.cells.image_preview.displayControl.src); i++) {
      if ($utils.blob.isBrowserSupportedImgFormat(images[i].FileExtension)) {
          let content = (await $flows.Attachments.get_file_content_flow({ entityType: 'MaterialsPackagingsLookup', attachmentId: images[i].Id })).fileContent;
          $row.cells.image_preview.displayControl.src = await $utils.blob.toBase64(content, true);
      }
  }
  
  $grid.headers.image_preview.width = images.length > 0 ? 125 : 0;
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel,
  $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_save_existing_row');
  try {
      if (isNaN($row.cells.verified_quantity.editControl.value)) {
          throw new Error(`Please enter a numeric value for 'Verified quantity'`);
      }
      if (Number($row.cells.verified_quantity.editControl.value) < 0) {
          throw new Error(`Please enter a non-negative value for 'Verified quantity'`);
      }
  
      // Calculate quantity
      const DELTA = $row.cells.verified_quantity.editControl.value - $row.vars.completedAmount;
  
      if (DELTA === 0) { return; }
  
      if (DELTA > 0) {
          // Add verified content
          await $row.on_add_verified_clicked({ quantity: DELTA });
      } else if (DELTA < 0) {
          // Remove verified content
          await $row.on_remove_verified_clicked({ quantity: -DELTA });
      }
  
      //await $grid.refresh();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.PackVerification.openErrorDialog('Error verifying manually', targetError.message);
  }
  }
  on_add_verified_clicked(event = null) {
    return this.on_add_verified_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_verified_clickedInternal(
    $row: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel,
  $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_add_verified_clicked');
  try {
      verifyContent($event?.quantity);
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.PackVerification.openErrorDialog('Error adding verified quantity', targetError.message);
  }
  
  async function verifyContent(quantity: number) {
      let result = (await $flows.PackVerification.verify_content_flow({
          shippingContainerId: $grid.inParams.shippingContainerId,
          inputManual: {
              lotId: $row.entity.LotId,
              packagingId: $row.entity.PackagedId,
              packagedAmount: quantity ?? 1,
              licenseplateId: $row.entity.LicensePlateId
          },
          startPackVerificationTaskId: $grid.inParams.startVerificationTaskId
      }));
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue)
      }
  
      await $row.refresh();
  }
  }
  on_remove_verified_clicked(event = null) {
    return this.on_remove_verified_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_remove_verified_clickedInternal(
    $row: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel,
  $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_remove_verified_clicked');
  $row.cells.remove_verified.displayControl.readOnly = true;
  const QUANTITY = $event?.quantity ?? 1;
  
  try {
      let serialNumberIds: number[];
      if ($utils.isDefined($grid.inParams.expectedSerialNumbers)) {
          // Get serials from completed tasks
          const TASKS = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ lotId: $row.entity.LotId, packagingId: $row.entity.PackagedId, licenseplateId: $row.entity.LicensePlateId, chainHead: $grid.inParams.startVerificationTaskId })).result;
  
          const SERIAL_IDS = new Set(TASKS.filter(t => t.StatusId === 2 && $utils.isDefined(t.SerialNumberId)).map(t => t.SerialNumberId));
  
          // Let user choose which serials to deselect
          const result = await $shell.FootprintManager.openserialnumbers_selection_gridDialog({
              lotId: $row.entity.LotId,
              licenseplateId: $row.entity.LicensePlateId,
              packagingId: $row.entity.PackagedId,
              packagedAmountRequest: QUANTITY,
              serialNumberIds: null,
              filterSerialNumberIds: Array.from(SERIAL_IDS)
          });
  
          if (!result.confirm) { return; }
  
          serialNumberIds = result.SerialNumberIds;
      }
  
      const CANCEL_RESULT = await $flows.PackVerification.cancel_verification_tasks_flow({
          start_verification_task_id: $grid.inParams.startVerificationTaskId,
          license_plate_id: $row.entity.LicensePlateId,
          lot_id: $row.entity.LotId,
          packaging_id: $row.entity.PackagedId,
          quantity: QUANTITY,
          serial_number_ids: serialNumberIds
      });
  
      if ($utils.isDefined(CANCEL_RESULT.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: CANCEL_RESULT.reasons })).formattedValue);
      }
  
      $grid.events.verification_tasks_cancelled.emit(serialNumberIds?.map(sn => ({ serial_number_id: sn })));
  
      await $row.refresh();
  } catch (error) {
      $shell.FootprintManager.openToaster(
          `Cancel verification task${QUANTITY > 1 ? 's' : ''}`,
          error.message,
          EToasterType.Error,
          {
              closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight
          }
      );
  }
  
  $row.cells.remove_verified.displayControl.readOnly = false;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_scanned_values_gridComponent)
  ],
  selector: 'FootprintManager-pack_verification_shipping_container_contents_grid',
  templateUrl: './FootprintManager.pack_verification_shipping_container_contents_grid.component.html'
})
export class FootprintManager_pack_verification_shipping_container_contents_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['set-width-table']);


  inParams: IFootprintManager_pack_verification_shipping_container_contents_gridComponentInParams = { shippingContainerId: null, canAddManually: null, startVerificationTaskId: null, isReadOnly: null, scannedValues: [], isBlind: null, expectedSerialNumbers: [], locationId: null };


  //#region Variables
  vars: { suppressFlows?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  refocus = new EventEmitter<void>();
  @Output()
  verification_tasks_cancelled = new EventEmitter<{ serial_number_id?: number }[]>();
  
  events = {
    refocus: this.refocus,
    verification_tasks_cancelled: this.verification_tasks_cancelled,
  }
  //#endregion

  headers = {
     image_preview: new GridHeaderModel(new HeaderStyles(null, null), 'image_preview', ' ', false, false, 100, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, 200, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, 250, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, 100, false),       verified_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'verified_quantity', 'Quantity', false, false, 100, false),       remove_verified: new GridHeaderModel(new HeaderStyles(null, null), 'remove_verified', ' ', false, false, 50, false),       add_verified: new GridHeaderModel(new HeaderStyles(null, null), 'add_verified', ' ', false, false, 50, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel[] = [];

  @Input('shippingContainerId') set $inParams_shippingContainerId(value: any) {
    this.inParams['shippingContainerId'] = value;
  }
  get $inParams_shippingContainerId(): any {
    return this.inParams['shippingContainerId'] ;
  }
  @Input('canAddManually') set $inParams_canAddManually(value: any) {
    this.inParams['canAddManually'] = value;
  }
  get $inParams_canAddManually(): any {
    return this.inParams['canAddManually'] ;
  }
  @Input('startVerificationTaskId') set $inParams_startVerificationTaskId(value: any) {
    this.inParams['startVerificationTaskId'] = value;
  }
  get $inParams_startVerificationTaskId(): any {
    return this.inParams['startVerificationTaskId'] ;
  }
  @Input('isReadOnly') set $inParams_isReadOnly(value: any) {
    this.inParams['isReadOnly'] = value;
  }
  get $inParams_isReadOnly(): any {
    return this.inParams['isReadOnly'] ;
  }
  @Input('scannedValues') set $inParams_scannedValues(value: any) {
    this.inParams['scannedValues'] = value;
  }
  get $inParams_scannedValues(): any {
    return this.inParams['scannedValues'] ;
  }
  @Input('isBlind') set $inParams_isBlind(value: any) {
    this.inParams['isBlind'] = value;
  }
  get $inParams_isBlind(): any {
    return this.inParams['isBlind'] ;
  }
  @Input('expectedSerialNumbers') set $inParams_expectedSerialNumbers(value: any) {
    this.inParams['expectedSerialNumbers'] = value;
  }
  get $inParams_expectedSerialNumbers(): any {
    return this.inParams['expectedSerialNumbers'] ;
  }
  @Input('locationId') set $inParams_locationId(value: any) {
    this.inParams['locationId'] = value;
  }
  get $inParams_locationId(): any {
    return this.inParams['locationId'] ;
  }

  topToolbar = {
      unpick: new ToolModel(new ButtonModel('unpick', new ButtonStyles(null, null), false, false, false, 'Unpick', 'icon-ic_fluent_arrow_step_back_20_regular', null)
    , false),
      transfer: new ToolModel(new ButtonModel('transfer', new ButtonStyles(null, null), false, false, false, 'Transfer', 'icon-ic_fluent_person_swap_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Container contents';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
      if(isNil(this.inParams.startVerificationTaskId)) {
        this.$missingRequiredInParams.push('startVerificationTaskId');
      }
      if(isNil(this.inParams.scannedValues)) {
        this.$missingRequiredInParams.push('scannedValues');
      }
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 100;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shippingContainerId:  $grid.inParams.shippingContainerId ,
    };
    try {
      const data = await this.$datasources.PackVerification.ds_pack_verification_shipping_container_contents_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_pack_verification_shipping_container_contents_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_pack_verification_shipping_container_contents_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_data_loaded');
  $grid.vars.suppressFlows = false;
  
  if ($grid.inParams.canAddManually && !$grid.inParams.isReadOnly) {
      $grid.canEdit = true;
      $grid.topToolbar.unpick.hidden = false;
      $grid.topToolbar.transfer.hidden = false;
  }
  else {
      $grid.canEdit = false;
      $grid.topToolbar.unpick.hidden = true;
      $grid.topToolbar.transfer.hidden = true;
  }
  
  if (!$utils.isDefined($grid.inParams.expectedSerialNumbers)) {
      const CONTAINER = (await $datasources.PackVerification.ds_pack_verification_verify_container_editor.get({ shippingContainerId: $grid.inParams.shippingContainerId })).result;
      
      $grid.inParams.expectedSerialNumbers = CONTAINER.LicensePlates.reduce((output, lp) => output.concat(lp.SerialNumbers.map(sn => ({ id: sn.Id, lookupCode: sn.LookupCode, lotId: sn.LotId, packagingId: sn.PackagingId, licensePlateId: sn.LicensePlateId}))), []);
  }
  
  for (let row of $grid.rows) {
      row.vars.expectedSerialNumbers = $grid.inParams.expectedSerialNumbers
          .filter(sn => sn.lotId === row.entity.LotId && sn.packagingId === row.entity.PackagedId)
          .map(sn => sn.lookupCode);
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_init');
  $grid.inParams.isBlind = $grid.inParams.isBlind ?? false;
  $grid.inParams.isReadOnly = $grid.inParams.isReadOnly ?? false;
  $grid.inParams.canAddManually = $grid.inParams.canAddManually ?? false;
  
  if ($grid.inParams.isBlind) {
      $grid.topToolbar.unpick.hidden = true;
  }
  
  if (!$grid.inParams.canAddManually || $grid.inParams.isReadOnly) {
      $grid.headers.add_verified.hidden = true;
      $grid.headers.remove_verified.hidden = true;
  }
  
  
  document.getElementsByTagName('FootprintManager-pack_verification_wizard')[0].addEventListener('handlepack_verification_verify_container_editorValidated', ((event: CustomEvent) => {
      $grid.refresh();
  }) as EventListener);
  }
  on_unpick_clicked(event = null) {
    return this.on_unpick_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unpick_clickedInternal(
    $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_unpick_clicked');
  try {
      if ($grid.selectedRows.length === 1) {
          let row = $grid.selectedRows[0];
  
          let completedTasks = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ licenseplateId: row.entity.LicensePlateId, lotId: row.entity.LotId, packagingId: row.entity.PackagedId, chainHead: $grid.inParams.startVerificationTaskId })).result;
          completedTasks = completedTasks.filter(t => t.StatusId === 2);
  
          let unpickPackagedAmount = row.entity.PackagedAmount - completedTasks.reduce((total, task) => total += task.ActualPackagedAmount, 0);
  
          if (unpickPackagedAmount > 0) {
              let missingSerialNumberIds: number[];
  
              let allSerials = (await $datasources.PackVerification.ds_get_serialnumbers_by_shippingContainerId.get({ shippingContainerId: $grid.inParams.shippingContainerId })).result;
  
              if ($utils.isDefined($grid.inParams.expectedSerialNumbers)) {
                  let verificationTasks = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ licenseplateId: row.entity.LicensePlateId, lotId: row.entity.LotId, packagingId: row.entity.PackagedId, chainHead: $grid.inParams.startVerificationTaskId })).result;
                  let missingSerials = allSerials.filter(sn =>
                      sn.Lot.Id === row.entity.LotId &&
                      sn.PackagingId === row.entity.PackagedId &&
                      sn.LicensePlateId === row.entity.LicensePlateId &&
                      !$utils.isDefined(verificationTasks.find(task => task.SerialNumberId === sn.Id))
                  );
  
                  missingSerialNumberIds = missingSerials.map(sn => sn.Id);
              }
  
              let result = (await $shell.FootprintManager.openunpicking_formDialog({
                  licenseplateId: row.entity.LicensePlateId,
                  inventory: {
                      lotId: row.entity.LotId,
                      packagingId: row.entity.PackagedId,
                      packagedAmount: row.entity.PackagedAmount,
                      shipmentLineId: row.entity.ShipmentLineId,
                      unpickPackagedAmount: unpickPackagedAmount,
                      serialNumberIds: missingSerialNumberIds
                  },
                  locationId: $grid.inParams.locationId
              }));
  
              row.refresh();
          }
          else {
              throw new Error('All content has been verified and cannot be unpicked');
          }
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error unpicking contents', error.message);
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_row_selected');
  // REMOVED (TO REVIEW): BROKEN BY GAMMA UPDATE
  // if (!$grid.vars.suppressFlows) {
  //     $grid.vars.suppressFlows = true;
  
  //     for (let row of $grid.rows) {
  //         if (row.selected) {
  //             if (row.vars.isSelected) {
  //                 row.selected = false;
  //                 row.vars.isSelected = false;
  //             }
  //             else {
  //                 row.vars.isSelected = true;
  //             }
  //         }
  //         else {
  //             row.vars.isSelected = false;
  //         }
  //     }
  
  //     $grid.vars.suppressFlows = false;
  // }
  
  if ($grid.selectedRows.length === 1) {
      $grid.topToolbar.unpick.control.readOnly = false;
      $grid.topToolbar.transfer.control.readOnly = false;
  }
  else {
      $grid.topToolbar.unpick.control.readOnly = true;
      $grid.topToolbar.transfer.control.readOnly = true;
  }
  
  }
  on_transfer_clicked(event = null) {
    return this.on_transfer_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transfer_clickedInternal(
    $grid: FootprintManager_pack_verification_shipping_container_contents_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_contents_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pack_verification_shipping_container_contents_grid.on_transfer_clicked');
  try {
      if ($grid.selectedRows.length > 0) {
          let row = $grid.selectedRows[0]
  
          let completedTasks = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ licenseplateId: row.entity.LicensePlateId, lotId: row.entity.LotId, packagingId: row.entity.PackagedId, chainHead: $grid.inParams.startVerificationTaskId })).result;
          completedTasks = completedTasks.filter(t => t.StatusId === 2);
  
          let transferPackagedAmount = row.entity.PackagedAmount - completedTasks.reduce((total, task) => total += task.ActualPackagedAmount, 0);
  
          if (transferPackagedAmount > 0) {
              let missingSerialNumberIds: number[];
  
              let allSerials = (await $datasources.PackVerification.ds_get_serialnumbers_by_shippingContainerId.get({ shippingContainerId: $grid.inParams.shippingContainerId })).result;
  
              if ($utils.isDefined($grid.inParams.expectedSerialNumbers)) {
                  let verificationTasks = (await $datasources.PackVerification.ds_get_pack_verification_tasks_by_content.get({ licenseplateId: row.entity.LicensePlateId, lotId: row.entity.LotId, packagingId: row.entity.PackagedId, chainHead: $grid.inParams.startVerificationTaskId })).result;
                  let missingSerials = allSerials.filter(sn =>
                      sn.Lot.Id === row.entity.LotId &&
                      sn.PackagingId === row.entity.PackagedId &&
                      sn.LicensePlateId === row.entity.LicensePlateId &&
                      !$utils.isDefined(verificationTasks.find(task => task.SerialNumberId === sn.Id))
                  );
  
                  missingSerialNumberIds = missingSerials.map(sn => sn.Id);
              }
  
              await $shell.FootprintManager.openinventory_and_licenseplate_move_formDialog({
                  warehouseId: null,
                  locationId: null,
                  materialId: row.entity.Lot.Material.Id,
                  vendorlotId: row.entity.Lot.VendorLotId,
                  licenseplates: [{
                      id: row.entity.LicensePlateId,
                      content: [{
                          lotId: row.entity.LotId,
                          packagingId: row.entity.PackagedId,
                          packagedAmount: transferPackagedAmount,
                          availablePackagedAmount: transferPackagedAmount
                      }]
                  }],
                  serialNumberIds: missingSerialNumberIds
              });
  
              if (transferPackagedAmount === row.entity.PackagedAmount) {
                  await $grid.refresh()
  
                  if ($grid.rows.length === 0) {
                      throw new Error('Shipping container has no content and pack verification will now be closed');
                  }
  
              }
              else {
                  await row.refresh();
              }
          }
          else {
              throw new Error('All content has been verified and cannot be transferred');
          }
  
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error transferring content', error.message);
  
      document.getElementsByTagName('FootprintManager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handleCloseWizard'));
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
