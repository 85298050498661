<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="component">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.component.displayControl.styles.style"
          [ngClass]="row.cells.component.displayControl.styles.classes"
          [matTooltip]="row.cells.component.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.component.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="remaining_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.remaining_quantity.displayControl.styles.style"
          [ngClass]="row.cells.remaining_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.remaining_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.remaining_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="counted_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.counted_quantity.displayControl.styles.style"
          [ngClass]="row.cells.counted_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.counted_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.counted_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['counted_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.counted_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.counted_quantity.editControl.readOnly || row.cells.counted_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.counted_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.counted_quantity.editControl.styles.style"
            [ngClass]="row.cells.counted_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.counted_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="difference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.difference.displayControl.styles.style"
          [ngClass]="row.cells.difference.displayControl.styles.classes"
          [matTooltip]="row.cells.difference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.difference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="new_inventory_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.new_inventory_quantity.displayControl.styles.style"
          [ngClass]="row.cells.new_inventory_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.new_inventory_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.new_inventory_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="license_plate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.license_plate.displayControl.styles.style"
          [ngClass]="row.cells.license_plate.displayControl.styles.classes"
          [matTooltip]="row.cells.license_plate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.license_plate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-licenseplates_by_location_single 
        data-cy="selector"
        [type]="row.cells.license_plate.editControl.type"
        [formControl]="row.formGroup.controls['license_plate_edit']"
        (displayTextChange)="row.cells.license_plate.editControl.displayText=$event"
        [placeholder]="row.cells.license_plate.editControl.placeholder"
        [styles]="row.cells.license_plate.editControl.styles"
        [tooltip]="row.cells.license_plate.editControl.tooltip"
      [location_id]="row.$fields_license_plate_selector_inParams_location_id"
    >
    </Manufacturing-licenseplates_by_location_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
