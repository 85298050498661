import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService } from './FootprintManager.inventory_transformation_target_lines_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { InventoryTransformations_licenseplates_dd_singleComponent } from './InventoryTransformations.licenseplates_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'


interface IFootprintManager_inventory_transformation_target_lines_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, InventoryTransformationId?: number, SourceLineId?: number, StatusId?: number, TargetInventoryCreationDate?: string, TargetLicensePlateId?: number, TargetLicensePlateLookupCode?: string, TargetLocationId?: number, TargetLotId?: number, TargetPackageAmount?: number, TargetSerialRequests?: string, InventoryTransformationLineStatus?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, LocationContainer?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, Name?: string, ShortName?: string }, new_lp?: boolean}

interface IFootprintManager_inventory_transformation_target_lines_gridComponentInParams {
  inventory_transformation_id: number, line_numbers?: number[], source_line_id?: number, inventory_to_transform?: { target_material_id?: number, packaging_id?: number, lot_id?: number, vendor_lot_id?: number, weight?: number, weight_uom?: number, new_quantity?: number, length?: number, width?: number, height?: number, dimensions_uom?: number }, warehouse_id: number, additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } }, is_read_only?: boolean, inventory_transformation_status_id?: number}

interface IFootprintManager_inventory_transformation_target_lines_gridComponentOutParams {
  target_line_id?: number}

class FootprintManager_inventory_transformation_target_lines_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_transformation_target_lines_gridComponent;
  entity: IFootprintManager_inventory_transformation_target_lines_gridComponentEntity;

  vars: { source_licenseplate_id?: number, source_location_id?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    is_new_lp_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_new_lp_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    new_license_plate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    existing_license_plate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    target_quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    status: null,
    target_material: null,
    is_new_lp: null,
    new_license_plate: null,
    existing_license_plate: null,
    location: null,
    target_quantity: null,
    lot: null,
    vendorlot: null,
    packaging: null,
    target_inventory_create_date: null,
  }

  get $fields_existing_license_plate_selector_inParams_locationId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.location.editControl.value;
    
    return expr;
  }
  get $fields_existing_license_plate_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_existing_license_plate_selector_inParams_archived(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }
  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid?.inParams?.warehouse_id;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row?.entity?.Material?.Id ?? $grid?.inParams?.inventory_to_transform?.target_material_id;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row?.cells?.vendorlot?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row?.entity?.Material?.Id ?? $grid.inParams.inventory_to_transform?.target_material_id;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row?.cells?.lot?.editControl?.value;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row?.entity?.Material?.Id ?? $grid?.inParams?.inventory_to_transform?.target_material_id;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.target_material = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.target_material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.is_new_lp = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.is_new_lp,
      new CheckBoxModel(this.formGroup.controls['is_new_lp_display'] as DatexFormControl, null, true, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['is_new_lp_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.new_license_plate = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.new_license_plate,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['new_license_plate_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.existing_license_plate = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.existing_license_plate,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['existing_license_plate_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.target_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.target_quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['target_quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.target_inventory_create_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.target_inventory_create_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
    this.formGroup
      .controls['is_new_lp_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_new_lp_changed();
      });
    this.formGroup
      .controls['location_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_inventory_transformation_target_lines_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_transformation_target_lines_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      inventory_transformation_id:  $grid.inParams.inventory_transformation_id ,
      line_numbers:  null ,
      source_line_number:  $grid.inParams.source_line_id ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_transformation_target_lines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.status.displayControl as TextModel).text = $row?.entity?.InventoryTransformationLineStatus?.Name;
    (this.cells.target_material.displayControl as TextModel).text = $row?.entity?.Material?.LookupCode;
    (this.cells.is_new_lp.displayControl as CheckBoxModel).reset($row?.entity?.new_lp);
    (this.cells.is_new_lp.editControl as CheckBoxModel).reset($row?.entity?.new_lp);
    (this.cells.new_license_plate.displayControl as TextModel).text = $row?.entity?.TargetLicensePlateLookupCode ?? $row?.entity?.LicensePlate?.LookupCode;
    (this.cells.new_license_plate.editControl as TextBoxModel).reset($row?.entity?.TargetLicensePlateLookupCode);
    (this.cells.existing_license_plate.displayControl as TextModel).text = $row?.entity?.LicensePlate?.LookupCode ?? $row?.entity?.TargetLicensePlateLookupCode;
    (this.cells.existing_license_plate.editControl as SelectBoxModel).reset($row?.entity?.LicensePlate?.Id);
    (this.cells.location.displayControl as TextModel).text = $row?.entity?.LicensePlate?.Location?.Name ?? $row?.entity?.LocationContainer?.Name;
    (this.cells.location.editControl as SelectBoxModel).reset($row?.entity?.TargetLocationId ?? $row.entity?.LicensePlate?.Location?.Id);
    (this.cells.target_quantity.displayControl as TextModel).text = $row.entity?.TargetPackageAmount?.toString();
    (this.cells.target_quantity.editControl as NumberBoxModel).reset($row.entity?.TargetPackageAmount);
    (this.cells.lot.displayControl as TextModel).text = $row.entity?.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity?.Lot?.Id);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity?.Lot?.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.Lot?.VendorLot?.Id);
    (this.cells.packaging.displayControl as TextModel).text = $row?.entity?.Packaging?.Name;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row?.entity?.Packaging?.Id);
    (this.cells.target_inventory_create_date.displayControl as TextModel).text = $row?.entity?.TargetInventoryCreationDate;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'new_license_plate' : {
        this.on_is_new_lp_clicked();
        break;
      }
      case 'existing_license_plate' : {
        this.on_is_new_lp_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_save_new_row');
  
  
  
  try {
  
  
      //validation
  
      if (!$utils.isDefined($grid.inParams.inventory_to_transform.target_material_id) && !$utils.isDefined($grid.inParams.inventory_to_transform.lot_id)) {
          throw Error('Target material or lot must be defined.')
      }
  
      if (!$utils.isDefined($row.cells.existing_license_plate.editControl.value) && ($row?.cells?.is_new_lp?.editControl?.value === false || !$utils.isDefined($row?.cells?.is_new_lp?.editControl?.value))) {
          throw Error('An existing target lienseplate must be selected.')
      }
  
      if ($row?.cells?.is_new_lp.editControl.value === true && (!$utils.isDefined($row.cells.new_license_plate.editControl.value) || !$utils.isDefined($row.cells.location.editControl.value))) {
  
          throw Error('A target licenseplate lookupcode and location must be defined.')
      }
  
  
  
      //we get the material controller 
      let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $grid.inParams.inventory_to_transform.target_material_id }))?.result[0]
      let material_controller = material_check?.ControllerTypeId
      $grid.vars.target_material_controller_type = material_controller
  
      if (material_check?.IsFixedLength === true && material_check?.IsFixedVolume === true && material_check?.IsFixedWeight === true) {
          $grid.vars.is_fixed = true
      }
      else {
          $grid.vars.is_fixed = false
      }
  
  
      //non serialized
      if (($grid.vars.target_material_controller_type === 1 || $grid.vars.target_material_controller_type === 2) && $grid.vars.is_fixed === true) {
  
  
          //non serialized create target lines existing LP
          if ($utils.isDefined($row?.cells?.existing_license_plate?.editControl?.value) && ($row?.cells?.is_new_lp?.editControl?.value === false || !$utils.isDefined($row?.cells?.is_new_lp?.editControl?.value))) {
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_on_existing_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $row.cells.lot.editControl.value,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  packaged_amount: $row.cells.target_quantity.editControl.value,
                  package_id: $row.cells.packaging.editControl.value,
                  source_line_id: $grid.inParams.source_line_id,
                  target_license_plate_id: $row.cells.existing_license_plate.editControl.value,
  
              }))
  
              if ($utils.isDefined(target_line_request?.reason)) {
                  throw Error(target_line_request?.reason)
              }
  
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date === true) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $row?.cells?.target_inventory_create_date.displayControl.text
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
                  if ($utils.isDefined(update_row?.reason)) {
                      throw Error(update_row?.reason)
                  }
              }
  
              $shell.InventoryTransformations.openToaster(
                  'Successfully created inventory transformation target line/s.',
                  `Inventory transformation target line/s has been created.`,
                  EToasterType.Success,
                  {
                      positionClass: EToasterPosition.topRight,
                      tapToDismiss: true,
                      timeOut: 5000,
                      progressBar: true,
                      newestOnTop: true
                  });
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': target_line_request?.inventory_transformation_target_line_id })); ///change to FootprintManager-inventory_transformation_advanced_wizard
              $grid.outParams.target_line_id = target_line_request?.inventory_transformation_target_line_id
              await $grid.events.outParamsChange.emit()
              await $grid.refresh()
  
          }
  
          //non serialized create target lines new LP
          if ($utils.isDefined($row?.cells?.new_license_plate?.editControl?.value) && $row?.cells?.is_new_lp.editControl.value === true) {
  
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_on_new_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $row.cells.lot.editControl.value,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  packaged_amount: $row.cells.target_quantity.editControl.value,
                  package_id: $row.cells.packaging.editControl.value,
                  source_line_id: $grid.inParams.source_line_id,
                  license_plate_lookupcode: $row.cells.new_license_plate.editControl.value,
                  location_id: $row.cells.location.editControl.value
              }))
  
              if ($utils.isDefined(target_line_request?.reason)) {
                  throw Error(target_line_request?.reason)
              }
  
  
              ///change to FootprintManager-inventory_transformation_advanced_wizard
  
              if ($utils.isDefined(target_line_request?.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date === true) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $row?.cells?.target_inventory_create_date.displayControl.text
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
                  if ($utils.isDefined(update_row?.reason)) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $shell.InventoryTransformations.openToaster(
                  'Successfully created inventory transformation target line/s.',
                  `Inventory transformation target line/s has been created.`,
                  EToasterType.Success,
                  {
                      positionClass: EToasterPosition.topRight,
                      tapToDismiss: true,
                      timeOut: 5000,
                      progressBar: true,
                      newestOnTop: true
                  });
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': target_line_request?.inventory_transformation_target_line_id }));
              $grid.outParams.target_line_id = target_line_request?.inventory_transformation_target_line_id
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              await $grid.events.outParamsChange.emit()
              await $grid.refresh()
  
          }
  
      }
  
      //serialized
      else {
  
          //serialized create target lines existing LP
          if ($utils.isDefined($row?.cells?.existing_license_plate?.editControl?.value) && ($row?.cells?.is_new_lp?.editControl?.value === false || !$utils.isDefined($row?.cells?.is_new_lp?.editControl?.value))) {
  
              let serial_numbers = (await $shell.FootprintManager.openserialnumbers_creation_gridDialog({
                  materialId: $grid.inParams.inventory_to_transform.target_material_id,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmount: $row.cells.target_quantity.editControl.value,
                  lotId: $row.cells.lot.editControl.value,
                  licensePlateId: $row.cells.existing_license_plate.editControl.value,
                  averageLength: ($grid.inParams.inventory_to_transform.length != 0) ? $grid.inParams.inventory_to_transform.length : null,
                  averageWidth: ($grid.inParams.inventory_to_transform.width != 0) ? $grid.inParams.inventory_to_transform.width : null,
                  averageHeight: ($grid.inParams.inventory_to_transform.height != 0) ? $grid.inParams.inventory_to_transform.height : null,
                  dimensionUomId: ($grid.inParams.inventory_to_transform.dimensions_uom != 0) ? $grid.inParams.inventory_to_transform.dimensions_uom : null,
                  averageGross: ($grid.inParams.inventory_to_transform.weight != 0) ? $grid.inParams.inventory_to_transform.weight : null,
                  weightUomId: ($grid.inParams.inventory_to_transform.weight_uom != 0) ? $grid.inParams.inventory_to_transform.weight_uom : null,
              }))
  
              if (!serial_numbers?.confirm) {
                  throw Error('No serial numbers selected')
              }
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $row.cells.lot.editControl.value,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  package_id: $row.cells.packaging.editControl.value,
                  source_line_id: $grid.inParams.source_line_id,
                  target_licenseplate_id: $row.cells.existing_license_plate.editControl.value,
                  serial_numbers: serial_numbers?.serialNumbers
  
              }))
  
  
              if ($utils.isDefined(target_line_request?.reason)) {
                  throw Error(target_line_request?.reason)
              }
  
              if ($utils.isDefined(target_line_request?.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date === true) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $row?.cells?.target_inventory_create_date.displayControl.text
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
                  if ($utils.isDefined(update_row?.reason)) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $shell.InventoryTransformations.openToaster(
                  'Successfully created inventory transformation target line/s.',
                  `Inventory transformation target line/s has been created.`,
                  EToasterType.Success,
                  {
                      positionClass: EToasterPosition.topRight,
                      tapToDismiss: true,
                      timeOut: 5000,
                      progressBar: true,
                      newestOnTop: true
                  });
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': target_line_request?.inventory_transformation_target_line_id }));
              $grid.outParams.target_line_id = target_line_request?.inventory_transformation_target_line_id
              await $grid.events.outParamsChange.emit()
              await $grid.refresh()
          }
  
          //serialized create target lines new LP
          if ($utils.isDefined($row?.cells?.new_license_plate?.editControl?.value) && $row?.cells?.is_new_lp.editControl.value === true) {
  
              let serial_numbers = (await $shell.FootprintManager.openserialnumbers_creation_gridDialog({
                  materialId: $grid.inParams.inventory_to_transform.target_material_id,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmount: $row.cells.target_quantity.editControl.value,
                  lotId: $row.cells.lot.editControl.value,
                  averageLength: ($grid.inParams.inventory_to_transform.length != 0) ? $grid.inParams.inventory_to_transform.length : null,
                  averageWidth: ($grid.inParams.inventory_to_transform.width != 0) ? $grid.inParams.inventory_to_transform.width : null,
                  averageHeight: ($grid.inParams.inventory_to_transform.height != 0) ? $grid.inParams.inventory_to_transform.height : null,
                  dimensionUomId: ($grid.inParams.inventory_to_transform.dimensions_uom != 0) ? $grid.inParams.inventory_to_transform.dimensions_uom : null,
                  averageGross: ($grid.inParams.inventory_to_transform.weight != 0) ? $grid.inParams.inventory_to_transform.weight : null,
                  weightUomId: ($grid.inParams.inventory_to_transform.weight_uom != 0) ? $grid.inParams.inventory_to_transform.weight_uom : null,
                  //licensePlateId: $row.cells.existing_license_plate.editControl.value
              }))
  
              if (!serial_numbers?.confirm) {
                  throw Error('No serial numbers selected')
              }
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $row.cells.lot.editControl.value,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  package_id: $row.cells.packaging.editControl.value,
                  source_line_id: $grid.inParams.source_line_id,
                  serial_numbers: serial_numbers?.serialNumbers,
                  license_plate_lookupcode: $row.cells.new_license_plate.editControl.value,
                  location_id: $row.cells.location.editControl.value,
  
              }))
  
              if ($utils.isDefined(target_line_request?.reason)) {
                  throw Error(target_line_request?.reason)
              }
  
  
  
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date === true) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $row?.cells?.target_inventory_create_date.displayControl.text
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
  
                  if ($utils.isDefined(update_row?.reason)) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $shell.InventoryTransformations.openToaster(
                  'Successfully created inventory transformation target line/s.',
                  `Inventory transformation target line/s has been created.`,
                  EToasterType.Success,
                  {
                      positionClass: EToasterPosition.topRight,
                      tapToDismiss: true,
                      timeOut: 5000,
                      progressBar: true,
                      newestOnTop: true
                  });
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': target_line_request?.inventory_transformation_target_line_id })); ///change to FootprintManager-inventory_transformation_advanced_wizard
              $grid.outParams.target_line_id = target_line_request?.inventory_transformation_target_line_id
              await $grid.events.outParamsChange.emit()
              await $grid.refresh()
          }
  
      }
  
  
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      await $row.refresh()
      //throw error; // to prevent displayMode 
  }
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_save_existing_row');
  
  if ($utils.isDefined($row?.entity?.TargetLicensePlateId)) {
      $row?.cells?.is_new_lp.editControl.value === true
      $grid.headers.new_license_plate.hidden = true
      $grid.headers.existing_license_plate.hidden = false
      //$grid.headers.license_plate.hidden = true
  }
  else {
      $row?.cells?.is_new_lp.editControl.value === false
      $grid.headers.new_license_plate.hidden = false
      $grid.headers.existing_license_plate.hidden = true
      //$grid.headers.license_plate.hidden = true
  
  }
  
  try {
  
  
  
      if (!$utils.isDefined($grid.inParams.inventory_to_transform.target_material_id) && !$utils.isDefined($grid.inParams.inventory_to_transform.lot_id)) {
          throw Error('Target material or lot must be defined.')
      }
  
      if (!$utils.isDefined($row.cells.existing_license_plate.editControl.value) && ($row?.cells?.is_new_lp?.editControl?.value === false || !$utils.isDefined($row?.cells?.is_new_lp?.editControl?.value))) {
          throw Error('An existing target licenseplate must be selected.')
      }
  
      if ($row?.cells?.is_new_lp.editControl.value === true && (!$utils.isDefined($row.cells.new_license_plate.editControl.value) || !$utils.isDefined($row.cells.location.editControl.value))) {
  
          throw Error('A target licenseplate lookupcode and location must be defined.')
      }
  
  
      let inventory_target_line_payload: any = {}
  
  
      if ($row?.cells?.existing_license_plate?.editControl?.isChanged) {
          inventory_target_line_payload.TargetLicensePlateId = $row?.cells.existing_license_plate?.editControl?.value
          inventory_target_line_payload.TargetLicensePlateLookupCode = null
      }
      if ($row?.cells?.new_license_plate?.editControl?.isChanged) {
          inventory_target_line_payload.TargetLicensePlateLookupCode = $row?.cells.new_license_plate?.editControl?.value
          inventory_target_line_payload.TargetLicensePlateId = null
      }
  
      if ($row?.cells?.location?.editControl?.isChanged) {
          inventory_target_line_payload.TargetLocationId = $row?.cells.location?.editControl?.value
      }
  
      if ($row?.cells?.target_quantity?.editControl?.isChanged) {
          //if new quantity is less than original, allow users to select serial numbers to include.
          if ($utils.isDefined($row.entity.TargetSerialRequests) && ($row.cells.target_quantity.editControl.value < $row.entity.TargetPackageAmount)) {
              let select_serials = (await $shell.FootprintManager.openserialnumbers_modification_gridDialog({ serial_number_request: JSON.parse($row.entity.TargetSerialRequests), packagedAmountRequest: $row.cells.target_quantity.editControl.value }))
  
              if (!select_serials?.confirm) {
                  throw Error('No serial numbers selected')
              }
  
              inventory_target_line_payload.TargetSerialRequests = JSON.stringify(select_serials?.serial_number_request)
          }
  
          //If serial, do not allow increasing quantity. 
          //This is because we do not know if the additional option details from when the target line was created. Hence we cant map the weights, and dims properly.
          if ($utils.isDefined($row.entity.TargetSerialRequests) && ($row.cells.target_quantity.editControl.value > $row.entity.TargetPackageAmount)) {
  
  
              let serial_numbers = (await $shell.FootprintManager.openserialnumbers_creation_gridDialog({
                  materialId: $row?.entity?.Material?.Id,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmount: $row.cells.target_quantity.editControl.value - $row.entity.TargetPackageAmount,
                  lotId: $row.cells.lot.editControl.value,
                  licensePlateId: $row.cells.existing_license_plate.editControl.value,
                  averageLength: ($grid.inParams.inventory_to_transform.length != 0) ? $grid.inParams.inventory_to_transform.length : null,
                  averageWidth: ($grid.inParams.inventory_to_transform.width != 0) ? $grid.inParams.inventory_to_transform.width : null,
                  averageHeight: ($grid.inParams.inventory_to_transform.height != 0) ? $grid.inParams.inventory_to_transform.height : null,
                  dimensionUomId: ($grid.inParams.inventory_to_transform.dimensions_uom != 0) ? $grid.inParams.inventory_to_transform.dimensions_uom : null,
                  averageGross: ($grid.inParams.inventory_to_transform.weight != 0) ? $grid.inParams.inventory_to_transform.weight : null,
                  weightUomId: ($grid.inParams.inventory_to_transform.weight_uom != 0) ? $grid.inParams.inventory_to_transform.weight_uom : null,
              }))
  
              if (!serial_numbers?.confirm) {
                  throw Error('No serial numbers created')
              }
  
              let serial_number_request = JSON.parse($row.entity.TargetSerialRequests);
  
              for (let serial_number of serial_numbers?.serialNumbers)
              {
              serial_number_request.push(serial_number);
              }
  
              let new_serial_number_request = JSON.stringify(serial_number_request);
  
              inventory_target_line_payload.TargetSerialRequests = new_serial_number_request
  
              //throw Error('Please add another target line instead of increasing the quantity for serialized materials.')
          }
  
          inventory_target_line_payload.TargetPackageAmount = $row?.cells.target_quantity?.editControl?.value
      }
  
      if ($row?.cells?.lot?.editControl?.isChanged) {
          inventory_target_line_payload.TargetLotId = $row?.cells.lot?.editControl?.value
      }
  
      if ($row?.cells?.packaging?.editControl?.isChanged) {
          inventory_target_line_payload.TargetPackageId = $row?.cells.packaging?.editControl?.value
      }
  
      if (!$utils.isDefined($grid.inParams.inventory_to_transform.target_material_id) && !$utils.isDefined($grid.inParams.inventory_to_transform.lot_id)) {
          throw Error('Target material or lot must be defined.')
      }
  
  
      if (!$utils.isDefined($row.cells.existing_license_plate.editControl.value,) && $row?.cells?.is_new_lp.editControl.value === false) {
          throw Error('An existing target lienseplate must be selected.')
      }
  
      if (!$utils.isDefined($row.cells.new_license_plate.editControl.value,) && $row?.cells?.is_new_lp.editControl.value === true) {
          throw Error('An target licenseplate lookupcode must be defined.')
      }
  
      //updated if updated amount is greater than 0
      if ($row.cells.target_quantity?.editControl?.value > 0) {
          let update_row = (await $flows.Utilities.crud_update_flow({
              entitySet: 'InventoryTransformationTargetLines',
              id: $row?.entity?.Id,
              entity: inventory_target_line_payload
          }));
  
  
          if ($utils.isDefined(update_row?.reason)) {
              throw Error(update_row?.reason)
          }
  
          $shell.InventoryTransformations.openToaster(
              'Successfully updated inventory transformation target line/s.',
              `Inventory transformation target line/s has been updated.`,
              EToasterType.Success,
              {
                  positionClass: EToasterPosition.topRight,
                  tapToDismiss: true,
                  timeOut: 5000,
                  progressBar: true,
                  newestOnTop: true
              });
  
      }
      //delete if updated amount is less than or equal 0
      else {
          let delete_row = (await $flows.Utilities.crud_delete_flow({
              entitySet: 'InventoryTransformationTargetLines',
              id: $row?.entity?.Id
          }))
  
          if (delete_row?.reason) {
              throw Error(delete_row?.reason)
          }
  
          $shell.InventoryTransformations.openToaster(
              'Successfully deleted inventory transformation target line/s.',
              `Inventory transformation target line/s has been deleted.`,
              EToasterType.Success,
              {
                  positionClass: EToasterPosition.topRight,
                  tapToDismiss: true,
                  timeOut: 5000,
                  progressBar: true,
                  newestOnTop: true
              });
  
      }
  
  
      let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          source_line_number: $grid.inParams.source_line_id
      }))?.result
  
  
      document?.getElementsByTagName('FootprintManager-inventory_transformation_target_line_editor')[0]?.dispatchEvent(new CustomEvent('handle_target_line_value', { 'detail': target_line_check[0]?.Id })); ///change to FootprintManager-inventory_transformation_advanced_wizard
      $grid.outParams.target_line_id = target_line_check[0]?.Id
  
      $grid.headers.existing_license_plate.hidden = true
      $grid.headers.new_license_plate.hidden = true
      await $row.refresh();
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      await $row.refresh();
  }
  }
  on_is_new_lp_changed(event = null) {
    return this.on_is_new_lp_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_new_lp_changedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_is_new_lp_changed');
  
  
  if ($row?.cells?.is_new_lp.editControl.value) {
      $grid.headers.new_license_plate.hidden = false
      $grid.headers.existing_license_plate.hidden = true
  }
  else {
      $grid.headers.new_license_plate.hidden = true
      $grid.headers.existing_license_plate.hidden = false
  }
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_row_data_loaded');
  
  //prevent edititng if not in created status
  if ($utils.isDefined($row?.entity?.StatusId)) {
      if ($row?.entity?.StatusId != 1 && $grid.rows.length > 0) {
          for (let key in $row.cells) {
              if ($utils.isDefined($row.cells[key].editControl?.readOnly)) {
                  $row.cells[key].editControl.readOnly = true;
              }
          }
          return;
      }
  }
  
  
  //for new lines only
  if ($row.isNew) {
  
      if ($utils.isAllDefined($grid?.vars?.source_licenseplate_id, $grid?.vars?.source_location_id)) {
          //set visibility
          $grid.headers.new_license_plate.hidden = true
          $grid.headers.existing_license_plate.hidden = false
          //$grid.headers.license_plate.hidden = true
  
          //set values
          //$row.cells.is_new_lp.editControl.value = false
          $row.cells.existing_license_plate.editControl.value = $grid?.vars?.source_licenseplate_id ?? null
  
      }
  
      //for new lines only
      if (!$utils.isAllDefined($grid?.vars?.source_licenseplate_id, $grid?.vars?.source_location_id)) {
          //set visibility
          $grid.headers.new_license_plate.hidden = false
          $grid.headers.existing_license_plate.hidden = true
          //$grid.headers.license_plate.hidden = true
          $row.cells.location.editControl.readOnly = false
  
          //set values
          //$row.cells.is_new_lp.editControl.readOnly = true
          $row.cells.is_new_lp.editControl.value = true
  
      }
  
  
      
      //set values
      $row.cells.lot.editControl.value = ($grid.inParams?.inventory_to_transform?.lot_id != 0) ? $grid.inParams?.inventory_to_transform?.lot_id : ($row.entity?.TargetLotId ?? null);
      $row.cells.vendorlot.editControl.value = ($grid.inParams?.inventory_to_transform?.vendor_lot_id != 0) ? $grid.inParams?.inventory_to_transform?.vendor_lot_id : ($row.entity?.Lot?.VendorLot?.Id ?? null)
      $row.cells.packaging.editControl.value = ($grid.inParams?.inventory_to_transform?.packaging_id != 0) ? $grid.inParams?.inventory_to_transform?.packaging_id : ($row.entity?.Packaging?.Id ?? null)
      $row.cells.location.editControl.value = $row.entity?.TargetLocationId ?? $row.entity?.LicensePlate?.Location?.Id ?? $grid?.vars?.source_location_id ?? null
      $row.cells.target_inventory_create_date.displayControl.text = $row.entity?.TargetInventoryCreationDate ?? $grid?.vars?.source_inventory_creation_date_time ?? null
      
  }
  
  /*
  else {
  
      if ($utils.isDefined($row.cells.existing_license_plate.editControl.value)) {
  
          $row.cells.location.editControl.readOnly = true
      }
      else {
          $row.cells.location.editControl.readOnly = false
      }
  
  }
  */
  
  
  }
  on_location_changed(event = null) {
    return this.on_location_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_location_changed');
  $row.cells.existing_license_plate.editControl.value = null
  }
  on_existing_lp_changed(event = null) {
    return this.on_existing_lp_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_existing_lp_changedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_existing_lp_changed');
  //If licenseplate is selected, make location readonly
  if ($utils.isDefined($row.cells.existing_license_plate.editControl.value)) {
      $row.cells.location.editControl.readOnly = true
  }
  else {
      $row.cells.location.editControl.readOnly = false
  }
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_licenseplate_clicked');
  
  }
  on_is_new_lp_clicked(event = null) {
    return this.on_is_new_lp_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_new_lp_clickedInternal(
    $row: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_target_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_is_new_lp_clicked');
  //if ($row.entity?.new_lp === true) {
  if ($row?.cells.is_new_lp.editControl.value === true) {
      $grid.headers.new_license_plate.hidden = false
      $grid.headers.existing_license_plate.hidden = true
      //$grid.headers.license_plate.hidden = true
  
  }
  else {
  
      $grid.headers.new_license_plate.hidden = true
      $grid.headers.existing_license_plate.hidden = false
      //$grid.headers.license_plate.hidden = true
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => InventoryTransformations_licenseplates_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Lots_lots_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_target_lines_grid',
  templateUrl: './FootprintManager.inventory_transformation_target_lines_grid.component.html'
})
export class FootprintManager_inventory_transformation_target_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_transformation_target_lines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_transformation_target_lines_gridComponentInParams = { inventory_transformation_id: null, line_numbers: [], source_line_id: null, inventory_to_transform: { target_material_id: null, packaging_id: null, lot_id: null, vendor_lot_id: null, weight: null, weight_uom: null, new_quantity: null, length: null, width: null, height: null, dimensions_uom: null }, warehouse_id: null, additional_options: { general: { keep_source_creation_date: null, convert_packaging_to_base: null }, serial_number_options: { copy_weights: null, copy_volume: null, copy_dimensions: null }, lot_number_options: { copy_udfs: null, copy_lot_number: null, copy_vendor_lot_number: null } }, is_read_only: null, inventory_transformation_status_id: null };

  outParams: IFootprintManager_inventory_transformation_target_lines_gridComponentOutParams = { target_line_id: null };

  //#region Variables
  vars: { source_licenseplate_id?: number, source_location_id?: number, source_inventory_creation_date_time?: string, row_created_from_data_loaded?: boolean, target_material_controller_type?: number, source_serial_number?: { id?: number, length?: number, width?: number, height?: number, gross_volume?: number, gross_weight?: number }, old_target_material_id?: number, is_fixed?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ target_line_id?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       target_material: new GridHeaderModel(new HeaderStyles(null, null), 'target_material', 'Target material', false, false, null, false),       is_new_lp: new GridHeaderModel(new HeaderStyles(null, null), 'is_new_lp', 'New LP?', false, false, null, false),       new_license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'new_license_plate', 'License plate', false, false, null, false),       existing_license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'existing_license_plate', 'License plate', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       target_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'target_quantity', 'Quantity', true, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', true, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       target_inventory_create_date: new GridHeaderModel(new HeaderStyles(null, null), 'target_inventory_create_date', 'Produced on', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel[] = [];

  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(value: any) {
    this.inParams['inventory_transformation_id'] = value;
  }
  get $inParams_inventory_transformation_id(): any {
    return this.inParams['inventory_transformation_id'] ;
  }
  @Input('line_numbers') set $inParams_line_numbers(value: any) {
    this.inParams['line_numbers'] = value;
  }
  get $inParams_line_numbers(): any {
    return this.inParams['line_numbers'] ;
  }
  @Input('source_line_id') set $inParams_source_line_id(value: any) {
    this.inParams['source_line_id'] = value;
  }
  get $inParams_source_line_id(): any {
    return this.inParams['source_line_id'] ;
  }
  @Input('inventory_to_transform') set $inParams_inventory_to_transform(value: any) {
    this.inParams['inventory_to_transform'] = value;
  }
  get $inParams_inventory_to_transform(): any {
    return this.inParams['inventory_to_transform'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('additional_options') set $inParams_additional_options(value: any) {
    this.inParams['additional_options'] = value;
  }
  get $inParams_additional_options(): any {
    return this.inParams['additional_options'] ;
  }
  @Input('is_read_only') set $inParams_is_read_only(value: any) {
    this.inParams['is_read_only'] = value;
  }
  get $inParams_is_read_only(): any {
    return this.inParams['is_read_only'] ;
  }
  @Input('inventory_transformation_status_id') set $inParams_inventory_transformation_status_id(value: any) {
    this.inParams['inventory_transformation_status_id'] = value;
  }
  get $inParams_inventory_transformation_status_id(): any {
    return this.inParams['inventory_transformation_status_id'] ;
  }

  topToolbar = {
      remove: new ToolModel(new ButtonModel('remove', new ButtonStyles(null, null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      add_line: new ToolModel(new ButtonModel('add_line', new ButtonStyles(null, null), false, false, false, 'Add line', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Inventory transformation target lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      inventory_transformation_id:  $grid.inParams.inventory_transformation_id ,
      line_numbers:  null ,
      source_line_number:  $grid.inParams.source_line_id ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_transformation_target_lines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_transformation_target_lines_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_transformation_target_lines_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_inventory_transformation_target_lines_gridComponentEntity) {
    const row = new FootprintManager_inventory_transformation_target_lines_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_init');
  $grid.headers.existing_license_plate.hidden = true
  $grid.headers.new_license_plate.hidden = true
  $grid.vars.row_created_from_data_loaded = false
  $grid.topToolbar.remove.control.readOnly = true
  $grid.canAdd = false
  
  
  if($grid.inParams.is_read_only === true)
  {
      $grid.topToolbar.remove.hidden  = true
      $grid.canEdit = false
  }
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_data_loaded');
  
  //show if target is set to readonly
  /*
  if ($grid.inParams.is_read_only) {
      $grid.headers.target_material.hidden = false
  }
  else {
      $grid.headers.target_material.hidden = true
  }
  */
  
  //check source lines for selected input
  let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
      {
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          ids: [$grid.inParams.source_line_id]
      }
  ))?.result
  
  
  //we set the variables depending on the source input result
  if ($utils.isDefined(source_line_check)) {
      $grid.vars.source_location_id = source_line_check[0]?.LicensePlate?.Location?.Id ?? source_line_check[0]?.SerialNumbers?.LicensePlate?.Location?.Id
      $grid.vars.source_licenseplate_id = source_line_check[0]?.SourceLicensePlateId ?? source_line_check[0]?.SerialNumbers?.LicensePlate?.Id
      //$grid.vars.source_serial_number.id = source_line_check[0]?.SourceSerialNumberId
  
  }
  if ($grid.inParams.additional_options.general.keep_source_creation_date) {
      $grid.vars.source_inventory_creation_date_time = source_line_check[0]?.Lot?.ReceiveDate
  }
  
  
  
  
  /*
  if ($utils.isDefined($grid.vars.source_serial_number)) {
      let source_serial_check = (await $datasources.InventoryTransformations.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: source_line_check[0].SourceSerialNumberId }))?.result
      $grid.vars.source_serial_number.length = source_serial_check?.Length
      $grid.vars.source_serial_number.width = source_serial_check?.Width
      $grid.vars.source_serial_number.height = source_serial_check?.Height
      $grid.vars.source_serial_number.gross_volume = source_serial_check?.GrossVolume
      $grid.vars.source_serial_number.gross_weight = source_serial_check?.GrossWeight
  }
  */
  
  
  //we get the material controller 
  let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $grid.inParams.inventory_to_transform.target_material_id }))?.result[0]
  let material_controller = material_check?.ControllerTypeId
  $grid.vars.target_material_controller_type = material_controller
  
  if (material_check?.IsFixedLength === true && material_check?.IsFixedVolume === true && material_check?.IsFixedWeight === true) {
      $grid.vars.is_fixed = true
  }
  else {
      $grid.vars.is_fixed = false
  }
  
  
  /*
  //we create the target line automatically if we have  from input params
  //non-serialized
  if (material_controller === 1 || material_controller === 2) {
      //create target line for existing LP (non serialized)
      if (($grid.vars.row_created_from_data_loaded === false) && $utils.isAllDefined($grid.vars.source_licenseplate_id, $grid.inParams.inventory_to_transform.target_material_id, $grid.inParams.inventory_to_transform.packaging_id, $grid.inParams.inventory_to_transform.new_quantity)) {
          try {
  
              //call confirmation before creating lines
              if ($utils.isDefined($grid.inParams.inventory_to_transform?.target_material_id)) {
                  let confirm_create = (await $shell.InventoryTransformations.openConfirmationDialog('Create target line/s.', `Create target lines for inventory transformation [${$grid.inParams.inventory_transformation_id}]?`, 'Confirm', 'Cancel'))
                  if (!confirm_create) { return; }
              }
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_on_existing_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $grid.inParams.inventory_to_transform.lot_id,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  packaged_amount: $grid.inParams.inventory_to_transform.new_quantity,
                  package_id: $grid.inParams.inventory_to_transform.packaging_id,
                  source_line_id: $grid.inParams.source_line_id,
                  target_license_plate_id: $grid.vars.source_licenseplate_id,
  
              }))
  
              if ($utils.isDefined(target_line_request.reason)) {
                  throw Error(target_line_request.reason)
              }
  
              //update source creation date if keep_source_creation_date is true
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $grid?.vars?.source_inventory_creation_date_time ?? today.toISOString()
  
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              $grid.headers.is_new_lp.hidden = true
              //flag variable to true to prevent inifinite loop
              $grid.vars.row_created_from_data_loaded = true
  
              await $grid.refresh()
  
  
  
          }
          catch (error) {
              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
      }
  
      //create target line with new LP. Will use source_line_check[0]?.InventoryTransformation?.LookupCode
      if (($grid.vars.row_created_from_data_loaded === false) && !$utils.isDefined($grid.vars.source_licenseplate_id) && $utils.isAllDefined($grid.inParams.inventory_to_transform.target_material_id, $grid.inParams.inventory_to_transform.packaging_id, $grid.inParams.inventory_to_transform.new_quantity)) {
          try {
  
              //call confirmation before creating lines
              if ($utils.isDefined($grid.inParams.inventory_to_transform?.target_material_id)) {
                  let confirm_create = (await $shell.InventoryTransformations.openConfirmationDialog('Create target line/s.', `Create target lines for inventory transformation [${$grid.inParams.inventory_transformation_id}]?`, 'Confirm', 'Cancel'))
                  if (!confirm_create) { return; }
              } 
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_on_new_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $grid.inParams.inventory_to_transform.lot_id,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  packaged_amount: $grid.inParams.inventory_to_transform.new_quantity,
                  package_id: $grid.inParams.inventory_to_transform.packaging_id,
                  source_line_id: $grid.inParams.source_line_id,
                  license_plate_lookupcode: 'LP -' + source_line_check[0]?.InventoryTransformation?.LookupCode,
                  location_id: $grid.vars.source_location_id
              }))
  
              if ($utils.isDefined(target_line_request.reason)) {
                  throw Error(target_line_request.reason)
              }
  
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id && $grid?.inParams?.additional_options?.general?.keep_source_creation_date)) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $grid?.vars?.source_inventory_creation_date_time ?? today.toISOString()
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
              }
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              $grid.headers.is_new_lp.hidden = true
              //flag variable to true to prevent inifinite loop
              $grid.vars.row_created_from_data_loaded = true
              await $grid.refresh()
  
          }
          catch (error) {
              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
      }
  }
  
  //serialized
  else { ////create target line with existing LP serialized.
      if (($grid.vars.row_created_from_data_loaded === false) && $utils.isAllDefined($grid.vars.source_licenseplate_id, $grid.inParams.inventory_to_transform.target_material_id, $grid.inParams.inventory_to_transform.packaging_id, $grid.inParams.inventory_to_transform.new_quantity)) {
          try {
              
              //call confirmation before creating lines
              if ($utils.isDefined($grid.inParams.inventory_to_transform?.target_material_id)) {
                  let confirm_create = (await $shell.InventoryTransformations.openConfirmationDialog('Create target line/s.', `Create target lines for inventory transformation [${$grid.inParams.inventory_transformation_id}]?`, 'Confirm', 'Cancel'))
                  if (!confirm_create) { return; }
              }
  
              let serial_numbers = (await $shell.InventoryTransformations.openserialnumbers_creation_gridDialog({
                  materialId: $grid.inParams.inventory_to_transform.target_material_id,
                  packagingId: $grid.inParams.inventory_to_transform.packaging_id,
                  packagedAmount: $grid.inParams.inventory_to_transform.new_quantity,
                  averageGross: $grid.inParams.inventory_to_transform.weight,
                  weightUomId: $grid.inParams.inventory_to_transform.weight_uom,
                  lotId: $grid.inParams.inventory_to_transform.lot_id,
                  licensePlateId: $grid.vars.source_licenseplate_id,
              }))
  
              if (!$utils.isDefined(serial_numbers)) {
                  return;
              }
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_by_serial_numbers_on_existing_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $grid.inParams.inventory_to_transform.lot_id,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  serial_numbers: serial_numbers?.serialNumbers,
                  package_id: $grid.inParams.inventory_to_transform.packaging_id,
                  source_line_id: $grid.inParams.source_line_id,
                  target_licenseplate_id: $grid.vars.source_licenseplate_id,
  
              }))
  
              if ($utils.isDefined(target_line_request.reason)) {
                  throw Error(target_line_request.reason)
              }
  
              //update source creation date if keep_source_creation_date is true
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id) && $grid?.inParams?.additional_options?.general?.keep_source_creation_date) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $grid?.vars?.source_inventory_creation_date_time ?? today.toISOString()
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              $grid.headers.is_new_lp.hidden = true
              //flag variable to true to prevent inifinite loop
              $grid.vars.row_created_from_data_loaded = true
  
              await $grid.refresh()
  
  
  
          }
          catch (error) {
              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
      }
  
      //create target line with new LP serialized. Will use source_line_check[0]?.InventoryTransformation?.LookupCode
      if (($grid.vars.row_created_from_data_loaded === false) && !$utils.isDefined($grid.vars.source_licenseplate_id) && $utils.isAllDefined($grid.inParams.inventory_to_transform.target_material_id, $grid.inParams.inventory_to_transform.packaging_id, $grid.inParams.inventory_to_transform.new_quantity)) {
          try {
  
              //call confirmation before creating lines
              if ($utils.isDefined($grid.inParams.inventory_to_transform?.target_material_id)) {
                  let confirm_create = (await $shell.InventoryTransformations.openConfirmationDialog('Create target line/s.', `Create target lines for inventory transformation [${$grid.inParams.inventory_transformation_id}]?`, 'Confirm', 'Cancel'))
                  if (!confirm_create) { return; }
              }
  
              let source_serial_location_check = (await $datasources.InventoryTransformations.ds_get_serialnumber_by_serialnumberId.get({ serialnumberId: source_line_check[0].SourceSerialNumberId }))?.result.LicensePlate?.LocationId
  
              let serial_numbers = (await $shell.InventoryTransformations.openserialnumbers_creation_gridDialog({
                  materialId: $grid.inParams.inventory_to_transform.target_material_id,
                  packagingId: $grid.inParams.inventory_to_transform.packaging_id,
                  packagedAmount: $grid.inParams.inventory_to_transform.new_quantity,
                  averageGross: $grid.inParams.inventory_to_transform.weight,
                  weightUomId: $grid.inParams.inventory_to_transform.weight_uom,
                  lotId: $grid.inParams.inventory_to_transform.lot_id,
              }))
  
              let target_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_target_line_by_serial_numbers_on_new_licenseplate_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  lot_id: $grid.inParams.inventory_to_transform.lot_id,
                  material_id: $grid.inParams.inventory_to_transform.target_material_id,
                  serial_numbers: serial_numbers?.serialNumbers,
                  package_id: $grid.inParams.inventory_to_transform.packaging_id,
                  source_line_id: $grid.inParams.source_line_id,
                  license_plate_lookupcode: 'LP -' + source_line_check[0]?.InventoryTransformation?.LookupCode,
                  location_id: source_serial_location_check
  
              }))
  
  
              if ($utils.isDefined(target_line_request.reason)) {
                  throw Error(target_line_request.reason)
              }
  
              if ($utils.isDefined(target_line_request.inventory_transformation_target_line_id && $grid?.inParams?.additional_options?.general?.keep_source_creation_date)) {
                  let inventory_target_line_payload: any = {}
                  let today = new Date()
                  inventory_target_line_payload.TargetInventoryCreationDate = $grid?.vars?.source_inventory_creation_date_time ?? today.toISOString()
  
  
  
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line_request?.inventory_transformation_target_line_id,
                      entity: inventory_target_line_payload
                  }))
  
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
              }
  
              $grid.headers.existing_license_plate.hidden = true
              $grid.headers.new_license_plate.hidden = true
              $grid.headers.is_new_lp.hidden = true
              //flag variable to true to prevent inifinite loop
              $grid.vars.row_created_from_data_loaded = true
              await $grid.refresh()
  
          }
          catch (error) {
              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
      }
  }
  */
  
  //we set the headers back after the lines are created
  $grid.headers.new_license_plate.hidden = false
  $grid.headers.existing_license_plate.hidden = true
  
  $grid.headers.is_new_lp.hidden = false
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_delete_clicked');
  
  
  //only delete lines if source in created status
  
  
  let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
      inventory_transformation_id: $grid.inParams.inventory_transformation_id,
      ids: [$grid.inParams.source_line_id]
  }))?.result
  
  if (source_line_check[0]?.StatusId === 1) {
  
      let line_numbers = $grid.selectedRows.map(sr => sr?.entity?.Id)
      //target line check
      let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          line_numbers: line_numbers
      }))?.result
  
      let valid_rows = target_line_check.filter(tlc => tlc.StatusId === 1).map(tlc => tlc?.Id)
      let invalid_rows = target_line_check.filter(tlc => tlc.StatusId != 1).map(tlc => tlc?.Id)
  
      let delete_lines: boolean
  
      if (invalid_rows?.length > 0) {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete target lines.', `Lines ${JSON.stringify(invalid_rows)}] cannot be deleted as they are not in created status. Confirm to delete target lines ${JSON.stringify(valid_rows)}?`, 'Confirm', 'Cancel'))
      }
      else {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete target lines.', `Confirm to delete target lines ${JSON.stringify(valid_rows)}?`, 'Confirm', 'Cancel'))
      }
  
  
      if (delete_lines) {
          for (let row of valid_rows) {
  
              let delete_row = (await $flows.Utilities.crud_delete_flow({
                  entitySet: 'InventoryTransformationTargetLines',
                  id: row
              }))
              if ($utils.isDefined(delete_row?.reason)) {
                  throw Error(delete_row?.reason)
              }
          }
      }
      await $grid.refresh();
  }
  
  else {
      $shell.InventoryTransformations.showErrorDetails('Delete', 'Error on delete row.', `Source line is in [${source_line_check[0]?.InventoryTransformationLineStatus?.Name}] status.`);
  }
  
  
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_row_selected');
  
  $grid.topToolbar.remove.control.readOnly = ($grid.selectedRows.length === 0) 
  $grid.topToolbar.add_line.control.readOnly = ($grid.selectedRows.length > 0) 
  }
  on_add_line_clidked(event = null) {
    return this.on_add_line_clidkedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_line_clidkedInternal(
    $grid: FootprintManager_inventory_transformation_target_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_target_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_target_lines_grid.on_add_line_clidked');
  $grid.addRow()
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
