import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';


import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.Invoices = this.injector.get(Invoices_FlowService);
    this.FootprintManager = this.injector.get(FootprintManager_FlowService);
  }

    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Utilities: Utilities_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public Invoices: Invoices_FlowService;
    public FootprintManager: FootprintManager_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
}
