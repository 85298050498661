import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { PrintNode_ds_get_device_associationsService } from './PrintNode.datasource.index';
import { PrintNode_ds_get_location_by_location_idService } from './PrintNode.datasource.index';
import { PrintNode_ds_get_printersService } from './PrintNode.datasource.index';
import { PrintNode_ds_get_printjobsService } from './PrintNode.datasource.index';
import { PrintNode_ds_get_scalesService } from './PrintNode.datasource.index';

@Injectable({ providedIn: 'root' })
export class PrintNode_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public PrintNode: PrintNode_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_device_associations: PrintNode_ds_get_device_associationsService;
  public get ds_get_device_associations(): PrintNode_ds_get_device_associationsService {
    if(!this._ds_get_device_associations) {
      this._ds_get_device_associations = this.injector.get(PrintNode_ds_get_device_associationsService);
    }
    return this._ds_get_device_associations;
  }
  private _ds_get_location_by_location_id: PrintNode_ds_get_location_by_location_idService;
  public get ds_get_location_by_location_id(): PrintNode_ds_get_location_by_location_idService {
    if(!this._ds_get_location_by_location_id) {
      this._ds_get_location_by_location_id = this.injector.get(PrintNode_ds_get_location_by_location_idService);
    }
    return this._ds_get_location_by_location_id;
  }
  private _ds_get_printers: PrintNode_ds_get_printersService;
  public get ds_get_printers(): PrintNode_ds_get_printersService {
    if(!this._ds_get_printers) {
      this._ds_get_printers = this.injector.get(PrintNode_ds_get_printersService);
    }
    return this._ds_get_printers;
  }
  private _ds_get_printjobs: PrintNode_ds_get_printjobsService;
  public get ds_get_printjobs(): PrintNode_ds_get_printjobsService {
    if(!this._ds_get_printjobs) {
      this._ds_get_printjobs = this.injector.get(PrintNode_ds_get_printjobsService);
    }
    return this._ds_get_printjobs;
  }
  private _ds_get_scales: PrintNode_ds_get_scalesService;
  public get ds_get_scales(): PrintNode_ds_get_scalesService {
    if(!this._ds_get_scales) {
      this._ds_get_scales = this.injector.get(PrintNode_ds_get_scalesService);
    }
    return this._ds_get_scales;
  }
}

