import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private $utils: UtilsService,
  ) {
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.FootprintManager = this.injector.get(FootprintManager_OperationService);
  }

    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public Utilities: Utilities_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public Invoices: Invoices_OperationService;
    public FootprintManager: FootprintManager_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.$utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
