import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_form_ComponentContextService } from './Manufacturing.manufacturing_order_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_owners_dd_singleComponent } from './Manufacturing.owners_dd_single.component'
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component'
import { Manufacturing_projects_dd_singleComponent } from './Manufacturing.projects_dd_single.component'
import { Manufacturing_manufacturing_staging_locations_dd_singleComponent } from './Manufacturing.manufacturing_staging_locations_dd_single.component'
import { Manufacturing_manufacturing_output_locations_dd_singleComponent } from './Manufacturing.manufacturing_output_locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_owners_dd_singleComponent),
    forwardRef(() => Manufacturing_warehouses_dd_singleComponent),
    forwardRef(() => Manufacturing_projects_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_staging_locations_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_output_locations_dd_singleComponent),
  ],
  selector: 'Manufacturing-manufacturing_order_form',
  templateUrl: './Manufacturing.manufacturing_order_form.component.html'
})
export class Manufacturing_manufacturing_order_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookup_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner_id: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse_id: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project_id: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    staging_location_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    output_location_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    estimated_start: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    estimated_end: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), true, false, false, 'Save', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    lookup_code: new FieldModel(new TextBoxModel(this.formGroup.controls['lookup_code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Manufacturing order', true, false),
    owner_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner_id'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Customer', true, false),
    warehouse_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse_id'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    project_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project_id'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    staging_location_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['staging_location_id'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Staging location', false, false),
    output_location_id: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['output_location_id'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Output location', false, false),
    estimated_start: new FieldModel(new DateBoxModel(this.formGroup.controls['estimated_start'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Estimated start date', true, false),
    estimated_end: new FieldModel(new DateBoxModel(this.formGroup.controls['estimated_end'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Estimated end date', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
  };

  fieldsets = {
    order_group: new FieldsetModel(
      'Order',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    locations_group: new FieldsetModel(
      'Locations',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    notes_group: new FieldsetModel(
      'Notes',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_project_id_selector_inParams_status_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_id_selector_inParams_owner_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner_id.control.value;
    
    return expr;
  }

  get $fields_staging_location_id_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.warehouse_id.control.value;
    
    return expr;
  }

  get $fields_staging_location_id_selector_inParams_project_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner_id.control.value;
    
    return expr;
  }

  get $fields_output_location_id_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.warehouse_id.control.value;
    
    return expr;
  }

  get $fields_output_location_id_selector_inParams_project_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner_id.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookup_code: this.fields.lookup_code.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    owner_id: this.fields.owner_id.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    warehouse_id: this.fields.warehouse_id.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    project_id: this.fields.project_id.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    staging_location_id: this.fields.staging_location_id.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    output_location_id: this.fields.output_location_id.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    estimated_start: this.fields.estimated_start.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    estimated_end: this.fields.estimated_end.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    notes: this.fields.notes.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'manufacturing_order_form';
  
    const $form = this;
    const $utils = this.$utils;

    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookup_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_customer();
      });
    this.$formGroupFieldValidationObservables
      .warehouse_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_warehouse();
      });
    this.$formGroupFieldValidationObservables
      .project_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .staging_location_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .output_location_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .estimated_start
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .estimated_end
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: Manufacturing_manufacturing_order_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form.on_save');
  
  const order = {
      LookupCode: $form.fields.lookup_code.control.value,
      WarehouseId: $form.fields.warehouse_id.control.value,
      ProjectId: $form.fields.project_id.control.value,
      StatusId: 1,
      LicensePlateMixingRuleId: 0,
      DefaultStagingLocationId: $form.fields.staging_location_id.control.value,
      ManufacturingLocationId: $form.fields.output_location_id.control.value,
      Notes: $form.fields.notes.control.value,
      EstimatedStartOn: $form.fields.estimated_start.control.value,
      EstimatedCompleteOn: $form.fields.estimated_end.control.value,
  }
  
  const createdOrder = (await $flows.Utilities.crud_create_flow({
      entitySet: "ManufacturingOrders",
      entity: order
  }));
  
  if (!createdOrder.reason) {
      $form.close();
  
      $shell.Manufacturing.openmanufacturing_order_editor({
          manufacturing_order_id: createdOrder.result.Id
      });
  } else {
      $shell.Utilities.openErrorDialog('Save failed', createdOrder.reason);
  }
  
  }
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: Manufacturing_manufacturing_order_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form.on_cancel');
  
  $form.close()
  }
  change_warehouse(event = null) {
    return this.change_warehouseInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async change_warehouseInternal(
    $form: Manufacturing_manufacturing_order_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form.change_warehouse');
  
  $form.fields.staging_location_id.control.value = null;
  $form.fields.output_location_id.control.value = null;
  }
  change_customer(event = null) {
    return this.change_customerInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async change_customerInternal(
    $form: Manufacturing_manufacturing_order_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form.change_customer');
  
  $form.fields.project_id.control.value = null;
  }
  //#endregion private flows
  //#region validation flows
  
  validate(fieldErrors: { lookup_code: Pick<string[], 'push'>,owner_id: Pick<string[], 'push'>,warehouse_id: Pick<string[], 'push'>,project_id: Pick<string[], 'push'>,staging_location_id: Pick<string[], 'push'>,output_location_id: Pick<string[], 'push'>,estimated_start: Pick<string[], 'push'>,estimated_end: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, } = null) {
    const emptyResult = { lookup_code:[],owner_id:[],warehouse_id:[],project_id:[],staging_location_id:[],output_location_id:[],estimated_start:[],estimated_end:[],notes:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validateInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validateInternal(
    $form: Manufacturing_manufacturing_order_formComponent,
    $validation:{
      fieldErrors: { lookup_code: Pick<string[], 'push'>,owner_id: Pick<string[], 'push'>,warehouse_id: Pick<string[], 'push'>,project_id: Pick<string[], 'push'>,staging_location_id: Pick<string[], 'push'>,output_location_id: Pick<string[], 'push'>,estimated_start: Pick<string[], 'push'>,estimated_end: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, }
    },
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $context: Manufacturing_manufacturing_order_form_ComponentContextService
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form.validate');
    
  const formIsValid =
      $form.fields.lookup_code.control.value
      && $form.fields.owner_id.control.value
      && $form.fields.warehouse_id.control.value
      && $form.fields.project_id.control.value;
  
  $form.toolbar.save.control.readOnly = !formIsValid;
    return $validation.fieldErrors as { lookup_code:[],owner_id:[],warehouse_id:[],project_id:[],staging_location_id:[],output_location_id:[],estimated_start:[],estimated_end:[],notes:[], };
  }
  //#endregion validation flows
}
