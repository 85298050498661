<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-details"
                     *ngIf="!filtersets.details.hidden && !filtersets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.details.collapsible }">
                    <div *ngIf="!filtersets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.details.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-scheduled_run" *ngIf="!filters.scheduled_run.hidden && !filters.scheduled_run.removed" 
                                  class="field-container standard {{filters.scheduled_run.invalid ? 'invalid' : ''}} {{filters.scheduled_run.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.scheduled_run.styles.style"
                                  [ngClass]="filters.scheduled_run.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.scheduled_run.label + (filters.scheduled_run.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.scheduled_run.label}}<span *ngIf="filters.scheduled_run.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <div data-cy="text" class="datex-text" 
                                    [ngStyle]="filters.scheduled_run.control.styles.style"
                                    [ngClass]="filters.scheduled_run.control.styles.classes"
                                    [matTooltip]="filters.scheduled_run.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">{{filters.scheduled_run.control.text }}</div>
                              <ng-container *ngIf="filters.scheduled_run.invalid">
                                <ng-container *ngFor="let error of filters.scheduled_run.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-output_location" *ngIf="!filters.output_location.hidden && !filters.output_location.removed" 
                                  class="field-container standard {{filters.output_location.invalid ? 'invalid' : ''}} {{filters.output_location.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.output_location.styles.style"
                                  [ngClass]="filters.output_location.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.output_location.label + (filters.output_location.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.output_location.label}}<span *ngIf="filters.output_location.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-manufacturing_output_locations_dd_single 
                                  data-cy="selector"
                                  [type]="filters.output_location.control.type"
                                  formControlName="output_location"
                                  (displayTextChange)="filters.output_location.control.displayText=$event"
                                  [placeholder]="filters.output_location.control.placeholder"
                                  [styles]="filters.output_location.control.styles"
                                  [tooltip]="filters.output_location.control.tooltip"
                                [warehouse_id]="$fields_output_location_selector_inParams_warehouse_id"
                                [project_id]="$fields_output_location_selector_inParams_project_id"
                              >
                              </Manufacturing-manufacturing_output_locations_dd_single>
                              <ng-container *ngIf="filters.output_location.invalid">
                                <ng-container *ngFor="let error of filters.output_location.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-workshift" *ngIf="!filters.workshift.hidden && !filters.workshift.removed" 
                                  class="field-container standard {{filters.workshift.invalid ? 'invalid' : ''}} {{filters.workshift.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.workshift.styles.style"
                                  [ngClass]="filters.workshift.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.workshift.label + (filters.workshift.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.workshift.label}}<span *ngIf="filters.workshift.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-manufacturing_orders_workshifts_dd_single 
                                  data-cy="selector"
                                  [type]="filters.workshift.control.type"
                                  formControlName="workshift"
                                  (displayTextChange)="filters.workshift.control.displayText=$event"
                                  [placeholder]="filters.workshift.control.placeholder"
                                  [styles]="filters.workshift.control.styles"
                                  [tooltip]="filters.workshift.control.tooltip"
                                [warehouse_id]="$fields_workshift_selector_inParams_warehouse_id"
                              >
                              </Manufacturing-manufacturing_orders_workshifts_dd_single>
                              <ng-container *ngIf="filters.workshift.invalid">
                                <ng-container *ngFor="let error of filters.workshift.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-equipment" *ngIf="!filters.equipment.hidden && !filters.equipment.removed" 
                                  class="field-container standard {{filters.equipment.invalid ? 'invalid' : ''}} {{filters.equipment.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.equipment.styles.style"
                                  [ngClass]="filters.equipment.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.equipment.label + (filters.equipment.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.equipment.label}}<span *ngIf="filters.equipment.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Manufacturing-manufacturing_orders_equipment_dd_single 
                                  data-cy="selector"
                                  [type]="filters.equipment.control.type"
                                  formControlName="equipment"
                                  (displayTextChange)="filters.equipment.control.displayText=$event"
                                  [placeholder]="filters.equipment.control.placeholder"
                                  [styles]="filters.equipment.control.styles"
                                  [tooltip]="filters.equipment.control.tooltip"
                                [warehouse_id]="$fields_equipment_selector_inParams_warehouse_id"
                              >
                              </Manufacturing-manufacturing_orders_equipment_dd_single>
                              <ng-container *ngIf="filters.equipment.invalid">
                                <ng-container *ngFor="let error of filters.equipment.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.details.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.finushed_product.hasTabToShow" class="tab" data-cy="tab-finushed_product">
              <h2 [className]="tabs.finushed_product.active? 'active': ''" (click)="tabs.finushed_product.activate()">{{tabs.finushed_product.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-manufacturing_order_line_production_confirmation_grid *ngIf="tabs.finushed_product.active"
              #$tabs_finushed_product
              [finished_good]="$tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_finished_good"
              [planned_quantity]="$tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_planned_quantity"
              [material_id]="$tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_material_id"
              [output_location_id]="$tabs_finushed_product_manufacturing_order_line_production_confirmation_grid_inParams_output_location_id"
              ($refreshEvent)="refresh(false, false, '$tabs_finushed_product')">
              </Manufacturing-manufacturing_order_line_production_confirmation_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>