import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { ShippingContainers_DatasourceService } from './ShippingContainers.datasource.index';

interface IShippingContainers_packing_slip_reportServiceInParams {
  shippingContainerId: number}

interface IShippingContainers_packing_slip_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class ShippingContainers_packing_slip_reportService extends ReportBaseService<IShippingContainers_packing_slip_reportServiceInParams, IShippingContainers_packing_slip_reportServiceData> {

  protected reportReferenceName = 'packing_slip_report';
  protected appReferenceName = 'ShippingContainers';

  constructor(
    $utils: UtilsService,
    private $datasources: ShippingContainers_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('ShippingContainers', 'packing_slip_report');
  }

  override throwIfMissingRequiredInParams (inParams: IShippingContainers_packing_slip_reportServiceInParams) {
  }

  protected async getData(inParams: IShippingContainers_packing_slip_reportServiceInParams): Promise<IShippingContainers_packing_slip_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IShippingContainers_packing_slip_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IShippingContainers_packing_slip_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
