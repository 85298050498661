import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_get_shipping_container_by_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { id: number }): 
  Promise<{ result: { Id?: number, BillOfLading?: string, BondNumber?: string, CarrierId?: number, CarrierServiceTypeId?: number, CommodityDescription?: string, ConsigneeContactId?: number, ContainerMaterial?: string, ContainerNumber?: string, ContainerSizeId?: number, ContainerTypeId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomsReleaseNumber?: string, DeclaredValue?: number, DeclaredValueCurrencyId?: number, Deleted?: boolean, DimensionUomId?: number, EstimatedDeliveryDateTime?: string, FreightClassId?: number, Height?: number, InventoryMeasurementUnitId?: number, Length?: number, LookupCode?: string, ManifestedPackageBundleCode?: string, ManifestedPackageCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NmfcNumber?: string, NmfcSubNumber?: string, Notes?: string, OrderTypeId?: number, PackagedItemCount?: number, ParentId?: number, SailOnBoard?: string, SealId?: string, ShipmentId?: number, ShipperContactId?: number, ShippingCost?: number, ShippingCurrencyId?: number, ShippingDate?: string, ShippingManifestId?: number, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string }, ContainerSize?: { Name?: string }, ContainerType?: { Name?: string }, LicensePlates?: { Longitude?: string }[], Shipment?: { LookupCode?: string, LoadContainer?: { LookupCode?: string }, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string } }[] }, InventoryMeasurementUnit?: { Name?: string }, DimensionUom?: { Name?: string }, VolumeUom?: { Name?: string }, WeightUom?: { Name?: string }, ChildShippingContainers?: { LookupCode?: string }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.id)) {
      missingRequiredInParams.push('\'id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_get_shipping_container_by_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      id: number    }) {
        if(isNil(inParams.id)) {
          return true; 
        }
      return false;
    }
}
