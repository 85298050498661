import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_orders_for_calendar_card_ComponentContextService } from './Manufacturing.manufacturing_orders_for_calendar_card.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Manufacturing-manufacturing_orders_for_calendar_card',
  templateUrl: './Manufacturing.manufacturing_orders_for_calendar_card.component.html'
})
export class Manufacturing_manufacturing_orders_for_calendar_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['creation','border-left']);

  inParams: { order_id: number, lookup_code: string, order_lines?: string[], start_date: string, end_date: string } = { order_id: null, lookup_code: null, order_lines: [], start_date: null, end_date: null };
  //#region Inputs
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  @Input('lookup_code') set $inParams_lookup_code(v: string) {
    this.inParams.lookup_code = v;
  }
  get $inParams_lookup_code(): string {
    return this.inParams.lookup_code;
  }
  @Input('order_lines') set $inParams_order_lines(v: string[]) {
    this.inParams.order_lines = v;
  }
  get $inParams_order_lines(): string[] {
    return this.inParams.order_lines;
  }
  @Input('start_date') set $inParams_start_date(v: string) {
    this.inParams.start_date = v;
  }
  get $inParams_start_date(): string {
    return this.inParams.start_date;
  }
  @Input('end_date') set $inParams_end_date(v: string) {
    this.inParams.end_date = v;
  }
  get $inParams_end_date(): string {
    return this.inParams.end_date;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    dates: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    orders: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        dates: new FieldModel(new TextBoxModel(this.formGroupContent.controls['dates'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
        orders: new FieldModel(new TextBoxModel(this.formGroupContent.controls['orders'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    },
    fieldsets: {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $card = this;
        const $utils = this.$utils;
        return '';
      }
    ),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.$utils;
    const expr = $card.inParams.lookup_code;
    return expr;
  }






  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_orders_for_calendar_card_ComponentContextService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.lookup_code)) {
        this.$missingRequiredInParams.push('lookup_code');
      }
      if(isNil(this.inParams.start_date)) {
        this.$missingRequiredInParams.push('start_date');
      }
      if(isNil(this.inParams.end_date)) {
        this.$missingRequiredInParams.push('end_date');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.$utils;

    (this.content.fields.dates.control as TextBoxModel).reset(`${new Date($card.inParams.start_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} - ${new Date($card.inParams.end_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`);
    (this.content.fields.orders.control as TextBoxModel).reset($card.inParams.order_lines.map((str, index) => `Line ${index + 1} - ${str}`).join('\n'));



    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_open_order(event = null) {
    return this.on_open_orderInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_open_orderInternal(
    $card: Manufacturing_manufacturing_orders_for_calendar_cardComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_for_calendar_card_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_for_calendar_card.on_open_order');
  
  $shell.Manufacturing.openmanufacturing_order_editor({
      manufacturing_order_id: $card.inParams.order_id
  });
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
