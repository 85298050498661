import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_component_serial_number_selection_grid_ComponentContextService } from './Manufacturing.manufacturing_order_component_serial_number_selection_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity {
  Id?: number, Archived?: boolean, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, PackagingId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, WarehouseId?: number }, Packaging?: { Id?: number, Name?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }, DimensionsUom?: { Id?: number, Short_name?: string }}

interface IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentInParams {
  material_id?: number, warehouse_id?: number, serials_needed_count?: number, iteration?: number, license_plate?: number}

interface IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentOutParams {
  valid_data?: boolean, iteration?: number}

class Manufacturing_manufacturing_order_component_serial_number_selection_gridComponentRowModel extends GridRowModel {
  grid: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent;
  entity: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    expiration_date_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    serial_number: null,
    vendor_lot: null,
    lot: null,
    expiration_date: null,
    weight: null,
    dimensions: null,
  }



  constructor(
    grid: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_component_serial_number_selection_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.serial_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.serial_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendor_lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendor_lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.expiration_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.expiration_date,
      new DateBoxModel(this.formGroup.controls['expiration_date_display'] as DatexFormControl, null, true, false, '', 'date', null)
,
null
      );
    
    this.cells.weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.dimensions = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.dimensions,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      material_id:  $grid.inParams.material_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
      license_plate:  $grid.inParams.license_plate ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_component_serial_numbers_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.serial_number.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity.Lot.VendorLot.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot.LookupCode;
    (this.cells.expiration_date.displayControl as DateBoxModel).reset($row.entity.Lot.VendorLot.ExpirationDate);
    (this.cells.weight.displayControl as TextModel).text = `${$row.entity?.Packaging?.Weight} ${$row.entity?.Packaging?.WeightUom?.Short_name}`;
    (this.cells.dimensions.displayControl as TextModel).text = `${$row.entity?.Length}x${$row.entity?.Width}x${$row.entity?.Height} ${$row.entity?.DimensionsUom?.Short_name}`;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Manufacturing-manufacturing_order_component_serial_number_selection_grid',
  templateUrl: './Manufacturing.manufacturing_order_component_serial_number_selection_grid.component.html'
})
export class Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentInParams = { material_id: null, warehouse_id: null, serials_needed_count: null, iteration: null, license_plate: null };

  outParams: IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentOutParams = { valid_data: null, iteration: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  serial_selection_event = new EventEmitter<{ serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossWeight?: number, grossVolume?: number, netWeight?: number, netVolume?: number, materialId?: number }[]>();
  @Output()
  outParamsChange = new EventEmitter<{ valid_data?: boolean, iteration?: number }>();
  
  events = {
    serial_selection_event: this.serial_selection_event,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     serial_number: new GridHeaderModel(new HeaderStyles(null, null), 'serial_number', 'Serial number', false, false, null, false),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_lot', 'Vendor lot', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'expiration_date', 'Expiration', false, false, null, false),       weight: new GridHeaderModel(new HeaderStyles(null, null), 'weight', 'Weight', false, false, null, false),       dimensions: new GridHeaderModel(new HeaderStyles(null, null), 'dimensions', 'Dimensions', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponentRowModel[] = [];

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('serials_needed_count') set $inParams_serials_needed_count(value: any) {
    this.inParams['serials_needed_count'] = value;
  }
  get $inParams_serials_needed_count(): any {
    return this.inParams['serials_needed_count'] ;
  }
  @Input('iteration') set $inParams_iteration(value: any) {
    this.inParams['iteration'] = value;
  }
  get $inParams_iteration(): any {
    return this.inParams['iteration'] ;
  }
  @Input('license_plate') set $inParams_license_plate(value: any) {
    this.inParams['license_plate'] = value;
  }
  get $inParams_license_plate(): any {
    return this.inParams['license_plate'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_manufacturing_order_component_serial_number_selection_grid_ComponentContextService,
    ) {
    super();
    this.title = 'manufacturing_order_component_serial_number_selection_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      material_id:  $grid.inParams.material_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
      license_plate:  $grid.inParams.license_plate ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_component_serial_numbers_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IManufacturing_manufacturing_order_component_serial_number_selection_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Manufacturing_manufacturing_order_component_serial_number_selection_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_component_serial_number_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_component_serial_number_selection_grid.on_row_selected');
  
  let serialNumbers = [];
  
  $grid.selectedRows.forEach(x => {
      const serialNumber = {serialNumberId: x.entity.Id
                          , weightUOM: x.entity.WeightUomId
                          , volumeUOM: x.entity.VolumeUomId
                          , dimensionsUOM: x.entity.DimensionUomId
                          , grossVolume: x.entity.GrossVolume
                          , netVolume: x.entity.NetVolume
                          , grossWeight: x.entity.GrossWeight
                          , netWeight: x.entity.NetWeight
                          , materialId: $grid.inParams.material_id
                          , licensePlate: $grid.inParams.license_plate}
  
      serialNumbers.push(serialNumber);
  });
  
  $grid.events.serial_selection_event.emit(serialNumbers);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
