<datex-calendar-day-view #$calendarComponent 
  *ngIf="initialized "
  [(pageSize)]="columnsPageSize" [(pageSkip)]="columnsPageSkip"
  [columnsTotalCount]="columnsTotalCount" 
  [eventsTotalCount]="calendarEventsTotalCount" 
  [eventsFetchedCount]="calendarEventsFetchedCount"
  (pageChange)="$columnsPageChange()"
  [currentDate]="viewDate" [events]="calendarEvents" [columns]="columns"
  [dayStartHour]="dayStartHour" [dayStartMinute]="dayStartMinute" 
  [dayEndHour]="dayEndHour" [dayEndMinute]="dayEndMinute" 
  [hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight" 
  (hourSegmentClicked)="$onHourSegmentClicked($event)" 
  [headerTemplate]="customHeaderTemplate"
  [eventTemplate]="customEventTemplate" 
  [matchEventToColumn]="$matchEventToColumn"
  [containerClass]="containerClass"

  >

  <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="previous" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_previous($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="today" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_today($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="next" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_next($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-view_date" *ngIf="!filters.view_date.hidden && !filters.view_date.removed" 
            class="field-container standard {{filters.view_date.invalid ? 'invalid' : ''}} {{filters.view_date.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.view_date.styles.style"
            [ngClass]="filters.view_date.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.view_date.label + (filters.view_date.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.view_date.label}}<span *ngIf="filters.view_date.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="view_date"
            [format]="filters.view_date.control.format"
            [mode]="filters.view_date.control.mode"
            [ngStyle]="filters.view_date.control.styles.style"
            [ngClass]="filters.view_date.control.styles.classes"
            [tooltip]="filters.view_date.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.view_date.invalid">
          <ng-container *ngFor="let error of filters.view_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-output_location" *ngIf="!filters.output_location.hidden && !filters.output_location.removed" 
            class="field-container standard {{filters.output_location.invalid ? 'invalid' : ''}} {{filters.output_location.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.output_location.styles.style"
            [ngClass]="filters.output_location.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.output_location.label + (filters.output_location.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.output_location.label}}<span *ngIf="filters.output_location.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Manufacturing-manufacturing_output_locations_dd_multi 
            data-cy="selector-multi"
            [type]="filters.output_location.control.type"
            formControlName="output_location"
            (displayTextChange)="filters.output_location.control.displayText=$event"
            [placeholder]="filters.output_location.control.placeholder"
            [styles]="filters.output_location.control.styles"
            [tooltip]="filters.output_location.control.tooltip"
          [warehouse_id]="$fields_output_location_selector_inParams_warehouse_id"
          [project_id]="$fields_output_location_selector_inParams_project_id"
        >
        </Manufacturing-manufacturing_output_locations_dd_multi>
        <ng-container *ngIf="filters.output_location.invalid">
          <ng-container *ngFor="let error of filters.output_location.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-state" *ngIf="!filters.state.hidden && !filters.state.removed" 
            class="field-container standard {{filters.state.invalid ? 'invalid' : ''}} {{filters.state.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.state.styles.style"
            [ngClass]="filters.state.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.state.label + (filters.state.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.state.label}}<span *ngIf="filters.state.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Manufacturing-order_statuses_dd_single 
            data-cy="selector"
            [type]="filters.state.control.type"
            formControlName="state"
            (displayTextChange)="filters.state.control.displayText=$event"
            [placeholder]="filters.state.control.placeholder"
            [styles]="filters.state.control.styles"
            [tooltip]="filters.state.control.tooltip"
        >
        </Manufacturing-order_statuses_dd_single>
        <ng-container *ngIf="filters.state.invalid">
          <ng-container *ngFor="let error of filters.state.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

</datex-calendar-day-view>

<ng-template #customEventTemplate let-event="event">
 <Manufacturing-manufacturing_orders_for_calendar_card
    [order_id]="event.$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_id"
    [lookup_code]="event.$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_lookup_code"
    [order_lines]="event.$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines"
    [start_date]="event.$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_start_date"
    [end_date]="event.$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_end_date"
    ($refreshEvent)="refresh(false, false, null)"
    >
  </Manufacturing-manufacturing_orders_for_calendar_card>
</ng-template>

<ng-template #customHeaderTemplate let-column="column">
  <div [innerHtml]="$getHeaderHtml(column) | safeHtml"></div>
</ng-template>


