import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Manufacturing_activate_deactivate_planService } from './Manufacturing.flow.index';
import { Manufacturing_auto_schedule_format_flowService } from './Manufacturing.flow.index';
import { Manufacturing_cancel_manufacturing_order_flowService } from './Manufacturing.flow.index';
import { Manufacturing_complete_manufacturing_order_flowService } from './Manufacturing.flow.index';
import { Manufacturing_create_manufacturing_order_copiesService } from './Manufacturing.flow.index';
import { Manufacturing_create_manufacturing_order_line_flowService } from './Manufacturing.flow.index';
import { Manufacturing_create_serial_numbers_flowService } from './Manufacturing.flow.index';
import { Manufacturing_create_update_planService } from './Manufacturing.flow.index';
import { Manufacturing_delete_manufacturing_equipment_flowService } from './Manufacturing.flow.index';
import { Manufacturing_delete_manufacturing_order_line_components_flowService } from './Manufacturing.flow.index';
import { Manufacturing_delete_manufacturing_order_lines_flowService } from './Manufacturing.flow.index';
import { Manufacturing_delete_manufacturing_workshifts_flowService } from './Manufacturing.flow.index';
import { Manufacturing_delete_planService } from './Manufacturing.flow.index';
import { Manufacturing_get_material_default_packagingService } from './Manufacturing.flow.index';
import { Manufacturing_get_material_packaging_decimal_precision_formattedService } from './Manufacturing.flow.index';
import { Manufacturing_get_planService } from './Manufacturing.flow.index';
import { Manufacturing_get_total_minutesService } from './Manufacturing.flow.index';
import { Manufacturing_is_manufacturing_order_cancelable_flowService } from './Manufacturing.flow.index';
import { Manufacturing_minutes_between_timesService } from './Manufacturing.flow.index';
import { Manufacturing_round_to_first_decimal_digitService } from './Manufacturing.flow.index';

import { $frontendTypes } from './Manufacturing.frontend.types'

@Injectable({ providedIn: 'root' })
export class Manufacturing_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Manufacturing: Manufacturing_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _activate_deactivate_plan: Manufacturing_activate_deactivate_planService;
  public async activate_deactivate_plan(inParams: { plan_id: string, is_active: boolean }): Promise<void> {
    if(!this._activate_deactivate_plan) { 
      this._activate_deactivate_plan = this.injector.get(Manufacturing_activate_deactivate_planService);
    }
    return this._activate_deactivate_plan.run(inParams);
  }
   
   

   
 
  private _auto_schedule_format_flow: Manufacturing_auto_schedule_format_flowService;
  public async auto_schedule_format_flow(inParams: { schedule: $frontendTypes.Manufacturing.Schedule }): Promise< { scheduleString?: string }> {
    if(!this._auto_schedule_format_flow) { 
      this._auto_schedule_format_flow = this.injector.get(Manufacturing_auto_schedule_format_flowService);
    }
    return this._auto_schedule_format_flow.run(inParams);
  }
   
   

   
 
  private _cancel_manufacturing_order_flow: Manufacturing_cancel_manufacturing_order_flowService;
  public async cancel_manufacturing_order_flow(inParams: { order_id?: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_manufacturing_order_flow) { 
      this._cancel_manufacturing_order_flow = this.injector.get(Manufacturing_cancel_manufacturing_order_flowService);
    }
    return this._cancel_manufacturing_order_flow.run(inParams);
  }
   
   

   
 
  private _complete_manufacturing_order_flow: Manufacturing_complete_manufacturing_order_flowService;
  public async complete_manufacturing_order_flow(inParams: { order_id?: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_manufacturing_order_flow) { 
      this._complete_manufacturing_order_flow = this.injector.get(Manufacturing_complete_manufacturing_order_flowService);
    }
    return this._complete_manufacturing_order_flow.run(inParams);
  }
   
   

   
 
  private _create_manufacturing_order_copies: Manufacturing_create_manufacturing_order_copiesService;
  public async create_manufacturing_order_copies(inParams: { params?: $frontendTypes.Manufacturing.CreateOrderCopiesParams }): Promise< { order_ids: number[], reasons: string[] }> {
    if(!this._create_manufacturing_order_copies) { 
      this._create_manufacturing_order_copies = this.injector.get(Manufacturing_create_manufacturing_order_copiesService);
    }
    return this._create_manufacturing_order_copies.run(inParams);
  }
   
   

   
 
  private _create_manufacturing_order_line_flow: Manufacturing_create_manufacturing_order_line_flowService;
  public async create_manufacturing_order_line_flow(inParams: { order_id: number, packaging_id: number, packaged_amount: number, material_id: number, line_number: number, finished_good_id: number }): Promise< { reasons?: string[], lineNumber?: number, confirm?: boolean, messages?: any[], Id?: number }> {
    if(!this._create_manufacturing_order_line_flow) { 
      this._create_manufacturing_order_line_flow = this.injector.get(Manufacturing_create_manufacturing_order_line_flowService);
    }
    return this._create_manufacturing_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_serial_numbers_flow: Manufacturing_create_serial_numbers_flowService;
  public async create_serial_numbers_flow(inParams: { serialNumbers: { Archived?: boolean, ChainHead?: number, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, LookupCode?: string, MaterialId?: number }[], material_id?: number }): Promise< { reasons?: string[], serialNumbers?: { serialNumberId?: number, grossVolume?: number, grossWeight?: number, netVolume?: number, netWeight?: number, volumeUOM?: number, weightUOM?: number, dimensionsUOM?: number, materialId?: number }[] }> {
    if(!this._create_serial_numbers_flow) { 
      this._create_serial_numbers_flow = this.injector.get(Manufacturing_create_serial_numbers_flowService);
    }
    return this._create_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _create_update_plan: Manufacturing_create_update_planService;
  public async create_update_plan(inParams: { plan_id?: string, cron_expression: string, template_order_id: number, name: string, offset_in_days: number, total_runs: number, is_active: boolean }): Promise< { reasons?: string[], plan_id?: string }> {
    if(!this._create_update_plan) { 
      this._create_update_plan = this.injector.get(Manufacturing_create_update_planService);
    }
    return this._create_update_plan.run(inParams);
  }
   
   

   
 
  private _delete_manufacturing_equipment_flow: Manufacturing_delete_manufacturing_equipment_flowService;
  public async delete_manufacturing_equipment_flow(inParams: { equipment_id?: number }): Promise< { reason?: string }> {
    if(!this._delete_manufacturing_equipment_flow) { 
      this._delete_manufacturing_equipment_flow = this.injector.get(Manufacturing_delete_manufacturing_equipment_flowService);
    }
    return this._delete_manufacturing_equipment_flow.run(inParams);
  }
   
   

   
 
  private _delete_manufacturing_order_line_components_flow: Manufacturing_delete_manufacturing_order_line_components_flowService;
  public async delete_manufacturing_order_line_components_flow(inParams: { order_line_id: number }): Promise<void> {
    if(!this._delete_manufacturing_order_line_components_flow) { 
      this._delete_manufacturing_order_line_components_flow = this.injector.get(Manufacturing_delete_manufacturing_order_line_components_flowService);
    }
    return this._delete_manufacturing_order_line_components_flow.run(inParams);
  }
   
   

   
 
  private _delete_manufacturing_order_lines_flow: Manufacturing_delete_manufacturing_order_lines_flowService;
  public async delete_manufacturing_order_lines_flow(inParams: { order_id: number, line_number: number }): Promise< { reason?: string }> {
    if(!this._delete_manufacturing_order_lines_flow) { 
      this._delete_manufacturing_order_lines_flow = this.injector.get(Manufacturing_delete_manufacturing_order_lines_flowService);
    }
    return this._delete_manufacturing_order_lines_flow.run(inParams);
  }
   
   

   
 
  private _delete_manufacturing_workshifts_flow: Manufacturing_delete_manufacturing_workshifts_flowService;
  public async delete_manufacturing_workshifts_flow(inParams: { workshift_id?: number }): Promise< { reason?: string }> {
    if(!this._delete_manufacturing_workshifts_flow) { 
      this._delete_manufacturing_workshifts_flow = this.injector.get(Manufacturing_delete_manufacturing_workshifts_flowService);
    }
    return this._delete_manufacturing_workshifts_flow.run(inParams);
  }
   
   

   
 
  private _delete_plan: Manufacturing_delete_planService;
  public async delete_plan(inParams: { plan_id: string }): Promise<void> {
    if(!this._delete_plan) { 
      this._delete_plan = this.injector.get(Manufacturing_delete_planService);
    }
    return this._delete_plan.run(inParams);
  }
   
   

   
 
  private _get_material_default_packaging: Manufacturing_get_material_default_packagingService;
  public async get_material_default_packaging(inParams: { material_id: number }): Promise< { packagingId?: number }> {
    if(!this._get_material_default_packaging) { 
      this._get_material_default_packaging = this.injector.get(Manufacturing_get_material_default_packagingService);
    }
    return this._get_material_default_packaging.run(inParams);
  }
   
   

   
 
  private _get_material_packaging_decimal_precision_formatted: Manufacturing_get_material_packaging_decimal_precision_formattedService;
  public async get_material_packaging_decimal_precision_formatted(inParams: { material_id: number, packaging_id: number }): Promise< { format?: string }> {
    if(!this._get_material_packaging_decimal_precision_formatted) { 
      this._get_material_packaging_decimal_precision_formatted = this.injector.get(Manufacturing_get_material_packaging_decimal_precision_formattedService);
    }
    return this._get_material_packaging_decimal_precision_formatted.run(inParams);
  }
   
   

   
 
  private _get_plan: Manufacturing_get_planService;
  public async get_plan(inParams: { plan_id?: string }): Promise< { name?: string, template_order_id?: number, offset_in_days?: number, total_runs?: number, plan_id?: string, is_active?: boolean, schedule?: any }> {
    if(!this._get_plan) { 
      this._get_plan = this.injector.get(Manufacturing_get_planService);
    }
    return this._get_plan.run(inParams);
  }
   
   

   
 
  private _get_total_minutes: Manufacturing_get_total_minutesService;
  public async get_total_minutes(inParams: { time: string }): Promise< { totalMinutes: number }> {
    if(!this._get_total_minutes) { 
      this._get_total_minutes = this.injector.get(Manufacturing_get_total_minutesService);
    }
    return this._get_total_minutes.run(inParams);
  }
   
   

   
 
  private _is_manufacturing_order_cancelable_flow: Manufacturing_is_manufacturing_order_cancelable_flowService;
  public async is_manufacturing_order_cancelable_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._is_manufacturing_order_cancelable_flow) { 
      this._is_manufacturing_order_cancelable_flow = this.injector.get(Manufacturing_is_manufacturing_order_cancelable_flowService);
    }
    return this._is_manufacturing_order_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _minutes_between_times: Manufacturing_minutes_between_timesService;
  public async minutes_between_times(inParams: { start_time: string, end_time: string }): Promise< { minutes?: number }> {
    if(!this._minutes_between_times) { 
      this._minutes_between_times = this.injector.get(Manufacturing_minutes_between_timesService);
    }
    return this._minutes_between_times.run(inParams);
  }
   
   

   
 
  private _round_to_first_decimal_digit: Manufacturing_round_to_first_decimal_digitService;
  public async round_to_first_decimal_digit(inParams: { num: number }): Promise< { roundedNum: number }> {
    if(!this._round_to_first_decimal_digit) { 
      this._round_to_first_decimal_digit = this.injector.get(Manufacturing_round_to_first_decimal_digitService);
    }
    return this._round_to_first_decimal_digit.run(inParams);
  }
   
   

   
}
