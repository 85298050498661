<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="return_to_stock" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-finished_good" *ngIf="!fields.finished_good.hidden && !fields.finished_good.removed" 
                            class="field-container standard {{fields.finished_good.invalid ? 'invalid' : ''}} {{fields.finished_good.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.finished_good.styles.style"
                            [ngClass]="fields.finished_good.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.finished_good.label + (fields.finished_good.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.finished_good.label}}<span *ngIf="fields.finished_good.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.finished_good.control.styles.style"
                              [ngClass]="fields.finished_good.control.styles.classes"
                              [matTooltip]="fields.finished_good.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.finished_good.control.text }}</div>
                        <ng-container *ngIf="fields.finished_good.invalid">
                          <ng-container *ngFor="let error of fields.finished_good.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!fields.location.hidden && !fields.location.removed" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}} {{fields.location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.location.label + (fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.location.label}}<span *ngIf="fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-manufacturing_staging_locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                            [tooltip]="fields.location.control.tooltip"
                          [warehouse_id]="$fields_location_selector_inParams_warehouse_id"
                          [project_id]="$fields_location_selector_inParams_project_id"
                        >
                        </Manufacturing-manufacturing_staging_locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.components.hasTabToShow" class="tab" data-cy="tab-components">
              <h2 [className]="tabs.components.active? 'active': ''" (click)="tabs.components.activate()">{{tabs.components.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-manufacturing_order_return_to_stock_grid *ngIf="tabs.components.active"
              #$tabs_components
              [manufacturing_order_line_id]="$tabs_components_manufacturing_order_return_to_stock_grid_inParams_manufacturing_order_line_id"
              [warehouse_id]="$tabs_components_manufacturing_order_return_to_stock_grid_inParams_warehouse_id"
              [location_id]="$tabs_components_manufacturing_order_return_to_stock_grid_inParams_location_id"
              [manufacturing_order_id]="$tabs_components_manufacturing_order_return_to_stock_grid_inParams_manufacturing_order_id"
              [task_chain_head]="$tabs_components_manufacturing_order_return_to_stock_grid_inParams_task_chain_head"
              ($refreshEvent)="refresh(false, false, '$tabs_components')">
              </Manufacturing-manufacturing_order_return_to_stock_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>