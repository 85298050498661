import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_serialnumbers_creation_grid_ComponentContextService } from './FootprintManager.serialnumbers_creation_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { SerialNumbers_measurement_units_dd_singleComponent } from './SerialNumbers.measurement_units_dd_single.component'


interface IFootprintManager_serialnumbers_creation_gridComponentEntity {
  id?: number, Weight?: number, ShippingWeight?: number, WeightUomId?: number, Width?: number, Height?: number, Length?: number, DimensionUomId?: number, Volume?: number, ShippingVolume?: number, VolumeUomId?: number, DimensionsUom_Short_name?: string, WeightUom_Short_name?: string, VolumeUom_Short_name?: string, Packaging_Short_name?: string, Sub_packagingId?: number, Sub_Packaging_Amount?: number}

interface IFootprintManager_serialnumbers_creation_gridComponentInParams {
  materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number}

interface IFootprintManager_serialnumbers_creation_gridComponentOutParams {
  serialNumbers?: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[], confirm?: boolean}

class FootprintManager_serialnumbers_creation_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_serialnumbers_creation_gridComponent;
  entity: IFootprintManager_serialnumbers_creation_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    serial_lookupcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    net_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    gross_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    uom_weight_unit_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    length_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    width_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    uom_dimensions_unit_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    serial_lookupcode: null,
    net_weight: null,
    gross_weight: null,
    uom_weight_unit: null,
    length: null,
    width: null,
    height: null,
    uom_dimensions_unit: null,
  }

  get $fields_uom_weight_unit_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_uom_dimensions_unit_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_serialnumbers_creation_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.serial_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.serial_lookupcode,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['serial_lookupcode_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.net_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.net_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['net_weight_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.gross_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.gross_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['gross_weight_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.uom_weight_unit = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.uom_weight_unit,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['uom_weight_unit_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.length = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.length,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['length_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.width = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.width,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['width_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.height = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.height,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['height_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.uom_dimensions_unit = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.uom_dimensions_unit,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['uom_dimensions_unit_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_serialnumbers_creation_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_serialnumbers_creation_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      rows:  $grid.inParams.packagedAmount ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
    };
    const data = await this.$datasources.SerialNumbers.ds_serialnumbers_expand.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_serialnumbers_creation_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    
    (this.cells.net_weight.displayControl as TextModel).text = $utils.isDefined($grid.inParams.averageNet) ? $grid.inParams.averageNet.toString() :$row.entity.Weight?.toString();
    (this.cells.net_weight.editControl as NumberBoxModel).reset($utils.isDefined($grid.inParams.averageNet) ? $grid.inParams.averageNet :$row.entity.Weight);
    (this.cells.gross_weight.displayControl as TextModel).text = $utils.isDefined($grid.inParams.averageGross) ? $grid.inParams.averageGross.toString() :$row.entity.ShippingWeight?.toString();
    (this.cells.gross_weight.editControl as NumberBoxModel).reset($utils.isDefined($grid.inParams.averageGross) ? $grid.inParams.averageGross :$row.entity.ShippingWeight);
    (this.cells.uom_weight_unit.displayControl as TextModel).text = $utils.isDefined($grid.vars.weightUomShortName) ? $grid.vars.weightUomShortName :$row.entity.WeightUom_Short_name;
    (this.cells.uom_weight_unit.editControl as SelectBoxModel).reset($utils.isDefined($grid.inParams.weightUomId) ? $grid.inParams.weightUomId : $row.entity.WeightUomId);
    (this.cells.length.displayControl as TextModel).text = $utils.isDefined($grid.inParams.averageLength) ? $grid.inParams.averageLength.toString() :$row.entity.Length?.toString();
    (this.cells.length.editControl as NumberBoxModel).reset($utils.isDefined($grid.inParams.averageLength) ? $grid.inParams.averageLength :$row.entity.Length);
    (this.cells.width.displayControl as TextModel).text = $utils.isDefined($grid.inParams.averageWidth) ? $grid.inParams.averageWidth.toString() :$row.entity.Width?.toString();
    (this.cells.width.editControl as NumberBoxModel).reset($utils.isDefined($grid.inParams.averageWidth) ? $grid.inParams.averageWidth :$row.entity.Width);
    (this.cells.height.displayControl as TextModel).text = $utils.isDefined($grid.inParams.averageHeight) ? $grid.inParams.averageHeight.toString() :$row.entity.Height?.toString();
    (this.cells.height.editControl as NumberBoxModel).reset($utils.isDefined($grid.inParams.averageHeight) ? $grid.inParams.averageHeight :$row.entity.Height);
    (this.cells.uom_dimensions_unit.displayControl as TextModel).text = $utils.isDefined($grid.vars.dimensionUomShortName) ? $grid.vars.dimensionUomShortName :$row.entity.DimensionsUom_Short_name;
    (this.cells.uom_dimensions_unit.editControl as SelectBoxModel).reset($utils.isDefined($grid.inParams.dimensionUomId) ? $grid.inParams.dimensionUomId : $row.entity.DimensionUomId);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_serialnumbers_creation_gridComponentRowModel,
  $grid: FootprintManager_serialnumbers_creation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  var isFixedWeight = $grid.vars.isFixedWeight;
  var isFixedLength = $grid.vars.isFixedLength;
  
  
  if (isFixedWeight) {
      $row.cells.gross_weight.editControl.readOnly = true;
      $row.cells.net_weight.editControl.readOnly = true;
      $row.cells.uom_weight_unit.editControl.readOnly = true;
      $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
      $row.cells.net_weight.editControl.styles.setStyle('background', 'transparent');
      $row.cells.uom_weight_unit.editControl.styles.setStyle('background', 'transparent');
  
      $grid.topToolbar.separator1.hidden = true;
      $grid.topToolbar.average_weights.hidden = true;
  }
  
  if (isFixedLength) {
      $row.cells.length.editControl.readOnly = true;
      $row.cells.width.editControl.readOnly = true;
      $row.cells.height.editControl.readOnly = true;
      $row.cells.uom_dimensions_unit.editControl.readOnly = true;
  
  }
  
  
  const lookupcode = 'SI' + $row.entity.id.toString() + '-' + Math.floor(100000 + Math.random() * 900000)
  
  
  
  $row.cells.serial_lookupcode.displayControl.text = lookupcode;
  $row.cells.serial_lookupcode.editControl.value = lookupcode;
  
  $grid.topToolbar.confirm.control.readOnly = false;
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_serialnumbers_creation_gridComponentRowModel,
  $grid: FootprintManager_serialnumbers_creation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.serial_lookupcode.editControl.isChanged) {
  
      $row.cells.serial_lookupcode.displayControl.text = $row.cells.serial_lookupcode.editControl.value;
  }
  
  if ($row.cells.net_weight.editControl.isChanged) {
  
      $row.cells.net_weight.displayControl.text = $row.cells.net_weight.editControl.value;
  }
  
  if ($row.cells.gross_weight.editControl.isChanged) {
  
      $row.cells.gross_weight.displayControl.text = $row.cells.gross_weight.editControl.value;
  }
  
  if ($row.cells.uom_weight_unit.editControl.isChanged) {
  
      const measurementUnit = ( await $datasources.SerialNumbers.ds_get_measurement_unit_by_unitId.get({unitId: $row.cells.uom_weight_unit.editControl.value })).result;
      $row.cells.uom_weight_unit.displayControl.text = measurementUnit[0].Short_name;
  }
  
  if ($row.cells.length.editControl.isChanged) {
  
      $row.cells.length.displayControl.text = $row.cells.length.editControl.value;
  }
  
  if ($row.cells.width.editControl.isChanged) {
  
      $row.cells.width.displayControl.text = $row.cells.width.editControl.value;
  }
  
  if ($row.cells.height.editControl.isChanged) {
  
      $row.cells.height.displayControl.text = $row.cells.height.editControl.value;
  }
  
  if ($row.cells.uom_dimensions_unit.editControl.isChanged) {
  
      const measurementUnit = ( await $datasources.SerialNumbers.ds_get_measurement_unit_by_unitId.get({unitId: $row.cells.uom_dimensions_unit.editControl.value })).result;
      $row.cells.uom_dimensions_unit.displayControl.text = measurementUnit[0].Short_name;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SerialNumbers_measurement_units_dd_singleComponent),
  ],
  selector: 'FootprintManager-serialnumbers_creation_grid',
  templateUrl: './FootprintManager.serialnumbers_creation_grid.component.html'
})
export class FootprintManager_serialnumbers_creation_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_serialnumbers_creation_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootprintManager_serialnumbers_creation_gridComponentInParams = { materialId: null, packagingId: null, packagedAmount: null, averageGross: null, averageNet: null, weightUomId: null, averageLength: null, averageWidth: null, averageHeight: null, dimensionUomId: null, lotId: null, licensePlateId: null };

  outParams: IFootprintManager_serialnumbers_creation_gridComponentOutParams = { serialNumbers: [], confirm: null };

  //#region Variables
  vars: { weightUomShortName?: string, isFixedWeight?: boolean, isFixedLength?: boolean, dimensionUomShortName?: string, tareWeight?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ serialNumbers?: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[], confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     serial_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'serial_lookupcode', 'Serial number', false, false, null, false),       net_weight: new GridHeaderModel(new HeaderStyles(null, null), 'net_weight', 'Net', false, false, null, false),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'gross_weight', 'Gross', false, false, null, false),       uom_weight_unit: new GridHeaderModel(new HeaderStyles(null, null), 'uom_weight_unit', 'UOM', false, false, null, false),       length: new GridHeaderModel(new HeaderStyles(null, null), 'length', 'L', false, false, null, false),       width: new GridHeaderModel(new HeaderStyles(null, null), 'width', 'W', false, false, null, false),       height: new GridHeaderModel(new HeaderStyles(null, null), 'height', 'H', false, false, null, false),       uom_dimensions_unit: new GridHeaderModel(new HeaderStyles(null, null), 'uom_dimensions_unit', 'UOM', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_serialnumbers_creation_gridComponentRowModel[] = [];

  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('packagingId') set $inParams_packagingId(value: any) {
    this.inParams['packagingId'] = value;
  }
  get $inParams_packagingId(): any {
    return this.inParams['packagingId'] ;
  }
  @Input('packagedAmount') set $inParams_packagedAmount(value: any) {
    this.inParams['packagedAmount'] = value;
  }
  get $inParams_packagedAmount(): any {
    return this.inParams['packagedAmount'] ;
  }
  @Input('averageGross') set $inParams_averageGross(value: any) {
    this.inParams['averageGross'] = value;
  }
  get $inParams_averageGross(): any {
    return this.inParams['averageGross'] ;
  }
  @Input('averageNet') set $inParams_averageNet(value: any) {
    this.inParams['averageNet'] = value;
  }
  get $inParams_averageNet(): any {
    return this.inParams['averageNet'] ;
  }
  @Input('weightUomId') set $inParams_weightUomId(value: any) {
    this.inParams['weightUomId'] = value;
  }
  get $inParams_weightUomId(): any {
    return this.inParams['weightUomId'] ;
  }
  @Input('averageLength') set $inParams_averageLength(value: any) {
    this.inParams['averageLength'] = value;
  }
  get $inParams_averageLength(): any {
    return this.inParams['averageLength'] ;
  }
  @Input('averageWidth') set $inParams_averageWidth(value: any) {
    this.inParams['averageWidth'] = value;
  }
  get $inParams_averageWidth(): any {
    return this.inParams['averageWidth'] ;
  }
  @Input('averageHeight') set $inParams_averageHeight(value: any) {
    this.inParams['averageHeight'] = value;
  }
  get $inParams_averageHeight(): any {
    return this.inParams['averageHeight'] ;
  }
  @Input('dimensionUomId') set $inParams_dimensionUomId(value: any) {
    this.inParams['dimensionUomId'] = value;
  }
  get $inParams_dimensionUomId(): any {
    return this.inParams['dimensionUomId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('licensePlateId') set $inParams_licensePlateId(value: any) {
    this.inParams['licensePlateId'] = value;
  }
  get $inParams_licensePlateId(): any {
    return this.inParams['licensePlateId'] ;
  }

  topToolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      average_weights: new ToolModel(new ButtonModel('average_weights', new ButtonStyles(null, null), false, false, false, 'Distribute total weight', 'icon-ic_fluent_scales_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Create serial numbers';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.packagedAmount)) {
        this.$missingRequiredInParams.push('packagedAmount');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 1000;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      rows:  $grid.inParams.packagedAmount ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
    };
    try {
      const data = await this.$datasources.SerialNumbers.ds_serialnumbers_expand.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_serialnumbers_creation_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_serialnumbers_creation_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootprintManager_serialnumbers_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.outParams.serialNumbers = null;
  $grid.outParams.confirm = false;
  $grid.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $grid: FootprintManager_serialnumbers_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  $grid.outParams.serialNumbers = [];
  
  for (let i = 0; i < $grid.rows.length; i++) {
      $grid.outParams.serialNumbers.push(
          {
              LookupCode: $grid.rows[i].cells.serial_lookupcode.editControl.value,
              NetWeightValue: $grid.rows[i].cells.net_weight.editControl.value,
              GrossWeightValue: $grid.rows[i].cells.gross_weight.editControl.value,
              WeightUOM: $grid.rows[i].cells.uom_weight_unit.editControl.value,
              Width: $grid.rows[i].cells.width.editControl.value,
              Height: $grid.rows[i].cells.height.editControl.value,
              Length: $grid.rows[i].cells.length.editControl.value,
              DimensionUOM: $grid.rows[i].cells.uom_dimensions_unit.editControl.value,
              NetVolumeValue: $grid.rows[i].entity.Volume,
              GrossVolumeValue: $grid.rows[i].entity.ShippingVolume,
              VolumeUOM: $grid.rows[i].entity.VolumeUomId
          }
      )
  }
  
  $grid.outParams.confirm = true;
  $grid.close();
  
  
  
  
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_serialnumbers_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.outParams.confirm = false;
  $grid.topToolbar.confirm.control.readOnly = true;
  
  if ($utils.isDefined($grid.inParams.weightUomId)) {
      const weightUom = (await $datasources.SerialNumbers.ds_get_measurement_unit_by_unitId.get({
          unitId: $grid.inParams.weightUomId
      })).result
  
      if ($utils.isDefined(weightUom)) {
  
          $grid.vars.weightUomShortName = weightUom[0].Short_name;
  
      }
  }
  
  if ($utils.isDefined($grid.inParams.dimensionUomId)) {
      const dimensionUom = (await $datasources.SerialNumbers.ds_get_measurement_unit_by_unitId.get({
          unitId: $grid.inParams.dimensionUomId
      })).result
  
      if ($utils.isDefined(dimensionUom)) {
  
          $grid.vars.dimensionUomShortName = dimensionUom[0].Short_name;
  
      }
  }
  
  const materials = (await $datasources.SerialNumbers.ds_get_material_by_materialId.get({
      materialId: $grid.inParams.materialId
  })).result;
  if ($utils.isDefined(materials)) {
      let packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materials[0].Id, 
          packagingId: $grid.inParams.packagingId 
      })).result[0];
  
      $grid.vars.isFixedWeight = materials[0].IsFixedWeight;
      $grid.vars.isFixedLength = materials[0].IsFixedLength;
      $grid.vars.tareWeight = packaging.ShippingWeight - packaging.Weight;
  
      if ($grid.vars.isFixedWeight) {
          $grid.topToolbar.average_weights.hidden = true;
          $grid.topToolbar.separator1.hidden = true;
      } else {
          $grid.topToolbar.average_weights.hidden = false;
          $grid.topToolbar.separator1.hidden = false;
      }
      
  } else {
      throw new Error('Unable to determine material properties.')
  }
  }
  on_apply_weight_clicked(event = null) {
    return this.on_apply_weight_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_apply_weight_clickedInternal(
    $grid: FootprintManager_serialnumbers_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Get new weight to distribute
      let result = await $shell.FootprintManager.openget_average_serial_weights_formDialog({
          serial_numbers: $grid.rows.map(row => ({ id: null, lookup: row.cells.serial_lookupcode.editControl.value, net_weight: row.cells.net_weight.editControl.value, gross_weight: row.cells.gross_weight.editControl.value })),
          weight_uom_id: $grid.rows[0].entity.WeightUomId
          }, 'modal');
  
      if (!$utils.isDefined(result.serial_numbers)) { return; }
  
      // Set weight values
      for (let row of $grid.rows) {
          let match = result.serial_numbers.find(sn => sn.lookup.trim().toUpperCase() === row.cells.serial_lookupcode.editControl.value.trim().toUpperCase());
  
          if (!$utils.isDefined(match)) {
              throw new Error(`Failed to get weight for serial number ${row.cells.serial_lookupcode.editControl.value}`);
          }
  
          setNetWeight(row, match.net_weight);
          setGrossWeight(row, match.gross_weight);
          
          row.entity.WeightUomId = result.weight_uom_id;
          row.refresh();
      }
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error averaging weights', targetError.message);
  }
  
  function setNetWeight(row: typeof $grid.rows[0], weight: number) {
      weight = Math.max(0, weight);
  
      row.cells.net_weight.editControl.value = weight;
      row.cells.net_weight.displayControl.text = weight.toString();
      row.entity.Weight = weight;
  }
  function setGrossWeight(row: typeof $grid.rows[0], weight: number) {
      weight = Math.max(0, weight);
      
      row.cells.gross_weight.editControl.value = weight;
      row.cells.gross_weight.displayControl.text = weight.toString();
      row.entity.ShippingWeight = weight;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
