import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Manufacturing_manufacturing_library_homeComponent } from './Manufacturing.manufacturing_library_home.component';
import { Manufacturing_manufacturing_order_formComponent } from './Manufacturing.manufacturing_order_form.component';
import { Manufacturing_manufacturing_order_line_multiple_substitutions_swap_formComponent } from './Manufacturing.manufacturing_order_line_multiple_substitutions_swap_form.component';
import { Manufacturing_manufacturing_order_line_production_confirmation_setup_formComponent } from './Manufacturing.manufacturing_order_line_production_confirmation_setup_form.component';
import { Manufacturing_manufacturing_order_line_substitution_swap_formComponent } from './Manufacturing.manufacturing_order_line_substitution_swap_form.component';
import { Manufacturing_plan_formComponent } from './Manufacturing.plan_form.component';
import { Manufacturing_workshift_formComponent } from './Manufacturing.workshift_form.component';
import { Manufacturing_manufacturing_order_components_serial_numbers_pick_editorComponent } from './Manufacturing.manufacturing_order_components_serial_numbers_pick_editor.component';
import { Manufacturing_manufacturing_order_editorComponent } from './Manufacturing.manufacturing_order_editor.component';
import { Manufacturing_manufacturing_order_return_to_stock_editorComponent } from './Manufacturing.manufacturing_order_return_to_stock_editor.component';
import { Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent } from './Manufacturing.manufacturing_orders_create_serial_numbers_editor.component';
import { Manufacturing_plan_editorComponent } from './Manufacturing.plan_editor.component';
import { Manufacturing_workshift_editorComponent } from './Manufacturing.workshift_editor.component';
import { Manufacturing_manufacturing_equipment_gridComponent } from './Manufacturing.manufacturing_equipment_grid.component';
import { Manufacturing_manufacturing_locations_gridComponent } from './Manufacturing.manufacturing_locations_grid.component';
import { Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent } from './Manufacturing.manufacturing_order_component_serial_number_selection_grid.component';
import { Manufacturing_manufacturing_order_line_components_production_confirmation_gridComponent } from './Manufacturing.manufacturing_order_line_components_production_confirmation_grid.component';
import { Manufacturing_manufacturing_order_line_production_confirmation_gridComponent } from './Manufacturing.manufacturing_order_line_production_confirmation_grid.component';
import { Manufacturing_manufacturing_order_lines_components_gridComponent } from './Manufacturing.manufacturing_order_lines_components_grid.component';
import { Manufacturing_manufacturing_order_lines_gridComponent } from './Manufacturing.manufacturing_order_lines_grid.component';
import { Manufacturing_manufacturing_order_lines_production_sub_components_gridComponent } from './Manufacturing.manufacturing_order_lines_production_sub_components_grid.component';
import { Manufacturing_manufacturing_order_return_to_stock_gridComponent } from './Manufacturing.manufacturing_order_return_to_stock_grid.component';
import { Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent } from './Manufacturing.manufacturing_order_undo_manufacturing_production_components_grid.component';
import { Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent } from './Manufacturing.manufacturing_order_undo_manufacturing_production_finished_product_grid.component';
import { Manufacturing_manufacturing_orders_gridComponent } from './Manufacturing.manufacturing_orders_grid.component';
import { Manufacturing_manufacturing_workshifts_gridComponent } from './Manufacturing.manufacturing_workshifts_grid.component';
import { Manufacturing_plans_gridComponent } from './Manufacturing.plans_grid.component';
import { Manufacturing_serialnumbers_creation_gridComponent } from './Manufacturing.serialnumbers_creation_grid.component';
import { Manufacturing_manufacturing_configuration_hubComponent } from './Manufacturing.manufacturing_configuration_hub.component';
import { Manufacturing_manufacturing_locations_hubComponent } from './Manufacturing.manufacturing_locations_hub.component';
import { Manufacturing_manufacturing_order_confirm_finished_productComponent } from './Manufacturing.manufacturing_order_confirm_finished_product.component';
import { Manufacturing_manufacturing_orders_hubComponent } from './Manufacturing.manufacturing_orders_hub.component';
import { Manufacturing_manufacturing_orders_calendarComponent } from './Manufacturing.manufacturing_orders_calendar.component';
import { Manufacturing_manufacturing_order_production_confirmation_undo_wizardComponent } from './Manufacturing.manufacturing_order_production_confirmation_undo_wizard.component';
import { Manufacturing_manufacturing_order_production_confirmation_wizardComponent } from './Manufacturing.manufacturing_order_production_confirmation_wizard.component';
import { Manufacturing_manufacturing_order_reportComponent } from './Manufacturing.manufacturing_order_report.component';
import { Manufacturing_testRepComponent } from './Manufacturing.testRep.component';
import { Manufacturing_days_dd_singleComponent } from './Manufacturing.days_dd_single.component';
import { Manufacturing_license_plates_by_location_with_inventory_singleComponent } from './Manufacturing.license_plates_by_location_with_inventory_single.component';
import { Manufacturing_licenseplates_by_location_singleComponent } from './Manufacturing.licenseplates_by_location_single.component';
import { Manufacturing_lots_by_material_ids_singleComponent } from './Manufacturing.lots_by_material_ids_single.component';
import { Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_single.component';
import { Manufacturing_manufacturing_order_date_types_dd_singleComponent } from './Manufacturing.manufacturing_order_date_types_dd_single.component';
import { Manufacturing_manufacturing_orders_equipment_dd_singleComponent } from './Manufacturing.manufacturing_orders_equipment_dd_single.component';
import { Manufacturing_manufacturing_orders_workshifts_dd_singleComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_single.component';
import { Manufacturing_manufacturing_output_locations_dd_singleComponent } from './Manufacturing.manufacturing_output_locations_dd_single.component';
import { Manufacturing_manufacturing_staging_locations_dd_singleComponent } from './Manufacturing.manufacturing_staging_locations_dd_single.component';
import { Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent } from './Manufacturing.manufacturnig_order_production_confirmation_dd_single.component';
import { Manufacturing_material_packagings_dd_singleComponent } from './Manufacturing.material_packagings_dd_single.component';
import { Manufacturing_material_substitutions_dd_singleComponent } from './Manufacturing.material_substitutions_dd_single.component';
import { Manufacturing_materials_by_project_dd_singleComponent } from './Manufacturing.materials_by_project_dd_single.component';
import { Manufacturing_measurement_units_dd_singleComponent } from './Manufacturing.measurement_units_dd_single.component';
import { Manufacturing_order_statuses_dd_singleComponent } from './Manufacturing.order_statuses_dd_single.component';
import { Manufacturing_orders_dd_singleComponent } from './Manufacturing.orders_dd_single.component';
import { Manufacturing_owners_dd_singleComponent } from './Manufacturing.owners_dd_single.component';
import { Manufacturing_projects_dd_singleComponent } from './Manufacturing.projects_dd_single.component';
import { Manufacturing_time_selector_dd_singleComponent } from './Manufacturing.time_selector_dd_single.component';
import { Manufacturing_warehouse_dd_singleComponent } from './Manufacturing.warehouse_dd_single.component';
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component';
import { Manufacturing_days_dd_multiComponent } from './Manufacturing.days_dd_multi.component';
import { Manufacturing_license_plates_by_location_with_inventory_multiComponent } from './Manufacturing.license_plates_by_location_with_inventory_multi.component';
import { Manufacturing_licenseplates_by_location_multiComponent } from './Manufacturing.licenseplates_by_location_multi.component';
import { Manufacturing_lots_by_material_ids_multiComponent } from './Manufacturing.lots_by_material_ids_multi.component';
import { Manufacturing_lots_with_inventory_by_license_plate_dd_multiComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_multi.component';
import { Manufacturing_manufacturing_order_date_types_dd_multiComponent } from './Manufacturing.manufacturing_order_date_types_dd_multi.component';
import { Manufacturing_manufacturing_orders_equipment_dd_multiComponent } from './Manufacturing.manufacturing_orders_equipment_dd_multi.component';
import { Manufacturing_manufacturing_orders_workshifts_dd_multiComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_multi.component';
import { Manufacturing_manufacturing_output_locations_dd_multiComponent } from './Manufacturing.manufacturing_output_locations_dd_multi.component';
import { Manufacturing_manufacturing_staging_locations_dd_multiComponent } from './Manufacturing.manufacturing_staging_locations_dd_multi.component';
import { Manufacturing_manufacturnig_order_production_confirmation_dd_multiComponent } from './Manufacturing.manufacturnig_order_production_confirmation_dd_multi.component';
import { Manufacturing_material_packagings_dd_multiComponent } from './Manufacturing.material_packagings_dd_multi.component';
import { Manufacturing_material_substitutions_dd_multiComponent } from './Manufacturing.material_substitutions_dd_multi.component';
import { Manufacturing_materials_by_project_dd_multiComponent } from './Manufacturing.materials_by_project_dd_multi.component';
import { Manufacturing_measurement_units_dd_multiComponent } from './Manufacturing.measurement_units_dd_multi.component';
import { Manufacturing_order_statuses_dd_multiComponent } from './Manufacturing.order_statuses_dd_multi.component';
import { Manufacturing_orders_dd_multiComponent } from './Manufacturing.orders_dd_multi.component';
import { Manufacturing_owners_dd_multiComponent } from './Manufacturing.owners_dd_multi.component';
import { Manufacturing_projects_dd_multiComponent } from './Manufacturing.projects_dd_multi.component';
import { Manufacturing_time_selector_dd_multiComponent } from './Manufacturing.time_selector_dd_multi.component';
import { Manufacturing_warehouse_dd_multiComponent } from './Manufacturing.warehouse_dd_multi.component';
import { Manufacturing_warehouses_dd_multiComponent } from './Manufacturing.warehouses_dd_multi.component';
import { Manufacturing_pending_confirmation_widgetComponent } from './Manufacturing.pending_confirmation_widget.component';
import { Manufacturing_scheduled_for_today_widgetComponent } from './Manufacturing.scheduled_for_today_widget.component';
import { Manufacturing_manufacturing_orders_for_calendar_cardComponent } from './Manufacturing.manufacturing_orders_for_calendar_card.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Manufacturing: Manufacturing_ShellService = this;

  public openmanufacturing_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Manufacturing_manufacturing_library_home',
        component: Manufacturing_manufacturing_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openmanufacturing_order_form(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_form',
        referenceName: 'Manufacturing_manufacturing_order_form',
        component: Manufacturing_manufacturing_order_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_order_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_formComponent,
      'manufacturing_order_form',
      mode,
      dialogSize
    )
  }
  public openmanufacturing_order_line_multiple_substitutions_swap_form(inParams:{ material_name?: string, material_packaging?: string, material_amount?: number, substitution_ids?: number[], material_ids?: number[], warehouse_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_multiple_substitutions_swap_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_line_multiple_substitutions_swap_form',
        referenceName: 'Manufacturing_manufacturing_order_line_multiple_substitutions_swap_form',
        component: Manufacturing_manufacturing_order_line_multiple_substitutions_swap_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_line_multiple_substitutions_swap_formDialog(
    inParams:{ material_name?: string, material_packaging?: string, material_amount?: number, substitution_ids?: number[], material_ids?: number[], warehouse_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ material_swapped?: boolean, subtitution_id?: number }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_multiple_substitutions_swap_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_line_multiple_substitutions_swap_formComponent,
      'manufacturing_order_line_multiple_substitutions_swap_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_line_production_confirmation_setup_form(inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_setup_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_line_production_confirmation_setup_form',
        referenceName: 'Manufacturing_manufacturing_order_line_production_confirmation_setup_form',
        component: Manufacturing_manufacturing_order_line_production_confirmation_setup_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_line_production_confirmation_setup_formDialog(
    inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ workshift_id?: number, equipment_id?: number, output_location_id?: number }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_setup_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_line_production_confirmation_setup_formComponent,
      'manufacturing_order_line_production_confirmation_setup_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_line_substitution_swap_form(inParams:{ material_name?: string, material_packaging?: string, substitution_name?: string, substitution_packaging?: string, material_amount?: number, substitution_amount?: number, ratio?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_line_substitution_swap_form',
        referenceName: 'Manufacturing_manufacturing_order_line_substitution_swap_form',
        component: Manufacturing_manufacturing_order_line_substitution_swap_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_line_substitution_swap_formDialog(
    inParams:{ material_name?: string, material_packaging?: string, substitution_name?: string, substitution_packaging?: string, material_amount?: number, substitution_amount?: number, ratio?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ material_swapped?: boolean }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_line_substitution_swap_formComponent,
      'manufacturing_order_line_substitution_swap_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openplan_form(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'plan_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'plan_form',
        referenceName: 'Manufacturing_plan_form',
        component: Manufacturing_plan_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openplan_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'plan_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_plan_formComponent,
      'plan_form',
      mode,
      dialogSize
    )
  }
  public openworkshift_form(inParams:{ warehouse_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'workshift_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'workshift_form',
        referenceName: 'Manufacturing_workshift_form',
        component: Manufacturing_workshift_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openworkshift_formDialog(
    inParams:{ warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'workshift_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_workshift_formComponent,
      'workshift_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_components_serial_numbers_pick_editor(inParams:{ serial_controlled_materials?: number, material_ids?: { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[], warehouse_id?: number, iteration?: number, serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[], location?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_components_serial_numbers_pick_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Serial number selector',
        referenceName: 'Manufacturing_manufacturing_order_components_serial_numbers_pick_editor',
        component: Manufacturing_manufacturing_order_components_serial_numbers_pick_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_components_serial_numbers_pick_editorDialog(
    inParams:{ serial_controlled_materials?: number, material_ids?: { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[], warehouse_id?: number, iteration?: number, serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[], location?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_components_serial_numbers_pick_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_components_serial_numbers_pick_editorComponent,
      'Serial number selector',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_editor(inParams:{ manufacturing_order_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_editor',
        referenceName: 'Manufacturing_manufacturing_order_editor',
        component: Manufacturing_manufacturing_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_editorDialog(
    inParams:{ manufacturing_order_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_editorComponent,
      'manufacturing_order_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_return_to_stock_editor(inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, finished_good?: string, warehouse_id?: number, project_id?: number, task_chain_head?: number, location_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_return_to_stock',
        referenceName: 'Manufacturing_manufacturing_order_return_to_stock_editor',
        component: Manufacturing_manufacturing_order_return_to_stock_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_return_to_stock_editorDialog(
    inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, finished_good?: string, warehouse_id?: number, project_id?: number, task_chain_head?: number, location_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_return_to_stock_editorComponent,
      'manufacturing_order_return_to_stock',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_orders_create_serial_numbers_editor(inParams:{ material_id?: number, produced_quantity?: number, lot_id?: number, packaging_id?: number, license_plate_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Create new serials',
        referenceName: 'Manufacturing_manufacturing_orders_create_serial_numbers_editor',
        component: Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_orders_create_serial_numbers_editorDialog(
    inParams:{ material_id?: number, produced_quantity?: number, lot_id?: number, packaging_id?: number, license_plate_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serials?: { MaterialId?: number, LicensePlateId?: number, LotId?: number, GrossVolume?: number, GrossWeight?: number, VolumeUomId?: number, WeightUomId?: number, NetWeight?: number, NetVolume?: number, DimensionUomId?: number, Height?: number, Length?: number, PackagingId?: number, Width?: number, LookupCode?: string }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent,
      'Create new serials',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openplan_editor(inParams:{ plan_id?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'plan_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'plan_editor',
        referenceName: 'Manufacturing_plan_editor',
        component: Manufacturing_plan_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openplan_editorDialog(
    inParams:{ plan_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'plan_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_plan_editorComponent,
      'plan_editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openworkshift_editor(inParams:{ workshift_id: number, is_new: boolean }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'workshift_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Workshift Edit',
        referenceName: 'Manufacturing_workshift_editor',
        component: Manufacturing_workshift_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openworkshift_editorDialog(
    inParams:{ workshift_id: number, is_new: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'workshift_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_workshift_editorComponent,
      'Workshift Edit',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_equipment_grid(inParams:{ warehouse: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_equipment_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Equipment',
        referenceName: 'Manufacturing_manufacturing_equipment_grid',
        component: Manufacturing_manufacturing_equipment_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_equipment_gridDialog(
    inParams:{ warehouse: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_equipment_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_equipment_gridComponent,
      'Equipment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_locations_grid(inParams:{ warehouse_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_locations_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Locations',
        referenceName: 'Manufacturing_manufacturing_locations_grid',
        component: Manufacturing_manufacturing_locations_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_locations_gridDialog(
    inParams:{ warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_locations_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_locations_gridComponent,
      'Locations',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_component_serial_number_selection_grid(inParams:{ material_id?: number, warehouse_id?: number, serials_needed_count?: number, iteration?: number, license_plate?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_component_serial_number_selection_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_component_serial_number_selection_grid',
        referenceName: 'Manufacturing_manufacturing_order_component_serial_number_selection_grid',
        component: Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_component_serial_number_selection_gridDialog(
    inParams:{ material_id?: number, warehouse_id?: number, serials_needed_count?: number, iteration?: number, license_plate?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ valid_data?: boolean, iteration?: number }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_component_serial_number_selection_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent,
      'manufacturing_order_component_serial_number_selection_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_line_components_production_confirmation_grid(inParams:{ finished_good_id?: number, warehouse_id?: number, quantity_to_produce?: number, license_plate_ids?: number[], location_id?: number, project_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_components_production_confirmation_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_line_components_production_confirmation_grid',
        referenceName: 'Manufacturing_manufacturing_order_line_components_production_confirmation_grid',
        component: Manufacturing_manufacturing_order_line_components_production_confirmation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_line_components_production_confirmation_gridDialog(
    inParams:{ finished_good_id?: number, warehouse_id?: number, quantity_to_produce?: number, license_plate_ids?: number[], location_id?: number, project_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ data?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_components_production_confirmation_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_line_components_production_confirmation_gridComponent,
      'manufacturing_order_line_components_production_confirmation_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_line_production_confirmation_grid(inParams:{ finished_good?: string, planned_quantity?: number, material_id?: number, output_location_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_line_production_confirmation_grid',
        referenceName: 'Manufacturing_manufacturing_order_line_production_confirmation_grid',
        component: Manufacturing_manufacturing_order_line_production_confirmation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_line_production_confirmation_gridDialog(
    inParams:{ finished_good?: string, planned_quantity?: number, material_id?: number, output_location_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ license_plate_ids?: { amountToIncrease?: number, licensePlate?: number, lotId?: number, materialId?: number, packaging?: number }[], actual_produced_quantity?: number }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_line_production_confirmation_gridComponent,
      'manufacturing_order_line_production_confirmation_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_lines_components_grid(inParams:{ finished_good_id: number, warehouse_id: number, finished_good_quantity?: number, is_kit?: boolean, project_id: number, order_id: number, line_number: number, order_status_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Manufacturing Order Lines Components',
        referenceName: 'Manufacturing_manufacturing_order_lines_components_grid',
        component: Manufacturing_manufacturing_order_lines_components_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_lines_components_gridDialog(
    inParams:{ finished_good_id: number, warehouse_id: number, finished_good_quantity?: number, is_kit?: boolean, project_id: number, order_id: number, line_number: number, order_status_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_lines_components_gridComponent,
      'Manufacturing Order Lines Components',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_lines_grid(inParams:{ order_id: number, project_id: number, order_status_id: number, warehouse_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Manufacturing order lines',
        referenceName: 'Manufacturing_manufacturing_order_lines_grid',
        component: Manufacturing_manufacturing_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_lines_gridDialog(
    inParams:{ order_id: number, project_id: number, order_status_id: number, warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_lines_gridComponent,
      'Manufacturing order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_lines_production_sub_components_grid(inParams:{ material_id?: number, location_id?: number, component?: string, main_component_identifier?: number, rows?: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[], package_id?: number, project_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_production_sub_components_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_lines_production_sub_components_grid',
        referenceName: 'Manufacturing_manufacturing_order_lines_production_sub_components_grid',
        component: Manufacturing_manufacturing_order_lines_production_sub_components_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_lines_production_sub_components_gridDialog(
    inParams:{ material_id?: number, location_id?: number, component?: string, main_component_identifier?: number, rows?: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[], package_id?: number, project_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ rows?: { lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_lines_production_sub_components_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_lines_production_sub_components_gridComponent,
      'manufacturing_order_lines_production_sub_components_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_return_to_stock_grid(inParams:{ manufacturing_order_line_id?: number, warehouse_id?: number, location_id?: number, manufacturing_order_id?: number, task_chain_head?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Components',
        referenceName: 'Manufacturing_manufacturing_order_return_to_stock_grid',
        component: Manufacturing_manufacturing_order_return_to_stock_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_return_to_stock_gridDialog(
    inParams:{ manufacturing_order_line_id?: number, warehouse_id?: number, location_id?: number, manufacturing_order_id?: number, task_chain_head?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_return_to_stock_gridComponent,
      'Components',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_undo_manufacturing_production_components_grid(inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, chain_head_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_components_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_undo_manufacturing_production_components_grid',
        referenceName: 'Manufacturing_manufacturing_order_undo_manufacturing_production_components_grid',
        component: Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_undo_manufacturing_production_components_gridDialog(
    inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, chain_head_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ rows?: { material_id?: number, increase_quantity?: number, license_plate?: number, location?: number, lot?: number, packaging_id?: number }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_components_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent,
      'manufacturing_order_undo_manufacturing_production_components_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_undo_manufacturing_production_finished_product_grid(inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_finished_product_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_undo_manufacturing_production_finished_product_grid',
        referenceName: 'Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_grid',
        component: Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_undo_manufacturing_production_finished_product_gridDialog(
    inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ rows?: { material_id?: number, decrease_quantity?: number, license_plate?: number, location?: number, packaging_id?: number }[] }> {
    this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_finished_product_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent,
      'manufacturing_order_undo_manufacturing_production_finished_product_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_orders_grid(inParams:{ warehouse_ids?: number[], owner_id?: number, project_id?: number, status_ids?: number[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Orders',
        referenceName: 'Manufacturing_manufacturing_orders_grid',
        component: Manufacturing_manufacturing_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_orders_gridDialog(
    inParams:{ warehouse_ids?: number[], owner_id?: number, project_id?: number, status_ids?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_orders_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_orders_gridComponent,
      'Orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_workshifts_grid(inParams:{ warehouse: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_workshifts_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Workshifts',
        referenceName: 'Manufacturing_manufacturing_workshifts_grid',
        component: Manufacturing_manufacturing_workshifts_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_workshifts_gridDialog(
    inParams:{ warehouse: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_workshifts_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_workshifts_gridComponent,
      'Workshifts',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openplans_grid(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'plans_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Plans',
        referenceName: 'Manufacturing_plans_grid',
        component: Manufacturing_plans_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openplans_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'plans_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_plans_gridComponent,
      'Plans',
      mode,
      dialogSize
    )
  }
  public openserialnumbers_creation_grid(inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Create serial numbers',
        referenceName: 'Manufacturing_serialnumbers_creation_grid',
        component: Manufacturing_serialnumbers_creation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumbers_creation_gridDialog(
    inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serialNumbers?: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number, LicensePlateId?: number, LotId?: number }[], confirm?: boolean }> {
    this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_serialnumbers_creation_gridComponent,
      'Create serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_configuration_hub(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_configuration_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_configuration_hub',
        referenceName: 'Manufacturing_manufacturing_configuration_hub',
        component: Manufacturing_manufacturing_configuration_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_configuration_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_configuration_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_configuration_hubComponent,
      'manufacturing_configuration_hub',
      mode,
      dialogSize
    )
  }
  public openmanufacturing_locations_hub(inParams:{ warehouse_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_locations_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'locations_for_manufacturing_hub',
        referenceName: 'Manufacturing_manufacturing_locations_hub',
        component: Manufacturing_manufacturing_locations_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_locations_hubDialog(
    inParams:{ warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_locations_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_locations_hubComponent,
      'locations_for_manufacturing_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_confirm_finished_product(inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_confirm_finished_product');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_confirm_finished_product',
        referenceName: 'Manufacturing_manufacturing_order_confirm_finished_product',
        component: Manufacturing_manufacturing_order_confirm_finished_productComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_order_confirm_finished_productDialog(
    inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_confirm_finished_product');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_confirm_finished_productComponent,
      'manufacturing_order_confirm_finished_product',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_orders_hub(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_orders_hub',
        referenceName: 'Manufacturing_manufacturing_orders_hub',
        component: Manufacturing_manufacturing_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_orders_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_orders_hubComponent,
      'manufacturing_orders_hub',
      mode,
      dialogSize
    )
  }
  public openmanufacturing_orders_calendar(inParams:{ warehouse_id?: number, project_id?: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_orders_calendar',
        referenceName: 'Manufacturing_manufacturing_orders_calendar',
        component: Manufacturing_manufacturing_orders_calendarComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmanufacturing_orders_calendarDialog(
    inParams:{ warehouse_id?: number, project_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_orders_calendarComponent,
      'manufacturing_orders_calendar',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openmanufacturing_order_production_confirmation_undo_wizardDialog(
    inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number, warehouse_id?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_production_confirmation_undo_wizard');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_production_confirmation_undo_wizardComponent,
      'Undo production confirmation',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public openmanufacturing_order_production_confirmation_wizardDialog(
    inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, material_id?: number, finished_good?: string, finished_good_id?: number, expected_source_location?: number, manufacturing_order_id?: number, finished_product_is_serialized?: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_production_confirmation_wizard');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_production_confirmation_wizardComponent,
      'manufacturing_order_production_confirmation_wizard',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_report(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_report',
        referenceName: 'Manufacturing_manufacturing_order_report',
        component: Manufacturing_manufacturing_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_order_reportDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_reportComponent,
      'manufacturing_order_report',
      mode,
      dialogSize
    )
  }
  public opentestRep(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'testRep');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'testRep',
        referenceName: 'Manufacturing_testRep',
        component: Manufacturing_testRepComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentestRepDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'testRep');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_testRepComponent,
      'testRep',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Manufacturing_manufacturing_library_home') {
      this.$logger.log('Manufacturing', 'manufacturing_library_home');
      const title = 'Home';
      const component = Manufacturing_manufacturing_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_form') {
      this.$logger.log('Manufacturing', 'manufacturing_order_form');
      const title = 'manufacturing_order_form';
      const component = Manufacturing_manufacturing_order_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_line_multiple_substitutions_swap_form') {
      this.$logger.log('Manufacturing', 'manufacturing_order_line_multiple_substitutions_swap_form');
      const title = 'manufacturing_order_line_multiple_substitutions_swap_form';
      const component = Manufacturing_manufacturing_order_line_multiple_substitutions_swap_formComponent;
      const inParams:{ material_name?: string, material_packaging?: string, material_amount?: number, substitution_ids?: number[], material_ids?: number[], warehouse_id?: number } = { material_name: null, material_packaging: null, material_amount: null, substitution_ids: [], material_ids: [], warehouse_id: null };
      if (!isNil(params.get('material_name'))) {
        const paramValueString = params.get('material_name');
        // TODO: date
        inParams.material_name = paramValueString;
              }
      if (!isNil(params.get('material_packaging'))) {
        const paramValueString = params.get('material_packaging');
        // TODO: date
        inParams.material_packaging = paramValueString;
              }
      if (!isNil(params.get('material_amount'))) {
        const paramValueString = params.get('material_amount');
        inParams.material_amount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_ids'))) {
        const paramValueString = params.get('substitution_ids');
        inParams.substitution_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_line_production_confirmation_setup_form') {
      this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_setup_form');
      const title = 'manufacturing_order_line_production_confirmation_setup_form';
      const component = Manufacturing_manufacturing_order_line_production_confirmation_setup_formComponent;
      const inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string } = { scheduled_run_code: null, warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, finished_good_id: null, finished_good: null };
      if (!isNil(params.get('scheduled_run_code'))) {
        const paramValueString = params.get('scheduled_run_code');
        inParams.scheduled_run_code = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('output_location_id'))) {
        const paramValueString = params.get('output_location_id');
        inParams.output_location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('planned_quantity'))) {
        const paramValueString = params.get('planned_quantity');
        inParams.planned_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good_id'))) {
        const paramValueString = params.get('finished_good_id');
        inParams.finished_good_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good'))) {
        const paramValueString = params.get('finished_good');
        // TODO: date
        inParams.finished_good = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_line_substitution_swap_form') {
      this.$logger.log('Manufacturing', 'manufacturing_order_line_substitution_swap_form');
      const title = 'manufacturing_order_line_substitution_swap_form';
      const component = Manufacturing_manufacturing_order_line_substitution_swap_formComponent;
      const inParams:{ material_name?: string, material_packaging?: string, substitution_name?: string, substitution_packaging?: string, material_amount?: number, substitution_amount?: number, ratio?: number } = { material_name: null, material_packaging: null, substitution_name: null, substitution_packaging: null, material_amount: null, substitution_amount: null, ratio: null };
      if (!isNil(params.get('material_name'))) {
        const paramValueString = params.get('material_name');
        // TODO: date
        inParams.material_name = paramValueString;
              }
      if (!isNil(params.get('material_packaging'))) {
        const paramValueString = params.get('material_packaging');
        // TODO: date
        inParams.material_packaging = paramValueString;
              }
      if (!isNil(params.get('substitution_name'))) {
        const paramValueString = params.get('substitution_name');
        // TODO: date
        inParams.substitution_name = paramValueString;
              }
      if (!isNil(params.get('substitution_packaging'))) {
        const paramValueString = params.get('substitution_packaging');
        // TODO: date
        inParams.substitution_packaging = paramValueString;
              }
      if (!isNil(params.get('material_amount'))) {
        const paramValueString = params.get('material_amount');
        inParams.material_amount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_amount'))) {
        const paramValueString = params.get('substitution_amount');
        inParams.substitution_amount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ratio'))) {
        const paramValueString = params.get('ratio');
        inParams.ratio = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_plan_form') {
      this.$logger.log('Manufacturing', 'plan_form');
      const title = 'plan_form';
      const component = Manufacturing_plan_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_workshift_form') {
      this.$logger.log('Manufacturing', 'workshift_form');
      const title = 'workshift_form';
      const component = Manufacturing_workshift_formComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_components_serial_numbers_pick_editor') {
      this.$logger.log('Manufacturing', 'manufacturing_order_components_serial_numbers_pick_editor');
      const title = 'Serial number selector';
      const component = Manufacturing_manufacturing_order_components_serial_numbers_pick_editorComponent;
      const inParams:{ serial_controlled_materials?: number, material_ids?: { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[], warehouse_id?: number, iteration?: number, serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[], location?: number } = { serial_controlled_materials: null, material_ids: [], warehouse_id: null, iteration: null, serial_numbers: [], location: null };
      if (!isNil(params.get('serial_controlled_materials'))) {
        const paramValueString = params.get('serial_controlled_materials');
        inParams.serial_controlled_materials = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('iteration'))) {
        const paramValueString = params.get('iteration');
        inParams.iteration = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('serial_numbers'))) {
        const paramValueString = params.get('serial_numbers');
        inParams.serial_numbers = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('location'))) {
        const paramValueString = params.get('location');
        inParams.location = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_editor') {
      this.$logger.log('Manufacturing', 'manufacturing_order_editor');
      const title = 'manufacturing_order_editor';
      const component = Manufacturing_manufacturing_order_editorComponent;
      const inParams:{ manufacturing_order_id: number } = { manufacturing_order_id: null };
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_return_to_stock_editor') {
      this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_editor');
      const title = 'manufacturing_order_return_to_stock';
      const component = Manufacturing_manufacturing_order_return_to_stock_editorComponent;
      const inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, finished_good?: string, warehouse_id?: number, project_id?: number, task_chain_head?: number, location_id?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, finished_good: null, warehouse_id: null, project_id: null, task_chain_head: null, location_id: null };
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_line_id'))) {
        const paramValueString = params.get('manufacturing_order_line_id');
        inParams.manufacturing_order_line_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good'))) {
        const paramValueString = params.get('finished_good');
        // TODO: date
        inParams.finished_good = paramValueString;
              }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('task_chain_head'))) {
        const paramValueString = params.get('task_chain_head');
        inParams.task_chain_head = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_create_serial_numbers_editor') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_create_serial_numbers_editor');
      const title = 'Create new serials';
      const component = Manufacturing_manufacturing_orders_create_serial_numbers_editorComponent;
      const inParams:{ material_id?: number, produced_quantity?: number, lot_id?: number, packaging_id?: number, license_plate_id?: number } = { material_id: null, produced_quantity: null, lot_id: null, packaging_id: null, license_plate_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('produced_quantity'))) {
        const paramValueString = params.get('produced_quantity');
        inParams.produced_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packaging_id'))) {
        const paramValueString = params.get('packaging_id');
        inParams.packaging_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_id'))) {
        const paramValueString = params.get('license_plate_id');
        inParams.license_plate_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_plan_editor') {
      this.$logger.log('Manufacturing', 'plan_editor');
      const title = 'plan_editor';
      const component = Manufacturing_plan_editorComponent;
      const inParams:{ plan_id?: string } = { plan_id: null };
      if (!isNil(params.get('plan_id'))) {
        const paramValueString = params.get('plan_id');
        // TODO: date
        inParams.plan_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_workshift_editor') {
      this.$logger.log('Manufacturing', 'workshift_editor');
      const title = 'Workshift Edit';
      const component = Manufacturing_workshift_editorComponent;
      const inParams:{ workshift_id: number, is_new: boolean } = { workshift_id: null, is_new: null };
      if (!isNil(params.get('workshift_id'))) {
        const paramValueString = params.get('workshift_id');
        inParams.workshift_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('is_new'))) {
        const paramValueString = params.get('is_new');
        inParams.is_new = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_equipment_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_equipment_grid');
      const title = 'Equipment';
      const component = Manufacturing_manufacturing_equipment_gridComponent;
      const inParams:{ warehouse: number } = { warehouse: null };
      if (!isNil(params.get('warehouse'))) {
        const paramValueString = params.get('warehouse');
        inParams.warehouse = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_locations_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_locations_grid');
      const title = 'Locations';
      const component = Manufacturing_manufacturing_locations_gridComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_component_serial_number_selection_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_component_serial_number_selection_grid');
      const title = 'manufacturing_order_component_serial_number_selection_grid';
      const component = Manufacturing_manufacturing_order_component_serial_number_selection_gridComponent;
      const inParams:{ material_id?: number, warehouse_id?: number, serials_needed_count?: number, iteration?: number, license_plate?: number } = { material_id: null, warehouse_id: null, serials_needed_count: null, iteration: null, license_plate: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('serials_needed_count'))) {
        const paramValueString = params.get('serials_needed_count');
        inParams.serials_needed_count = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('iteration'))) {
        const paramValueString = params.get('iteration');
        inParams.iteration = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_line_components_production_confirmation_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_line_components_production_confirmation_grid');
      const title = 'manufacturing_order_line_components_production_confirmation_grid';
      const component = Manufacturing_manufacturing_order_line_components_production_confirmation_gridComponent;
      const inParams:{ finished_good_id?: number, warehouse_id?: number, quantity_to_produce?: number, license_plate_ids?: number[], location_id?: number, project_id?: number } = { finished_good_id: null, warehouse_id: null, quantity_to_produce: null, license_plate_ids: [], location_id: null, project_id: null };
      if (!isNil(params.get('finished_good_id'))) {
        const paramValueString = params.get('finished_good_id');
        inParams.finished_good_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('quantity_to_produce'))) {
        const paramValueString = params.get('quantity_to_produce');
        inParams.quantity_to_produce = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_ids'))) {
        const paramValueString = params.get('license_plate_ids');
        inParams.license_plate_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_line_production_confirmation_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_line_production_confirmation_grid');
      const title = 'manufacturing_order_line_production_confirmation_grid';
      const component = Manufacturing_manufacturing_order_line_production_confirmation_gridComponent;
      const inParams:{ finished_good?: string, planned_quantity?: number, material_id?: number, output_location_id?: number } = { finished_good: null, planned_quantity: null, material_id: null, output_location_id: null };
      if (!isNil(params.get('finished_good'))) {
        const paramValueString = params.get('finished_good');
        // TODO: date
        inParams.finished_good = paramValueString;
              }
      if (!isNil(params.get('planned_quantity'))) {
        const paramValueString = params.get('planned_quantity');
        inParams.planned_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('output_location_id'))) {
        const paramValueString = params.get('output_location_id');
        inParams.output_location_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_lines_components_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_lines_components_grid');
      const title = 'Manufacturing Order Lines Components';
      const component = Manufacturing_manufacturing_order_lines_components_gridComponent;
      const inParams:{ finished_good_id: number, warehouse_id: number, finished_good_quantity?: number, is_kit?: boolean, project_id: number, order_id: number, line_number: number, order_status_id: number } = { finished_good_id: null, warehouse_id: null, finished_good_quantity: null, is_kit: null, project_id: null, order_id: null, line_number: null, order_status_id: null };
      if (!isNil(params.get('finished_good_id'))) {
        const paramValueString = params.get('finished_good_id');
        inParams.finished_good_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good_quantity'))) {
        const paramValueString = params.get('finished_good_quantity');
        inParams.finished_good_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('is_kit'))) {
        const paramValueString = params.get('is_kit');
        inParams.is_kit = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line_number'))) {
        const paramValueString = params.get('line_number');
        inParams.line_number = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_status_id'))) {
        const paramValueString = params.get('order_status_id');
        inParams.order_status_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_lines_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_lines_grid');
      const title = 'Manufacturing order lines';
      const component = Manufacturing_manufacturing_order_lines_gridComponent;
      const inParams:{ order_id: number, project_id: number, order_status_id: number, warehouse_id: number } = { order_id: null, project_id: null, order_status_id: null, warehouse_id: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_status_id'))) {
        const paramValueString = params.get('order_status_id');
        inParams.order_status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_lines_production_sub_components_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_lines_production_sub_components_grid');
      const title = 'manufacturing_order_lines_production_sub_components_grid';
      const component = Manufacturing_manufacturing_order_lines_production_sub_components_gridComponent;
      const inParams:{ material_id?: number, location_id?: number, component?: string, main_component_identifier?: number, rows?: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[], package_id?: number, project_id?: number } = { material_id: null, location_id: null, component: null, main_component_identifier: null, rows: [], package_id: null, project_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('component'))) {
        const paramValueString = params.get('component');
        // TODO: date
        inParams.component = paramValueString;
              }
      if (!isNil(params.get('main_component_identifier'))) {
        const paramValueString = params.get('main_component_identifier');
        inParams.main_component_identifier = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('rows'))) {
        const paramValueString = params.get('rows');
        inParams.rows = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_return_to_stock_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid');
      const title = 'Components';
      const component = Manufacturing_manufacturing_order_return_to_stock_gridComponent;
      const inParams:{ manufacturing_order_line_id?: number, warehouse_id?: number, location_id?: number, manufacturing_order_id?: number, task_chain_head?: number } = { manufacturing_order_line_id: null, warehouse_id: null, location_id: null, manufacturing_order_id: null, task_chain_head: null };
      if (!isNil(params.get('manufacturing_order_line_id'))) {
        const paramValueString = params.get('manufacturing_order_line_id');
        inParams.manufacturing_order_line_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('task_chain_head'))) {
        const paramValueString = params.get('task_chain_head');
        inParams.task_chain_head = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_undo_manufacturing_production_components_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_components_grid');
      const title = 'manufacturing_order_undo_manufacturing_production_components_grid';
      const component = Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent;
      const inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, chain_head_id?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, chain_head_id: null };
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_line_id'))) {
        const paramValueString = params.get('manufacturing_order_line_id');
        inParams.manufacturing_order_line_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('chain_head_id'))) {
        const paramValueString = params.get('chain_head_id');
        inParams.chain_head_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_order_undo_manufacturing_production_finished_product_grid');
      const title = 'manufacturing_order_undo_manufacturing_production_finished_product_grid';
      const component = Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent;
      const inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, task_chain_head: null };
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_line_id'))) {
        const paramValueString = params.get('manufacturing_order_line_id');
        inParams.manufacturing_order_line_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('task_chain_head'))) {
        const paramValueString = params.get('task_chain_head');
        inParams.task_chain_head = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_grid');
      const title = 'Orders';
      const component = Manufacturing_manufacturing_orders_gridComponent;
      const inParams:{ warehouse_ids?: number[], owner_id?: number, project_id?: number, status_ids?: number[] } = { warehouse_ids: [], owner_id: null, project_id: null, status_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('status_ids'))) {
        const paramValueString = params.get('status_ids');
        inParams.status_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_workshifts_grid') {
      this.$logger.log('Manufacturing', 'manufacturing_workshifts_grid');
      const title = 'Workshifts';
      const component = Manufacturing_manufacturing_workshifts_gridComponent;
      const inParams:{ warehouse: number } = { warehouse: null };
      if (!isNil(params.get('warehouse'))) {
        const paramValueString = params.get('warehouse');
        inParams.warehouse = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_plans_grid') {
      this.$logger.log('Manufacturing', 'plans_grid');
      const title = 'Plans';
      const component = Manufacturing_plans_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_serialnumbers_creation_grid') {
      this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
      const title = 'Create serial numbers';
      const component = Manufacturing_serialnumbers_creation_gridComponent;
      const inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean } = { materialId: null, packagingId: null, packagedAmount: null, averageGross: null, averageNet: null, weightUomId: null, averageLength: null, averageWidth: null, averageHeight: null, dimensionUomId: null, lotId: null, licensePlateId: null, close_grid_on_confirm: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageGross'))) {
        const paramValueString = params.get('averageGross');
        inParams.averageGross = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageNet'))) {
        const paramValueString = params.get('averageNet');
        inParams.averageNet = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weightUomId'))) {
        const paramValueString = params.get('weightUomId');
        inParams.weightUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageLength'))) {
        const paramValueString = params.get('averageLength');
        inParams.averageLength = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageWidth'))) {
        const paramValueString = params.get('averageWidth');
        inParams.averageWidth = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageHeight'))) {
        const paramValueString = params.get('averageHeight');
        inParams.averageHeight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('dimensionUomId'))) {
        const paramValueString = params.get('dimensionUomId');
        inParams.dimensionUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('close_grid_on_confirm'))) {
        const paramValueString = params.get('close_grid_on_confirm');
        inParams.close_grid_on_confirm = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_configuration_hub') {
      this.$logger.log('Manufacturing', 'manufacturing_configuration_hub');
      const title = 'manufacturing_configuration_hub';
      const component = Manufacturing_manufacturing_configuration_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_locations_hub') {
      this.$logger.log('Manufacturing', 'manufacturing_locations_hub');
      const title = 'locations_for_manufacturing_hub';
      const component = Manufacturing_manufacturing_locations_hubComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_confirm_finished_product') {
      this.$logger.log('Manufacturing', 'manufacturing_order_confirm_finished_product');
      const title = 'manufacturing_order_confirm_finished_product';
      const component = Manufacturing_manufacturing_order_confirm_finished_productComponent;
      const inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string } = { scheduled_run_code: null, warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, finished_good_id: null, finished_good: null };
      if (!isNil(params.get('scheduled_run_code'))) {
        const paramValueString = params.get('scheduled_run_code');
        inParams.scheduled_run_code = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('output_location_id'))) {
        const paramValueString = params.get('output_location_id');
        inParams.output_location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('planned_quantity'))) {
        const paramValueString = params.get('planned_quantity');
        inParams.planned_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good_id'))) {
        const paramValueString = params.get('finished_good_id');
        inParams.finished_good_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good'))) {
        const paramValueString = params.get('finished_good');
        // TODO: date
        inParams.finished_good = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_hub') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_hub');
      const title = 'manufacturing_orders_hub';
      const component = Manufacturing_manufacturing_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_calendar') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_calendar');
      const title = 'manufacturing_orders_calendar';
      const component = Manufacturing_manufacturing_orders_calendarComponent;
      const inParams:{ warehouse_id?: number, project_id?: number } = { warehouse_id: null, project_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_production_confirmation_undo_wizard') {
      this.$logger.log('Manufacturing', 'manufacturing_order_production_confirmation_undo_wizard');
      const title = 'Undo production confirmation';
      const component = Manufacturing_manufacturing_order_production_confirmation_undo_wizardComponent;
      const inParams:{ manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number, warehouse_id?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, task_chain_head: null, warehouse_id: null };
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_line_id'))) {
        const paramValueString = params.get('manufacturing_order_line_id');
        inParams.manufacturing_order_line_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('task_chain_head'))) {
        const paramValueString = params.get('task_chain_head');
        inParams.task_chain_head = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_production_confirmation_wizard') {
      this.$logger.log('Manufacturing', 'manufacturing_order_production_confirmation_wizard');
      const title = 'manufacturing_order_production_confirmation_wizard';
      const component = Manufacturing_manufacturing_order_production_confirmation_wizardComponent;
      const inParams:{ scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, material_id?: number, finished_good?: string, finished_good_id?: number, expected_source_location?: number, manufacturing_order_id?: number, finished_product_is_serialized?: boolean } = { scheduled_run_code: null, warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, material_id: null, finished_good: null, finished_good_id: null, expected_source_location: null, manufacturing_order_id: null, finished_product_is_serialized: null };
      if (!isNil(params.get('scheduled_run_code'))) {
        const paramValueString = params.get('scheduled_run_code');
        inParams.scheduled_run_code = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('output_location_id'))) {
        const paramValueString = params.get('output_location_id');
        inParams.output_location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('planned_quantity'))) {
        const paramValueString = params.get('planned_quantity');
        inParams.planned_quantity = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_good'))) {
        const paramValueString = params.get('finished_good');
        // TODO: date
        inParams.finished_good = paramValueString;
              }
      if (!isNil(params.get('finished_good_id'))) {
        const paramValueString = params.get('finished_good_id');
        inParams.finished_good_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('expected_source_location'))) {
        const paramValueString = params.get('expected_source_location');
        inParams.expected_source_location = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('manufacturing_order_id'))) {
        const paramValueString = params.get('manufacturing_order_id');
        inParams.manufacturing_order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('finished_product_is_serialized'))) {
        const paramValueString = params.get('finished_product_is_serialized');
        inParams.finished_product_is_serialized = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_report') {
      this.$logger.log('Manufacturing', 'manufacturing_order_report');
      const title = 'manufacturing_order_report';
      const component = Manufacturing_manufacturing_order_reportComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_testRep') {
      this.$logger.log('Manufacturing', 'testRep');
      const title = 'testRep';
      const component = Manufacturing_testRepComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_days_dd_single') {
      this.$logger.log('Manufacturing', 'days_dd_single');
      const title = 'days_dd';
      const component = Manufacturing_days_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_license_plates_by_location_with_inventory_single') {
      this.$logger.log('Manufacturing', 'license_plates_by_location_with_inventory_single');
      const title = 'license_plates_by_location_with_inventory';
      const component = Manufacturing_license_plates_by_location_with_inventory_singleComponent;
      const inParams:{ material_id?: number, project_id?: number, location_id?: number, license_plate?: number } = { material_id: null, project_id: null, location_id: null, license_plate: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_licenseplates_by_location_single') {
      this.$logger.log('Manufacturing', 'licenseplates_by_location_single');
      const title = 'licenseplates_by_location';
      const component = Manufacturing_licenseplates_by_location_singleComponent;
      const inParams:{ location_id?: number, license_plate_ids?: number[] } = { location_id: null, license_plate_ids: [] };
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_ids'))) {
        const paramValueString = params.get('license_plate_ids');
        inParams.license_plate_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_by_material_ids_single') {
      this.$logger.log('Manufacturing', 'lots_by_material_ids_single');
      const title = 'lots_by_material_ids';
      const component = Manufacturing_lots_by_material_ids_singleComponent;
      const inParams:{ material_ids?: number[], lot_id?: number } = { material_ids: [], lot_id: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_with_inventory_by_license_plate_dd_single') {
      this.$logger.log('Manufacturing', 'lots_with_inventory_by_license_plate_dd_single');
      const title = 'lots_with_inventory_by_license_plate_dd';
      const component = Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent;
      const inParams:{ material_id?: number, license_plate?: number, location?: number, package_id?: number, warehouse_id?: number, lot_id?: number } = { material_id: null, license_plate: null, location: null, package_id: null, warehouse_id: null, lot_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location'))) {
        const paramValueString = params.get('location');
        inParams.location = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_date_types_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_order_date_types_dd_single');
      const title = 'manufacturing_order_date_types_dd';
      const component = Manufacturing_manufacturing_order_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_equipment_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_equipment_dd_single');
      const title = 'manufacturing_orders_equipment_dd';
      const component = Manufacturing_manufacturing_orders_equipment_dd_singleComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_workshifts_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_workshifts_dd_single');
      const title = 'manufacturing_orders_workshifts_dd';
      const component = Manufacturing_manufacturing_orders_workshifts_dd_singleComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_output_locations_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_output_locations_dd_single');
      const title = 'manufacturing_output_locations_dd';
      const component = Manufacturing_manufacturing_output_locations_dd_singleComponent;
      const inParams:{ warehouse_id?: number, project_id?: number } = { warehouse_id: null, project_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_staging_locations_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_staging_locations_dd_single');
      const title = 'manufacturing_staging_locations_dd';
      const component = Manufacturing_manufacturing_staging_locations_dd_singleComponent;
      const inParams:{ warehouse_id?: number, project_id?: number } = { warehouse_id: null, project_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturnig_order_production_confirmation_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturnig_order_production_confirmation_dd_single');
      const title = 'manufacturnig_order_production_confirmation_dd';
      const component = Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_packagings_dd_single') {
      this.$logger.log('Manufacturing', 'material_packagings_dd_single');
      const title = 'material_packagings_dd';
      const component = Manufacturing_material_packagings_dd_singleComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_substitutions_dd_single') {
      this.$logger.log('Manufacturing', 'material_substitutions_dd_single');
      const title = 'material_substitutions_dd';
      const component = Manufacturing_material_substitutions_dd_singleComponent;
      const inParams:{ material_ids?: number[], package_id?: number, warehouse_id?: number, substitution_ids?: number[], needed_amount?: number } = { material_ids: [], package_id: null, warehouse_id: null, substitution_ids: [], needed_amount: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_ids'))) {
        const paramValueString = params.get('substitution_ids');
        inParams.substitution_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('needed_amount'))) {
        const paramValueString = params.get('needed_amount');
        inParams.needed_amount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_materials_by_project_dd_single') {
      this.$logger.log('Manufacturing', 'materials_by_project_dd_single');
      const title = 'materials_by_project_dd';
      const component = Manufacturing_materials_by_project_dd_singleComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_measurement_units_dd_single') {
      this.$logger.log('Manufacturing', 'measurement_units_dd_single');
      const title = 'measurement_units_dd';
      const component = Manufacturing_measurement_units_dd_singleComponent;
      const inParams:{ type_id?: number } = { type_id: null };
      if (!isNil(params.get('type_id'))) {
        const paramValueString = params.get('type_id');
        inParams.type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_order_statuses_dd_single') {
      this.$logger.log('Manufacturing', 'order_statuses_dd_single');
      const title = 'order_statuses_dd';
      const component = Manufacturing_order_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_orders_dd_single') {
      this.$logger.log('Manufacturing', 'orders_dd_single');
      const title = 'orders_dd';
      const component = Manufacturing_orders_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_owners_dd_single') {
      this.$logger.log('Manufacturing', 'owners_dd_single');
      const title = 'owners_dd';
      const component = Manufacturing_owners_dd_singleComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_projects_dd_single') {
      this.$logger.log('Manufacturing', 'projects_dd_single');
      const title = 'projects_dd';
      const component = Manufacturing_projects_dd_singleComponent;
      const inParams:{ status_id?: number, owner_id?: number } = { status_id: null, owner_id: null };
      if (!isNil(params.get('status_id'))) {
        const paramValueString = params.get('status_id');
        inParams.status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_time_selector_dd_single') {
      this.$logger.log('Manufacturing', 'time_selector_dd_single');
      const title = 'time_selector_dd';
      const component = Manufacturing_time_selector_dd_singleComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouse_dd_single') {
      this.$logger.log('Manufacturing', 'warehouse_dd_single');
      const title = 'warehouse_dd';
      const component = Manufacturing_warehouse_dd_singleComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouses_dd_single') {
      this.$logger.log('Manufacturing', 'warehouses_dd_single');
      const title = 'warehouses_dd';
      const component = Manufacturing_warehouses_dd_singleComponent;
      const inParams:{ warehouse_ids?: number[] } = { warehouse_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_days_dd_multi') {
      this.$logger.log('Manufacturing', 'days_dd_multi');
      const title = 'days_dd';
      const component = Manufacturing_days_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_license_plates_by_location_with_inventory_multi') {
      this.$logger.log('Manufacturing', 'license_plates_by_location_with_inventory_multi');
      const title = 'license_plates_by_location_with_inventory';
      const component = Manufacturing_license_plates_by_location_with_inventory_multiComponent;
      const inParams:{ material_id?: number, project_id?: number, location_id?: number, license_plate?: number } = { material_id: null, project_id: null, location_id: null, license_plate: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_licenseplates_by_location_multi') {
      this.$logger.log('Manufacturing', 'licenseplates_by_location_multi');
      const title = 'licenseplates_by_location';
      const component = Manufacturing_licenseplates_by_location_multiComponent;
      const inParams:{ location_id?: number, license_plate_ids?: number[] } = { location_id: null, license_plate_ids: [] };
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_ids'))) {
        const paramValueString = params.get('license_plate_ids');
        inParams.license_plate_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_by_material_ids_multi') {
      this.$logger.log('Manufacturing', 'lots_by_material_ids_multi');
      const title = 'lots_by_material_ids';
      const component = Manufacturing_lots_by_material_ids_multiComponent;
      const inParams:{ material_ids?: number[], lot_id?: number } = { material_ids: [], lot_id: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_with_inventory_by_license_plate_dd_multi') {
      this.$logger.log('Manufacturing', 'lots_with_inventory_by_license_plate_dd_multi');
      const title = 'lots_with_inventory_by_license_plate_dd';
      const component = Manufacturing_lots_with_inventory_by_license_plate_dd_multiComponent;
      const inParams:{ material_id?: number, license_plate?: number, location?: number, package_id?: number, warehouse_id?: number, lot_id?: number } = { material_id: null, license_plate: null, location: null, package_id: null, warehouse_id: null, lot_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location'))) {
        const paramValueString = params.get('location');
        inParams.location = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_date_types_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_order_date_types_dd_multi');
      const title = 'manufacturing_order_date_types_dd';
      const component = Manufacturing_manufacturing_order_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_equipment_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_equipment_dd_multi');
      const title = 'manufacturing_orders_equipment_dd';
      const component = Manufacturing_manufacturing_orders_equipment_dd_multiComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_workshifts_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_workshifts_dd_multi');
      const title = 'manufacturing_orders_workshifts_dd';
      const component = Manufacturing_manufacturing_orders_workshifts_dd_multiComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_output_locations_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_output_locations_dd_multi');
      const title = 'manufacturing_output_locations_dd';
      const component = Manufacturing_manufacturing_output_locations_dd_multiComponent;
      const inParams:{ warehouse_id?: number, project_id?: number } = { warehouse_id: null, project_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_staging_locations_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_staging_locations_dd_multi');
      const title = 'manufacturing_staging_locations_dd';
      const component = Manufacturing_manufacturing_staging_locations_dd_multiComponent;
      const inParams:{ warehouse_id?: number, project_id?: number } = { warehouse_id: null, project_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturnig_order_production_confirmation_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturnig_order_production_confirmation_dd_multi');
      const title = 'manufacturnig_order_production_confirmation_dd';
      const component = Manufacturing_manufacturnig_order_production_confirmation_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_packagings_dd_multi') {
      this.$logger.log('Manufacturing', 'material_packagings_dd_multi');
      const title = 'material_packagings_dd';
      const component = Manufacturing_material_packagings_dd_multiComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_substitutions_dd_multi') {
      this.$logger.log('Manufacturing', 'material_substitutions_dd_multi');
      const title = 'material_substitutions_dd';
      const component = Manufacturing_material_substitutions_dd_multiComponent;
      const inParams:{ material_ids?: number[], package_id?: number, warehouse_id?: number, substitution_ids?: number[], needed_amount?: number } = { material_ids: [], package_id: null, warehouse_id: null, substitution_ids: [], needed_amount: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_ids'))) {
        const paramValueString = params.get('substitution_ids');
        inParams.substitution_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('needed_amount'))) {
        const paramValueString = params.get('needed_amount');
        inParams.needed_amount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_materials_by_project_dd_multi') {
      this.$logger.log('Manufacturing', 'materials_by_project_dd_multi');
      const title = 'materials_by_project_dd';
      const component = Manufacturing_materials_by_project_dd_multiComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_measurement_units_dd_multi') {
      this.$logger.log('Manufacturing', 'measurement_units_dd_multi');
      const title = 'measurement_units_dd';
      const component = Manufacturing_measurement_units_dd_multiComponent;
      const inParams:{ type_id?: number } = { type_id: null };
      if (!isNil(params.get('type_id'))) {
        const paramValueString = params.get('type_id');
        inParams.type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_order_statuses_dd_multi') {
      this.$logger.log('Manufacturing', 'order_statuses_dd_multi');
      const title = 'order_statuses_dd';
      const component = Manufacturing_order_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_orders_dd_multi') {
      this.$logger.log('Manufacturing', 'orders_dd_multi');
      const title = 'orders_dd';
      const component = Manufacturing_orders_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_owners_dd_multi') {
      this.$logger.log('Manufacturing', 'owners_dd_multi');
      const title = 'owners_dd';
      const component = Manufacturing_owners_dd_multiComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_projects_dd_multi') {
      this.$logger.log('Manufacturing', 'projects_dd_multi');
      const title = 'projects_dd';
      const component = Manufacturing_projects_dd_multiComponent;
      const inParams:{ status_id?: number, owner_id?: number } = { status_id: null, owner_id: null };
      if (!isNil(params.get('status_id'))) {
        const paramValueString = params.get('status_id');
        inParams.status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_time_selector_dd_multi') {
      this.$logger.log('Manufacturing', 'time_selector_dd_multi');
      const title = 'time_selector_dd';
      const component = Manufacturing_time_selector_dd_multiComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouse_dd_multi') {
      this.$logger.log('Manufacturing', 'warehouse_dd_multi');
      const title = 'warehouse_dd';
      const component = Manufacturing_warehouse_dd_multiComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouses_dd_multi') {
      this.$logger.log('Manufacturing', 'warehouses_dd_multi');
      const title = 'warehouses_dd';
      const component = Manufacturing_warehouses_dd_multiComponent;
      const inParams:{ warehouse_ids?: number[] } = { warehouse_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_pending_confirmation_widget') {
      this.$logger.log('Manufacturing', 'pending_confirmation_widget');
      const title = 'Pending confirmation';
      const component = Manufacturing_pending_confirmation_widgetComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_scheduled_for_today_widget') {
      this.$logger.log('Manufacturing', 'scheduled_for_today_widget');
      const title = 'Scheduled for today';
      const component = Manufacturing_scheduled_for_today_widgetComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_for_calendar_card') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_for_calendar_card');
      const title = 'manufacturing_orders_for_calendar_card';
      const component = Manufacturing_manufacturing_orders_for_calendar_cardComponent;
      const inParams:{ order_id: number, lookup_code: string, order_lines?: string[], start_date: string, end_date: string } = { order_id: null, lookup_code: null, order_lines: [], start_date: null, end_date: null };
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lookup_code'))) {
        const paramValueString = params.get('lookup_code');
        // TODO: date
        inParams.lookup_code = paramValueString;
              }
      if (!isNil(params.get('order_lines'))) {
        const paramValueString = params.get('order_lines');
        inParams.order_lines = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('start_date'))) {
        const paramValueString = params.get('start_date');
        // TODO: date
        inParams.start_date = paramValueString;
              }
      if (!isNil(params.get('end_date'))) {
        const paramValueString = params.get('end_date');
        // TODO: date
        inParams.end_date = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
