import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_production_confirmation_undo_wizard_ComponentContextService } from './Manufacturing.manufacturing_order_production_confirmation_undo_wizard.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent } from './Manufacturing.manufacturing_order_undo_manufacturing_production_finished_product_grid.component';
import { Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent } from './Manufacturing.manufacturing_order_undo_manufacturing_production_components_grid.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent),
    forwardRef(() => Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent),
  ],
  selector: 'Manufacturing-manufacturing_order_production_confirmation_undo_wizard',
  templateUrl: './Manufacturing.manufacturing_order_production_confirmation_undo_wizard.component.html'
})
export class Manufacturing_manufacturing_order_production_confirmation_undo_wizardComponent extends BaseComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number, warehouse_id?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, task_chain_head: null, warehouse_id: null };
  //#region Inputs
  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(v: number) {
    this.inParams.manufacturing_order_id = v;
  }
  get $inParams_manufacturing_order_id(): number {
    return this.inParams.manufacturing_order_id;
  }
  @Input('manufacturing_order_line_id') set $inParams_manufacturing_order_line_id(v: number) {
    this.inParams.manufacturing_order_line_id = v;
  }
  get $inParams_manufacturing_order_line_id(): number {
    return this.inParams.manufacturing_order_line_id;
  }
  @Input('task_chain_head') set $inParams_task_chain_head(v: number) {
    this.inParams.task_chain_head = v;
  }
  get $inParams_task_chain_head(): number {
    return this.inParams.task_chain_head;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_production_confirmation_undo_wizard_ComponentContextService,
) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    this.title = 'Undo production confirmation';
  
    const $wizard = this;
    const $utils = this.$utils;


    this.initialized = true;
  }

  steps: {
    step1?: {
      outParams?: { rows?: { material_id?: number, decrease_quantity?: number, license_plate?: number, location?: number, packaging_id?: number }[] }
    }
    step2?: {
      outParams?: { rows?: { material_id?: number, increase_quantity?: number, license_plate?: number, location?: number, lot?: number, packaging_id?: number }[] }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.$utils;

      this._stepConfigs = [
      {
          id: 'step1',
          title: 'Undo finished product',
          component: Manufacturing_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              manufacturing_order_id: $wizard.inParams.manufacturing_order_id,
              manufacturing_order_line_id: $wizard.inParams.manufacturing_order_line_id,
              task_chain_head: $wizard.inParams.task_chain_head,
            }
          },
          next: 'step2',
      },
      {
          id: 'step2',
          title: 'Undo components',
          component: Manufacturing_manufacturing_order_undo_manufacturing_production_components_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              manufacturing_order_id: $wizard.inParams.manufacturing_order_id,
              manufacturing_order_line_id: $wizard.inParams.manufacturing_order_line_id,
              chain_head_id: $wizard.inParams.task_chain_head,
            }
          },
          nextButtonLabel: 'Confirm',
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.$utils;

    await this.finish_flow();

    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
  //#region private flows
  finish_flow(event = null) {
    return this.finish_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async finish_flowInternal(
    $wizard: Manufacturing_manufacturing_order_production_confirmation_undo_wizardComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_production_confirmation_undo_wizard_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_production_confirmation_undo_wizard.finish_flow');
  try {
  
      console.log( $wizard.steps.step1?.outParams?.rows );
      console.log( $wizard.steps.step2?.outParams?.rows );
  
      // Creating the main task for the manufacturing production undo
      const mainTask = await $flows.Utilities.crud_create_flow({
          entitySet: "Tasks",
          entity: {
              Id: 0,
              ChainHead: $wizard.inParams.task_chain_head,
              ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
              ManufacturingOrderLineId: $wizard.inParams.manufacturing_order_line_id,
              OperationCodeId: 62,
              StatusId: 2,
              WarehouseId: $wizard.inParams.warehouse_id
          }
      });
  
      if (!$utils.isDefined(mainTask.result))
      {
          await $shell.Utilities.openErrorDialog('Error undoing production', mainTask.reason);
          throw new Error(mainTask.reason);
      }
  
      if ($utils.isDefined(mainTask.result)) {
          for (const licensePlate of $wizard.steps.step1.outParams.rows) {
  
              // Creating the "Finished product rework" task for the manufacturing production undo
              const finishedGoodReworkTask = await $flows.Utilities.crud_create_flow({
                  entitySet: "Tasks",
                  entity: {
                      Id: 0,
                      ActualInventoryAmount: licensePlate.decrease_quantity,
                      ActualInventoryAmountPackId: licensePlate.packaging_id,
                      ActualPackagedAmount: licensePlate.decrease_quantity,
                      ActualPackagedPackId: licensePlate.packaging_id,
                      ActualSourceLicensePlateId: licensePlate.license_plate,
                      ActualSourceLocationId: licensePlate.location,
                      ChainHead: mainTask.result.Id,
                      StatusId: 2,
                      WarehouseId: $wizard.inParams.warehouse_id,
                      ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                      ManufacturingOrderLineId: $wizard.inParams.manufacturing_order_line_id,
                      MaterialId: licensePlate.material_id,
                      OperationCodeId: 60,
                  }
              });
  
              if ($utils.isDefined(finishedGoodReworkTask.reason))
              {
                  await $shell.Utilities.openErrorDialog('Error undoing production', finishedGoodReworkTask.reason);
                  throw new Error(finishedGoodReworkTask.reason);
              }
          }
  
          for (const componentData of $wizard.steps.step2.outParams.rows) {
              const componentConsumedReworkTask = await $flows.Utilities.crud_create_flow({
  
                  // Creating the "Component consumed" task for the manufacturing production(manufacturing feedback)
                  entitySet: "Tasks",
                  entity: {
                      Id: 0,
                      ActualInventoryAmount: componentData.increase_quantity,
                      ActualInventoryAmountPackId: componentData.packaging_id,
                      ActualPackagedAmount: componentData.increase_quantity,
                      ActualPackagedPackId: componentData.packaging_id,
                      ActualTargetLicensePlateId: componentData.license_plate,
                      ActualTargetLocationId: componentData.location,
                      ChainHead: mainTask.result.Id,
                      StatusId: 2,
                      WarehouseId: $wizard.inParams.warehouse_id,
                      ExpectedPackagedAmount: componentData.increase_quantity,
                      ExpectedPackagedPackId: componentData.packaging_id,
                      LotId: componentData.lot,
                      ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                      ManufacturingOrderLineId: $wizard.inParams.manufacturing_order_line_id,
                      MaterialId: componentData.material_id,
                      OperationCodeId: 61
                  }
              });
  
              if ($utils.isDefined(componentConsumedReworkTask.reason)) {
                  await $shell.Utilities.openErrorDialog('Error undoing production', componentConsumedReworkTask.reason);
                  throw new Error(componentConsumedReworkTask.reason);
              }
          }
      }
  } catch (error) {
      // Get inner-most error message
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      }
  
      let message = targetError.message;
  }
  
  }
  //#endregion private flows
}
