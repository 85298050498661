import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Manufacturing_ds_get_inventory_by_license_plate_lotService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_kit_materialsService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_license_plate_inventory_by_material_warehouse_locationService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_licenseplates_by_locationIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_lots_by_materialIdsService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_equipment_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_line_components_by_finished_good_idService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_location_for_calendarService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_cancellation_dataService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_component_serial_numbers_editorService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_component_serial_numbers_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_lineService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_lines_components_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_lines_production_sub_components_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_order_production_confirmation_dataService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_orders_countService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_orders_for_calendarService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_orders_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_output_locations_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_staging_locations_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_manufacturing_workshifts_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_material_by_materialIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_material_packaging_by_materialId_and_packagingIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_material_substitutionsService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_materials_basepackaging_by_materialIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_materials_defaultpackaging_by_materialIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_measurement_unit_by_unitIdService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_orders_for_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_planService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_plansService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_serial_numbers_max_idService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_single_measurement_unitService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_warehouse_by_idService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_get_workshift_by_idService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_license_plate_navigation_list_extendedService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_licenseplate_navigation_listService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_locations_by_warehouseId_for_manufacturing_settingsService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_order_editorService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_order_lines_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_order_production_confirmation_editorService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_order_production_confirmation_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_order_return_to_stock_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_orders_create_serial_numbers_editor_dummyService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_orders_serial_numbers_production_createService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_manufacturing_return_to_stock_dummyService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_material_packagings_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_materials_by_project_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_measurement_units_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_owners_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_physical_inventory_by_license_plate_lotService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_projects_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_serialnumbers_creation_gridService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_serialnumbers_expandService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_time_selector_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_ds_warehouses_ddService } from './Manufacturing.datasource.index';
import { Manufacturing_inventory_reportService } from './Manufacturing.datasource.index';
import { Manufacturing_test_get_planService } from './Manufacturing.datasource.index';
import { Manufacturing_test_owner_inventoryService } from './Manufacturing.datasource.index';
import { Manufacturing_testdfvrService } from './Manufacturing.datasource.index';
import { Manufacturing_testzService } from './Manufacturing.datasource.index';

@Injectable({ providedIn: 'root' })
export class Manufacturing_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Manufacturing: Manufacturing_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_inventory_by_license_plate_lot: Manufacturing_ds_get_inventory_by_license_plate_lotService;
  public get ds_get_inventory_by_license_plate_lot(): Manufacturing_ds_get_inventory_by_license_plate_lotService {
    if(!this._ds_get_inventory_by_license_plate_lot) {
      this._ds_get_inventory_by_license_plate_lot = this.injector.get(Manufacturing_ds_get_inventory_by_license_plate_lotService);
    }
    return this._ds_get_inventory_by_license_plate_lot;
  }
  private _ds_get_kit_materials: Manufacturing_ds_get_kit_materialsService;
  public get ds_get_kit_materials(): Manufacturing_ds_get_kit_materialsService {
    if(!this._ds_get_kit_materials) {
      this._ds_get_kit_materials = this.injector.get(Manufacturing_ds_get_kit_materialsService);
    }
    return this._ds_get_kit_materials;
  }
  private _ds_get_license_plate_inventory_by_material_warehouse_location: Manufacturing_ds_get_license_plate_inventory_by_material_warehouse_locationService;
  public get ds_get_license_plate_inventory_by_material_warehouse_location(): Manufacturing_ds_get_license_plate_inventory_by_material_warehouse_locationService {
    if(!this._ds_get_license_plate_inventory_by_material_warehouse_location) {
      this._ds_get_license_plate_inventory_by_material_warehouse_location = this.injector.get(Manufacturing_ds_get_license_plate_inventory_by_material_warehouse_locationService);
    }
    return this._ds_get_license_plate_inventory_by_material_warehouse_location;
  }
  private _ds_get_licenseplates_by_locationId: Manufacturing_ds_get_licenseplates_by_locationIdService;
  public get ds_get_licenseplates_by_locationId(): Manufacturing_ds_get_licenseplates_by_locationIdService {
    if(!this._ds_get_licenseplates_by_locationId) {
      this._ds_get_licenseplates_by_locationId = this.injector.get(Manufacturing_ds_get_licenseplates_by_locationIdService);
    }
    return this._ds_get_licenseplates_by_locationId;
  }
  private _ds_get_lots_by_materialIds: Manufacturing_ds_get_lots_by_materialIdsService;
  public get ds_get_lots_by_materialIds(): Manufacturing_ds_get_lots_by_materialIdsService {
    if(!this._ds_get_lots_by_materialIds) {
      this._ds_get_lots_by_materialIds = this.injector.get(Manufacturing_ds_get_lots_by_materialIdsService);
    }
    return this._ds_get_lots_by_materialIds;
  }
  private _ds_get_manufacturing_equipment_grid: Manufacturing_ds_get_manufacturing_equipment_gridService;
  public get ds_get_manufacturing_equipment_grid(): Manufacturing_ds_get_manufacturing_equipment_gridService {
    if(!this._ds_get_manufacturing_equipment_grid) {
      this._ds_get_manufacturing_equipment_grid = this.injector.get(Manufacturing_ds_get_manufacturing_equipment_gridService);
    }
    return this._ds_get_manufacturing_equipment_grid;
  }
  private _ds_get_manufacturing_line_components_by_finished_good_id: Manufacturing_ds_get_manufacturing_line_components_by_finished_good_idService;
  public get ds_get_manufacturing_line_components_by_finished_good_id(): Manufacturing_ds_get_manufacturing_line_components_by_finished_good_idService {
    if(!this._ds_get_manufacturing_line_components_by_finished_good_id) {
      this._ds_get_manufacturing_line_components_by_finished_good_id = this.injector.get(Manufacturing_ds_get_manufacturing_line_components_by_finished_good_idService);
    }
    return this._ds_get_manufacturing_line_components_by_finished_good_id;
  }
  private _ds_get_manufacturing_location_for_calendar: Manufacturing_ds_get_manufacturing_location_for_calendarService;
  public get ds_get_manufacturing_location_for_calendar(): Manufacturing_ds_get_manufacturing_location_for_calendarService {
    if(!this._ds_get_manufacturing_location_for_calendar) {
      this._ds_get_manufacturing_location_for_calendar = this.injector.get(Manufacturing_ds_get_manufacturing_location_for_calendarService);
    }
    return this._ds_get_manufacturing_location_for_calendar;
  }
  private _ds_get_manufacturing_order_cancellation_data: Manufacturing_ds_get_manufacturing_order_cancellation_dataService;
  public get ds_get_manufacturing_order_cancellation_data(): Manufacturing_ds_get_manufacturing_order_cancellation_dataService {
    if(!this._ds_get_manufacturing_order_cancellation_data) {
      this._ds_get_manufacturing_order_cancellation_data = this.injector.get(Manufacturing_ds_get_manufacturing_order_cancellation_dataService);
    }
    return this._ds_get_manufacturing_order_cancellation_data;
  }
  private _ds_get_manufacturing_order_component_serial_numbers_editor: Manufacturing_ds_get_manufacturing_order_component_serial_numbers_editorService;
  public get ds_get_manufacturing_order_component_serial_numbers_editor(): Manufacturing_ds_get_manufacturing_order_component_serial_numbers_editorService {
    if(!this._ds_get_manufacturing_order_component_serial_numbers_editor) {
      this._ds_get_manufacturing_order_component_serial_numbers_editor = this.injector.get(Manufacturing_ds_get_manufacturing_order_component_serial_numbers_editorService);
    }
    return this._ds_get_manufacturing_order_component_serial_numbers_editor;
  }
  private _ds_get_manufacturing_order_component_serial_numbers_grid: Manufacturing_ds_get_manufacturing_order_component_serial_numbers_gridService;
  public get ds_get_manufacturing_order_component_serial_numbers_grid(): Manufacturing_ds_get_manufacturing_order_component_serial_numbers_gridService {
    if(!this._ds_get_manufacturing_order_component_serial_numbers_grid) {
      this._ds_get_manufacturing_order_component_serial_numbers_grid = this.injector.get(Manufacturing_ds_get_manufacturing_order_component_serial_numbers_gridService);
    }
    return this._ds_get_manufacturing_order_component_serial_numbers_grid;
  }
  private _ds_get_manufacturing_order_line: Manufacturing_ds_get_manufacturing_order_lineService;
  public get ds_get_manufacturing_order_line(): Manufacturing_ds_get_manufacturing_order_lineService {
    if(!this._ds_get_manufacturing_order_line) {
      this._ds_get_manufacturing_order_line = this.injector.get(Manufacturing_ds_get_manufacturing_order_lineService);
    }
    return this._ds_get_manufacturing_order_line;
  }
  private _ds_get_manufacturing_order_lines_components_grid: Manufacturing_ds_get_manufacturing_order_lines_components_gridService;
  public get ds_get_manufacturing_order_lines_components_grid(): Manufacturing_ds_get_manufacturing_order_lines_components_gridService {
    if(!this._ds_get_manufacturing_order_lines_components_grid) {
      this._ds_get_manufacturing_order_lines_components_grid = this.injector.get(Manufacturing_ds_get_manufacturing_order_lines_components_gridService);
    }
    return this._ds_get_manufacturing_order_lines_components_grid;
  }
  private _ds_get_manufacturing_order_lines_production_sub_components_grid: Manufacturing_ds_get_manufacturing_order_lines_production_sub_components_gridService;
  public get ds_get_manufacturing_order_lines_production_sub_components_grid(): Manufacturing_ds_get_manufacturing_order_lines_production_sub_components_gridService {
    if(!this._ds_get_manufacturing_order_lines_production_sub_components_grid) {
      this._ds_get_manufacturing_order_lines_production_sub_components_grid = this.injector.get(Manufacturing_ds_get_manufacturing_order_lines_production_sub_components_gridService);
    }
    return this._ds_get_manufacturing_order_lines_production_sub_components_grid;
  }
  private _ds_get_manufacturing_order_production_confirmation_data: Manufacturing_ds_get_manufacturing_order_production_confirmation_dataService;
  public get ds_get_manufacturing_order_production_confirmation_data(): Manufacturing_ds_get_manufacturing_order_production_confirmation_dataService {
    if(!this._ds_get_manufacturing_order_production_confirmation_data) {
      this._ds_get_manufacturing_order_production_confirmation_data = this.injector.get(Manufacturing_ds_get_manufacturing_order_production_confirmation_dataService);
    }
    return this._ds_get_manufacturing_order_production_confirmation_data;
  }
  private _ds_get_manufacturing_orders_count: Manufacturing_ds_get_manufacturing_orders_countService;
  public get ds_get_manufacturing_orders_count(): Manufacturing_ds_get_manufacturing_orders_countService {
    if(!this._ds_get_manufacturing_orders_count) {
      this._ds_get_manufacturing_orders_count = this.injector.get(Manufacturing_ds_get_manufacturing_orders_countService);
    }
    return this._ds_get_manufacturing_orders_count;
  }
  private _ds_get_manufacturing_orders_for_calendar: Manufacturing_ds_get_manufacturing_orders_for_calendarService;
  public get ds_get_manufacturing_orders_for_calendar(): Manufacturing_ds_get_manufacturing_orders_for_calendarService {
    if(!this._ds_get_manufacturing_orders_for_calendar) {
      this._ds_get_manufacturing_orders_for_calendar = this.injector.get(Manufacturing_ds_get_manufacturing_orders_for_calendarService);
    }
    return this._ds_get_manufacturing_orders_for_calendar;
  }
  private _ds_get_manufacturing_orders_grid: Manufacturing_ds_get_manufacturing_orders_gridService;
  public get ds_get_manufacturing_orders_grid(): Manufacturing_ds_get_manufacturing_orders_gridService {
    if(!this._ds_get_manufacturing_orders_grid) {
      this._ds_get_manufacturing_orders_grid = this.injector.get(Manufacturing_ds_get_manufacturing_orders_gridService);
    }
    return this._ds_get_manufacturing_orders_grid;
  }
  private _ds_get_manufacturing_output_locations_dd: Manufacturing_ds_get_manufacturing_output_locations_ddService;
  public get ds_get_manufacturing_output_locations_dd(): Manufacturing_ds_get_manufacturing_output_locations_ddService {
    if(!this._ds_get_manufacturing_output_locations_dd) {
      this._ds_get_manufacturing_output_locations_dd = this.injector.get(Manufacturing_ds_get_manufacturing_output_locations_ddService);
    }
    return this._ds_get_manufacturing_output_locations_dd;
  }
  private _ds_get_manufacturing_staging_locations_dd: Manufacturing_ds_get_manufacturing_staging_locations_ddService;
  public get ds_get_manufacturing_staging_locations_dd(): Manufacturing_ds_get_manufacturing_staging_locations_ddService {
    if(!this._ds_get_manufacturing_staging_locations_dd) {
      this._ds_get_manufacturing_staging_locations_dd = this.injector.get(Manufacturing_ds_get_manufacturing_staging_locations_ddService);
    }
    return this._ds_get_manufacturing_staging_locations_dd;
  }
  private _ds_get_manufacturing_workshifts_grid: Manufacturing_ds_get_manufacturing_workshifts_gridService;
  public get ds_get_manufacturing_workshifts_grid(): Manufacturing_ds_get_manufacturing_workshifts_gridService {
    if(!this._ds_get_manufacturing_workshifts_grid) {
      this._ds_get_manufacturing_workshifts_grid = this.injector.get(Manufacturing_ds_get_manufacturing_workshifts_gridService);
    }
    return this._ds_get_manufacturing_workshifts_grid;
  }
  private _ds_get_material_by_materialId: Manufacturing_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): Manufacturing_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(Manufacturing_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: Manufacturing_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): Manufacturing_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(Manufacturing_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_material_substitutions: Manufacturing_ds_get_material_substitutionsService;
  public get ds_get_material_substitutions(): Manufacturing_ds_get_material_substitutionsService {
    if(!this._ds_get_material_substitutions) {
      this._ds_get_material_substitutions = this.injector.get(Manufacturing_ds_get_material_substitutionsService);
    }
    return this._ds_get_material_substitutions;
  }
  private _ds_get_materials_basepackaging_by_materialId: Manufacturing_ds_get_materials_basepackaging_by_materialIdService;
  public get ds_get_materials_basepackaging_by_materialId(): Manufacturing_ds_get_materials_basepackaging_by_materialIdService {
    if(!this._ds_get_materials_basepackaging_by_materialId) {
      this._ds_get_materials_basepackaging_by_materialId = this.injector.get(Manufacturing_ds_get_materials_basepackaging_by_materialIdService);
    }
    return this._ds_get_materials_basepackaging_by_materialId;
  }
  private _ds_get_materials_defaultpackaging_by_materialId: Manufacturing_ds_get_materials_defaultpackaging_by_materialIdService;
  public get ds_get_materials_defaultpackaging_by_materialId(): Manufacturing_ds_get_materials_defaultpackaging_by_materialIdService {
    if(!this._ds_get_materials_defaultpackaging_by_materialId) {
      this._ds_get_materials_defaultpackaging_by_materialId = this.injector.get(Manufacturing_ds_get_materials_defaultpackaging_by_materialIdService);
    }
    return this._ds_get_materials_defaultpackaging_by_materialId;
  }
  private _ds_get_measurement_unit_by_unitId: Manufacturing_ds_get_measurement_unit_by_unitIdService;
  public get ds_get_measurement_unit_by_unitId(): Manufacturing_ds_get_measurement_unit_by_unitIdService {
    if(!this._ds_get_measurement_unit_by_unitId) {
      this._ds_get_measurement_unit_by_unitId = this.injector.get(Manufacturing_ds_get_measurement_unit_by_unitIdService);
    }
    return this._ds_get_measurement_unit_by_unitId;
  }
  private _ds_get_orders_for_dd: Manufacturing_ds_get_orders_for_ddService;
  public get ds_get_orders_for_dd(): Manufacturing_ds_get_orders_for_ddService {
    if(!this._ds_get_orders_for_dd) {
      this._ds_get_orders_for_dd = this.injector.get(Manufacturing_ds_get_orders_for_ddService);
    }
    return this._ds_get_orders_for_dd;
  }
  private _ds_get_plan: Manufacturing_ds_get_planService;
  public get ds_get_plan(): Manufacturing_ds_get_planService {
    if(!this._ds_get_plan) {
      this._ds_get_plan = this.injector.get(Manufacturing_ds_get_planService);
    }
    return this._ds_get_plan;
  }
  private _ds_get_plans: Manufacturing_ds_get_plansService;
  public get ds_get_plans(): Manufacturing_ds_get_plansService {
    if(!this._ds_get_plans) {
      this._ds_get_plans = this.injector.get(Manufacturing_ds_get_plansService);
    }
    return this._ds_get_plans;
  }
  private _ds_get_serial_numbers_max_id: Manufacturing_ds_get_serial_numbers_max_idService;
  public get ds_get_serial_numbers_max_id(): Manufacturing_ds_get_serial_numbers_max_idService {
    if(!this._ds_get_serial_numbers_max_id) {
      this._ds_get_serial_numbers_max_id = this.injector.get(Manufacturing_ds_get_serial_numbers_max_idService);
    }
    return this._ds_get_serial_numbers_max_id;
  }
  private _ds_get_single_measurement_unit: Manufacturing_ds_get_single_measurement_unitService;
  public get ds_get_single_measurement_unit(): Manufacturing_ds_get_single_measurement_unitService {
    if(!this._ds_get_single_measurement_unit) {
      this._ds_get_single_measurement_unit = this.injector.get(Manufacturing_ds_get_single_measurement_unitService);
    }
    return this._ds_get_single_measurement_unit;
  }
  private _ds_get_warehouse_by_id: Manufacturing_ds_get_warehouse_by_idService;
  public get ds_get_warehouse_by_id(): Manufacturing_ds_get_warehouse_by_idService {
    if(!this._ds_get_warehouse_by_id) {
      this._ds_get_warehouse_by_id = this.injector.get(Manufacturing_ds_get_warehouse_by_idService);
    }
    return this._ds_get_warehouse_by_id;
  }
  private _ds_get_workshift_by_id: Manufacturing_ds_get_workshift_by_idService;
  public get ds_get_workshift_by_id(): Manufacturing_ds_get_workshift_by_idService {
    if(!this._ds_get_workshift_by_id) {
      this._ds_get_workshift_by_id = this.injector.get(Manufacturing_ds_get_workshift_by_idService);
    }
    return this._ds_get_workshift_by_id;
  }
  private _ds_license_plate_navigation_list_extended: Manufacturing_ds_license_plate_navigation_list_extendedService;
  public get ds_license_plate_navigation_list_extended(): Manufacturing_ds_license_plate_navigation_list_extendedService {
    if(!this._ds_license_plate_navigation_list_extended) {
      this._ds_license_plate_navigation_list_extended = this.injector.get(Manufacturing_ds_license_plate_navigation_list_extendedService);
    }
    return this._ds_license_plate_navigation_list_extended;
  }
  private _ds_licenseplate_navigation_list: Manufacturing_ds_licenseplate_navigation_listService;
  public get ds_licenseplate_navigation_list(): Manufacturing_ds_licenseplate_navigation_listService {
    if(!this._ds_licenseplate_navigation_list) {
      this._ds_licenseplate_navigation_list = this.injector.get(Manufacturing_ds_licenseplate_navigation_listService);
    }
    return this._ds_licenseplate_navigation_list;
  }
  private _ds_locations_by_warehouseId_for_manufacturing_settings: Manufacturing_ds_locations_by_warehouseId_for_manufacturing_settingsService;
  public get ds_locations_by_warehouseId_for_manufacturing_settings(): Manufacturing_ds_locations_by_warehouseId_for_manufacturing_settingsService {
    if(!this._ds_locations_by_warehouseId_for_manufacturing_settings) {
      this._ds_locations_by_warehouseId_for_manufacturing_settings = this.injector.get(Manufacturing_ds_locations_by_warehouseId_for_manufacturing_settingsService);
    }
    return this._ds_locations_by_warehouseId_for_manufacturing_settings;
  }
  private _ds_manufacturing_order_editor: Manufacturing_ds_manufacturing_order_editorService;
  public get ds_manufacturing_order_editor(): Manufacturing_ds_manufacturing_order_editorService {
    if(!this._ds_manufacturing_order_editor) {
      this._ds_manufacturing_order_editor = this.injector.get(Manufacturing_ds_manufacturing_order_editorService);
    }
    return this._ds_manufacturing_order_editor;
  }
  private _ds_manufacturing_order_lines_grid: Manufacturing_ds_manufacturing_order_lines_gridService;
  public get ds_manufacturing_order_lines_grid(): Manufacturing_ds_manufacturing_order_lines_gridService {
    if(!this._ds_manufacturing_order_lines_grid) {
      this._ds_manufacturing_order_lines_grid = this.injector.get(Manufacturing_ds_manufacturing_order_lines_gridService);
    }
    return this._ds_manufacturing_order_lines_grid;
  }
  private _ds_manufacturing_order_production_confirmation_editor: Manufacturing_ds_manufacturing_order_production_confirmation_editorService;
  public get ds_manufacturing_order_production_confirmation_editor(): Manufacturing_ds_manufacturing_order_production_confirmation_editorService {
    if(!this._ds_manufacturing_order_production_confirmation_editor) {
      this._ds_manufacturing_order_production_confirmation_editor = this.injector.get(Manufacturing_ds_manufacturing_order_production_confirmation_editorService);
    }
    return this._ds_manufacturing_order_production_confirmation_editor;
  }
  private _ds_manufacturing_order_production_confirmation_grid: Manufacturing_ds_manufacturing_order_production_confirmation_gridService;
  public get ds_manufacturing_order_production_confirmation_grid(): Manufacturing_ds_manufacturing_order_production_confirmation_gridService {
    if(!this._ds_manufacturing_order_production_confirmation_grid) {
      this._ds_manufacturing_order_production_confirmation_grid = this.injector.get(Manufacturing_ds_manufacturing_order_production_confirmation_gridService);
    }
    return this._ds_manufacturing_order_production_confirmation_grid;
  }
  private _ds_manufacturing_order_return_to_stock_grid: Manufacturing_ds_manufacturing_order_return_to_stock_gridService;
  public get ds_manufacturing_order_return_to_stock_grid(): Manufacturing_ds_manufacturing_order_return_to_stock_gridService {
    if(!this._ds_manufacturing_order_return_to_stock_grid) {
      this._ds_manufacturing_order_return_to_stock_grid = this.injector.get(Manufacturing_ds_manufacturing_order_return_to_stock_gridService);
    }
    return this._ds_manufacturing_order_return_to_stock_grid;
  }
  private _ds_manufacturing_orders_create_serial_numbers_editor_dummy: Manufacturing_ds_manufacturing_orders_create_serial_numbers_editor_dummyService;
  public get ds_manufacturing_orders_create_serial_numbers_editor_dummy(): Manufacturing_ds_manufacturing_orders_create_serial_numbers_editor_dummyService {
    if(!this._ds_manufacturing_orders_create_serial_numbers_editor_dummy) {
      this._ds_manufacturing_orders_create_serial_numbers_editor_dummy = this.injector.get(Manufacturing_ds_manufacturing_orders_create_serial_numbers_editor_dummyService);
    }
    return this._ds_manufacturing_orders_create_serial_numbers_editor_dummy;
  }
  private _ds_manufacturing_orders_serial_numbers_production_create: Manufacturing_ds_manufacturing_orders_serial_numbers_production_createService;
  public get ds_manufacturing_orders_serial_numbers_production_create(): Manufacturing_ds_manufacturing_orders_serial_numbers_production_createService {
    if(!this._ds_manufacturing_orders_serial_numbers_production_create) {
      this._ds_manufacturing_orders_serial_numbers_production_create = this.injector.get(Manufacturing_ds_manufacturing_orders_serial_numbers_production_createService);
    }
    return this._ds_manufacturing_orders_serial_numbers_production_create;
  }
  private _ds_manufacturing_return_to_stock_dummy: Manufacturing_ds_manufacturing_return_to_stock_dummyService;
  public get ds_manufacturing_return_to_stock_dummy(): Manufacturing_ds_manufacturing_return_to_stock_dummyService {
    if(!this._ds_manufacturing_return_to_stock_dummy) {
      this._ds_manufacturing_return_to_stock_dummy = this.injector.get(Manufacturing_ds_manufacturing_return_to_stock_dummyService);
    }
    return this._ds_manufacturing_return_to_stock_dummy;
  }
  private _ds_material_packagings_dd: Manufacturing_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): Manufacturing_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(Manufacturing_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_materials_by_project_dd: Manufacturing_ds_materials_by_project_ddService;
  public get ds_materials_by_project_dd(): Manufacturing_ds_materials_by_project_ddService {
    if(!this._ds_materials_by_project_dd) {
      this._ds_materials_by_project_dd = this.injector.get(Manufacturing_ds_materials_by_project_ddService);
    }
    return this._ds_materials_by_project_dd;
  }
  private _ds_measurement_units_dd: Manufacturing_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Manufacturing_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Manufacturing_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_owners_dd: Manufacturing_ds_owners_ddService;
  public get ds_owners_dd(): Manufacturing_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Manufacturing_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_physical_inventory_by_license_plate_lot: Manufacturing_ds_physical_inventory_by_license_plate_lotService;
  public get ds_physical_inventory_by_license_plate_lot(): Manufacturing_ds_physical_inventory_by_license_plate_lotService {
    if(!this._ds_physical_inventory_by_license_plate_lot) {
      this._ds_physical_inventory_by_license_plate_lot = this.injector.get(Manufacturing_ds_physical_inventory_by_license_plate_lotService);
    }
    return this._ds_physical_inventory_by_license_plate_lot;
  }
  private _ds_projects_dd: Manufacturing_ds_projects_ddService;
  public get ds_projects_dd(): Manufacturing_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Manufacturing_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_serialnumbers_creation_grid: Manufacturing_ds_serialnumbers_creation_gridService;
  public get ds_serialnumbers_creation_grid(): Manufacturing_ds_serialnumbers_creation_gridService {
    if(!this._ds_serialnumbers_creation_grid) {
      this._ds_serialnumbers_creation_grid = this.injector.get(Manufacturing_ds_serialnumbers_creation_gridService);
    }
    return this._ds_serialnumbers_creation_grid;
  }
  private _ds_serialnumbers_expand: Manufacturing_ds_serialnumbers_expandService;
  public get ds_serialnumbers_expand(): Manufacturing_ds_serialnumbers_expandService {
    if(!this._ds_serialnumbers_expand) {
      this._ds_serialnumbers_expand = this.injector.get(Manufacturing_ds_serialnumbers_expandService);
    }
    return this._ds_serialnumbers_expand;
  }
  private _ds_time_selector_dd: Manufacturing_ds_time_selector_ddService;
  public get ds_time_selector_dd(): Manufacturing_ds_time_selector_ddService {
    if(!this._ds_time_selector_dd) {
      this._ds_time_selector_dd = this.injector.get(Manufacturing_ds_time_selector_ddService);
    }
    return this._ds_time_selector_dd;
  }
  private _ds_warehouses_dd: Manufacturing_ds_warehouses_ddService;
  public get ds_warehouses_dd(): Manufacturing_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(Manufacturing_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
  private _inventory_report: Manufacturing_inventory_reportService;
  public get inventory_report(): Manufacturing_inventory_reportService {
    if(!this._inventory_report) {
      this._inventory_report = this.injector.get(Manufacturing_inventory_reportService);
    }
    return this._inventory_report;
  }
  private _test_get_plan: Manufacturing_test_get_planService;
  public get test_get_plan(): Manufacturing_test_get_planService {
    if(!this._test_get_plan) {
      this._test_get_plan = this.injector.get(Manufacturing_test_get_planService);
    }
    return this._test_get_plan;
  }
  private _test_owner_inventory: Manufacturing_test_owner_inventoryService;
  public get test_owner_inventory(): Manufacturing_test_owner_inventoryService {
    if(!this._test_owner_inventory) {
      this._test_owner_inventory = this.injector.get(Manufacturing_test_owner_inventoryService);
    }
    return this._test_owner_inventory;
  }
  private _testdfvr: Manufacturing_testdfvrService;
  public get testdfvr(): Manufacturing_testdfvrService {
    if(!this._testdfvr) {
      this._testdfvr = this.injector.get(Manufacturing_testdfvrService);
    }
    return this._testdfvr;
  }
  private _testz: Manufacturing_testzService;
  public get testz(): Manufacturing_testzService {
    if(!this._testz) {
      this._testz = this.injector.get(Manufacturing_testzService);
    }
    return this._testz;
  }
}

