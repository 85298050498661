import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { InventoryTransfers_accounts_dd_singleComponent } from './InventoryTransfers.accounts_dd_single.component';
import { InventoryTransfers_inventory_transfer_statuses_dd_singleComponent } from './InventoryTransfers.inventory_transfer_statuses_dd_single.component';
import { InventoryTransfers_accounts_dd_multiComponent } from './InventoryTransfers.accounts_dd_multi.component';
import { InventoryTransfers_inventory_transfer_statuses_dd_multiComponent } from './InventoryTransfers.inventory_transfer_statuses_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public InventoryTransfers: InventoryTransfers_ShellService = this;


  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'InventoryTransfers_accounts_dd_single') {
      this.$logger.log('InventoryTransfers', 'accounts_dd_single');
      const title = 'Accounts dropdown';
      const component = InventoryTransfers_accounts_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransfers_inventory_transfer_statuses_dd_single') {
      this.$logger.log('InventoryTransfers', 'inventory_transfer_statuses_dd_single');
      const title = 'inventory_transfer_statuses_dd';
      const component = InventoryTransfers_inventory_transfer_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransfers_accounts_dd_multi') {
      this.$logger.log('InventoryTransfers', 'accounts_dd_multi');
      const title = 'Accounts dropdown';
      const component = InventoryTransfers_accounts_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'InventoryTransfers_inventory_transfer_statuses_dd_multi') {
      this.$logger.log('InventoryTransfers', 'inventory_transfer_statuses_dd_multi');
      const title = 'inventory_transfer_statuses_dd';
      const component = InventoryTransfers_inventory_transfer_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
