import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_source_line_editor_ComponentContextService } from './FootprintManager.inventory_transformation_source_line_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_transformation_source_lines_selector_by_licenseplate_gridComponent } from './FootprintManager.inventory_transformation_source_lines_selector_by_licenseplate_grid.component';
import { FootprintManager_inventory_transformation_source_lines_selector_by_lot_gridComponent } from './FootprintManager.inventory_transformation_source_lines_selector_by_lot_grid.component';
import { FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent } from './FootprintManager.inventory_transformation_source_lines_selector_by_location_grid.component';
import { FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent } from './FootprintManager.inventory_transformation_source_lines_selector_by_serialnumbers_grid.component';
import { FootprintManager_inventory_transformation_source_lines_selector_by_material_gridComponent } from './FootprintManager.inventory_transformation_source_lines_selector_by_material_grid.component';
import { InventoryTransformations_inventory_transformation_types_dd_singleComponent } from './InventoryTransformations.inventory_transformation_types_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'

type EntityType = { 
    Id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_selector_by_licenseplate_gridComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_selector_by_lot_gridComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_selector_by_material_gridComponent),
    forwardRef(() => InventoryTransformations_inventory_transformation_types_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Lots_lots_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_source_line_editor',
  templateUrl: './FootprintManager.inventory_transformation_source_line_editor.component.html'
})
export class FootprintManager_inventory_transformation_source_line_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { project_id: number, warehouse_id: number, inventory_transformation_id: number, source_line_id?: number } = { project_id: null, warehouse_id: null, inventory_transformation_id: null, source_line_id: null };
  //#region Inputs
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(v: number) {
    this.inParams.inventory_transformation_id = v;
  }
  get $inParams_inventory_transformation_id(): number {
    return this.inParams.inventory_transformation_id;
  }
  @Input('source_line_id') set $inParams_source_line_id(v: number) {
    this.inParams.source_line_id = v;
  }
  get $inParams_source_line_id(): number {
    return this.inParams.source_line_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { source_line_id?: number } = { source_line_id: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { source_line?: { Id?: number, SourceLicensePlateId?: number, SourceLotId?: number, SourceMaterialId?: number, SourcePackageAmount?: number, SourcePackageId?: number, SourceSerialNumberId?: number, SourceVendorLotId?: number }[], source_line_ids?: number[], source_line_id?: number, is_fixed?: boolean, serialized?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Type', true, false),
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material code', true, false),
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', false, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot', false, false),
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      inventory: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory: new TabItemModel(
        this.rootTabGroup, 
        'Inventory', 
        false,
        true,
        {
          by_licenseplate: new TabItemModel(
          this.subTabGroups.inventory, 
          'By licenseplate', 
          false
          ),
          by_lot: new TabItemModel(
          this.subTabGroups.inventory, 
          'By lot', 
          false
          ),
          by_location: new TabItemModel(
          this.subTabGroups.inventory, 
          'By location', 
          false
          ),
          by_serialnumber: new TabItemModel(
          this.subTabGroups.inventory, 
          'By serialnumber', 
          false
          ),
          by_material: new TabItemModel(
          this.subTabGroups.inventory, 
          'By material', 
          false
          ),
        }
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.material.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_vendor_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.vendor_lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_package_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.packaging.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_type(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.type.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_source_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars?.source_line_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.material.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_vendor_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.vendor_lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_package_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.packaging.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_type(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.type.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_source_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars?.source_line_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.material.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_vendor_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.vendor_lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_package_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.packaging.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_type(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.type.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.material.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_vendor_lot_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.vendor_lot.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_package_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.packaging.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_type(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.type.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_source_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.source_line_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.material.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_package_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.packaging.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_type(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.type.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_source_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.source_line_id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
        @ViewChild('$tabs_inventory_by_licenseplate', { read: FootprintManager_inventory_transformation_source_lines_selector_by_licenseplate_gridComponent }) $tabs_inventory_by_licenseplate: FootprintManager_inventory_transformation_source_lines_selector_by_licenseplate_gridComponent;
        @ViewChild('$tabs_inventory_by_lot', { read: FootprintManager_inventory_transformation_source_lines_selector_by_lot_gridComponent }) $tabs_inventory_by_lot: FootprintManager_inventory_transformation_source_lines_selector_by_lot_gridComponent;
        @ViewChild('$tabs_inventory_by_location', { read: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent }) $tabs_inventory_by_location: FootprintManager_inventory_transformation_source_lines_selector_by_location_gridComponent;
        @ViewChild('$tabs_inventory_by_serialnumber', { read: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent }) $tabs_inventory_by_serialnumber: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent;
        @ViewChild('$tabs_inventory_by_material', { read: FootprintManager_inventory_transformation_source_lines_selector_by_material_gridComponent }) $tabs_inventory_by_material: FootprintManager_inventory_transformation_source_lines_selector_by_material_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.inParams.project_id;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.vendor_lot.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.material.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.lot.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    type: this.fields.type.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory,
    ]; 
    this.subTabGroups.inventory.tabs = [
        this.tabs.inventory.tabs.by_licenseplate,
        this.tabs.inventory.tabs.by_lot,
        this.tabs.inventory.tabs.by_location,
        this.tabs.inventory.tabs.by_serialnumber,
        this.tabs.inventory.tabs.by_material,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'inventory_transformation_source_line_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      project_id:  $editor.inParams.project_id 
    };

    const data = await this.$datasources.InventoryTransformations.ds_get_inventory_transformation_source_line_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.type.control as SelectBoxModel).reset(1);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_inventory_by_licenseplate') {
        if (!isNil(this.$tabs_inventory_by_licenseplate) && !this.tabs.inventory.tabs.by_licenseplate.hidden && !this.tabs.inventory.tabs.by_licenseplate.removed) {
          this.$tabs_inventory_by_licenseplate.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_by_lot') {
        if (!isNil(this.$tabs_inventory_by_lot) && !this.tabs.inventory.tabs.by_lot.hidden && !this.tabs.inventory.tabs.by_lot.removed) {
          this.$tabs_inventory_by_lot.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_by_location') {
        if (!isNil(this.$tabs_inventory_by_location) && !this.tabs.inventory.tabs.by_location.hidden && !this.tabs.inventory.tabs.by_location.removed) {
          this.$tabs_inventory_by_location.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_by_serialnumber') {
        if (!isNil(this.$tabs_inventory_by_serialnumber) && !this.tabs.inventory.tabs.by_serialnumber.hidden && !this.tabs.inventory.tabs.by_serialnumber.removed) {
          this.$tabs_inventory_by_serialnumber.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_inventory_by_material') {
        if (!isNil(this.$tabs_inventory_by_material) && !this.tabs.inventory.tabs.by_material.hidden && !this.tabs.inventory.tabs.by_material.removed) {
          this.$tabs_inventory_by_material.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_type_changed();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_changed();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_type_changed(event = null) {
    return this.on_type_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_type_changedInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_type_changed');
  //disable all fields if type != 1
  if ($editor.fields.type.control.value != 1)
  {
  
      $editor.fields.material.hidden = true
      $editor.fields.lot.hidden = true
      $editor.fields.packaging.hidden = true
      $editor.fields.vendor_lot.hidden = true
  
      for (let key in $editor.tabs) {
      $editor.tabs[key].hidden = true;
  }
  }
  
  else
  {
      $editor.fields.material.hidden = false
      $editor.fields.lot.hidden = false
      $editor.fields.packaging.hidden = false
      $editor.fields.vendor_lot.hidden = false
  
      for (let key in $editor.tabs) {
      $editor.tabs[key].hidden = false;
  }
  
  }
  
  }
  on_grid_output_change(event = null) {
    return this.on_grid_output_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_output_changeInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_grid_output_change');
  
  
  
  $editor.outParams.source_line_id = $editor?.vars?.source_line_id
  // Emit all outparams that have been set
  document?.getElementsByTagName('FootprintManager-inventory_transformation_advanced_wizard')[0]?.dispatchEvent(new CustomEvent('handle_source_line_value', { 'detail': [$editor?.outParams?.source_line_id] })); ///change to FootprintManager-inventory_transformation_advanced_wizard
  document?.getElementsByTagName('FootprintManager-inventory_transformation_quick_wizard')[0]?.dispatchEvent(new CustomEvent('handle_source_line_value', { 'detail': [$editor?.outParams?.source_line_id] })); ///change to FootprintManager-inventory_transformation_advanced_wizard
  
  
  
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_data_loaded');
  
  
  //check if source line already exists for the transformation
  
  let source_line: {
      Id?: number;
      SourceLicensePlateId?: number;
      SourceLotId?: number;
      SourceVendorLotId?: number;
      SourceMaterialId?: number;
      SourcePackageAmount?: number;
      SourcePackageId?: number;
      SourceSerialNumberId?: number;
      StatusId?: number;
      ErrorMessage?: string;
      LicensePlate?: {
          LookupCode?: string;
          Location?: {
              Id?: number;
              Name?: string;
          }
      }
      Material?: {
          ControllerTypeId?: number;
      }
      Lot?: {
          Id?: number;
          LookupCode?: string;
          VendorLot?: {
              Id?: number;
              LookupCode?: string;
              ManufactureDate?: string;
              ExpirationDate?: string;
          }
      }
      InventoryTransformationLineStatus?: {
          Name?: string;
      }
      InventoryTransformation?: {
          LookupCode?: string;
      }
      SerialNumbers?: {
          Id?: number;
          LookupCode?: string;
          Licenseplate?: {
              Id?: number;
              LookupCode?: string;
              Location?: {
                  Id?: number;
                  Name?: string;
              }
          }
      }
  }[] = null
  
  
  if ($utils.isDefined($editor.inParams.source_line_id) || $utils.isDefined($editor.vars.source_line_ids)) {
      source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $editor.inParams.inventory_transformation_id,
          ids: $editor.vars.source_line_ids ?? [$editor.inParams.source_line_id],
      }))?.result
  
      if ($utils.isDefined(source_line)) {
          $editor.vars.source_line = source_line
          $editor.vars.source_line_id = source_line[0]?.Id
          $editor.fields.material.control.value = source_line[0]?.SourceMaterialId
          $editor.fields.lot.control.value = source_line[0]?.SourceLotId
          $editor.fields.packaging.control.value = source_line[0]?.SourcePackageId
          $editor.fields.vendor_lot.control.value = source_line[0]?.SourceVendorLotId
  
          $editor.fields.material.control.readOnly = true
          $editor.fields.lot.control.readOnly = true
          $editor.fields.packaging.control.readOnly = true
          $editor.fields.vendor_lot.control.readOnly = true
  
          //if source licenseplate is defined, hide by lot and by serial
          if ($utils.isDefined(source_line[0]?.SourceLicensePlateId)) {
              $editor.tabs.inventory.tabs.by_licenseplate.hidden = false
              $editor.tabs.inventory.tabs.by_lot.hidden = true
              $editor.tabs.inventory.tabs.by_serialnumber.hidden = true
              $editor.tabs.inventory.tabs.by_material.hidden = true
              await $editor.tabs.inventory.tabs.by_licenseplate.activate()
          }
  
          //if source serial is defined, hide by lot and by LP
          if ($utils.isDefined(source_line[0]?.SourceSerialNumberId)) {
              $editor.tabs.inventory.tabs.by_licenseplate.hidden = true
              $editor.tabs.inventory.tabs.by_lot.hidden = true
              $editor.tabs.inventory.tabs.by_serialnumber.hidden = false
              $editor.tabs.inventory.tabs.by_material.hidden = true
              await $editor.tabs.inventory.tabs.by_serialnumber.activate()
          }
  
          //if source licenseplate and serial is missing, hide by serial and by LP
          if (!$utils.isDefined(source_line[0]?.SourceSerialNumberId) && !$utils.isDefined(source_line[0]?.SourceLicensePlateId)) {
              $editor.tabs.inventory.tabs.by_licenseplate.hidden = true
              $editor.tabs.inventory.tabs.by_lot.hidden = false
              $editor.tabs.inventory.tabs.by_serialnumber.hidden = true
              $editor.tabs.inventory.tabs.by_material.hidden = true
              await $editor.tabs.inventory.tabs.by_lot.activate()
          }
  
          //if source licenseplate, lot, and serial is missing, hide by serial and by LP
          if (!$utils.isDefined(source_line[0]?.SourceSerialNumberId) && !$utils.isDefined(source_line[0]?.SourceLicensePlateId) && !$utils.isDefined(source_line[0]?.SourceLotId)) {
              $editor.tabs.inventory.tabs.by_licenseplate.hidden = true
              $editor.tabs.inventory.tabs.by_lot.hidden = true
              $editor.tabs.inventory.tabs.by_serialnumber.hidden = true
              $editor.tabs.inventory.tabs.by_material.hidden = false
              await $editor.tabs.inventory.tabs.by_material.activate()
          }
      }
      else {
  
          $editor.fields.material.control.readOnly = false
          $editor.fields.lot.control.readOnly = false
          $editor.fields.packaging.control.readOnly = false
          $editor.fields.vendor_lot.control.readOnly = false
  
          $editor.tabs.inventory.tabs.by_licenseplate.hidden = false
          $editor.tabs.inventory.tabs.by_lot.hidden = false
          $editor.tabs.inventory.tabs.by_serialnumber.hidden = false
          $editor.tabs.inventory.tabs.by_material.hidden = false
          $editor.vars.source_line = null
          $editor.vars.source_line_id = null
  
  
      }
  
  
  
  }
  
  
  
  
  
  await $editor.on_grid_output_change();
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_init');
  //disable by location tab
  $editor.tabs.inventory.tabs.by_location.hidden = true
  $editor.fields.type.hidden = true
  
  
  await add_events()
  
  
  
  
  /*************************************
   * CUSTOM EVENTS
  **************************************/
  async function add_events() {
      await new Promise(result => setTimeout(result, 100));
  
      // Get element to attach to 
      const element = document?.getElementsByTagName('FootprintManager-inventory_transformation_source_line_editor')[0]; ///change to FootprintManager-inventory_transformation_advanced_wizard
  
      if (element == null) {
          // throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      // 'on' events to detect different stages of the wizard
      element?.addEventListener('on_inventory_transformation_source_lines_selector_by_licenseplate_grid_initialized', ((event?: CustomEvent | null) => { }) as EventListener);
  
      // 'handle' events to perform common actions that may be invoked
      element?.addEventListener('handle_source_line_value', ((event?: CustomEvent<number[]> | null) => {
          $editor.vars.source_line_ids = event?.detail ?? [];
          $editor.vars.source_line_id = event?.detail[0]
      }) as EventListener);
  
  }
  
  
  
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_material_changed');
  $editor.fields.lot.control.value = null
  $editor.fields.vendor_lot.control.value = null
  $editor.fields.packaging.control.value = null
  $editor.tabs.inventory.tabs.by_lot.hidden = true
  $editor.tabs.inventory.tabs.by_licenseplate.hidden = true
  $editor.tabs.inventory.tabs.by_serialnumber.hidden = true
  $editor.tabs.inventory.tabs.by_material.hidden = false
  
  $editor.vars.serialized = false
  
  //check if material is serial controlled
  if ($utils.isDefined($editor.fields.material.control.value)) {
      
  
      let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: $editor.fields.material.control.value
      }))?.result[0]
  
      let material_controller = material_check?.ControllerTypeId
  
      //hide full_quantity for serialized materials
      if (material_controller === 3 || material_controller === 4 || !material_check?.IsFixedLength || !material_check?.IsFixedVolume || !material_check?.IsFixedWeight) {
          $editor.tabs.inventory.tabs.by_serialnumber.hidden = false
          $editor.tabs.inventory.tabs.by_licenseplate.hidden = false
          $editor.tabs.inventory.tabs.by_lot.hidden = false
          await $editor.tabs.inventory.tabs.by_licenseplate.activate()
          $editor.vars.serialized = true  
      }
  
      if (material_controller === 1) {
          $editor.tabs.inventory.tabs.by_licenseplate.hidden = false
          await $editor.tabs.inventory.tabs.by_licenseplate.activate()
      }
      if (material_controller === 2) {
          $editor.tabs.inventory.tabs.by_licenseplate.hidden = false
          $editor.tabs.inventory.tabs.by_lot.hidden = false
          await $editor.tabs.inventory.tabs.by_licenseplate.activate()
      }
  
  
  }
  
  
  
  
  }
  on_lot_changed(event = null) {
    return this.on_lot_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changedInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_lot_changed');
  $editor.fields.vendor_lot.control.value = null
  }
  //#endregion private flows
  //#region validation flows
  
  on_form_validation(fieldErrors: { type: Pick<string[], 'push'>,material: Pick<string[], 'push'>,packaging: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>, } = null) {
    const emptyResult = { type:[],material:[],packaging:[],lot:[],vendor_lot:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_form_validationInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async on_form_validationInternal(
    $editor: FootprintManager_inventory_transformation_source_line_editorComponent,
    $validation:{
      fieldErrors: { type: Pick<string[], 'push'>,material: Pick<string[], 'push'>,packaging: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>, }
    },
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $context: FootprintManager_inventory_transformation_source_line_editor_ComponentContextService
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_line_editor.on_form_validation');
    
  
  
  
  switch ($editor.fields.type.control.value) {
      //one-to-many
      case 1:
          //check source lines for selected input
          let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
              {
                  inventory_transformation_id: $editor.inParams.inventory_transformation_id,
              }
          ))?.result
  
          if ($utils.isDefined(source_line_check)) {
  
              $editor.outParams.source_line_id = source_line_check[0].Id
          
          }
  
          break;
  
      case 2:
          break;
  
      case 3:
          break;
  
      default:
          break;
  
  }
  
  
  
    return $validation.fieldErrors as { type:[],material:[],packaging:[],lot:[],vendor_lot:[], };
  }
  //#endregion validation flows
  
}
