import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_print_order_labels_form_ComponentContextService } from './FootprintManager.print_order_labels_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { PrintNode_printers_dd_singleComponent } from './PrintNode.printers_dd_single.component'
import { BarTender_printers_dd_singleComponent } from './BarTender.printers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PrintNode_printers_dd_singleComponent),
    forwardRef(() => BarTender_printers_dd_singleComponent),
  ],
  selector: 'FootprintManager-print_order_labels_form',
  templateUrl: './FootprintManager.print_order_labels_form.component.html'
})
export class FootprintManager_print_order_labels_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { orderId: number, context: string, shipmentId?: number } = { orderId: null, context: null, shipmentId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  @Input('context') set $inParams_context(v: string) {
    this.inParams.context = v;
  }
  get $inParams_context(): string {
    return this.inParams.context;
  }
  @Input('shipmentId') set $inParams_shipmentId(v: number) {
    this.inParams.shipmentId = v;
  }
  get $inParams_shipmentId(): number {
    return this.inParams.shipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    printnode_printer: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    bartender_printer: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(['primary'], null), false, false, false, 'Print', 'icon-ic_fluent_print_20_regular', null)
    , false),
      preview: new ToolModel(new ButtonModel('preview', new ButtonStyles(['secondary'], null), false, false, false, 'Preview', 'icon-ic_fluent_document_search_20_regular', null)
    , false)
  };

  fields = {
    printnode_printer: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printnode_printer'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Printer', true, false),
    bartender_printer: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['bartender_printer'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Printer', true, false),
  };

  fieldsets = {
    printnode: new FieldsetModel(
      'Print node',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    bartender: new FieldsetModel(
      'Bartender',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    printnode_printer: this.fields.printnode_printer.control.valueChanges
    ,
    bartender_printer: this.fields.bartender_printer.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_print_order_labels_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.context)) {
        this.$missingRequiredInParams.push('context');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Print order labels form';
  
    const $form = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .printnode_printer
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_printer_change();
      });
    this.$formGroupFieldValidationObservables
      .bartender_printer
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_printer_change();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_print_order_labels_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_print_order_labels_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const bartenderConfigs = await $datasources.BarTender.ds_get_configurations.getList({});
  const bartenderConnectionString = bartenderConfigs.result.find(c => c.Name === $types.BarTender.ConfigurationsEnum.AzureBlobConnectionString)?.Value;
  
  // PrintNode
  if ($utils.isDefinedTrimmed($settings.FootprintManager.PrintNodeKey) && $settings.FootprintManager.PrintNodeKey.trim().toUpperCase() !== 'N/A') {
      $form.toolbar.print.control.readOnly = true;
      $form.fieldsets.bartender.hidden = true;
  
      let result = (await $flows.PrintNode.validate_credentials_flow({}));
  }
  // BarTender
  else if ($utils.isDefinedTrimmed(bartenderConnectionString)) {
      $form.toolbar.print.control.readOnly = true;
      $form.toolbar.preview.hidden = true;
      $form.fieldsets.printnode.hidden = true;
  }
  // Browser print
  else {
      $form.close();
      await $form.browser_print_report();
  }
  }
  browser_print_report(event = null) {
    return this.browser_print_reportInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async browser_print_reportInternal(
    $form: FootprintManager_print_order_labels_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_print_order_labels_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($form.inParams.context === "po") {
  
      await $shell.AsnOrders.openlicense_plate_labels_by_order_reportDialog({ orderId: $form.inParams.orderId });
  }
  else if ($form.inParams.context === "asn") {
  
      await $shell.AsnOrders.openlicense_plate_labels_by_order_reportDialog({ orderId: $form.inParams.orderId });
  }
  else if ($form.inParams.context === "so") {
      
      await $shell.SalesOrders.openlicense_plate_labels_by_order_reportDialog({ orderId: $form.inParams.orderId });
  }
  else {
      await $shell.FootprintManager.openErrorDialog('Error printing labels', 'The labal report is not configured!');
  }
  }
  on_preview_clicked(event = null) {
    return this.on_preview_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_preview_clickedInternal(
    $form: FootprintManager_print_order_labels_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_print_order_labels_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $form.browser_print_report();
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $form: FootprintManager_print_order_labels_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_print_order_labels_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      if (!$form.fieldsets.printnode.hidden) {
  
          let reportData: Blob;
  
          if ($form.inParams.context === "po") {
  
              reportData = (await $reports.AsnOrders.license_plate_labels_by_order_report.exportAsPdf({ orderId: $form.inParams.orderId })).data;
          }
          else if ($form.inParams.context === "asn") {
  
              reportData = (await $reports.AsnOrders.license_plate_labels_by_order_report.exportAsPdf({ orderId: $form.inParams.orderId })).data;
          }
          else if ($form.inParams.context === "so") {
  
              reportData = (await $reports.SalesOrders.license_plate_labels_by_order_report.exportAsPdf({ orderId: $form.inParams.orderId })).data;
          }
  
          // Print via PrintNode
          await $flows.PrintNode.print_request_flow({
              printerId: $form.fields.printnode_printer.control.value,
              contentType: 'pdf_base64',
              content: await $utils.blob.toBase64(reportData)
          })
      }
      else if (!$form.fieldsets.bartender.hidden) {
  
          // Print via BarTender
          let result = (await $flows.BarTender.print_asn_license_plate_labels_flow({
              orderId: $form.inParams.orderId,
              licensePlateId: null,
              options: {
                  printerName: $form.fields.bartender_printer.control.value
              }
          }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error('An error occurred when printing: ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
      }
      else {
          
          // Print via browser client
          $form.browser_print_report();
      }
  
      $form.close();
  }
  catch (error) {
      await $shell.FootprintManager.openErrorDialog('Error printing labels', error.message);
  }
  }
  on_printer_change(event = null) {
    return this.on_printer_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printer_changeInternal(
    $form: FootprintManager_print_order_labels_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_print_order_labels_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $form.toolbar.print.control.readOnly = (!$form.fieldsets.printnode.hidden && !$utils.isDefined($form.fields.printnode_printer.control.value)) || (!$form.fieldsets.bartender.hidden && !$utils.isDefined($form.fields.bartender_printer.control.value));
  }
  //#endregion private flows
}
