import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { CalendarDayViewComponent } from './components/calendar/calendar-day-view.component';
import { CalendarEvent } from './components/calendar/calendar-utils';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_orders_calendar_ComponentContextService } from './Manufacturing.manufacturing_orders_calendar.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_manufacturing_output_locations_dd_multiComponent } from './Manufacturing.manufacturing_output_locations_dd_multi.component'
import { Manufacturing_order_statuses_dd_singleComponent } from './Manufacturing.order_statuses_dd_single.component'


import { Manufacturing_manufacturing_orders_for_calendar_cardComponent } from './Manufacturing.manufacturing_orders_for_calendar_card.component';

interface IManufacturing_manufacturing_orders_calendarComponentEntityData {
Id?: number, ActualCompleteOn?: string, ActualStartOn?: string, DefaultStagingLocationId?: number, EstimatedCompleteOn?: string, EstimatedStartOn?: string, LookupCode?: string, ManufacturingLocationId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, OrderLines?: { Id?: number, Amount?: number, Material?: { Name?: string }, Tasks?: { ActualPackagedAmount?: number, OperationCodeId?: number }[] }[]}

interface IManufacturing_manufacturing_orders_calendarComponentColumnData {
Id?: number, EligibleForAllocation?: boolean, IsLoose?: boolean, ManufacturingLineFlag?: boolean, Name?: string, TypeId?: number, WarehouseId?: number, LocationContainersAssignedProjectsLookupLocationContainer?: { ProjectId?: number }[]}

interface IManufacturing_manufacturing_orders_calendarComponentInParams {
  warehouse_id?: number, project_id?: number}


class Manufacturing_manufacturing_orders_calendarComponentCalendarEventModel extends CalendarEvent<IManufacturing_manufacturing_orders_calendarComponentEntityData> {
  calendar: Manufacturing_manufacturing_orders_calendarComponent;
  
  //#region eventContent inParams


  get $eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_id(): number {

    const $calendar = this.calendar;
    const $utils = this.$utils;
    const $calendarEvent = this;

    const expr = $calendarEvent.entity.Id;
    
    return expr;
  }


  get $eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_lookup_code(): string {

    const $calendar = this.calendar;
    const $utils = this.$utils;
    const $calendarEvent = this;

    const expr = $calendarEvent.entity.LookupCode;
    
    return expr;
  }

  cacheValueFor_$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines: string[];

  get $eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines(): string[] {

    const $calendar = this.calendar;
    const $utils = this.$utils;
    const $calendarEvent = this;

    const expr = $calendarEvent.entity.OrderLines.map(o => `${o.Material.Name} - ${Math.floor((o.Tasks.filter(t => t.OperationCodeId == 49).reduce((acc, item) => acc + item.ActualPackagedAmount, 0)-o.Tasks.filter(t => t.OperationCodeId == 60).reduce((acc, item) => acc + item.ActualPackagedAmount, 0))*100/o.Amount)}% completed of ${o.Amount}`);
    
    if(!isEqual(this.cacheValueFor_$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines, expr)) {
      this.cacheValueFor_$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines = expr;
    }
    return this.cacheValueFor_$eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_order_lines;
  }


  get $eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_start_date(): string {

    const $calendar = this.calendar;
    const $utils = this.$utils;
    const $calendarEvent = this;

    const expr = $calendarEvent.entity.ActualStartOn ?? $calendarEvent.entity.EstimatedStartOn;
    
    return expr;
  }


  get $eventContent_Manufacturing_manufacturing_orders_for_calendar_card_inParams_end_date(): string {

    const $calendar = this.calendar;
    const $utils = this.$utils;
    const $calendarEvent = this;

    const expr = $calendarEvent.entity.ActualCompleteOn ?? $calendarEvent.entity.EstimatedCompleteOn;
    
    return expr;
  }
  //#endregion eventContent inParams


  constructor(
    private $utils: UtilsService) {
      super();
  }
}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_manufacturing_output_locations_dd_multiComponent),
    forwardRef(() => Manufacturing_order_statuses_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_orders_for_calendar_cardComponent)
  ],
  selector: 'Manufacturing-manufacturing_orders_calendar',
  templateUrl: './Manufacturing.manufacturing_orders_calendar.component.html'
})
export class Manufacturing_manufacturing_orders_calendarComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManufacturing_manufacturing_orders_calendarComponentEntityData[];
  columns: IManufacturing_manufacturing_orders_calendarComponentColumnData[];
  calendarEvents: Manufacturing_manufacturing_orders_calendarComponentCalendarEventModel[] = [];

  dayStartHour = 0;
  dayStartMinute = 0;
  dayEndHour = 23;
  dayEndMinute = 59;
  hourSegments = 2;
  hourSegmentHeight = 80;

  columnsPageSize = 5;
  columnsPageSkip = 0;
  columnsTotalCount = 0;
  calendarEventsTotalCount = 0;
  calendarEventsFetchedCount = 0;


  // TODO: 
  containerClass: string;



  inParams: IManufacturing_manufacturing_orders_calendarComponentInParams = { warehouse_id: null, project_id: null };


  //#region Variables
  vars: { start_date?: string, end_date?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  @ViewChild('$calendarComponent', { read:  CalendarDayViewComponent}) $calendarComponent: CalendarDayViewComponent;

  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }

  topToolbar = {
      previous: new ToolModel(new ButtonModel('previous', new ButtonStyles(null, null), false, false, false, 'Previous', 'icon-ic_fluent_arrow_circle_left_20_regular', null)
    , false),
      today: new ToolModel(new ButtonModel('today', new ButtonStyles(null, null), false, false, false, 'Today', 'ms-Icon ms-Icon--GotoToday', null)
    , false),
      next: new ToolModel(new ButtonModel('next', new ButtonStyles(null, null), false, false, false, 'Next', 'icon-ic_fluent_arrow_circle_right_20_regular', null)
    , false)
  };

  formGroup: FormGroup = new FormGroup({
    view_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    output_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    state: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    view_date: new FieldModel(new DateBoxModel(this.formGroup.controls['view_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Current date', false, false),
    output_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['output_location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Output location', false, false),
    state: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['state'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Completion state', false, false),
  }

  //#region filters inParams
  get $fields_output_location_selector_inParams_warehouse_id(): number {
    const $calendar = this;
    const $utils = this.$utils;
    const expr = $calendar.inParams.warehouse_id;
    
    return expr;
  }

  get $fields_output_location_selector_inParams_project_id(): number {
    const $calendar = this;
    const $utils = this.$utils;
    const expr = $calendar.inParams.project_id;
    
    return expr;
  }

  //#endregion filters inParams

  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_orders_calendar_ComponentContextService,
) {
    super();
    this.title = 'manufacturing_orders_calendar';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    const $calendar = this;
    const $utils = this.$utils;

    (this.filters.view_date.control as DateBoxModel).reset((new Date()).toISOString());

    await this.on_date_change();
    this.initialized = true;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearCalendarEvents();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['view_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.columnsPageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $calendarEventPropertyChangeCallback (source: CalendarEvent, property: string): void {
    if (property === 'selected') {
      this.$calendarComponent.updateAllSelected();
    }
  }

  get viewDate(): string {
    const $calendar = this;
    const $utils = this.$utils;
    return $calendar.filters.view_date.control.value;
  }

  $matchEventToColumn(
    $column: IManufacturing_manufacturing_orders_calendarComponentColumnData, 
    $calendarEvent: Manufacturing_manufacturing_orders_calendarComponentCalendarEventModel): boolean {
    const $calendar = this;
    const $utils = this.$utils;
    return $column.Id === $calendarEvent.entity?.ManufacturingLocationId;
  }

  async $dataLoad() {
    if(!this.formGroup.valid) {
      return;
    }

    await this.$dataLoadColumns();
    await this.$dataLoadEvents();
    await this.$dataLoaded();
  }

  async $dataLoadColumns() {
    const $calendar = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.columnsPageSize,
      $skip: this.columnsPageSkip,
      warehouse_id:  $calendar.inParams.warehouse_id ,
      full_text_search:  null ,
      project_id:  $calendar.inParams.project_id ,
      location_ids:  $calendar.filters.output_location.control.value ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_output_locations_dd.getList(inParams);
    this.columns = data.result;
    this.columnsTotalCount = data.totalCount;
  }

  async $dataLoadEvents() {
    const $calendar = this;
    const $utils = this.$utils;
    const inParams = {
      start_of_day:  $calendar.vars.start_date ,
      end_of_day:  $calendar.vars.end_date ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_orders_for_calendar.getList(inParams);
    this.entities = data.result;
    this.calendarEventsTotalCount = data.totalCount;
    this.calendarEventsFetchedCount = this.entities?.length;
  }  


  async $dataLoaded() {
    
    const calendarEvents = [];

    if(this.entities) {
      for (let entity of this.entities) {
        const $calendar = this;
        const $utils = this.$utils;        
        const $calendarEvent = new Manufacturing_manufacturing_orders_calendarComponentCalendarEventModel(this.$utils);
        $calendarEvent.entity = entity;
        $calendarEvent.calendar = this;

        $calendarEvent.startDate = $calendarEvent.entity.ActualStartOn ?? $calendarEvent.entity.EstimatedStartOn;
        $calendarEvent.endDate = $calendarEvent.entity.ActualCompleteOn ?? $calendarEvent.entity.EstimatedCompleteOn;
        $calendarEvent.title = $calendarEvent.entity.LookupCode;
        $calendarEvent.draggable = false;
        $calendarEvent.selected = false;

        let $isMatchEventToColumn = false;
        for (var column of this.columns) {
          if (this.$matchEventToColumn(column,$calendarEvent )) {
            $isMatchEventToColumn = true;
            break;
          }
        }
        if ($isMatchEventToColumn) {
          $calendarEvent.$propertyChangeCallback = this.$calendarEventPropertyChangeCallback.bind(this);
          calendarEvents.push($calendarEvent);
        }
      }
    }

    this.calendarEvents = calendarEvents;


  }

  async $columnsPageChange() {
    await this.$dataLoadColumns();
    await this.$dataLoaded();
  }

  $getHeaderHtml(
    $column: IManufacturing_manufacturing_orders_calendarComponentColumnData
    ): string {
    const $calendar = this;
    const $utils = this.$utils;
    return `${$column.Name}`;
  }

  async $onHourSegmentClicked($orgEvent: {
    date: string;
    column: IManufacturing_manufacturing_orders_calendarComponentColumnData
    sourceEvent: MouseEvent;
  }) {
    console.log('onHourSegmentClicked', $orgEvent);

    const $event = {date: $orgEvent.date, column: $orgEvent.column};
    const $calendar = this;
    const $shell = this.$shell;
    const $datasources = this.$datasources;
    const $flows = this.$flows;
    const $reports = this.$reports;
    const $settings = this.$settings;
    const $utils = this.$utils;

    const a = $event.column;
const d = $event.date;

$shell.Manufacturing.openmanufacturing_order_form();
  }


  clearCalendarEvents() {
    this.calendarEvents = [];
  }


  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_next(event = null) {
    return this.on_nextInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_nextInternal(
    $calendar: Manufacturing_manufacturing_orders_calendarComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_calendar_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar.on_next');
  
  $calendar.filters.view_date.control.value = $utils.date.add(1, 'day', $calendar.filters.view_date.control.value);
  $calendar.on_date_change();
  $calendar.refresh();
  }
  on_previous(event = null) {
    return this.on_previousInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_previousInternal(
    $calendar: Manufacturing_manufacturing_orders_calendarComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_calendar_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar.on_previous');
  
  $calendar.filters.view_date.control.value = $utils.date.add(-1, 'day', $calendar.filters.view_date.control.value);
  $calendar.on_date_change();
  $calendar.refresh();
  }
  on_today(event = null) {
    return this.on_todayInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_todayInternal(
    $calendar: Manufacturing_manufacturing_orders_calendarComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_calendar_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar.on_today');
  
  $calendar.filters.view_date.control.value = (new Date()).toISOString();
  $calendar.on_date_change();
  $calendar.refresh();
  }
  on_date_change(event = null) {
    return this.on_date_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_date_changeInternal(
    $calendar: Manufacturing_manufacturing_orders_calendarComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_orders_calendar_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_orders_calendar.on_date_change');
  
  const date = $calendar.filters.view_date.control.value;
  const start_datetime = new Date(date);
  start_datetime.setUTCHours(0, 0, 0, 0);
  
  const end_datetime = new Date(date);
  end_datetime.setUTCHours(23, 59, 59, 999);
  
  $calendar.vars.start_date = start_datetime.toISOString();
  $calendar.vars.end_date = end_datetime.toISOString();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }

}
