import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_outbound_orders_hub_ComponentContextService } from './FootprintManager.outbound_orders_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_outbound_orders_gridComponent } from './FootprintManager.outbound_orders_grid.component';
import { FootprintManager_dock_appointments_calendarComponent } from './FootprintManager.dock_appointments_calendar.component';
import { FootprintManager_dock_appointments_dashboard_gridComponent } from './FootprintManager.dock_appointments_dashboard_grid.component';
import { SalesOrders_order_total_picked_order_count_by_date_widgetComponent } from './SalesOrders.order_total_picked_order_count_by_date_widget.component';
import { SalesOrders_order_total_completed_order_count_by_date_widgetComponent } from './SalesOrders.order_total_completed_order_count_by_date_widget.component';
import { SalesOrders_order_total_picked_units_by_date_widgetComponent } from './SalesOrders.order_total_picked_units_by_date_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_multiComponent } from './Materials.materials_by_project_dd_multi.component'
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { SalesOrders_orderstatuses_dd_multiComponent } from './SalesOrders.orderstatuses_dd_multi.component'
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_outbound_orders_gridComponent),
    forwardRef(() => FootprintManager_dock_appointments_calendarComponent),
    forwardRef(() => FootprintManager_dock_appointments_dashboard_gridComponent),
    forwardRef(() => SalesOrders_order_total_picked_order_count_by_date_widgetComponent),
    forwardRef(() => SalesOrders_order_total_completed_order_count_by_date_widgetComponent),
    forwardRef(() => SalesOrders_order_total_picked_units_by_date_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_multiComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => SalesOrders_orderstatuses_dd_multiComponent),
    forwardRef(() => Carriers_carriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'FootprintManager-outbound_orders_hub',
  templateUrl: './FootprintManager.outbound_orders_hub.component.html'
})
export class FootprintManager_outbound_orders_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, calendar_viewDate?: string, calendar_dockDoorIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    materials: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_sales_order: new ToolModel(new ButtonModel('create_sales_order', new ButtonStyles(null, null), false, false, false, 'New sales order', 'icon-ic_fluent_add_20_regular', null)
    , false),
      import_orders: new ToolModel(new ButtonModel('import_orders', new ButtonStyles(null, null), false, false, false, 'Import orders', 'icon-ic_fluent_arrow_upload_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      manage_carriers: new ToolModel(new ButtonModel('manage_carriers', new ButtonStyles(null, null), false, false, false, 'Manage carriers', 'ms-Icon ms-Icon--SettingsAdd', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    materials: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['materials'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Reference', false, false),
    carrier: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Carrier', false, false),
    carrier_service: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['carrier_service'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Service', false, false),
  };


  filtersets = {
    newGroup1: new FieldsetModel(
      'Filters',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inbound_orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
      calendar: new TabItemModel(
        this.rootTabGroup, 
        'Calendar', 
        false,
        ),
      dock_appointment_dashboard: new TabItemModel(
        this.rootTabGroup, 
        'Orders dashboard', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inbound_orders_outbound_orders_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_warehouseId;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.status.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_statusIds;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_carrierServiceId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier_service.control.value;
      
      return expr;
    }
  
    get $tabs_inbound_orders_outbound_orders_grid_inParams_reference(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.reference.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds: number[];
    get $tabs_inbound_orders_outbound_orders_grid_inParams_materialIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.materials.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds, expr)) {
        this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds = expr;
      }
      return this.cacheValueFor_$tabs_inbound_orders_outbound_orders_grid_inParams_materialIds;
    }
  
    cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds: number[];
    get $tabs_calendar_dock_appointments_calendar_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds: number[];
    get $tabs_calendar_dock_appointments_calendar_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = [2];
      
      if(!isEqual(this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_calendar_dock_appointments_calendar_inParams_typeIds;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_carrierId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.carrier.control.value;
      
      return expr;
    }
  
    get $tabs_calendar_dock_appointments_calendar_inParams_viewDate(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.calendar_viewDate;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds: number[];
    get $tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = [2];
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds, expr)) {
        this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inbound_orders', { read: FootprintManager_outbound_orders_gridComponent }) $tabs_inbound_orders: FootprintManager_outbound_orders_gridComponent;
      @ViewChild('$tabs_calendar', { read: FootprintManager_dock_appointments_calendarComponent }) $tabs_calendar: FootprintManager_dock_appointments_calendarComponent;
      @ViewChild('$tabs_dock_appointment_dashboard', { read: FootprintManager_dock_appointments_dashboard_gridComponent }) $tabs_dock_appointment_dashboard: FootprintManager_dock_appointments_dashboard_gridComponent;
    //#endregion tabs children
    widgets = {
      order_total_picked_order_count_by_date_widget: new WidgetModel(false),
      order_total_completed_order_count_by_date_wdiget: new WidgetModel(false),
      order_total_picked_units_by_date_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId: number[];
    get $widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_date(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId: number[];
    get $widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_completed_order_count_by_date_wdiget_inParams_warehouseId;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_date(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.currentDate;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $widgets_order_total_picked_units_by_date_widget_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId: number[];
    get $widgets_order_total_picked_units_by_date_widget_inParams_warehouseId(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId, expr)) {
        this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId = expr;
      }
      return this.cacheValueFor_$widgets_order_total_picked_units_by_date_widget_inParams_warehouseId;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_order_total_picked_order_count_by_date_widget', { read:  SalesOrders_order_total_picked_order_count_by_date_widgetComponent }) $widgets_order_total_picked_order_count_by_date_widget: SalesOrders_order_total_picked_order_count_by_date_widgetComponent;
      @ViewChild('$widgets_order_total_completed_order_count_by_date_wdiget', { read:  SalesOrders_order_total_completed_order_count_by_date_widgetComponent }) $widgets_order_total_completed_order_count_by_date_wdiget: SalesOrders_order_total_completed_order_count_by_date_widgetComponent;
      @ViewChild('$widgets_order_total_picked_units_by_date_widget', { read:  SalesOrders_order_total_picked_units_by_date_widgetComponent }) $widgets_order_total_picked_units_by_date_widget: SalesOrders_order_total_picked_units_by_date_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  get $fields_materials_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  cacheValueFor_$fields_status_selector_inParams_statusIds: number[];
  get $fields_status_selector_inParams_statusIds(): number[] {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [1,2,4,8];
    
    if(!isEqual(this.cacheValueFor_$fields_status_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_status_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_status_selector_inParams_statusIds;
  }



  get $fields_carrier_service_selector_inParams_carrierId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.carrier.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Outbound orders hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Create, pick, pack, and load sales orders to ship inventory out of your warehouse.`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_outbound_orders_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inbound_orders,
      this.tabs.calendar,
      this.tabs.dock_appointment_dashboard,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Outbound orders hub';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.status.control as SelectBoxModel).reset([1]);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_order_total_picked_order_count_by_date_widget') {
      if (!isNil(this.$widgets_order_total_picked_order_count_by_date_widget) && !this.widgets.order_total_picked_order_count_by_date_widget.hidden) {
        this.$widgets_order_total_picked_order_count_by_date_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_completed_order_count_by_date_wdiget') {
      if (!isNil(this.$widgets_order_total_completed_order_count_by_date_wdiget) && !this.widgets.order_total_completed_order_count_by_date_wdiget.hidden) {
        this.$widgets_order_total_completed_order_count_by_date_wdiget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_picked_units_by_date_widget') {
      if (!isNil(this.$widgets_order_total_picked_units_by_date_widget) && !this.widgets.order_total_picked_units_by_date_widget.hidden) {
        this.$widgets_order_total_picked_units_by_date_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_inbound_orders') {
      if (!isNil(this.$tabs_inbound_orders) && !this.tabs.inbound_orders.hidden) {
        this.$tabs_inbound_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_calendar') {
      if (!isNil(this.$tabs_calendar) && !this.tabs.calendar.hidden) {
        this.$tabs_calendar.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_dock_appointment_dashboard') {
      if (!isNil(this.$tabs_dock_appointment_dashboard) && !this.tabs.dock_appointment_dashboard.hidden) {
        this.$tabs_dock_appointment_dashboard.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_sales_order_clicked(event = null) {
    return this.on_create_sales_order_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_sales_order_clickedInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Initialize the first warehouse selected
  var warehouseId = null;
  
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      warehouseId = $hub.filters.warehouse.control.value[0];
  };
  
  
   const dialogResult = (await $shell.FootprintManager.opensales_order_creation_form({
       ownerId: $hub.filters.owner.control.value,
       projectId: $hub.filters.project.control.value,
       warehouseId: $utils.isDefined(warehouseId) ? warehouseId : null
       }));
  $hub.refresh()
  
  
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $hub.filters.project.control.value = null;
  $hub.filters.materials.control.value = null;
  $hub.refresh()
  
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $hub.filters.project.control.value
  
  //Clear material field
  if ($utils.isDefined(projectId)){
      $hub.filters.materials.control.value = null;
  }
   
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
  
          $hub.filters.owner.control.value = project[0].OwnerId;
  
      }
  
  }
  $hub.refresh()
  }
  on_import_orders_clicked(event = null) {
    return this.on_import_orders_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_import_orders_clickedInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.ExcelOrderImport.openexcel_order_import_grid();
  $hub.refresh
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if (await $operations.FootprintManager.Disable_Navigation_Outbound_Orders_Hub.isAuthorized()) {
      
      $hub.close();
      throw new Error ('This screen currently requires authorization, please see your system admin for access.')
    
  }
  
  
  
  await $hub.apply_operations();
  $hub.refresh
  }
  on_calendar_filters_updated(event = null) {
    return this.on_calendar_filters_updatedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_calendar_filters_updatedInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Update tab's variables to event values
  $hub.vars.calendar_dockDoorIds = $event.filters.dockDoorIds;
  $hub.vars.calendar_viewDate = $event.filters.viewDate;
  
  $hub.refresh()
  
  }
  on_manage_carriers_clicked(event = null) {
    return this.on_manage_carriers_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_carriers_clickedInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opencarriers_grid();
  $hub.refresh()
  
  
  
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $hub: FootprintManager_outbound_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_outbound_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if (await $operations.FootprintManager.Disable_SalesOrders_OrderCreation.isAuthorized()) {
      $hub.toolbar.create_sales_order.hidden = true;
  }
  
  if (await $operations.FootprintManager.Disable_Outbound_Order_Import.isAuthorized()) {
      $hub.toolbar.import_orders.hidden = true;
  }
  
  if (await $operations.FootprintManager.Disable_Carriers_Management_View.isAuthorized()) {
      $hub.toolbar.manage_carriers.hidden = true;
  }
  
  
  
  $hub.vars.currentDate = $utils.date.now();
  
  var defaultStatus = (await $operations.FootprintManager.Enable_SalesOrders_DefaultOrderHubCreatedStatus.isAuthorized());
  
  if (defaultStatus == false) {
      // Push processing status to control
      $hub.filters.status.control.value.push(2);
  }
  else {
      console.log('Do nothing, screendefaults to status 1 (created)')
  }
  
  
  
  
  
  
  if ((await $operations.FootprintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
  
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouse.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  
  }
  
  }
  //#endregion private flows
}
