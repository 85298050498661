import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Invoices_freight_billing_header_grid_ComponentContextService } from './Invoices.freight_billing_header_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Invoices_freight_billing_details_gridComponent } from './Invoices.freight_billing_details_grid.component';

interface IInvoices_freight_billing_header_gridComponentEntity {
  header_id?: string, lookup_code?: string, warehouse_id?: number, warehouse_lookupcode?: string, account_id?: number, account_lookupcode?: string, destination?: string, shipping_days?: string, delivery_days?: string, owner_id?: number, owner_lookupcode?: string, shipping_days_mon?: boolean, shipping_days_tue?: boolean, shipping_days_wed?: boolean, shipping_days_thu?: boolean, shipping_days_fri?: boolean, shipping_days_sat?: boolean, shipping_days_sun?: boolean, delivery_days_mon?: boolean, delivery_days_tue?: boolean, delivery_days_wed?: boolean, delivery_days_thu?: boolean, delivery_days_fri?: boolean, delivery_days_sat?: boolean, delivery_days_sun?: boolean, address_id?: number, created_date?: string, date_number?: number, project_id?: number, project_lookupcode?: string, project_name?: string, owner_name?: string, account_name?: string}

interface IInvoices_freight_billing_header_gridComponentInParams {
  refresh?: boolean, warehouse_id?: number, owner_id?: number, account_id?: number, address_id?: number, fullTextSeach?: string, project_id?: number}


class Invoices_freight_billing_header_gridComponentRowModel extends GridRowModel {
  grid: Invoices_freight_billing_header_gridComponent;
  entity: IInvoices_freight_billing_header_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookup_code: null,
    warehouse_name: null,
    owner_name: null,
    project_id: null,
    account_name: null,
    destination: null,
    shipping_days: null,
    delivery_days: null,
  }


  //#region rowExpand inParams


  get $rowExpand_Invoices_freight_billing_details_grid_inParams_lookupcode(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.header_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: Invoices_freight_billing_header_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Invoices_ShellService,
private $datasources: Invoices_DatasourceService,
private $flows: Invoices_FlowService,
private $reports: Invoices_ReportService,
private $localization: Invoices_LocalizationService,
private $operations: Invoices_OperationService,
private $logger: CleanupLoggerService,
private $context: Invoices_freight_billing_header_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookup_code = new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      this.grid.headers.lookup_code,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse_name = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.warehouse_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.owner_name = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.owner_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project_id = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.project_id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.account_name = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.account_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.destination = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.destination,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.shipping_days = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.shipping_days,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.delivery_days = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.delivery_days,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IInvoices_freight_billing_header_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.header_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IInvoices_freight_billing_header_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.header_id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.header_id;
    const inParams = {
      $keys:[$resultKey],
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.fullTextSearch ,
      warehouse_id:  $grid.inParams.warehouse_id ,
      owner_id:  $grid.inParams.owner_id ,
      account_id:  $grid.inParams.account_id ,
      address_id:  $grid.inParams.address_id ,
      project_id:  $grid.inParams.project_id ,
    };
    const data = await this.$datasources.Invoices.ds_freight_billing_header.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IInvoices_freight_billing_header_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookup_code.displayControl as TextModel).text = $row.entity.lookup_code;
    (this.cells.warehouse_name.displayControl as TextModel).text = $row.entity.warehouse_lookupcode;
    (this.cells.owner_name.displayControl as TextModel).text = $row.entity.owner_name;
    (this.cells.project_id.displayControl as TextModel).text = $row.entity.project_name;
    (this.cells.account_name.displayControl as TextModel).text = $row.entity.account_name;
    (this.cells.destination.displayControl as TextModel).text = $row.entity.destination;
    (this.cells.shipping_days.displayControl as TextModel).text = $row.entity.shipping_days;
    (this.cells.delivery_days.displayControl as TextModel).text = $row.entity.delivery_days;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookup_code' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: Invoices_freight_billing_header_gridComponentRowModel,
  $grid: Invoices_freight_billing_header_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_freight_billing_header_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Invoices', 'freight_billing_header_grid.on_lookupcode_clicked');
  
  var freight_header_id = null;
  var lookup_code = null;
  
  if ($utils.isDefined($row.entity.header_id)) {
      freight_header_id = $row.entity.header_id;
  };
  
  if ($utils.isDefined($row.entity.lookup_code)) {
      lookup_code = $row.entity.lookup_code; 
  };
  
  
  const dialogResults = (await $shell.Invoices.openfreight_billing_matrix_editorDialog({
      freight_header_id: freight_header_id
  
  },'flyout',EModalSize.Large));
  
  if ($utils.isDefined(dialogResults)) {
  
      const confirm = dialogResults.confirm;
      if ($utils.isDefined(confirm)) {
  
          if (confirm) {
              $grid.refresh();
          }
  
      }
      
      const on_close = dialogResults.on_close;
      if ($utils.isDefined(on_close)) {
  
          if (on_close) {
              $grid.refresh();
          }
  
      }
  
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_freight_billing_details_gridComponent)
  ],
  selector: 'Invoices-freight_billing_header_grid',
  templateUrl: './Invoices.freight_billing_header_grid.component.html'
})
export class Invoices_freight_billing_header_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_freight_billing_header_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_freight_billing_header_gridComponentInParams = { refresh: null, warehouse_id: null, owner_id: null, account_id: null, address_id: null, fullTextSeach: null, project_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookup_code: new GridHeaderModel(new HeaderStyles(['left'], null), 'lookup_code', 'Lookup code', false, false, null, false),       warehouse_name: new GridHeaderModel(new HeaderStyles(['left'], null), 'warehouse_name', 'Warehouse', false, false, null, false),       owner_name: new GridHeaderModel(new HeaderStyles(['left'], null), 'owner_name', 'Owner', false, false, null, false),       project_id: new GridHeaderModel(new HeaderStyles(['left'], null), 'project_id', 'Project', false, false, null, false),       account_name: new GridHeaderModel(new HeaderStyles(['left'], null), 'account_name', 'Account', false, false, null, false),       destination: new GridHeaderModel(new HeaderStyles(['left'], null), 'destination', 'Destination', false, false, null, false),       shipping_days: new GridHeaderModel(new HeaderStyles(['left'], null), 'shipping_days', 'Shipping days', false, false, null, false),       delivery_days: new GridHeaderModel(new HeaderStyles(['left'], null), 'delivery_days', 'Delivery days', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_freight_billing_header_gridComponentRowModel[] = [];

  @Input('refresh') set $inParams_refresh(value: any) {
    this.inParams['refresh'] = value;
  }
  get $inParams_refresh(): any {
    return this.inParams['refresh'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('owner_id') set $inParams_owner_id(value: any) {
    this.inParams['owner_id'] = value;
  }
  get $inParams_owner_id(): any {
    return this.inParams['owner_id'] ;
  }
  @Input('account_id') set $inParams_account_id(value: any) {
    this.inParams['account_id'] = value;
  }
  get $inParams_account_id(): any {
    return this.inParams['account_id'] ;
  }
  @Input('address_id') set $inParams_address_id(value: any) {
    this.inParams['address_id'] = value;
  }
  get $inParams_address_id(): any {
    return this.inParams['address_id'] ;
  }
  @Input('fullTextSeach') set $inParams_fullTextSeach(value: any) {
    this.inParams['fullTextSeach'] = value;
  }
  get $inParams_fullTextSeach(): any {
    return this.inParams['fullTextSeach'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }

  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, false, ' ', 'ms-Icon ms-Icon--Refresh', () => { const $utils = this.$utils; const $grid = this; return 'Refresh'; })
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Invoices_ShellService,
    private $datasources: Invoices_DatasourceService,
    private $flows: Invoices_FlowService,
    private $reports: Invoices_ReportService,
    private $localization: Invoices_LocalizationService,
    private $operations: Invoices_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Invoices_freight_billing_header_grid_ComponentContextService,
    ) {
    super();
    this.title = 'freight_billing_header_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    await this.on_refresh_clicked();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.fullTextSearch ,
      warehouse_id:  $grid.inParams.warehouse_id ,
      owner_id:  $grid.inParams.owner_id ,
      account_id:  $grid.inParams.account_id ,
      address_id:  $grid.inParams.address_id ,
      project_id:  $grid.inParams.project_id ,
    };
    try {
      const data = await this.$datasources.Invoices.ds_freight_billing_header.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IInvoices_freight_billing_header_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_freight_billing_header_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: Invoices_freight_billing_header_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_freight_billing_header_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Invoices', 'freight_billing_header_grid.on_refresh_clicked');
  let result = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          lookup_code: " "
      }],
      action: `Read`
  }));
  
  if (result.count > 0) {
  
      let i: number = 0;
      while (i < result.count) {
  
          await $flows.Invoices.freight_billing_header_storage({
              payload: [{
                  header_id: result.payload[i].header_id
              }],
              action: `delete`
          });
          i++;
      }
  }
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
