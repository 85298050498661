<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="component">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.component.displayControl.styles.style"
          [ngClass]="row.cells.component.displayControl.styles.classes"
          [matTooltip]="row.cells.component.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.component.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="planned_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.planned_quantity.displayControl.styles.style"
          [ngClass]="row.cells.planned_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.planned_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.planned_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <Manufacturing-manufacturing_order_lines_production_sub_components_grid
    [material_id]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_material_id"
    [location_id]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_location_id"
    [component]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_component"
    [main_component_identifier]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_main_component_identifier"
    [rows]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_rows"
    [package_id]="row.$rowExpand_Manufacturing_manufacturing_order_lines_production_sub_components_grid_inParams_package_id"
    (row_modify)="row.sub_row_modify_event_handler($event)"
    (row_add)="row.sub_row_add_event_handler($event)"
    (row_delete)="row.sub_row_delete_event_handler($event)"
    ($refreshEvent)="row.refresh()">>
  </Manufacturing-manufacturing_order_lines_production_sub_components_grid>
</ng-template>

</datex-grid>
