import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { EasyPost_ReportService } from './EasyPost.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { ShipTheory_ReportService } from './ShipTheory.report.index';


@Injectable({ providedIn: 'root' })
export class Manifesting_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.EasyPost = this.injector.get(EasyPost_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.ShipTheory = this.injector.get(ShipTheory_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public EasyPost: EasyPost_ReportService;
    public PrintNode: PrintNode_ReportService;
    public ShipTheory: ShipTheory_ReportService;

  public Manifesting: Manifesting_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

