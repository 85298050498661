import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_entity_import_requests_grid_ComponentContextService } from './FootprintManager.entity_import_requests_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { EntityImport_entity_import_date_types_singleComponent } from './EntityImport.entity_import_date_types_single.component'


interface IFootprintManager_entity_import_requests_gridComponentEntity {
  RequestId?: string, CreatedOn?: string, ErrorMessage?: string, ModifiedOn?: string, Project?: string, ReferenceCode?: string, RequestXmlSourceFile?: string, StatusCode?: string, Warehouse?: string}

interface IFootprintManager_entity_import_requests_gridComponentInParams {
  statusCodes?: string[], projects?: string[], warehouses?: string[], singleWarehouse?: string, singleProjectName?: string, singleProjectLookupCode?: string, requestId?: string, projectsExact?: string[], warehousesExact?: string[], dateFrom?: string, dateTo?: string, dateType?: string}


class FootprintManager_entity_import_requests_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_entity_import_requests_gridComponent;
  entity: IFootprintManager_entity_import_requests_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    requestid: null,
    project: null,
    warehouse: null,
    reference_code: null,
    status_code: null,
    error_message: null,
    created_on: null,
    modified_on: null,
    request_xml_source_file: null,
  }



  constructor(
    grid: FootprintManager_entity_import_requests_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.requestid = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.requestid,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.reference_code = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.reference_code,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status_code = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status_code,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.error_message = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.error_message,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.created_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_on,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.modified_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.modified_on,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.request_xml_source_file = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.request_xml_source_file,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_entity_import_requests_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.RequestId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_entity_import_requests_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.RequestId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.RequestId;
    const inParams = {
      $keys:[$resultKey],
      statusCodes:  $grid.inParams.statusCodes ,
      dateFrom:  $grid.filters.date_from.control.value ,
      dateTo:  $grid.filters.date_to.control.value ,
      projects:  $grid.inParams.projects ,
      warehouses:  $grid.inParams.warehouses ,
      fullTextSearch:  $grid.fullTextSearch ,
      singleWarehouse:  $grid.inParams.singleWarehouse ,
      singleProjectLookupCode:  $grid.inParams.singleProjectLookupCode ,
      singleProjectName:  $grid.inParams.singleProjectName ,
      requestId:  $grid.inParams.requestId ,
      projectsExact:  $grid.inParams.projectsExact ,
      warehousesExact:  $grid.inParams.warehousesExact ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    const data = await this.$datasources.EntityImport.ds_entity_import_requests_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_entity_import_requests_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.requestid.displayControl as TextModel).text = $row.entity.RequestId?.toUpperCase();
    (this.cells.project.displayControl as TextModel).text = $row.entity.Project;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Warehouse;
    (this.cells.reference_code.displayControl as TextModel).text = $row.entity.ReferenceCode;
    (this.cells.status_code.displayControl as TextModel).text = $row.entity.StatusCode;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedOn?.toString();
    (this.cells.modified_on.displayControl as TextModel).text = $row.entity.ModifiedOn?.toString();
    (this.cells.request_xml_source_file.displayControl as TextModel).text = $row.entity.RequestXmlSourceFile;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'requestid' : {
        this.on_request_id_clicked();
        break;
      }
      case 'error_message' : {
        this.on_error_message_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Apply date format
  if ($utils.isDefined($row.entity.CreatedOn) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var createdOn = $utils.date.format($row.entity.CreatedOn, dateFormat);
      $row.cells.created_on.displayControl.text = createdOn;
  }
  
  if ($utils.isDefined($row.entity.ModifiedOn) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var modifiedOn = $utils.date.format($row.entity.ModifiedOn, dateFormat);
      $row.cells.modified_on.displayControl.text = modifiedOn;
  }
  
  
  if ($utils.isDefined($row.entity.ErrorMessage)){
  
      $row.cells.error_message.displayControl.text = 'Exception(s)'
  }
  }
  on_request_id_clicked(event = null) {
    return this.on_request_id_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_request_id_clickedInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($row.entity.StatusCode.trim().toUpperCase() !== 'UPLOADING') {
          await $shell.EntityImport.openrender_entity_import_request_code_editorDialog({request_id: $row.entity.RequestId}, 'modal', EModalSize.Xlarge);
      }
  } catch (e) { }
  
  }
  on_error_message_clicked(event = null) {
    return this.on_error_message_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_error_message_clickedInternal(
    $row: FootprintManager_entity_import_requests_gridComponentRowModel,
  $grid: FootprintManager_entity_import_requests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //$shell.FootprintManager.openentity_import_error_message_editorDialog({ requestId: $row.entity.RequestId }, 'modal', EModalSize.Xlarge);
  
  let payload_string: string = $row.entity.ErrorMessage;
  
  try {
  
      let parsed_error = (await $flows.EntityImport.entity_import_parse_errors({ request_id: $row.entity.RequestId }));
  
      if ($utils.isDefined(parsed_error)) {
          payload_string = parsed_error.summary;
      }
  } catch (e) { }
  
  await $shell.FootprintManager.openview_xml_payloadDialog({
      payload: payload_string,
      title: "Error message"
  }, "modal", EModalSize.Xlarge);
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => EntityImport_entity_import_date_types_singleComponent),
  ],
  selector: 'FootprintManager-entity_import_requests_grid',
  templateUrl: './FootprintManager.entity_import_requests_grid.component.html'
})
export class FootprintManager_entity_import_requests_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_entity_import_requests_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_entity_import_requests_gridComponentInParams = { statusCodes: [], projects: [], warehouses: [], singleWarehouse: null, singleProjectName: null, singleProjectLookupCode: null, requestId: null, projectsExact: [], warehousesExact: [], dateFrom: null, dateTo: null, dateType: null };


  //#region Variables
  //#endregion
  //#region Events
  @Output()
  DateParamsChange = new EventEmitter<{ DateFrom?: string, DateTo?: string, DateType?: string }>();
  
  events = {
    DateParamsChange: this.DateParamsChange,
  }
  //#endregion

  headers = {
     requestid: new GridHeaderModel(new HeaderStyles(null, null), 'requestid', 'Import ID', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       reference_code: new GridHeaderModel(new HeaderStyles(null, null), 'reference_code', 'Reference code', false, false, null, false),       status_code: new GridHeaderModel(new HeaderStyles(null, null), 'status_code', 'Status', false, false, null, false),       error_message: new GridHeaderModel(new HeaderStyles(null, null), 'error_message', 'Error Message', false, false, null, false),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'created_on', 'Created on', false, false, null, false),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'modified_on', 'Modified on', false, false, null, false),       request_xml_source_file: new GridHeaderModel(new HeaderStyles(null, null), 'request_xml_source_file', 'Source file', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_entity_import_requests_gridComponentRowModel[] = [];

  @Input('statusCodes') set $inParams_statusCodes(value: any) {
    this.inParams['statusCodes'] = value;
  }
  get $inParams_statusCodes(): any {
    return this.inParams['statusCodes'] ;
  }
  @Input('projects') set $inParams_projects(value: any) {
    this.inParams['projects'] = value;
  }
  get $inParams_projects(): any {
    return this.inParams['projects'] ;
  }
  @Input('warehouses') set $inParams_warehouses(value: any) {
    this.inParams['warehouses'] = value;
  }
  get $inParams_warehouses(): any {
    return this.inParams['warehouses'] ;
  }
  @Input('singleWarehouse') set $inParams_singleWarehouse(value: any) {
    this.inParams['singleWarehouse'] = value;
  }
  get $inParams_singleWarehouse(): any {
    return this.inParams['singleWarehouse'] ;
  }
  @Input('singleProjectName') set $inParams_singleProjectName(value: any) {
    this.inParams['singleProjectName'] = value;
  }
  get $inParams_singleProjectName(): any {
    return this.inParams['singleProjectName'] ;
  }
  @Input('singleProjectLookupCode') set $inParams_singleProjectLookupCode(value: any) {
    this.inParams['singleProjectLookupCode'] = value;
  }
  get $inParams_singleProjectLookupCode(): any {
    return this.inParams['singleProjectLookupCode'] ;
  }
  @Input('requestId') set $inParams_requestId(value: any) {
    this.inParams['requestId'] = value;
  }
  get $inParams_requestId(): any {
    return this.inParams['requestId'] ;
  }
  @Input('projectsExact') set $inParams_projectsExact(value: any) {
    this.inParams['projectsExact'] = value;
  }
  get $inParams_projectsExact(): any {
    return this.inParams['projectsExact'] ;
  }
  @Input('warehousesExact') set $inParams_warehousesExact(value: any) {
    this.inParams['warehousesExact'] = value;
  }
  get $inParams_warehousesExact(): any {
    return this.inParams['warehousesExact'] ;
  }
  @Input('dateFrom') set $inParams_dateFrom(value: any) {
    this.inParams['dateFrom'] = value;
  }
  get $inParams_dateFrom(): any {
    return this.inParams['dateFrom'] ;
  }
  @Input('dateTo') set $inParams_dateTo(value: any) {
    this.inParams['dateTo'] = value;
  }
  get $inParams_dateTo(): any {
    return this.inParams['dateTo'] ;
  }
  @Input('dateType') set $inParams_dateType(value: any) {
    this.inParams['dateType'] = value;
  }
  get $inParams_dateType(): any {
    return this.inParams['dateType'] ;
  }

  topToolbar = {
      on_resubmit: new ToolModel(new ButtonModel('on_resubmit', new ButtonStyles(null, null), true, false, false, 'Resubmit', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false),
      import_request: new ToolModel(new ButtonModel('import_request', new ButtonStyles(null, null), false, false, false, 'Import', 'icon-ic_fluent_arrow_upload_20_regular', null)
    , false),
      export_request: new ToolModel(new ButtonModel('export_request', new ButtonStyles(null, null), false, false, false, 'Export', 'icon-ic_fluent_arrow_download_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_arrow_clockwise_20_regular', () => { const $utils = this.$utils; const $grid = this; return 'Refresh'; })
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, false, 'l, LT', 'date', null)
, new ControlContainerStyles(null, null), 'Date from', false, false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, false, 'l, LT', 'date', null)
, new ControlContainerStyles(null, null), 'Date to', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Entity imports';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['date_from']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.formGroup
      .controls['date_to']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.formGroup
      .controls['date_type']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      statusCodes:  $grid.inParams.statusCodes ,
      dateFrom:  $grid.filters.date_from.control.value ,
      dateTo:  $grid.filters.date_to.control.value ,
      projects:  $grid.inParams.projects ,
      warehouses:  $grid.inParams.warehouses ,
      fullTextSearch:  $grid.fullTextSearch ,
      singleWarehouse:  $grid.inParams.singleWarehouse ,
      singleProjectLookupCode:  $grid.inParams.singleProjectLookupCode ,
      singleProjectName:  $grid.inParams.singleProjectName ,
      requestId:  $grid.inParams.requestId ,
      projectsExact:  $grid.inParams.projectsExact ,
      warehousesExact:  $grid.inParams.warehousesExact ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    try {
      const data = await this.$datasources.EntityImport.ds_entity_import_requests_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_entity_import_requests_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_entity_import_requests_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_entity_import_requests_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_entity_import_requests_gridComponentEntity) {
    const row = new FootprintManager_entity_import_requests_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Initialize date format
  var dateFormat = $settings.FootprintManager.DateFormat;
  
  if ($utils.isDefined($grid.inParams.dateFrom)) {
      //$grid.filters.date_from.control.value = $utils.date.add(-2,'day',$utils.date.now());
      $grid.filters.date_from.control.value = $grid.inParams.dateFrom
  };
  
  if ($utils.isDefined($grid.inParams.dateTo)) {
      //$grid.filters.date_to.control.value = $utils.date.now();
      $grid.filters.date_to.control.value = $grid.inParams.dateTo;
  };
  
  if ($utils.isDefined($grid.inParams.dateType)) {
      //$grid.filters.date_type.control.value = 'CreatedOn';
      $grid.filters.date_type.control.value = $grid.inParams.dateType;
  };
  
  $grid.filters.date_from.control.format = dateFormat;
  $grid.filters.date_to.control.format = dateFormat;
  
  $grid.canAdd = false;
  }
  on_import(event = null) {
    return this.on_importInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_importInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const fileNameLimit = 50;
  
  try {
  
      let file = await $utils.blob.openFile({ extensions: ['.xml'] });
  
      let fileContent = await new Blob([file]).text();
  
  
      let fileName = file.name.replace('.xml', '');
  
      if (fileName.length > fileNameLimit) {
          throw new Error(`The file name exceeds the limit of ${fileNameLimit} characters. Please shorten the file name and try again.`);
      }
  
      let result = (await $flows.EntityImport.submit_request_flow({ requestXml: fileContent, referenceCode: fileName }));
  
      if ($utils.isDefined(result.reasons)) {
          throw new Error(`Failed to import request: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue}`);
      }
  
      $grid.refresh();
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error importing request', error.message);
  }
  }
  on_export(event = null) {
    return this.on_exportInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_exportInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Loop through all selected rows
  for (let row of $grid.selectedRows) {
  
      // Get the XML from the selected row
      let xml = (await $datasources.EntityImport.ds_get_request_xml_by_requestId_editor.get({ requestId: row.entity.RequestId})).result.RequestXml;
  
      // Save XML to file
      $utils.blob.saveFile(new Blob([xml]), { 
          fileName: `${row.entity.ReferenceCode ?? row.entity.RequestId}.xml` ,
          extensions: ['.xml']
      });
  
      row.selected = false;
  }
  }
  on_resubmit_clicked(event = null) {
    return this.on_resubmit_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_resubmit_clickedInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const request = (await $flows.EntityImport.entity_import_resubmit_flow({requestId: $grid.selectedRows[0].entity.RequestId}));
  
  await $grid.selectedRows[0].refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const rowCount = $grid.selectedRows.length;
  
  $grid.topToolbar.export_request.control.readOnly = rowCount !== 1;
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  if (rowCount > 0) {
  
      const rowStatus = $grid.selectedRows[0].entity.StatusCode === 'Error';
  
      if (rowCount === 1 && rowStatus) {
          $grid.topToolbar.on_resubmit.control.readOnly = false;
      }
      else {
          $grid.topToolbar.on_resubmit.control.readOnly = true;
      };
  } else {
      $grid.topToolbar.on_resubmit.control.readOnly = true;
  };
  }
  on_date_change(event = null) {
    return this.on_date_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_date_changeInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/21/2023
  
  $grid.events.DateParamsChange.emit({DateFrom: $grid.filters.date_from.control.value, DateTo: $grid.filters.date_to.control.value, DateType: $grid.filters.date_type.control.value});
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.refresh();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_entity_import_requests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_entity_import_requests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/12/2024
  
  let rows = $grid.rows;
  
  if ($utils.isDefined(rows)) {
  
      let error_rows = rows.filter(r => r.entity.StatusCode?.toUpperCase() === "ERROR");
  
      if ($utils.isDefined(error_rows)) {
  
          for (let error_row of error_rows) {
  
              try {
  
                  let parsed_error = (await $flows.EntityImport.entity_import_parse_errors({ request_id: error_row.entity.RequestId }));
  
                  if ($utils.isDefined(parsed_error)) {
  
                      let error = parsed_error.summary;
  
                      error = (error.length > 75) ? `${error.substring(0, 75)}...` : error;
  
                      error_row.cells.error_message.displayControl.text = error;
                      error_row.cells.project.displayControl.text = parsed_error.project ?? error_row.entity.Project;
                      error_row.cells.reference_code.displayControl.text = parsed_error.reference ?? error_row.entity.ReferenceCode;
                      error_row.cells.warehouse.displayControl.text = parsed_error.warehouse ?? error_row.entity.Warehouse;
                  }
              } catch (e) { }
          }
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
