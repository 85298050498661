import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_workshift_editor_ComponentContextService } from './Manufacturing.workshift_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Manufacturing_warehouse_dd_singleComponent } from './Manufacturing.warehouse_dd_single.component'
import { Manufacturing_time_selector_dd_singleComponent } from './Manufacturing.time_selector_dd_single.component'
import { Manufacturing_days_dd_multiComponent } from './Manufacturing.days_dd_multi.component'

type EntityType = { 
    Id?: number, Code?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DurationMinutes?: number, Friday?: boolean, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Monday?: boolean, Name?: string, Saturday?: boolean, ShiftStartOffsetMinutes?: number, Sunday?: boolean, Thursday?: boolean, Tuesday?: boolean, WarehouseId?: number, Wednesday?: boolean}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_warehouse_dd_singleComponent),
    forwardRef(() => Manufacturing_time_selector_dd_singleComponent),
    forwardRef(() => Manufacturing_days_dd_multiComponent),
  ],
  selector: 'Manufacturing-workshift_editor',
  templateUrl: './Manufacturing.workshift_editor.component.html'
})
export class Manufacturing_workshift_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { workshift_id: number, is_new: boolean } = { workshift_id: null, is_new: null };
  //#region Inputs
  @Input('workshift_id') set $inParams_workshift_id(v: number) {
    this.inParams.workshift_id = v;
  }
  get $inParams_workshift_id(): number {
    return this.inParams.workshift_id;
  }
  @Input('is_new') set $inParams_is_new(v: boolean) {
    this.inParams.is_new = v;
  }
  get $inParams_is_new(): boolean {
    return this.inParams.is_new;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    workshift_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    start_time: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    end_time: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    shift_duration: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    created_by: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    created_on: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    modified_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    modified_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, false, 'Save', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    workshift_name: new FieldModel(new TextBoxModel(this.formGroup.controls['workshift_name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Workshift name', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    start_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['start_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Start time', true, false),
    end_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['end_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'End time', true, false),
    shift_duration: new FieldModel(new TextBoxModel(this.formGroup.controls['shift_duration'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Shift duration (in hours)', false, false),
    code: new FieldModel(new TextBoxModel(this.formGroup.controls['code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Code', true, false),
    days: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['days'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Days', true, false),
    created_by: new FieldModel(new TextBoxModel(this.formGroup.controls['created_by'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Created by', true, false),
    created_on: new FieldModel(new DateBoxModel(this.formGroup.controls['created_on'] as DatexFormControl, null, true, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Created on', true, false),
    modified_by: new FieldModel(new TextBoxModel(this.formGroup.controls['modified_by'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Modified by', false, false),
    modified_on: new FieldModel(new DateBoxModel(this.formGroup.controls['modified_on'] as DatexFormControl, null, true, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Modified on', false, false),
  };

  fieldsets = {
    group: new FieldsetModel(
      'group',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    details: new FieldsetModel(
      'Details',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};


  //#region fields inParams
  get $fields_warehouse_selector_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.WarehouseId;
    
    return expr;
  }

  get $fields_start_time_selector_inParams_intervalMinutes(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 5;
    
    return expr;
  }

  get $fields_end_time_selector_inParams_intervalMinutes(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 5;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    workshift_name: this.fields.workshift_name.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    warehouse: this.fields.warehouse.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    start_time: this.fields.start_time.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    end_time: this.fields.end_time.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    shift_duration: this.fields.shift_duration.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    code: this.fields.code.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    days: this.fields.days.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    created_by: this.fields.created_by.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    created_on: this.fields.created_on.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    modified_by: this.fields.modified_by.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    modified_on: this.fields.modified_on.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_workshift_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.workshift_id)) {
        this.$missingRequiredInParams.push('workshift_id');
      }
      if(isNil(this.inParams.is_new)) {
        this.$missingRequiredInParams.push('is_new');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Workshift Edit';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      workshift_id:  $editor.inParams.workshift_id 
    };

    const data = await this.$datasources.Manufacturing.ds_get_workshift_by_id.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.workshift_name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    
    (this.fields.code.control as TextBoxModel).reset($editor.entity.Code);
    (this.fields.created_by.control as TextBoxModel).reset($editor.entity.CreatedSysUser);
    (this.fields.created_on.control as DateBoxModel).reset($editor.entity.CreatedSysDateTime);
    (this.fields.modified_by.control as TextBoxModel).reset($editor.entity.ModifiedSysUser);
    (this.fields.modified_on.control as DateBoxModel).reset($editor.entity.ModifiedSysDateTime);

    await this.data_loaded_flow();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .workshift_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .start_time
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_time_flow();
      });
    this.$formGroupFieldValidationObservables
      .end_time
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_time_flow();
      });
    this.$formGroupFieldValidationObservables
      .shift_duration
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  change_time_flow(event = null) {
    return this.change_time_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async change_time_flowInternal(
    $editor: Manufacturing_workshift_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_editor.change_time_flow');
  
  
  const start_time = $editor.fields.start_time.control.value;
  const end_time = $editor.fields.end_time.control.value;
  
  if (start_time && end_time) {
      const hours = (await $flows.Manufacturing.minutes_between_times({start_time, end_time})).minutes / 60;
      $editor.fields.shift_duration.control.value = (await $flows.Manufacturing.round_to_first_decimal_digit({num: hours})).roundedNum;
  }
  
  }
  data_loaded_flow(event = null) {
    return this.data_loaded_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async data_loaded_flowInternal(
    $editor: Manufacturing_workshift_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_editor.data_loaded_flow');
  
  $editor.toolbar.save.control.readOnly = true;
  
  if( $editor.inParams.is_new )
  {
      $editor.title = "New workshift";
  }
  
  const workshift = $editor.entity;
  const days = [];
  if (workshift.Monday) { days.push(1); }
  if (workshift.Tuesday) { days.push(2); }
  if (workshift.Wednesday) { days.push(3); }
  if (workshift.Thursday) { days.push(4); }
  if (workshift.Friday) { days.push(5); }
  if (workshift.Saturday) { days.push(6); }
  if (workshift.Sunday) { days.push(7); }
  
  
  $editor.fields.days.control.value = days;
  $editor.fields.start_time.control.value = formatTimeFromMinutes(workshift.ShiftStartOffsetMinutes);
  let endTime = workshift.ShiftStartOffsetMinutes + workshift.DurationMinutes;
  if (endTime > 24*60) {
      endTime -= 24*60;
  }
  $editor.fields.end_time.control.value = formatTimeFromMinutes(endTime);
  
  const start_time = $editor.fields.start_time.control.value;
  const end_time = $editor.fields.end_time.control.value;
  
  if (start_time && end_time) {
      const hours = (await $flows.Manufacturing.minutes_between_times({start_time, end_time})).minutes / 60;
      $editor.fields.shift_duration.control.value = (await $flows.Manufacturing.round_to_first_decimal_digit({num: hours})).roundedNum;
  }
  
  function formatTimeFromMinutes(minutes: number): string {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = mins.toString().padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}`;
  }
  }
  save_flow(event = null) {
    return this.save_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async save_flowInternal(
    $editor: Manufacturing_workshift_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_editor.save_flow');
  
  const shiftStartOffsetMinutes = (await $flows.Manufacturing.get_total_minutes({ time: $editor.fields.start_time.control.value })).totalMinutes;
  const endTotalMinutes = (await $flows.Manufacturing.get_total_minutes({ time: $editor.fields.end_time.control.value })).totalMinutes;
  const diffInMinutes = endTotalMinutes - shiftStartOffsetMinutes;
  const days = $editor.fields.days.control.value ?? [];
  const payload = {
      Name: $editor.fields.workshift_name.control.value,
      Code: $editor.fields.code.control.value,
      WarehouseId: $editor.entity.WarehouseId,
      ShiftStartOffsetMinutes: shiftStartOffsetMinutes,
      DurationMinutes: diffInMinutes,
      Monday: days.includes(1),
      Tuesday: days.includes(2),
      Wednesday: days.includes(3),
      Thursday: days.includes(4),
      Friday: days.includes(5),
      Saturday: days.includes(6),
      Sunday: days.includes(7),
  }
  
  var result = await $flows.Utilities.crud_update_flow(
      {
      entitySet: 'WorkShifts',
      id: $editor.entity.Id,
      entity: payload
  });
  
  if (!result.reason?.length) {
      $editor.close();
  }
  }
  cancel_flow(event = null) {
    return this.cancel_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async cancel_flowInternal(
    $editor: Manufacturing_workshift_editorComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_editor.cancel_flow');
  
  if( $editor.inParams.is_new )
  {
      await $flows.Manufacturing.delete_manufacturing_workshifts_flow({
          workshift_id: $editor.inParams.workshift_id
      })
  }
  
  $editor.close();
  }
  //#endregion private flows
  //#region validation flows
  
  validate(fieldErrors: { workshift_name: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,start_time: Pick<string[], 'push'>,end_time: Pick<string[], 'push'>,shift_duration: Pick<string[], 'push'>,code: Pick<string[], 'push'>,days: Pick<string[], 'push'>,created_by: Pick<string[], 'push'>,created_on: Pick<string[], 'push'>,modified_by: Pick<string[], 'push'>,modified_on: Pick<string[], 'push'>, } = null) {
    const emptyResult = { workshift_name:[],warehouse:[],start_time:[],end_time:[],shift_duration:[],code:[],days:[],created_by:[],created_on:[],modified_by:[],modified_on:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validateInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validateInternal(
    $editor: Manufacturing_workshift_editorComponent,
    $validation:{
      fieldErrors: { workshift_name: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,start_time: Pick<string[], 'push'>,end_time: Pick<string[], 'push'>,shift_duration: Pick<string[], 'push'>,code: Pick<string[], 'push'>,days: Pick<string[], 'push'>,created_by: Pick<string[], 'push'>,created_on: Pick<string[], 'push'>,modified_by: Pick<string[], 'push'>,modified_on: Pick<string[], 'push'>, }
    },
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $context: Manufacturing_workshift_editor_ComponentContextService
  ) {
    this.$logger.log('Manufacturing', 'workshift_editor.validate');
    
  let isValid = true;
  
  const daysAreSelected = $editor.fields.days.control.value?.length;
  if (!daysAreSelected) {
      $validation.fieldErrors.days.push(`Select at least one day of the week`);
      isValid = false;
  }
  
  if (!$editor.fields.workshift_name.control.value
  || !$editor.fields.start_time.control.value
  || !$editor.fields.end_time.control.value
  || !$editor.fields.code.control.value) {
      isValid = false;
  }
  
  if ($editor.fields.code.control.value.length > 15) {
      $validation.fieldErrors.code.push(`Code length must be less than 16 characters`);
      isValid = false;
  }
  
  $editor.toolbar.save.control.readOnly = !isValid;
  
    return $validation.fieldErrors as { workshift_name:[],warehouse:[],start_time:[],end_time:[],shift_duration:[],code:[],days:[],created_by:[],created_on:[],modified_by:[],modified_on:[], };
  }
  //#endregion validation flows
  
}
