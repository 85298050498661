import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_measurement_units_grid_ComponentContextService } from './FootprintManager.inventory_measurement_units_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component'


interface IFootprintManager_inventory_measurement_units_gridComponentEntity {
  Id?: number, DimensionUomId?: number, Height?: number, Length?: number, Name?: string, ShippingWeight?: number, ShortName?: string, Weight?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }}



class FootprintManager_inventory_measurement_units_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_measurement_units_gridComponent;
  entity: IFootprintManager_inventory_measurement_units_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    short_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    length_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    width_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    height_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    dimensions_uom_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    weight_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    shipping_weight_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    weight_uom_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    short_name: null,
    length: null,
    width: null,
    height: null,
    dimensions_uom: null,
    weight: null,
    shipping_weight: null,
    weight_uom: null,
  }

  get $fields_dimensions_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_inventory_measurement_units_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_measurement_units_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.short_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.short_name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['short_name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.length = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.length,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['length_edit'] as DatexFormControl, null, false, false, '0.00', '', null)
      );
    
    this.cells.width = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.width,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['width_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.height = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.height,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['height_edit'] as DatexFormControl, null, false, false, '0.00', '', null)
      );
    
    this.cells.dimensions_uom = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.dimensions_uom,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['dimensions_uom_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['weight_edit'] as DatexFormControl, null, false, false, '0.00', '', null)
      );
    
    this.cells.shipping_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.shipping_weight,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['shipping_weight_edit'] as DatexFormControl, null, false, false, '0.00', '', null)
      );
    
    this.cells.weight_uom = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight_uom,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['weight_uom_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_inventory_measurement_units_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_measurement_units_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.Materials.ds_inventory_measurement_units_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_measurement_units_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.short_name.displayControl as TextModel).text = $row.entity.ShortName;
    (this.cells.short_name.editControl as TextBoxModel).reset($row.entity.ShortName);
    (this.cells.length.displayControl as TextModel).text = $row.entity.Length?.toString();
    (this.cells.length.editControl as NumberBoxModel).reset($row.entity.Length);
    (this.cells.width.displayControl as TextModel).text = $row.entity.Width?.toString();
    (this.cells.width.editControl as NumberBoxModel).reset($row.entity.Width);
    (this.cells.height.displayControl as TextModel).text = $row.entity.Height?.toString();
    (this.cells.height.editControl as NumberBoxModel).reset($row.entity.Height);
    (this.cells.dimensions_uom.displayControl as TextModel).text = $row.entity.DimensionsUom?.Short_name;
    (this.cells.dimensions_uom.editControl as SelectBoxModel).reset($row.entity.DimensionUomId);
    (this.cells.weight.displayControl as TextModel).text = $row.entity.Weight?.toString();
    (this.cells.weight.editControl as NumberBoxModel).reset($row.entity.Weight);
    (this.cells.shipping_weight.displayControl as TextModel).text = $row.entity.ShippingWeight?.toString();
    (this.cells.shipping_weight.editControl as NumberBoxModel).reset($row.entity.ShippingWeight);
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.weight_uom.editControl as SelectBoxModel).reset($row.entity.WeightUomId);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_inventory_measurement_units_gridComponentRowModel,
  $grid: FootprintManager_inventory_measurement_units_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_measurement_units_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'InventoryMeasurementUnit' })).nextId;
  
  $row.entity = {
      Id: nextId
  };
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_inventory_measurement_units_gridComponentRowModel,
  $grid: FootprintManager_inventory_measurement_units_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_measurement_units_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.short_name.editControl.value,
      $row.cells.length.editControl.value,
      $row.cells.width.editControl.value,
      $row.cells.height.editControl.value,
      $row.cells.weight.editControl.value,
      $row.cells.shipping_weight.editControl.value,
      $row.cells.dimensions_uom.editControl.value,
      $row.cells.weight_uom.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Materials.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // to prevent displayMode 
  }
  
  // Check for  duplicates
  const inventorymeasurementunits = (await $datasources.Materials.ds_get_material_inventory_measurements_by_shortname.get({ 
      shortname: $row.cells.short_name.editControl.value 
      })).result;
  if ($utils.isDefined(inventorymeasurementunits)) {
      $shell.Materials.openErrorDialog('Inventory measurement units creation error', 'Short name already exists.');
      return;
  
  }
  
  
  try {
  
  
      const payload = {
          "Id": $row.entity.Id,
          "Name": $row.cells.short_name.editControl.value,
          "ShortName": $row.cells.short_name.editControl.value,
          "AllowFractions": true,
          "AllowInSaleOrder": true,
          "AllowInPurchaseOrder": true,
          "IsReportingDefault": false,
          "Weight": $row.cells.weight?.editControl.value,
          "ShippingWeight": $row.cells.shipping_weight?.editControl.value,
          "WeightUomId": $row.cells.weight_uom?.editControl.value,
          "Height": $row.cells.height?.editControl.value,
          "Length": $row.cells.length.editControl.value,
          "Width": $row.cells.width.editControl.value,
          "DimensionUomId": $row.cells.dimensions_uom.editControl.value,
          "Volume": 1,
          "ShippingVolume": 1,
          "VolumeUomId": 11,
          "IsGenerated": false
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'InventoryMeasurementUnits', entity: payload });
  
      await $grid.refresh();
  
  } catch (error) {
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_inventory_measurement_units_gridComponentRowModel,
  $grid: FootprintManager_inventory_measurement_units_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_measurement_units_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($row.cells.short_name.editControl.isChanged) {
      // Check for  duplicates
      const inventorymeasurementunits = (await $datasources.Materials.ds_get_material_inventory_measurements_by_shortname.get({
          shortname: $row.cells.short_name.editControl.value
      })).result;
      if ($utils.isDefined(inventorymeasurementunits)) {
          $shell.Materials.openErrorDialog('Inventory measurement units creation error', 'Short name already exists.');
          return;
  
      }
  }
  
  // Update inventory measurement unit
  let payload: any = {};
  
  
  if ($row.cells.short_name.editControl.isChanged) {
      payload.ShortName = $row.cells.short_name.editControl.value;
  }
  if ($row.cells.length.editControl.isChanged) {
      payload.Length = $row.cells.length.editControl.value;
  }
  if ($row.cells.width.editControl.isChanged) {
      payload.Width = $row.cells.width.editControl.value;
  }
  if ($row.cells.height.editControl.isChanged) {
      payload.Height = $row.cells.height.editControl.value;
  }
  if ($row.cells.dimensions_uom.editControl.isChanged) {
      payload.DimensionUomId = $row.cells.dimensions_uom.editControl.value;
  }
  if ($row.cells.weight.editControl.isChanged) {
      payload.Weight = $row.cells.weight.editControl.value;
  }
  if ($row.cells.shipping_weight.editControl.isChanged) {
      payload.ShippingWeight = $row.cells.shipping_weight.editControl.value;
  }
  if ($row.cells.weight_uom.editControl.isChanged) {
      payload.WeightUomId = $row.cells.weight_uom.editControl.value;
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'InventoryMeasurementUnits', id: $row.entity.Id, entity: payload });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_measurement_units_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_measurement_units_grid',
  templateUrl: './FootprintManager.inventory_measurement_units_grid.component.html'
})
export class FootprintManager_inventory_measurement_units_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_measurement_units_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     short_name: new GridHeaderModel(new HeaderStyles(null, null), 'short_name', 'Short name', true, false, null, false),       length: new GridHeaderModel(new HeaderStyles(null, null), 'length', 'Length', true, false, null, false),       width: new GridHeaderModel(new HeaderStyles(null, null), 'width', 'Width', true, false, null, false),       height: new GridHeaderModel(new HeaderStyles(null, null), 'height', 'Height', true, false, null, false),       dimensions_uom: new GridHeaderModel(new HeaderStyles(null, null), 'dimensions_uom', 'Dimensions UOM', true, false, null, false),       weight: new GridHeaderModel(new HeaderStyles(null, null), 'weight', 'Weight', true, false, null, false),       shipping_weight: new GridHeaderModel(new HeaderStyles(null, null), 'shipping_weight', 'Shipping weight', true, false, null, false),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'weight_uom', 'Weight UOM', true, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_measurement_units_gridComponentRowModel[] = [];


  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_measurement_units_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Packages';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 5;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.Materials.ds_inventory_measurement_units_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_measurement_units_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_measurement_units_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IFootprintManager_inventory_measurement_units_gridComponentEntity) {
    const row = new FootprintManager_inventory_measurement_units_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
