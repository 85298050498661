import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_production_confirmation_wizard_ComponentContextService } from './FootprintManager.manufacturing_order_production_confirmation_wizard.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent } from './FootprintManager.manufacturing_order_line_production_confirmation_setup_form.component';
import { FootprintManager_manufacturing_order_line_production_confirmation_gridComponent } from './FootprintManager.manufacturing_order_line_production_confirmation_grid.component';
import { FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent } from './FootprintManager.manufacturing_order_line_components_production_confirmation_grid.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent),
    forwardRef(() => FootprintManager_manufacturing_order_line_production_confirmation_gridComponent),
    forwardRef(() => FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_production_confirmation_wizard',
  templateUrl: './FootprintManager.manufacturing_order_production_confirmation_wizard.component.html'
})
export class FootprintManager_manufacturing_order_production_confirmation_wizardComponent extends BaseComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { scheduled_run_code?: number, warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, material_id?: number, finished_good?: string, finished_good_id?: number, expected_source_location?: number, manufacturing_order_id?: number, finished_product_is_serialized?: boolean } = { scheduled_run_code: null, warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, material_id: null, finished_good: null, finished_good_id: null, expected_source_location: null, manufacturing_order_id: null, finished_product_is_serialized: null };
  //#region Inputs
  @Input('scheduled_run_code') set $inParams_scheduled_run_code(v: number) {
    this.inParams.scheduled_run_code = v;
  }
  get $inParams_scheduled_run_code(): number {
    return this.inParams.scheduled_run_code;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('output_location_id') set $inParams_output_location_id(v: number) {
    this.inParams.output_location_id = v;
  }
  get $inParams_output_location_id(): number {
    return this.inParams.output_location_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('planned_quantity') set $inParams_planned_quantity(v: number) {
    this.inParams.planned_quantity = v;
  }
  get $inParams_planned_quantity(): number {
    return this.inParams.planned_quantity;
  }
  @Input('material_id') set $inParams_material_id(v: number) {
    this.inParams.material_id = v;
  }
  get $inParams_material_id(): number {
    return this.inParams.material_id;
  }
  @Input('finished_good') set $inParams_finished_good(v: string) {
    this.inParams.finished_good = v;
  }
  get $inParams_finished_good(): string {
    return this.inParams.finished_good;
  }
  @Input('finished_good_id') set $inParams_finished_good_id(v: number) {
    this.inParams.finished_good_id = v;
  }
  get $inParams_finished_good_id(): number {
    return this.inParams.finished_good_id;
  }
  @Input('expected_source_location') set $inParams_expected_source_location(v: number) {
    this.inParams.expected_source_location = v;
  }
  get $inParams_expected_source_location(): number {
    return this.inParams.expected_source_location;
  }
  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(v: number) {
    this.inParams.manufacturing_order_id = v;
  }
  get $inParams_manufacturing_order_id(): number {
    return this.inParams.manufacturing_order_id;
  }
  @Input('finished_product_is_serialized') set $inParams_finished_product_is_serialized(v: boolean) {
    this.inParams.finished_product_is_serialized = v;
  }
  get $inParams_finished_product_is_serialized(): boolean {
    return this.inParams.finished_product_is_serialized;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_production_confirmation_wizard_ComponentContextService,
) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    this.title = 'Confirm manufacturing production';
  
    const $wizard = this;
    const $utils = this.$utils;


    this.initialized = true;
  }

  steps: {
    step0?: {
      outParams?: { workshift_id?: number, equipment_id?: number, output_location_id?: number }
    }
    step1?: {
      outParams?: { license_plate_ids?: { amountToIncrease?: number, licensePlate?: number, lotId?: number, materialId?: number, packaging?: number }[], actual_produced_quantity?: number }
    }
    step2?: {
      outParams?: { data?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[] }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.$utils;

      this._stepConfigs = [
      {
          id: 'step0',
          title: 'Confirm production setup',
          component: FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent,
          inParamsFunc: ($index?: number) => {
            return {
              scheduled_run_code: $wizard.inParams.scheduled_run_code,
              warehouse_id: $wizard.inParams.warehouse_id,
              output_location_id: $wizard.inParams.output_location_id,
              project_id: $wizard.inParams.project_id,
              planned_quantity: $wizard.inParams.planned_quantity,
              finished_good_id: $wizard.inParams.finished_good_id,
              finished_good: $wizard.inParams.finished_good,
            }
          },
          next: 'step1',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.steps.step0.outParams?.output_location_id);
          },
      },
      {
          id: 'step1',
          title: 'Confirm finished product',
          component: FootprintManager_manufacturing_order_line_production_confirmation_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              finished_good: $wizard.inParams.finished_good,
              planned_quantity: $wizard.inParams.planned_quantity,
              material_id: $wizard.inParams.material_id,
              output_location_id: $wizard.inParams.output_location_id,
            }
          },
          next: 'step2',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.steps.step1.outParams?.license_plate_ids) || $wizard.steps.step1.outParams?.license_plate_ids?.length <= 0;
          },
      },
      {
          id: 'step2',
          title: 'Confirm components',
          component: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              finished_good_id: $wizard.inParams.finished_good_id,
              warehouse_id: $wizard.inParams.warehouse_id,
              quantity_to_produce: $wizard.steps.step1.outParams?.actual_produced_quantity,
              license_plate_ids: null,
              location_id: $wizard.inParams.output_location_id,
              project_id: $wizard.inParams.project_id,
            }
          },
          nextButtonLabel: 'Confirm',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.steps.step2.outParams?.data) || $wizard.steps.step2.outParams?.data.length <= 0;
          },
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.$utils;

    await this.finish_flow();

    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
  //#region private flows
  finish_flow(event = null) {
    return this.finish_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async finish_flowInternal(
    $wizard: FootprintManager_manufacturing_order_production_confirmation_wizardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_production_confirmation_wizard_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_production_confirmation_wizard.finish_flow');
  try {
      let finishedProductSerials = [];
  
      // Creating serials for the finished product
      if ($wizard.inParams.finished_product_is_serialized) {
          for (const finishedProduct of $wizard.steps.step1.outParams.license_plate_ids) {
  
              const result = await $shell.Manufacturing.openmanufacturing_orders_create_serial_numbers_editorDialog({
                  material_id: $wizard.inParams.material_id,
                  produced_quantity: finishedProduct.amountToIncrease,
                  lot_id: finishedProduct.lotId,
                  packaging_id: finishedProduct.packaging,
                  license_plate_id: finishedProduct.licensePlate
              }, 'modal', EModalSize.Large);
  
              if (!$utils.isDefined(result.serials) || result.serials.length <= 0) {
  
                  await $shell.Utilities.openErrorDialog('Finished product is serialized', "Serial numbers must be created in order to continue");
                  throw new Error("Finished product is serialized. Serial numbers must be created in order to continue.")
              }
  
              finishedProductSerials = finishedProductSerials.concat(result.serials);
          }
      }
  
      let componentsRequiredSerials = [];
      let componentsWithRequiredSerialsCount = 0;
  
      // Checking which components are serialized so we select serials for them
      for (const componentData of $wizard.steps.step2.outParams.data) {
          const material = await $datasources.Manufacturing.ds_get_material_by_materialId.get({material_id: componentData.componentMaterialId});
          const packaging = await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({material_id: componentData.componentMaterialId, packaging_id: componentData.packageId });
          
          if( $utils.isDefined(material.result) && ( material.result.ControllerTypeId == 3 || material.result.ControllerTypeId == 4 ) )
          {
              const materialForSerial = {material_id: componentData.componentMaterialId
                                       , serial_count_needed: componentData.consumedQuantity
                                       , material_name: material.result.Name
                                       , packaging_name: $utils.isDefined(packaging.result) && packaging.result.length > 0 ? packaging.result[0].Packaging?.ShortName : ""
                                       , license_plate: componentData.sourceLicensePlate};
  
              componentsRequiredSerials.push(materialForSerial);
              componentsWithRequiredSerialsCount++;
          }
      }
  
      let componentSerials = [];
  
      // Calling the serial numbers select editor
      if( componentsWithRequiredSerialsCount > 0 )
      {
          const serialComponentsPickResult = await $shell.FootprintManager.openmanufacturing_order_components_serial_numbers_pick_editorDialog({
                  serial_controlled_materials: componentsWithRequiredSerialsCount,
                  material_ids: componentsRequiredSerials,
                  warehouse_id: $wizard.inParams.warehouse_id,
                  iteration: 1,
                  location: $wizard.inParams.expected_source_location
          }, 'modal', EModalSize.Large);
  
          if( !$utils.isDefined(serialComponentsPickResult) || serialComponentsPickResult.serial_numbers.length <= 0 )
          {
              await $shell.Utilities.openErrorDialog('Serialized components are detected', "Serial numbers must be consumed for the serialized components in order to continue.");
              throw new Error("Serialized components are detected. Serial numbers must be consumed for them in order to continue.");
          }
  
          componentSerials = serialComponentsPickResult.serial_numbers;
      }
  
      // Add inventory on the license plate for the finished product
      for (const licensePlate of $wizard.steps.step1.outParams.license_plate_ids) {
  
          if( $wizard.inParams.finished_product_is_serialized )
          {
              const serialsForCurrentProduct = finishedProductSerials.filter( item => item.LicensePlateId == licensePlate.licensePlate )
              const createdSerialNumbersForCurrentFinishedProduct = [];
  
              serialsForCurrentProduct.forEach( item => {
                  const serialForInventoryCreation = { DimensionUOM: item.DimensionUomId,
                                                       LookupCode: item.LookupCode,
                                                       NetWeightValue: item.NetWeight,
                                                       GrossWeightValue: item.GrossWeight,
                                                       WeightUOM: item.WeightUomId,
                                                       Width: item.Width,
                                                       Height: item.Height,
                                                       Length: item.Length,
                                                       NetVolumeValue: item.NetVolume,
                                                       GrossVolumeValue: item.GrossVolume,
                                                       VolumeUOM: item.VolumeUomId };
  
                  createdSerialNumbersForCurrentFinishedProduct.push( serialForInventoryCreation );
              } )
  
              const finishedGoodInventoryMove = await $flows.Inventory.create_serials_inventory_on_existing_licenseplate_flow({licenseplateId: licensePlate.licensePlate,
                                                                                                                      lotId: licensePlate.lotId,
                                                                                                                      packagingId: licensePlate.packaging,
                                                                                                                      serialNumbers: createdSerialNumbersForCurrentFinishedProduct,
                                                                                                                      reasoncodeId: 38,
                                                                                                                      operationcodeId: 7});
  
              if( $utils.isDefined(finishedGoodInventoryMove.reasons) )
              {
                  await $shell.Utilities.openErrorDialog('Error creating inventory', finishedGoodInventoryMove.reasons.toString());
                  throw new Error(finishedGoodInventoryMove.reasons.toString());
              }
          }
          else
          {
              const finishedGoodInventoryMove = await $flows.Inventory.create_inventory_on_existing_licenseplate_flow({licenseplateId: licensePlate.licensePlate,
                                                                                                                  lotId: licensePlate.lotId,
                                                                                                                  packagingId: licensePlate.packaging,
                                                                                                                  packagedAmount: licensePlate.amountToIncrease,
                                                                                                                  reasoncodeId: 38,
                                                                                                                  operationcodeId: 7});
  
  			if( $utils.isDefined(finishedGoodInventoryMove.reasons) )
  			{
  				await $shell.Utilities.openErrorDialog('Error creating inventory', finishedGoodInventoryMove.reasons.toString());
  				throw new Error(finishedGoodInventoryMove.reasons.toString());
  			}
          }                                                                                                 
      }
  
      // Remove inventory for the components from the selected license plates
      for (const componentLicensePlate of $wizard.steps.step2.outParams.data) {
  
          if( componentLicensePlate.consumedQuantity <= 0 )
              continue;
  
          // If the component is a serial controlled we will remove serial inventory, which will also archive the serials
          // else we will remove inventory from the license plate without touching any serials 
          const serialControllerComponent = componentsRequiredSerials.find( item => item.material_id == componentLicensePlate.componentMaterialId && item.license_plate == componentLicensePlate.sourceLicensePlate );
          if( $utils.isDefined(serialControllerComponent) )
          {
              let serialIdsForCurrentComponent = [];
              const serialsForCurrentComponent = componentSerials.filter( item => item.materialId == componentLicensePlate.componentMaterialId && item.licensePlate == componentLicensePlate.sourceLicensePlate );
              
              serialsForCurrentComponent.forEach( item => serialIdsForCurrentComponent.push(item.serialNumberId) );
  
              const componentInventoryMove = await $flows.Inventory.remove_serials_inventory_flow({licenseplateId: componentLicensePlate.sourceLicensePlate,
                                                                                          lotId: componentLicensePlate.lot,
                                                                                          packagingId: componentLicensePlate.packageId,
                                                                                          serialNumberIds: serialIdsForCurrentComponent,
                                                                                          reasoncodeId: 38,
                                                                                          operationcodeId: 1});
  
              if( $utils.isDefined(componentInventoryMove.reasons) )
              {
                  await $shell.Utilities.openErrorDialog('Error removing serialized inventory', componentInventoryMove.reasons.toString());
                  throw new Error(componentInventoryMove.reasons.toString());
              }     
          }
          else
          {
              const componentInventoryMove = await $flows.Inventory.remove_inventory_flow({licenseplateId: componentLicensePlate.sourceLicensePlate,
                                                                                          lotId: componentLicensePlate.lot,
                                                                                          packagingId: componentLicensePlate.packageId,
                                                                                          packagedAmount: componentLicensePlate.consumedQuantity,
                                                                                          reasoncodeId: 38,
                                                                                          operationcodeId: 1});
  
              if( $utils.isDefined(componentInventoryMove.reasons) )
              {
                  await $shell.Utilities.openErrorDialog('Error removing inventory', componentInventoryMove.reasons.toString());
                  throw new Error(componentInventoryMove.reasons.toString());
              }        
          }
      }
      
      // Creating the main task for the manufacturing production(manufacturing feedback)
      const mainTask = await $flows.Utilities.crud_create_flow({
          entitySet: "Tasks",
          entity: {
              Id: 0,
              ActualTargetLocationId: $wizard.steps.step0.outParams.output_location_id,
              ChainHead: 0,
              ExpectedSourceLocationId: $wizard.inParams.expected_source_location,
              ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
              ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
              MaterialId: $wizard.inParams.material_id,
              OperationCodeId: 55,
              StatusId: 2,
              WarehouseId: $wizard.inParams.warehouse_id
          }
      });
  
      if (!$utils.isDefined(mainTask.result))
      {
          await $shell.Utilities.openErrorDialog('Error finalizing production', mainTask.reason);
          throw new Error(mainTask.reason);
      }
  
      if ($utils.isDefined(mainTask.result)) {
          for (const licensePlate of $wizard.steps.step1.outParams.license_plate_ids) {
  
              // Creating the "Finished product created" task for the manufacturing production(manufacturing feedback)
              const finishedGoodCreateTask = await $flows.Utilities.crud_create_flow({
                  entitySet: "Tasks",
                  entity: {
                      Id: 0,
                      ActualInventoryAmount: licensePlate.amountToIncrease,
                      ActualInventoryAmountPackId: licensePlate.packaging,
                      ActualPackagedAmount: licensePlate.amountToIncrease,
                      ActualPackagedPackId: licensePlate.packaging,
                      ActualTargetLicensePlateId: licensePlate.licensePlate,
                      ActualTargetLocationId: $wizard.inParams.output_location_id,
                      ChainHead: mainTask.result.Id,
                      StatusId: 2,
                      WarehouseId: $wizard.inParams.warehouse_id,
                      LotId: licensePlate.lotId,
                      ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                      ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
                      MaterialId: $wizard.inParams.material_id,
                      OperationCodeId: 49,
                      WorkShiftId: $utils.isDefined($wizard.steps.step0.outParams.workshift_id) ? $wizard.steps.step0.outParams.workshift_id : null
                  }
              });
  
              if ($utils.isDefined(finishedGoodCreateTask.reason))
              {
                  await $shell.Utilities.openErrorDialog('Error finalizing production', finishedGoodCreateTask.reason);
                  throw new Error(finishedGoodCreateTask.reason);
              }
  
              const finishedGoodLicensePlateSerials = finishedProductSerials.filter(item => item.MaterialId == licensePlate.materialId && item.LicensePlateId == licensePlate.licensePlate);
              if (finishedGoodLicensePlateSerials.length > 0) {
                  for (const finishedGoodSerialNumber of finishedGoodLicensePlateSerials) {
  
                      const createdSerialNumber = await $datasources.SerialNumbers.ds_get_serialnumber_by_code.get({code: finishedGoodSerialNumber.LookupCode});
  
                      // Creating the "Serial created" tasks for the manufacturing production(manufacturing feedback)
                      const serialTaskCreateResult = await $flows.Utilities.crud_create_flow({
                          entitySet: "Tasks",
                          entity: {
                              Id: 0,
                              ActualInventoryAmount: 1,
                              ActualInventoryAmountPackId: licensePlate.packaging,
                              ActualPackagedAmount: 1,
                              ActualPackagedPackId: licensePlate.packaging,
                              ActualTargetLicensePlateId: licensePlate.licensePlate,
                              ActualSourceLicensePlateId: licensePlate.licensePlate,
                              ActualTargetLocationId: $wizard.inParams.output_location_id,
                              AuxiliaryExternalIdentifier: finishedGoodCreateTask.result.Id,
                              ChainHead: finishedGoodCreateTask.result.Id,
                              ExpectedInventoryAmount: 1,
                              ExpectedInventoryAmountPackId: licensePlate.packaging,
                              ExpectedPackagedAmount: 1,
                              ExpectedPackagedPackId: licensePlate.packaging,
                              StatusId: 2,
                              WarehouseId: $wizard.inParams.warehouse_id,
                              LotId: licensePlate.lotId,
                              ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                              ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
                              MaterialId: $wizard.inParams.material_id,
                              OperationCodeId: 17,
                              SerialNumberId: $utils.isDefined(createdSerialNumber.result) && createdSerialNumber.result.length >= 0 ? createdSerialNumber.result[0].Id : 0,
                              GrossWeight: finishedGoodSerialNumber.grossWeight,
                              NetWeight: finishedGoodSerialNumber.netWeight,
                              NetVolume: finishedGoodSerialNumber.netVolume,
                              GrossVolume: finishedGoodSerialNumber.grossVolume,
                              DimensionsUomId: finishedGoodSerialNumber.dimensionsUOM,
                              VolumeUomId: finishedGoodSerialNumber.volumeUOM,
                              WeightUomId: finishedGoodSerialNumber.weightUOM
                          }
                      });
  
                      if (!$utils.isDefined(serialTaskCreateResult.result)) {
                          await $shell.Utilities.openErrorDialog('Error finalizing production', serialTaskCreateResult.reason);
                          throw new Error(serialTaskCreateResult.reason);
                      }
                  }
              }
          }
  
          for (const componentData of $wizard.steps.step2.outParams.data) {
              const componentConsumedCreateTask = await $flows.Utilities.crud_create_flow({
  
                  // Creating the "Component consumed" task for the manufacturing production(manufacturing feedback)
                  entitySet: "Tasks",
                  entity: {
                      Id: 0,
                      ActualInventoryAmount: componentData.consumedQuantity,
                      ActualInventoryAmountPackId: componentData.packageId,
                      ActualPackagedAmount: componentData.consumedQuantity,
                      ActualPackagedPackId: componentData.packageId,
                      ActualSourceLicensePlateId: componentData.sourceLicensePlate,
                      ActualSourceLocationId: $wizard.inParams.expected_source_location,
                      ChainHead: mainTask.result.Id,
                      StatusId: 2,
                      WarehouseId: $wizard.inParams.warehouse_id,
                      ExpectedSourceLocationId: $wizard.inParams.expected_source_location,
                      LotId: componentData.lot,
                      ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                      ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
                      MaterialId: componentData.componentMaterialId,
                      OperationCodeId: 47
                  }
              });
  
              if ($utils.isDefined(componentConsumedCreateTask.reason)) {
                  await $shell.Utilities.openErrorDialog('Error finalizing production', componentConsumedCreateTask.reason);
                  throw new Error(componentConsumedCreateTask.reason);
              }
  
              const componentScrappedCreateTask = await $flows.Utilities.crud_create_flow({
  
                  // Creating the "Component scrapped" task for the manufacturing production(manufacturing feedback)
                  entitySet: "Tasks",
                  entity: {
                      Id: 0,
                      ActualInventoryAmount: $utils.isDefined(componentData.scrappedQuantity) ? componentData.scrappedQuantity : 0,
                      ActualInventoryAmountPackId: componentData.packageId,
                      ActualPackagedAmount: $utils.isDefined(componentData.scrappedQuantity) ? componentData.scrappedQuantity : 0,
                      ActualPackagedPackId: componentData.packageId,
                      ActualSourceLicensePlateId: componentData.sourceLicensePlate,
                      ActualSourceLocationId: $wizard.inParams.expected_source_location,
                      ChainHead: mainTask.result.Id,
                      StatusId: 2,
                      WarehouseId: $wizard.inParams.warehouse_id,
                      ExpectedSourceLocationId: $wizard.inParams.expected_source_location,
                      LotId: componentData.lot,
                      ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                      ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
                      MaterialId: componentData.componentMaterialId,
                      OperationCodeId: 48
                  }
              });
  
              if ($utils.isDefined(componentScrappedCreateTask.reason)) {
                  await $shell.Utilities.openErrorDialog('Error finalizing production', componentScrappedCreateTask.reason);
                  throw new Error(componentScrappedCreateTask.reason);
              }
  
              const purgedSerialsForMaterial = componentSerials.filter( serial => serial.materialId == componentData.componentMaterialId);
              for (const componentSerialData of purgedSerialsForMaterial) {
                  // Creating the "Serial purged" tasks for the manufacturing production(manufacturing feedback)
                  const serialTaskCreateResult = await $flows.Utilities.crud_create_flow({
                      entitySet: "Tasks",
                      entity: {
                          Id: 0,
                          ActualInventoryAmount: 1,
                          ActualInventoryAmountPackId: componentData.packageId,
                          ActualPackagedAmount: 1,
                          ActualPackagedPackId: componentData.packageId,
                          ActualSourceLicensePlateId: componentData.sourceLicensePlate,
                          AuxiliaryExternalIdentifier: componentConsumedCreateTask.result.Id,
                          ChainHead: componentConsumedCreateTask.result.Id,
                          ExpectedInventoryAmount: 1,
                          ExpectedInventoryAmountPackId: componentData.packageId,
                          ExpectedPackagedAmount: 1,
                          ExpectedPackagedPackId: componentData.packageId,
                          StatusId: 2,
                          WarehouseId: $wizard.inParams.warehouse_id,
                          LotId: componentData.lot,
                          ManufacturingOrderId: $wizard.inParams.manufacturing_order_id,
                          ManufacturingOrderLineId: $wizard.inParams.finished_good_id,
                          MaterialId: $wizard.inParams.material_id,
                          OperationCodeId: 19,
                          SerialNumberId: componentSerialData.serialNumberId,
                          GrossWeight: componentSerialData.grossWeight,
                          NetWeight: componentSerialData.netWeight,
                          NetVolume: componentSerialData.netVolume,
                          GrossVolume: componentSerialData.grossVolume,
                          DimensionsUomId: componentSerialData.dimensionsUOM,
                          VolumeUomId: componentSerialData.volumeUOM,
                          WeightUomId: componentSerialData.weightUOM
                      }
                  });
  
                  if (!$utils.isDefined(serialTaskCreateResult.result)) {
                      await $shell.Utilities.openErrorDialog('Error finalizing production', serialTaskCreateResult.reason);
                      throw new Error(serialTaskCreateResult.reason);
                  }
              }
          }
      }
  } catch (error) {
      // Get inner-most error message
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      }
  
      let message = targetError.message;
  }
  
  }
  //#endregion private flows
}
