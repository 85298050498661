import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { LaborManagement_assigned_task_operation_codes_dd_singleComponent } from './LaborManagement.assigned_task_operation_codes_dd_single.component';
import { LaborManagement_assigned_users_dd_singleComponent } from './LaborManagement.assigned_users_dd_single.component';
import { LaborManagement_equipment_types_dd_singleComponent } from './LaborManagement.equipment_types_dd_single.component';
import { LaborManagement_is_assigned_dd_singleComponent } from './LaborManagement.is_assigned_dd_single.component';
import { LaborManagement_operation_codes_dd_singleComponent } from './LaborManagement.operation_codes_dd_single.component';
import { LaborManagement_user_list_dd_singleComponent } from './LaborManagement.user_list_dd_single.component';
import { LaborManagement_warehouses_dd_singleComponent } from './LaborManagement.warehouses_dd_single.component';
import { LaborManagement_work_classes_dd_singleComponent } from './LaborManagement.work_classes_dd_single.component';
import { LaborManagement_assigned_task_operation_codes_dd_multiComponent } from './LaborManagement.assigned_task_operation_codes_dd_multi.component';
import { LaborManagement_assigned_users_dd_multiComponent } from './LaborManagement.assigned_users_dd_multi.component';
import { LaborManagement_equipment_types_dd_multiComponent } from './LaborManagement.equipment_types_dd_multi.component';
import { LaborManagement_is_assigned_dd_multiComponent } from './LaborManagement.is_assigned_dd_multi.component';
import { LaborManagement_operation_codes_dd_multiComponent } from './LaborManagement.operation_codes_dd_multi.component';
import { LaborManagement_user_list_dd_multiComponent } from './LaborManagement.user_list_dd_multi.component';
import { LaborManagement_warehouses_dd_multiComponent } from './LaborManagement.warehouses_dd_multi.component';
import { LaborManagement_work_classes_dd_multiComponent } from './LaborManagement.work_classes_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public LaborManagement: LaborManagement_ShellService = this;


  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'LaborManagement_assigned_task_operation_codes_dd_single') {
      this.$logger.log('LaborManagement', 'assigned_task_operation_codes_dd_single');
      const title = 'assigned_task_operation_codes_dd';
      const component = LaborManagement_assigned_task_operation_codes_dd_singleComponent;
      const inParams:{ warehouseIds?: number[], workClassIds?: number[], ownerId?: number, projectId?: number, fromDate?: string, toDate?: string } = { warehouseIds: [], workClassIds: [], ownerId: null, projectId: null, fromDate: null, toDate: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('workClassIds'))) {
        const paramValueString = params.get('workClassIds');
        inParams.workClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_assigned_users_dd_single') {
      this.$logger.log('LaborManagement', 'assigned_users_dd_single');
      const title = 'assigned_users_dd';
      const component = LaborManagement_assigned_users_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_equipment_types_dd_single') {
      this.$logger.log('LaborManagement', 'equipment_types_dd_single');
      const title = 'equipment_types_dd';
      const component = LaborManagement_equipment_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_is_assigned_dd_single') {
      this.$logger.log('LaborManagement', 'is_assigned_dd_single');
      const title = 'is_assigned_dd';
      const component = LaborManagement_is_assigned_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_operation_codes_dd_single') {
      this.$logger.log('LaborManagement', 'operation_codes_dd_single');
      const title = 'operation_codes_dd';
      const component = LaborManagement_operation_codes_dd_singleComponent;
      const inParams:{ excludeIds?: number[] } = { excludeIds: [] };
      if (!isNil(params.get('excludeIds'))) {
        const paramValueString = params.get('excludeIds');
        inParams.excludeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_user_list_dd_single') {
      this.$logger.log('LaborManagement', 'user_list_dd_single');
      const title = 'user_list_dd';
      const component = LaborManagement_user_list_dd_singleComponent;
      const inParams:{ restrictToOperationCodes?: number[] } = { restrictToOperationCodes: [] };
      if (!isNil(params.get('restrictToOperationCodes'))) {
        const paramValueString = params.get('restrictToOperationCodes');
        inParams.restrictToOperationCodes = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_warehouses_dd_single') {
      this.$logger.log('LaborManagement', 'warehouses_dd_single');
      const title = 'warehouses_dd';
      const component = LaborManagement_warehouses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_work_classes_dd_single') {
      this.$logger.log('LaborManagement', 'work_classes_dd_single');
      const title = 'work_classes_dd';
      const component = LaborManagement_work_classes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_assigned_task_operation_codes_dd_multi') {
      this.$logger.log('LaborManagement', 'assigned_task_operation_codes_dd_multi');
      const title = 'assigned_task_operation_codes_dd';
      const component = LaborManagement_assigned_task_operation_codes_dd_multiComponent;
      const inParams:{ warehouseIds?: number[], workClassIds?: number[], ownerId?: number, projectId?: number, fromDate?: string, toDate?: string } = { warehouseIds: [], workClassIds: [], ownerId: null, projectId: null, fromDate: null, toDate: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('workClassIds'))) {
        const paramValueString = params.get('workClassIds');
        inParams.workClassIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('fromDate'))) {
        const paramValueString = params.get('fromDate');
        // TODO: date
        inParams.fromDate = paramValueString;
              }
      if (!isNil(params.get('toDate'))) {
        const paramValueString = params.get('toDate');
        // TODO: date
        inParams.toDate = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_assigned_users_dd_multi') {
      this.$logger.log('LaborManagement', 'assigned_users_dd_multi');
      const title = 'assigned_users_dd';
      const component = LaborManagement_assigned_users_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_equipment_types_dd_multi') {
      this.$logger.log('LaborManagement', 'equipment_types_dd_multi');
      const title = 'equipment_types_dd';
      const component = LaborManagement_equipment_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_is_assigned_dd_multi') {
      this.$logger.log('LaborManagement', 'is_assigned_dd_multi');
      const title = 'is_assigned_dd';
      const component = LaborManagement_is_assigned_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_operation_codes_dd_multi') {
      this.$logger.log('LaborManagement', 'operation_codes_dd_multi');
      const title = 'operation_codes_dd';
      const component = LaborManagement_operation_codes_dd_multiComponent;
      const inParams:{ excludeIds?: number[] } = { excludeIds: [] };
      if (!isNil(params.get('excludeIds'))) {
        const paramValueString = params.get('excludeIds');
        inParams.excludeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_user_list_dd_multi') {
      this.$logger.log('LaborManagement', 'user_list_dd_multi');
      const title = 'user_list_dd';
      const component = LaborManagement_user_list_dd_multiComponent;
      const inParams:{ restrictToOperationCodes?: number[] } = { restrictToOperationCodes: [] };
      if (!isNil(params.get('restrictToOperationCodes'))) {
        const paramValueString = params.get('restrictToOperationCodes');
        inParams.restrictToOperationCodes = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_warehouses_dd_multi') {
      this.$logger.log('LaborManagement', 'warehouses_dd_multi');
      const title = 'warehouses_dd';
      const component = LaborManagement_warehouses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LaborManagement_work_classes_dd_multi') {
      this.$logger.log('LaborManagement', 'work_classes_dd_multi');
      const title = 'work_classes_dd';
      const component = LaborManagement_work_classes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
