import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_temperature_readings_grid_ComponentContextService } from './FootprintManager.temperature_readings_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_temperature_readings_gridComponentEntity {
  Id?: number, DateCaptured?: string, Employee?: string, LicensePlateId?: number, LoadContainerId?: number, Notes?: string, ReadingPointId?: number, ReadingTypeId?: number, ShipmentId?: number, ShipmentLineId?: number, Temperature1?: number, Temperature2?: number, Temperature3?: number, LicensePlate?: { LookupCode?: string }, LoadContainer?: { LookupCode?: string }, ReadingPoint?: { Name?: string }, ReadingType?: { Name?: string }, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number }[] }, ShipmentLine?: { LineNumber?: number }, Temperature1MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature2MeasurementUnit?: { Name?: string, Short_name?: string }, Temperature3MeasurementUnit?: { Name?: string, Short_name?: string }}

interface IFootprintManager_temperature_readings_gridComponentInParams {
  loadContainerId?: number, shipmentId?: number, licensePlateId?: number, orderId?: number}


class FootprintManager_temperature_readings_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_temperature_readings_gridComponent;
  entity: IFootprintManager_temperature_readings_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    DateCaptured: null,
    Employee: null,
    Temperature1: null,
    Temperature2: null,
    Temperature3: null,
    Temperature1MeasurementUnit_Short_name: null,
    ReadingPoint_Name: null,
    ReadingType_Name: null,
    Notes: null,
    LicensePlate_LookupCode: null,
    LoadContainer_LookupCode: null,
    Shipment_LookupCode: null,
    ShipmentLine_LineNumber: null,
  }



  constructor(
    grid: FootprintManager_temperature_readings_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_temperature_readings_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.DateCaptured = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.DateCaptured,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Employee = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Employee,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Temperature1 = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Temperature1,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Temperature2 = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Temperature2,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Temperature3 = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Temperature3,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Temperature1MeasurementUnit_Short_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Temperature1MeasurementUnit_Short_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ReadingPoint_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ReadingPoint_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ReadingType_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ReadingType_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.LicensePlate_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.LicensePlate_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.LoadContainer_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.LoadContainer_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Shipment_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Shipment_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ShipmentLine_LineNumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ShipmentLine_LineNumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_temperature_readings_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_temperature_readings_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      shipmentId:  $grid.inParams.shipmentId ,
      shipmentLineId:  null ,
      licensePlateId:  $grid.inParams.licensePlateId ,
      loadContainerId:  $grid.inParams.loadContainerId ,
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.$datasources.TemperatureReadings.ds_temperature_readings_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_temperature_readings_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.DateCaptured.displayControl as TextModel).text = $row.entity.DateCaptured?.toString();
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.Temperature1.displayControl as TextModel).text = $row.entity.Temperature1?.toString();
    (this.cells.Temperature2.displayControl as TextModel).text = $row.entity.Temperature2?.toString();
    (this.cells.Temperature3.displayControl as TextModel).text = $row.entity.Temperature3?.toString();
    (this.cells.Temperature1MeasurementUnit_Short_name.displayControl as TextModel).text = $row.entity.Temperature1MeasurementUnit?.Short_name;
    (this.cells.ReadingPoint_Name.displayControl as TextModel).text = $row.entity.ReadingPoint?.Name;
    (this.cells.ReadingType_Name.displayControl as TextModel).text = $row.entity.ReadingType?.Name;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.LicensePlate_LookupCode.displayControl as TextModel).text = $row.entity.LicensePlate?.LookupCode;
    (this.cells.LoadContainer_LookupCode.displayControl as TextModel).text = $row.entity.LoadContainer?.LookupCode;
    (this.cells.Shipment_LookupCode.displayControl as TextModel).text = $row.entity.Shipment?.LookupCode;
    (this.cells.ShipmentLine_LineNumber.displayControl as TextModel).text = $row.entity.ShipmentLine?.LineNumber?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_temperature_readings_gridComponentRowModel,
  $grid: FootprintManager_temperature_readings_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_temperature_readings_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // O.Arias - 07/21/2023
  
  // Set Date/Time Formats
  const format = `${$settings.FootprintManager.DateFormat}, ${$settings.FootprintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.DateCaptured)) {
          $row.cells.DateCaptured.displayControl.text = $utils.date.format($row.entity.DateCaptured, format);
      };
  };
  
  // Set the shipment id variable
  if (!$utils.isDefined($grid.vars.shipmentId)) {
      if ($utils.isDefined($row.entity.ShipmentId)) {
          $grid.vars.shipmentId = $row.entity.ShipmentId
      };
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-temperature_readings_grid',
  templateUrl: './FootprintManager.temperature_readings_grid.component.html'
})
export class FootprintManager_temperature_readings_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_temperature_readings_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootprintManager_temperature_readings_gridComponentInParams = { loadContainerId: null, shipmentId: null, licensePlateId: null, orderId: null };


  //#region Variables
  vars: { shipmentId?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     DateCaptured: new GridHeaderModel(new HeaderStyles(null, null), 'DateCaptured', 'Date', false, false, null, false),       Employee: new GridHeaderModel(new HeaderStyles(null, null), 'Employee', 'Employee', false, false, null, false),       Temperature1: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature1', 'T1', false, false, null, false),       Temperature2: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature2', 'T2', false, false, null, false),       Temperature3: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature3', 'T3', false, false, null, false),       Temperature1MeasurementUnit_Short_name: new GridHeaderModel(new HeaderStyles(null, null), 'Temperature1MeasurementUnit_Short_name', 'UOM', false, false, null, false),       ReadingPoint_Name: new GridHeaderModel(new HeaderStyles(null, null), 'ReadingPoint_Name', 'Point', false, false, null, false),       ReadingType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'ReadingType_Name', 'Type', false, false, null, false),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', 'Notes', false, false, null, false),       LicensePlate_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'LicensePlate_LookupCode', 'LP', false, false, null, false),       LoadContainer_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'LoadContainer_LookupCode', 'Load container', false, false, null, false),       Shipment_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Shipment_LookupCode', 'Shipment', false, false, null, false),       ShipmentLine_LineNumber: new GridHeaderModel(new HeaderStyles(null, null), 'ShipmentLine_LineNumber', 'Shipment line', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_temperature_readings_gridComponentRowModel[] = [];

  @Input('loadContainerId') set $inParams_loadContainerId(value: any) {
    this.inParams['loadContainerId'] = value;
  }
  get $inParams_loadContainerId(): any {
    return this.inParams['loadContainerId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('licensePlateId') set $inParams_licensePlateId(value: any) {
    this.inParams['licensePlateId'] = value;
  }
  get $inParams_licensePlateId(): any {
    return this.inParams['licensePlateId'] ;
  }
  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      new_reading: new ToolModel(new ButtonModel('new_reading', new ButtonStyles(null, null), false, false, false, 'New reading', 'icon-ic_fluent_add_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_reading: new ToolModel(new ButtonModel('delete_reading', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_temperature_readings_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Temperature readings';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 15;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shipmentId:  $grid.inParams.shipmentId ,
      shipmentLineId:  null ,
      licensePlateId:  $grid.inParams.licensePlateId ,
      loadContainerId:  $grid.inParams.loadContainerId ,
      orderId:  $grid.inParams.orderId ,
    };
    try {
      const data = await this.$datasources.TemperatureReadings.ds_temperature_readings_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_temperature_readings_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_temperature_readings_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_temperature_readings_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: FootprintManager_temperature_readings_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_temperature_readings_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/19/2023
  
  let shipmentId = $grid.inParams.shipmentId
  
  //Set the shipment id variable
  if (!$utils.isDefined(shipmentId)) {
  
      if ($utils.isDefined($grid.vars.shipmentId)) {
          shipmentId = $grid.vars.shipmentId;
      } else {
  
          if ($utils.isDefined($grid.inParams.orderId)) {
              let shipments = (await $datasources.SalesOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
              if ($utils.isDefined(shipments)) {
                  shipmentId = shipments[0].ShipmentId;
              };
          };
      };
  };
  
  await $shell.FootprintManager.opentemperature_capture_formDialog({ shipmentId: shipmentId, loadContainerId: $grid.inParams.loadContainerId, licensePlateId: $grid.inParams.licensePlateId }, 'flyout', EModalSize.Large);
  
  $grid.refresh();
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintManager_temperature_readings_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_temperature_readings_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/19/2023
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Error removing readings', 'No readings selected.');
      return;
  } else {
  
      const candidates = [];
      const failures = [];
      const errors = [];
  
      for (const row of selectedRows) {
  
          //No validation needed, we'll populate the candidates right away
  
          candidates.push(row);
  
      };
  
      //No candidate
      if (candidates.length === 0) {
          const title = 'Error removing readings';
          const errorMessage = `Readings(s) ${failures.map(c => c.entity.Id).join(',')} cannot be removed`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
  
          const confirmCandidates = `Readings(s) ${candidates.map(c => c.entity.Id).join(',')} - will be deleted.`
  
          let confirm = false;
  
  
          if (failures.length >= 1) {
  
              const title = 'Some Readings(s) cannot be removed';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
  
          } else {
  
              const title = 'Remove the following readings(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
  
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
  
                      await $flows.Utilities.crud_delete_flow({entitySet: "TemperatureReadings", id: candidate.entity.Id});
                      candidateSuccess.push(candidate);
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Readings ${candidate.entity.Id} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              //All succeeded
              if (candidateSuccess.length === candidates.length) {
  
                  const title = 'All Readings(s) removed';
                  const message = `Readings(s) ${candidateSuccess.map(c => c.entity.Id).join(',')} removed`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $grid.refresh();
  
              } else {
  
                  //All failures
                  if (candidateFailures.length === candidates.length) {
  
                      const title = 'All readings(s) failed to be removed';
                      const message = `Readings(s) ${candidateFailures.map(c => c.entity.Id).join(',')} could not be removed`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
                  } else {
  
                      const title = 'Some readings(s) could not be removed';
                      const success = `Readings(s) ${candidateSuccess.map(c => c.entity.Id).join(',')} were removed.`;
                      const errors = `The following readings(s) could not be removed: ${candidateFailures.map(c => c.entity.Id).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
  
                  };
              };
          };
      };
  };
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
