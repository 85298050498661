import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService } from './Manufacturing.manufacturing_order_return_to_stock_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_licenseplates_by_location_singleComponent } from './Manufacturing.licenseplates_by_location_single.component'


interface IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity {
  lineNumber?: number, component?: string, lot?: string, remainingQuantity?: number, componentPackageId?: number, countedQuantity?: number, materialId?: number, lotId?: number}

interface IManufacturing_manufacturing_order_return_to_stock_gridComponentInParams {
  manufacturing_order_line_id?: number, warehouse_id?: number, location_id?: number, manufacturing_order_id?: number, task_chain_head?: number}


class Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel extends GridRowModel {
  grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent;
  entity: IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    counted_quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    license_plate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    line_number: null,
    component: null,
    lot: null,
    remaining_quantity: null,
    counted_quantity: null,
    difference: null,
    new_inventory_quantity: null,
    license_plate: null,
  }

  get $fields_license_plate_selector_inParams_location_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.location_id;
    
    return expr;
  }


  constructor(
    grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.line_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.component = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.component,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.remaining_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.remaining_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.counted_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.counted_quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['counted_quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.difference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.difference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.new_inventory_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.new_inventory_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.license_plate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.license_plate,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['license_plate_edit'] as DatexFormControl, 
        null, null,
        true,
        false,
        '', null)
      );
    
    
    this.formGroup
      .controls['counted_quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.counted_quantity_changed();
      });
  }

  async $initializeExisting(entity: IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.lineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.lineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.lineNumber;
    const inParams = {
      $keys:[$resultKey],
    };
    const data = await this.$datasources.Manufacturing.ds_manufacturing_order_return_to_stock_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_new_row_saving();
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  counted_quantity_changed(event = null) {
    return this.counted_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async counted_quantity_changedInternal(
    $row: Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid.counted_quantity_changed');
  
  if(!$utils.isDefined($row.entity.remainingQuantity) || !$utils.isDefined($row.cells.counted_quantity.editControl.value))
  {
      $row.cells.difference.displayControl.text = "";
      $row.cells.new_inventory_quantity.displayControl.text = "";
      $row.cells.license_plate.editControl.readOnly = true;
      return;
  }
  
  if( $row.entity.remainingQuantity != $row.cells.counted_quantity.editControl.value )
  {
      $row.cells.difference.styles.setStyle('color', 'rgb(224, 134, 0)');
      $row.cells.difference.displayControl.text = `\u26A0 ${$row.cells.counted_quantity.editControl.value - $row.entity.remainingQuantity}`;
      $row.cells.license_plate.editControl.readOnly = false;
  }
  else
  {
      $row.cells.new_inventory_quantity.displayControl.text = "";
      $row.cells.difference.displayControl.text = "";
      $row.cells.license_plate.editControl.readOnly = true;
  }
  
  if( $row.cells.counted_quantity.editControl.value - $row.entity.remainingQuantity > 0 )
  {
      $row.cells.new_inventory_quantity.displayControl.text = ($row.cells.counted_quantity.editControl.value - $row.entity.remainingQuantity).toString();
  }
  else if( $row.cells.counted_quantity.editControl.value - $row.entity.remainingQuantity < 0 )
  {
      $row.cells.new_inventory_quantity.displayControl.text = $row.cells.counted_quantity.editControl.value;
  }
  else
  {
      $row.cells.new_inventory_quantity.displayControl.text = "";
      $row.cells.license_plate.editControl.readOnly = true;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid.on_row_data_loaded');
  
  }
  on_new_row_saving(event = null) {
    return this.on_new_row_savingInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_row_savingInternal(
    $row: Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel,
  $grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent, 
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid.on_new_row_saving');
  if( ( Number($row.cells.new_inventory_quantity.displayControl.text) < 0 || Number($row.cells.new_inventory_quantity.displayControl.text) > 0 ) && !$utils.isDefined($row.cells.license_plate.editControl.value) )
  {
      $shell.Utilities.openErrorDialog("Select license plate", "License plate must be selected for components with difference in remaining and counted inventory.");
      throw new Error();
  }
  
  const lot = await $datasources.Manufacturing.ds_get_lots_by_materialIds.get({materialIds:[$row.entity.materialId], lot_id: $row.entity.lotId});
  
  if( $utils.isDefined(lot.result) )
  {
      $row.cells.lot.displayControl.text = lot.result[0].LookupCode;
  }
  
  $row.cells.counted_quantity.displayControl.text = $row.cells.counted_quantity.editControl.value;
  
  const licensePlate = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $grid.inParams.location_id, license_plate_ids: [$row.cells.license_plate.editControl.value]});
  
  if( $utils.isDefined(licensePlate.result) )
  {
      $row.cells.license_plate.displayControl.text = licensePlate.result[0].LookupCode;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_licenseplates_by_location_singleComponent),
  ],
  selector: 'Manufacturing-manufacturing_order_return_to_stock_grid',
  templateUrl: './Manufacturing.manufacturing_order_return_to_stock_grid.component.html'
})
export class Manufacturing_manufacturing_order_return_to_stock_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IManufacturing_manufacturing_order_return_to_stock_gridComponentInParams = { manufacturing_order_line_id: null, warehouse_id: null, location_id: null, manufacturing_order_id: null, task_chain_head: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'line_number', '#', false, false, null, false),       component: new GridHeaderModel(new HeaderStyles(null, null), 'component', 'Component', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       remaining_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'remaining_quantity', 'Remaining quantity', false, false, null, false),       counted_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'counted_quantity', 'Counted quantity', true, false, null, false),       difference: new GridHeaderModel(new HeaderStyles(null, null), 'difference', 'Difference', false, false, null, false),       new_inventory_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'new_inventory_quantity', 'Quantity to return', false, false, null, false),       license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'license_plate', 'License plate', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel[] = [];

  @Input('manufacturing_order_line_id') set $inParams_manufacturing_order_line_id(value: any) {
    this.inParams['manufacturing_order_line_id'] = value;
  }
  get $inParams_manufacturing_order_line_id(): any {
    return this.inParams['manufacturing_order_line_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('location_id') set $inParams_location_id(value: any) {
    this.inParams['location_id'] = value;
  }
  get $inParams_location_id(): any {
    return this.inParams['location_id'] ;
  }
  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(value: any) {
    this.inParams['manufacturing_order_id'] = value;
  }
  get $inParams_manufacturing_order_id(): any {
    return this.inParams['manufacturing_order_id'] ;
  }
  @Input('task_chain_head') set $inParams_task_chain_head(value: any) {
    this.inParams['task_chain_head'] = value;
  }
  get $inParams_task_chain_head(): any {
    return this.inParams['task_chain_head'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Manufacturing_ShellService,
    private $datasources: Manufacturing_DatasourceService,
    private $flows: Manufacturing_FlowService,
    private $reports: Manufacturing_ReportService,
    private $localization: Manufacturing_LocalizationService,
    private $operations: Manufacturing_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Components';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_manufacturing_order_return_to_stock_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IManufacturing_manufacturing_order_return_to_stock_gridComponentEntity) {
    const row = new Manufacturing_manufacturing_order_return_to_stock_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Manufacturing_manufacturing_order_return_to_stock_gridComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_manufacturing_order_return_to_stock_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'manufacturing_order_return_to_stock_grid.on_grid_data_loaded');
  
  $grid.bottomToolbar.addLine.hidden = true;
  
  const components = await $datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.get({finished_good_id: $grid.inParams.manufacturing_order_line_id, warehouse_id: $grid.inParams.warehouse_id});
  const confirmedProductionRows = await $datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.get({manufacturing_order_id: $grid.inParams.manufacturing_order_id
                                                                                                                              , manufacturing_order_line_id: $grid.inParams.manufacturing_order_line_id
                                                                                                                              , operation_code: 47
                                                                                                                              , task_chain_head: $grid.inParams.task_chain_head});
  
  if( !$utils.isDefined(components.result) || !$utils.isDefined(confirmedProductionRows.result) )
      return;
  
  let index = 0;
  
  for (const confirmedComponent of confirmedProductionRows.result) {
      index++;
  
      const newRow = await $grid.addRow();
  
      const component = components.result.find(item => item.MaterialId == confirmedComponent.MaterialId);
  
      newRow.cells.component.displayControl.text = confirmedComponent.Material?.Name;
      newRow.cells.line_number.displayControl.text = index.toString();
  
      const remainingQuantity = component.PackagedAmount - confirmedComponent.ActualPackagedAmount < 0 ? 0 : component.PackagedAmount - confirmedComponent.ActualPackagedAmount;
  
      newRow.entity.remainingQuantity = remainingQuantity;
      newRow.entity.materialId = component.MaterialId;
  
      const lot = await $datasources.Manufacturing.ds_get_lots_by_materialIds.get({materialIds: [confirmedComponent.MaterialId], lot_id: confirmedComponent.LotId});
  
      if($utils.isDefined(lot.result))
      {
          newRow.cells.lot.displayControl.text = lot.result[0].LookupCode;
      }
  
      newRow.cells.remaining_quantity.displayControl.text = `${remainingQuantity} ${component?.Material?.PackagingLookups?.find(item => item.PackagingId == component.PackagingId)?.Packaging?.ShortName}`;
      newRow.cells.counted_quantity.editControl.focus();
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
