import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  TemplateRef,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StepConfig } from './components/wizard.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_quick_wizard_ComponentContextService } from './FootprintManager.inventory_transformation_quick_wizard.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_transformation_creation_formComponent } from './FootprintManager.inventory_transformation_creation_form.component';
import { FootprintManager_inventory_transformation_source_line_editorComponent } from './FootprintManager.inventory_transformation_source_line_editor.component';
import { FootprintManager_inventory_transformation_additional_options_formComponent } from './FootprintManager.inventory_transformation_additional_options_form.component';
import { FootprintManager_inventory_transformation_target_line_editorComponent } from './FootprintManager.inventory_transformation_target_line_editor.component';
import { FootprintManager_inventory_transformation_source_lines_gridComponent } from './FootprintManager.inventory_transformation_source_lines_grid.component';


@Component({
  standalone: true,
  imports: [
    SharedModule,

    forwardRef(() => FootprintManager_inventory_transformation_creation_formComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_line_editorComponent),
    forwardRef(() => FootprintManager_inventory_transformation_additional_options_formComponent),
    forwardRef(() => FootprintManager_inventory_transformation_target_line_editorComponent),
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_gridComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_quick_wizard',
  templateUrl: './FootprintManager.inventory_transformation_quick_wizard.component.html'
})
export class FootprintManager_inventory_transformation_quick_wizardComponent extends BaseComponent implements OnInit, OnChanges {
  _stepConfigs: StepConfig[];

  inParams: { is_quick_transformation: boolean } = { is_quick_transformation: null };
  //#region Inputs
  @Input('is_quick_transformation') set $inParams_is_quick_transformation(v: boolean) {
    this.inParams.is_quick_transformation = v;
  }
  get $inParams_is_quick_transformation(): boolean {
    return this.inParams.is_quick_transformation;
  }
  //#endregion Inputs

  //#region Outputs
  @Output() 
  $commandsTmpRef = new EventEmitter<TemplateRef<any>>();
  @Output()
  $finish = new EventEmitter();
  outParams: { inventory_transformation_id?: number } = { inventory_transformation_id: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { is_properties_initialized?: boolean, source_line_id?: number, isContentValidated?: boolean, isPropertiesValidated?: boolean, processQueue?: string[], warehouse_id?: number, target_line_id?: number, executing?: boolean } = { };
  //#endregion
  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_quick_wizard_ComponentContextService,
) { 
    super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.is_quick_transformation)) {
        this.$missingRequiredInParams.push('is_quick_transformation');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Quick transformation a material';
  
    const $wizard = this;
    const $utils = this.$utils;

    await this.on_init();

    this.initialized = true;
  }

  steps: {
    step1?: {
      outParams?: { inventory_transformation?: { id: number, warehouse_id: number, project_id: number } }
    }
    step2?: {
      outParams?: { source_line_id?: number }
    }
    additional_options?: {
      outParams?: { additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } }
    }
    step4?: {
      outParams?: { target_line_id?: number }
    }
    step6?: {
      outParams?: {  }
    }
  } = { };

  stepsResultFunc(result: any) {
    this.steps = result;
  }
  
  getStepConfigs(): StepConfig[] {
    if (!this._stepConfigs) {
      const $wizard = this;
      const $utils = this.$utils;

      this._stepConfigs = [
      {
          id: 'step1',
          title: 'Owner and project',
          component: FootprintManager_inventory_transformation_creation_formComponent,
          inParamsFunc: ($index?: number) => {
            return {
              projectId: null,
              ownerId: null,
              warehouseId: null,
              quick_transform: $wizard.inParams.is_quick_transformation,
            }
          },
          next: 'step2',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.steps.step1.outParams?.inventory_transformation?.id);
          },
      },
      {
          id: 'step2',
          title: 'Original material',
          component: FootprintManager_inventory_transformation_source_line_editorComponent,
          inParamsFunc: ($index?: number) => {
            return {
              project_id: $wizard.steps.step1.outParams.inventory_transformation.project_id,
              warehouse_id: $wizard.steps.step1.outParams.inventory_transformation.warehouse_id,
              inventory_transformation_id: $wizard.steps.step1.outParams.inventory_transformation.id,
              source_line_id: null,
            }
          },
          next: 'additional_options',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard?.vars?.source_line_id);
          },
      },
      {
          id: 'additional_options',
          title: 'Additional options',
          component: FootprintManager_inventory_transformation_additional_options_formComponent,
          inParamsFunc: ($index?: number) => {
            return {
              inventory_transformation_id: $wizard?.steps?.step1?.outParams?.inventory_transformation?.id,
              source_line_id: $wizard.vars.source_line_id,
            }
          },
          next: 'step4',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard?.vars?.source_line_id) || !$utils.isDefined($wizard?.steps?.additional_options?.outParams?.additional_options);
          },
      },
      {
          id: 'step4',
          title: 'Inventory to transform',
          component: FootprintManager_inventory_transformation_target_line_editorComponent,
          inParamsFunc: ($index?: number) => {
            return {
              inventory_transformation_id: $wizard.steps.step1.outParams.inventory_transformation.id,
              source_line_id: $wizard.vars.source_line_id,
              projectId: $wizard.steps.step1.outParams.inventory_transformation.project_id,
              warehouse_id: $wizard.steps.step1.outParams.inventory_transformation.warehouse_id,
              additional_options: $wizard.steps.additional_options.outParams.additional_options,
            }
          },
          next: 'step6',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return !$utils.isDefined($wizard.vars.target_line_id);
          },
      },
      {
          id: 'step6',
          title: 'Summary',
          component: FootprintManager_inventory_transformation_source_lines_gridComponent,
          inParamsFunc: ($index?: number) => {
            return {
              inventory_transformation_id: $wizard.steps.step1.outParams.inventory_transformation.id,
              line_number: $wizard.vars.source_line_id,
              project_id: $wizard.steps.step1.outParams.inventory_transformation.project_id,
              warehouse_id: $wizard.steps.step1.outParams.inventory_transformation.warehouse_id,
              inventory_transformation_status_id: null,
              source_read_only: true,
              target_read_only: true,
            }
          },
          nextButtonLabel: 'Finish and complete',
          nextButtonDisabledConditionFunc: ($index?: number) => {
            return $wizard.vars.executing;
          },
      },
      ];
    }

    return this._stepConfigs;
  }

  async finish() {
    const $wizard = this;
    const $utils = this.$utils;

    await this.on_finish();

    this.close();
  }

  close() {
    this.$finish.emit();
  }

  commandsTmpRefChange(tmp: any) {
    this.$commandsTmpRef.emit(tmp);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $wizard: FootprintManager_inventory_transformation_quick_wizardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_quick_wizard_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_quick_wizard.on_init');
  //run function to listen output from editor
  await add_events() 
  
  /************************************************
   * FUNCTIONS
  *************************************************/
  
  
  
  
  async function add_events() {
      await new Promise(result => setTimeout(result, 100));
  
      // Get element to attach to 
      const element = document?.getElementsByTagName('FootprintManager-inventory_transformation_quick_wizard')[0]; ///change to FootprintManager-inventory_transformation_advanced_wizard
  
      if (element == null) {
          // throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      // 'on' events to detect different stages of the wizard
      element?.addEventListener('on_inventory_transformation_source_line_editor_initialized', ((event?: CustomEvent | null) => { }) as EventListener);
  
  
      // 'handle' events to perform common actions that may be invoked
      element?.addEventListener('handle_source_line_value', ((event?: CustomEvent<number[]> | null) => {
          $wizard.vars.source_line_id = event?.detail[0] ?? null
      }) as EventListener);
  
  
      // 'on' events to detect different stages of the wizard
      element?.addEventListener('on_inventory_transformation_target_line_editor_initialized', ((event?: CustomEvent | null) => { }) as EventListener);
  
  
      // 'handle' events to perform common actions that may be invoked
      element?.addEventListener('handle_target_line_value', ((event?: CustomEvent<number> | null) => {
          $wizard.vars.target_line_id = event?.detail ?? null
      }) as EventListener);
  
  }
  
  
  }
  on_finish(event = null) {
    return this.on_finishInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finishInternal(
    $wizard: FootprintManager_inventory_transformation_quick_wizardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_quick_wizard_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_quick_wizard.on_finish');
  
  
  $wizard.outParams.inventory_transformation_id = $wizard.steps.step1.outParams.inventory_transformation.id
  $wizard.vars.executing = false
  
  try {
  
      $wizard.vars.executing = true
  
      $shell.InventoryTransformations.openInfoDialog('Inventory transformation completion in progress.','Please wait a moment while the inventory transformation is being completed.')
  
      let process_transformation = (await $flows.InventoryTransformations.process_inventory_transformation_flow({ inventory_transformation_id: $wizard.outParams.inventory_transformation_id }))
  
      if (process_transformation?.reason) {
          throw Error(process_transformation?.reason)
      }
  
  
      let execute_transformation = (await $flows.InventoryTransformations.execute_inventory_transformation_flow({ inventory_transformation_id: $wizard.outParams.inventory_transformation_id }))
  
      if (execute_transformation?.reason) {
          throw Error(execute_transformation?.reason)
      }
  
      $wizard.vars.executing = false
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on quick transformation.', error);
  }
  
  
  }
  //#endregion private flows
}
