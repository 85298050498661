import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Manufacturing_ModuleContextService } from './Manufacturing.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Manufacturing_manufacturing_staging_locations_dd_ComponentContextService {
  private readonly moduleContext: Manufacturing_ModuleContextService;

  constructor(moduleContext: Manufacturing_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'manufacturing_staging_locations_dd',
      type: ComponentType.selector
    }
  }
}